<template>
  <div>
    <template v-if="error">
      <div class="error-msg">
        {{ error }}
      </div>
    </template>
    <template v-else>
      <div class="users-container">
        <el-table v-loading="loading" :data="users" empty-text="This site has no users" :cell-style="cellStyle">
          <el-table-column type="expand">
            <template #default="props">
              <div class="access-list" :class="{ disabled: props.row.disabled }">
                <span v-if="props.row.disabled"> This user's account has been disabled </span>
                <template v-if="props.row.access_list.projects.length > 0">
                  <h3>Projects</h3>
                  <p v-for="project in props.row.access_list.projects" :key="project.id">
                    {{ project.name }}
                  </p>
                </template>
                <template v-if="props.row.access_list.dashboards.length > 0">
                  <h3>Dashboards</h3>
                  <el-tree
                    :data="dashboardArrayToTree(props.row.access_list.dashboards)"
                    :props="{ children: 'children', label: 'name' }"
                    default-expand-all
                  >
                    <template #default="{ node, data }">
                      <span class="custom-tree-node">
                        <template v-if="node.isLeaf">
                          <ul>
                            <li>
                              {{ node.label }}
                            </li>
                          </ul>
                        </template>
                        <template v-else>
                          <span>{{ node.label }}</span>
                        </template>
                      </span>
                    </template>
                  </el-tree>
                </template>
                <template
                  v-if="props.row.access_list.dashboards.length == 0 && props.row.access_list.projects.length == 0"
                >
                  <p>User has not been added to anything</p>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Name" sortable />
          <el-table-column prop="email" label="Email" :min-width="150" sortable />
          <el-table-column label="Last Login" prop="last_login" sortable>
            <template #default="scope">
              {{ scope.row.last_login ? date(scope.row.last_login) : 'N/A' }}
            </template>
          </el-table-column>
          <el-table-column prop="user_type" label="User Type" width="160px" sortable>
            <template #default="scope">
              <user-type-select :value="scope.row.user_type" @change="setPermission($event, scope.row)" />
            </template>
          </el-table-column>
          <el-table-column align="center" width="50px">
            <template #default="scope">
              <el-dropdown v-if="scope.row.user_id !== currentUserId" trigger="click">
                <i class="more-options kapiche-icon-dot-menu-vertical"></i>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="handleDeleteClicked(scope.row.id)"> Delete </el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.disabled" @click="setDisabled(scope.row, false)">
                      Enable
                    </el-dropdown-item>
                    <el-dropdown-item v-if="!scope.row.disabled" @click="setDisabled(scope.row, true)">
                      Disable
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <el-dialog :model-value="showDeleteModal" center :close-on-click-modal="false" @close="deleteClearAndClose">
      <template #header>
        <h2>Do you want to delete a user?</h2>
      </template>
      <div class="delete-body">
        <p>
          Deleting a user will remove their access from the system entirely.
          <br />Any projects for which they are the only user will have all admins granted access.
        </p>
      </div>
      <template #footer>
        <div class="actions">
          <bf-button size="big" @click="deleteClearAndClose"> No </bf-button>
          <bf-button color="red" size="big" :loading="true" @click="deleteUser"> Yes, delete the user </bf-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BfButton } from 'components/Butterfly'
import UserTypeSelect from 'components/account/UserList/UserTypeSelect.vue'
import Site, { Acl } from 'src/api/site'
import FormatUtils from 'src/utils/formatters'
import { arrayToTree } from 'src/utils/arrayToElTree'

export default defineComponent({
  name: 'SiteUsersList',
  components: { BfButton, UserTypeSelect },
  props: {
    currentUserId: { type: Number, default: undefined },
  },
  data() {
    return {
      loading: true as boolean,
      error: undefined as undefined | string,
      users: [] as Acl[],
      showDeleteModal: false as boolean,
      userToDelete: undefined as undefined | number,
      loadingDelete: false as boolean,
    }
  },
  mounted(): void {
    this.fetchUserData()
  },
  methods: {
    date(d: string): string {
      return d === null ? 'Never' : FormatUtils.date(d)
    },
    dashboardArrayToTree(dashboardData: Acl['access_list']['dashboards']) {
      const tree = arrayToTree(dashboardData, 'analysis_id', 'analysis_name', 'project_id', 'project_name')

      return tree.map((project) => {
        project.name = project.name + ' (Project)'
        project.children?.map((analysis) => {
          analysis.name = analysis.name + ' (Analysis)'
          analysis.children?.map((dashboard) => {
            dashboard.name = dashboard.name + ' (Dashboard)'
            return dashboard
          })
          return analysis
        })
        return project
      })
    },
    async setPermission(new_type: 'ADMIN' | 'VIEWER' | 'ANALYST', acl: Acl): Promise<void> {
      const { id, user_type, email } = acl
      await Site.setUserType(id, new_type)
      this.$analytics.track.site.userTypeChange(email, user_type, new_type)
      if (!this) return
      this.$emit('user-changed')
      this.fetchUserData()
    },
    async setDisabled(acl: Acl, disabled: boolean) {
      const { id, email } = acl
      await Site.setUserDisabled(id, disabled)
      this.$analytics.track.site.userAccountDisabled(email, disabled)
      if (!this) return
      this.$emit('user-changed')
      this.fetchUserData()
    },
    async fetchUserData(): Promise<void> {
      this.loading = true
      try {
        const response = await this._fetch()
        this.users = response
      } catch (e) {
        this.error = 'Unable to fetch user data'
      } finally {
        this.loading = false
      }
    },
    _fetch(): Promise<Acl[]> {
      return Site.getUserMembershipsV2()
    },
    deleteClearAndClose() {
      this.userToDelete = undefined
      this.loadingDelete = false
      this.showDeleteModal = false
    },
    handleDeleteClicked(membershipId: number) {
      this.showDeleteModal = true
      this.userToDelete = membershipId
    },
    async deleteUser() {
      this.loadingDelete = true
      if (!this.userToDelete) return
      try {
        await Site.deleteUserMembership(this.userToDelete)
        this.deleteClearAndClose()
        this.$emit('user-changed')
        this.fetchUserData()
      } finally {
        this.loadingDelete = false
      }
    },
    cellStyle({
      row,
      column,
      rowIndex,
      columnIndex,
    }: {
      row: Acl
      column: object
      rowIndex: number
      columnIndex: number
    }) {
      // We want to show the disabled state for every cell in the row except
      // the one with the menu to indicate that re-enabling the user is possible
      if (columnIndex === 5) return
      return row.disabled ? { opacity: 0.45 } : {}
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.spinner
  width: 100%
  min-height: 300px
  justify-content: center

.access-list
  padding: 5px 55px
  font-weight: normal
  h3
    font-size: 16px
    font-weight: bold
    color: $text-black

.delete-body
  text-align: center
  font-size: 16px

.actions
  padding-top: 15px
  button
    margin-right: 20px

.custom-tree-node
  font-weight: normal
  ul
    padding-left: 5px

    li::marker
      color: #c0c4cc

.disabled
  color: $red
  font-weight: bold

::v-deep
  .el-table__cell > .cell
    text-overflow: clip !important
  .el-dialog__footer
    padding-bottom: 20px
</style>
