import { DateRangeTypeEnum, DateRangeConfig } from 'src/types/DashboardTypes'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import dayjs, { extend } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'
extend(customParseFormat)
extend(utc)
extend(quarterOfYear)

export const DATE_FORMAT = 'DD-MMM-YYYY'

export const formatDate = (date: Date, format = DATE_FORMAT): string => dayjs(date).format(format)
export const strToDate = (date: string, format = DATE_FORMAT): Date => dayjs(date, format).toDate()

export const dateRangeToFilter = (
  dateField: string,
  type: DateRangeTypeEnum,
  from: string,
  to: string,
): ChrysalisFilter[] => {
  let filters: ChrysalisFilter[] = []
  if (!dateField) return filters
  switch (type) {
    case DateRangeTypeEnum.ALL_TIME: {
      break
    }
    case DateRangeTypeEnum.CUSTOM: {
      filters = datesToFilters(dateField, from, to)
      break
    }
    default: {
      filters = dynamicDateToFilter(dateField, type)
    }
  }
  return filters
}

export const calcRangeDates = (): { [key: string]: { from: string; to: string } } => {
  const today = dayjs().endOf('day').format(DATE_FORMAT)
  return {
    TODAY: { from: today, to: today },
    [DateRangeTypeEnum.LAST_7_DAYS]: {
      from: dayjs().subtract(7, 'day').startOf('day').format(DATE_FORMAT),
      to: today,
    },
    [DateRangeTypeEnum.LAST_14_DAYS]: {
      from: dayjs().subtract(14, 'day').startOf('day').format(DATE_FORMAT),
      to: today,
    },
    [DateRangeTypeEnum.LAST_30_DAYS]: {
      from: dayjs().subtract(30, 'day').startOf('day').format(DATE_FORMAT),
      to: today,
    },
    [DateRangeTypeEnum.LAST_90_DAYS]: {
      from: dayjs().subtract(90, 'day').startOf('day').format(DATE_FORMAT),
      to: today,
    },
    [DateRangeTypeEnum.LAST_12_MONTHS]: {
      from: dayjs().subtract(12, 'month').startOf('day').format(DATE_FORMAT),
      to: today,
    },
    [DateRangeTypeEnum.LAST_WEEK]: {
      from: dayjs().subtract(1, 'week').startOf('week').format(DATE_FORMAT),
      to: dayjs().subtract(1, 'week').endOf('week').format(DATE_FORMAT),
    },
    [DateRangeTypeEnum.LAST_MONTH]: {
      from: dayjs().subtract(1, 'month').startOf('month').format(DATE_FORMAT),
      to: dayjs().subtract(1, 'month').endOf('month').format(DATE_FORMAT),
    },
    [DateRangeTypeEnum.LAST_QUARTER]: {
      from: dayjs().subtract(1, 'quarter').startOf('quarter').format(DATE_FORMAT),
      to: dayjs().subtract(1, 'quarter').endOf('quarter').format(DATE_FORMAT),
    },
    [DateRangeTypeEnum.LAST_YEAR]: {
      from: dayjs().subtract(1, 'year').startOf('year').format(DATE_FORMAT),
      to: dayjs().subtract(1, 'year').endOf('year').format(DATE_FORMAT),
    },
    [DateRangeTypeEnum.THIS_WEEK]: {
      from: dayjs().startOf('week').format(DATE_FORMAT),
      to: dayjs().endOf('week').format(DATE_FORMAT),
    },
    [DateRangeTypeEnum.THIS_MONTH]: {
      from: dayjs().startOf('month').format(DATE_FORMAT),
      to: dayjs().endOf('month').format(DATE_FORMAT),
    },
    [DateRangeTypeEnum.THIS_YEAR]: {
      from: dayjs().startOf('year').format(DATE_FORMAT),
      to: dayjs().endOf('year').format(DATE_FORMAT),
    },
  }
}

export const dateRangeHints = {
  TODAY: { from: 'Today', to: 'Today' },
  [DateRangeTypeEnum.LAST_7_DAYS]: { from: '7 days ago', to: 'Today' },
  [DateRangeTypeEnum.LAST_14_DAYS]: { from: '14 days ago', to: 'Today' },
  [DateRangeTypeEnum.LAST_30_DAYS]: { from: '30 days ago', to: 'Today' },
  [DateRangeTypeEnum.LAST_90_DAYS]: { from: '90 days ago', to: 'Today' },
  [DateRangeTypeEnum.LAST_12_MONTHS]: { from: '12 months ago', to: 'Today' },
  [DateRangeTypeEnum.LAST_WEEK]: { from: 'Start of last week', to: 'End of last week' },
  [DateRangeTypeEnum.LAST_MONTH]: { from: 'Start of last month', to: 'End of last month' },
  [DateRangeTypeEnum.LAST_QUARTER]: { from: 'Start of last quarter', to: 'End of last quarter' },
  [DateRangeTypeEnum.LAST_YEAR]: { from: 'Start of last year', to: 'End of last year' },
  [DateRangeTypeEnum.THIS_WEEK]: { from: 'Start of current week', to: 'End of current week' },
  [DateRangeTypeEnum.THIS_MONTH]: { from: 'Start of current month', to: 'End of current month' },
  [DateRangeTypeEnum.THIS_YEAR]: { from: 'Start of current year', to: 'End of current year' },
}

export const dynamicDateToFilter = (dateField: string, type: DateRangeTypeEnum): ChrysalisFilter[] => {
  const endDate = calcRangeDates()[type].to
  const startDate = calcRangeDates()[type].from
  return datesToFilters(dateField, startDate, endDate)
}

export const datesToFilters = (dateField: string, startDate: string, endDate: string): ChrysalisFilter[] => {
  // Note: `.utc(true)` basically says treat this as a UTC datetime but don't adjust it.
  // Avoids unintended adjustment when using `toISOString`
  let filters: ChrysalisFilter[] = []
  if (startDate) {
    filters.push({
      field: dateField,
      value: dayjs(startDate, DATE_FORMAT).utc(true).startOf('day').toISOString().replace('Z', ''),
      op: '>=',
    })
  }
  if (endDate) {
    filters.push({
      field: dateField,
      value: dayjs(endDate, DATE_FORMAT).utc(true).endOf('day').toISOString().replace('Z', ''),
      op: '<=',
    })
  }
  return filters
}

export const filterToRange = (filters: ChrysalisFilter[]) => {
  if (!filters?.length) return { type: DateRangeTypeEnum.ALL_TIME }

  // determine dateFrom
  const dateFromFilter = filters.find((f) => f.op === '>=')
  const dateFrom = dateFromFilter?.value ? dayjs(`${dateFromFilter.value}`, 'YYYY-MM-DD').format(DATE_FORMAT) : ''

  // determine dateTo
  const dateToFilter = filters.find((f) => f.op === '<=')
  const dateTo = dateToFilter?.value ? dayjs(`${dateToFilter.value}`, 'YYYY-MM-DD').format(DATE_FORMAT) : ''

  // determine dateField
  const dateField = dateFromFilter?.field ?? dateToFilter?.field

  return {
    type: DateRangeTypeEnum.CUSTOM,
    dateTo,
    dateFrom,
    dateField,
  }
}

/**
 Generates a textual description of the differences between the two date range configs
 */
export const dateRangeDiffDesc = (original: DateRangeConfig, current: DateRangeConfig) => {
  /*
    Three array element are:
    1. Date Field.  Only if there is a change from one first to another.
    2. From Date description.
    3. To date description.
  */
  enum Field {
    TYPE,
    FROM,
    TO,
  }
  let desc: { [key: string]: [string, string, string] } = {
    original: ['', '', ''],
    current: ['', '', ''],
  }

  // the date field changes
  if (original.dateField !== current.dateField) {
    desc.original[Field.TYPE] = original.dateField || ''
    desc.current[Field.TYPE] = current.dateField || ''
  }

  const type_change = original.type !== current.type
  const from_change = original.dateFrom !== current.dateFrom
  const to_change = original.dateTo !== current.dateTo

  // the range type changes
  if (type_change) {
    desc.original[Field.FROM] =
      original.type === DateRangeTypeEnum.CUSTOM ? `${original.dateFrom || 'All time'}` : original.type || ''
    desc.current[Field.FROM] =
      current.type === DateRangeTypeEnum.CUSTOM ? `${current.dateFrom || 'All time'}` : current.type
    desc.original[Field.TO] =
      original.type === DateRangeTypeEnum.CUSTOM ? `${original.dateTo || 'All time'}` : original.type
    desc.current[Field.TO] =
      current.type === DateRangeTypeEnum.CUSTOM ? `${current.dateTo || 'All time'}` : current.type
  }

  // the type is CUSTOM & doesn't change, but dateFrom or dateTo does change
  if (!type_change && (from_change || to_change) && original.type === DateRangeTypeEnum.CUSTOM) {
    desc.original[Field.FROM] = original.dateFrom || 'All time'
    desc.current[Field.FROM] = current.dateFrom || 'All time'
    desc.original[Field.TO] = original.dateTo || 'All time'
    desc.current[Field.TO] = current.dateTo || 'All time'
  }

  ;['original', 'current'].forEach((range) => {
    // add `:` before type if necessary
    if (desc[range][Field.TYPE] && (desc[range][Field.FROM] || desc[range][Field.FROM])) {
      desc[range][Field.TYPE] = `${desc[range][Field.TYPE]}:`
    }
    // prefix or clear TO field
    if (desc[range][Field.FROM] == desc[range][Field.TO]) {
      desc[range][Field.TO] = ''
    } else {
      desc[range][Field.TO] = `to ${desc[range][Field.TO]}`
    }
  })

  return {
    original: desc.original.join(' ').trim(),
    current: desc.current.join(' ').trim(),
  }
}

export const calculateDateWithUTCOffset = (date: string | number) => {
  // date parsed in will likely be milliseconds since epoc
  // Parse the date using dayjs. This will give us a dayjs date object
  // with a UTC timezone attached
  // e.g. Date Sat Sep 30 2023 15:00:00 GMT-0900 (Hawaii-Aleutian Daylight Time)
  const dateString = typeof date === 'number' ? date.toString() : date
  const dateInMillis = parseInt(dateString, 10)
  const parsedDate = dayjs.utc(dateInMillis)
  // Set the time to the beginning of the day
  const startOfDay = parsedDate.startOf('day')
  // Convert the result to a JavaScript Date object
  return startOfDay.toDate()
}
