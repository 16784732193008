<template>
  <modal :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Add your theme to dashboards</div>
    </template>
    <div class="content">
      <dashboard-selection
        :dashboard-list="dashboardList"
        :can-add-to-dashboard="canAddToDashboard"
        :dashboard-ids="selectedDashboards"
        @update="selectedDashboards = $event"
      />
    </div>
    <template #buttons>
      <div class="modal-actions">
        <bf-button color="grey" size="large" @click="$emit('close')"> Cancel </bf-button>
        <bf-button color="blue" size="large" @click="update"> Apply </bf-button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, watch, nextTick } from 'vue'
import { BfButton } from 'components/Butterfly'
import Modal from 'components/Modal.vue'
import DashboardSelection from './DashboardSelection.vue'
import { uniq } from 'lodash'

interface Dashboard {
  id: number
  name: string
}

const ThemeNameModal = defineComponent({
  components: {
    BfButton,
    Modal,
    DashboardSelection,
  },
  props: {
    visible: { type: Boolean, required: true },
    dashboardList: { type: Array as PropType<Dashboard[]>, required: true },
    canAddToDashboard: { type: Boolean, required: true },
    dashboardIds: { type: Array as PropType<number[]>, required: true },
  },
  setup(props, { emit }) {
    const selectedDashboards = ref<number[]>(props.dashboardIds ?? [])

    // Set form to current query values when modal is opened
    watch(
      () => props.visible,
      (newVal, oldVal) => {
        if (!oldVal && newVal) {
          nextTick(() => {
            selectedDashboards.value = props.dashboardIds?.slice() ?? []
            selectedDashboards.value = uniq(selectedDashboards.value)
          })
        }
      },
    )

    const update = () => {
      emit('update-theme', { dashboard_ids: selectedDashboards.value })
    }

    return {
      update,
      selectedDashboards,
    }
  },
})

export default ThemeNameModal
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.add-to-dashboards
  .select a
    margin-left: 10px

  a
    white-space: nowrap
    img
      display: inline-block
  .disabled
    a
      opacity: 0.5
      pointer-events: none
  ul
    list-style: none
    padding: 0

.modal-actions
  display: flex
  justify-content: center
  margin-top: 30px
  > *:not(:last-child)
    margin-right: 20px
</style>
