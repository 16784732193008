<template>
  <div v-if="hasNPS" class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">{{ title }}</span>
      <span class="right floated squares title">
        <bf-button v-if="featureFlags.dev_mode" size="tiny" @click="showingRawData = !showingRawData">
          <i class="icon wrench" />{{ showingRawData ? 'hide' : 'show' }} data
        </bf-button>
        <div class="query_1 square"></div>
        <span class="label">QUERY 1</span>
        <div class="query_2 square"></div>
        <span class="label">QUERY 2</span>
      </span>
    </div>
    <div class="ui body segment">
      <div v-if="showingRawData">
        <ul>
          <li>this.nps: {{ nps }}</li>
          <li>this.queryResultCounts: {{ queryResultCounts }}</li>
          <li>this.queries: {{ queries }}</li>
          <li>this.waiting: {{ waiting }}</li>
          <hr />
          <li>this.overallNpsScores: {{ overallNpsScores }}</li>
          <li>this.npsScores: {{ npsScores }}</li>
          <li>this.otherScores: {{ otherScores }}</li>
          <li>this.impactScores: {{ impactScores }}</li>
        </ul>
      </div>
      <div class="ui four column grid nps-statistics">
        <div class="row statline">
          <!-- Loading Mask -->
          <template v-if="loading">
            <div v-for="x in [1, 2, 3, 4]" :key="x" class="column center aligned">
              <div class="stat-box left animated-background"></div>
              <div class="stat-box right animated-background"></div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="score in ['nps', 'promoters', 'passives', 'detractors']"
              :key="score"
              class="column center aligned"
            >
              <div class="stat-box left" :class="score === 'nps' ? 'nps-score' : 'bar-divider'">
                <tiny-bar v-if="score !== 'nps'" :score="npsScores[0][score]" :colour="colorQuery[0]"></tiny-bar>
                <span class="value query_0-colour right-aligned">
                  <template v-if="score === 'nps'">
                    {{ formatNPS(npsScores[0][score]) }}
                  </template>
                  <template v-else> {{ npsScores[0][score] }}% </template>
                </span>
              </div>
              <div class="stat-box right" :class="{ 'nps-score': score === 'nps' }">
                <tiny-bar v-if="score !== 'nps'" :score="npsScores[1][score]" :colour="colorQuery[1]"></tiny-bar>
                <span class="value query_1-colour left-aligned">
                  <template v-if="score === 'nps'">
                    {{ formatNPS(npsScores[1][score]) }}
                  </template>
                  <template v-else> {{ npsScores[1][score] }}% </template>
                </span>
              </div>
            </div>
          </template>
        </div>

        <div class="row labels">
          <div class="column center aligned">
            <div class="label">NPS</div>
          </div>
          <div class="column center aligned">
            <div class="label">Promoters</div>
          </div>
          <div class="column center aligned">
            <div class="label">Passives</div>
          </div>
          <div class="column center aligned">
            <div class="label">Detractors</div>
          </div>
        </div>
      </div>

      <!-- Impact Scores -->
      <div class="impact-container">
        <div class="ui divider"></div>
        <div class="impact-scores">
          <div class="impact-score-text">Impact on NPS: &nbsp;</div>
          <div v-if="loading" class="animated-background impact-loading"></div>
          <div v-else class="stat-box parent">
            <div class="stat-box left nps-score">
              <span class="value query_0-colour right-aligned bar-divider"> {{ formatNPS(impactScores[0]) }} </span>
            </div>
            <div class="stat-box right nps-score">
              <span class="value query_1-colour right-aligned"> {{ formatNPS(impactScores[1]) }} </span>
            </div>
          </div>
        </div>
        <!-- Adjusted NPS scores -->
        <div v-if="loading" class="animated-background subtext-loading"></div>
        <div v-else>
          <div v-for="(impact, index) in impactScores" :key="`impact_${index}_${impact}`">
            <div class="subtext">
              If the records matching <span :class="`query_${index}-colour`">Query{{ index + 1 }}</span> didn’t exist,
              your overall NPS would
              <template v-if="impact === 0">
                remain at <span class="other-nps">{{ overallNpsScores.nps }}</span
                >.
              </template>
              <template v-else>
                be
                <span class="other-nps">{{ otherScores[index].nps }}</span>
                instead of <span class="other-nps">{{ overallNpsScores.nps }}</span
                >.
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import TinyBar from 'components/project/analysis/results/widgets/TinyBar.vue'
import { BfButton } from 'components/Butterfly'
import Query from 'src/api/query'
import { calculateNPS, calculateNPSImpact, calculateOtherNPS } from 'src/utils/nps'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { TinyBar, BfButton },
  props: {
    clickable: { type: Boolean, default: true },
    title: { type: String, default: 'Net Promoter Score (NPS)' },
    queries: { type: Array, required: false, default: () => [] },
    queryResultCounts: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      waiting: 0 as number,
      showingRawData: false,
      npsScores: [],
      impactScores: [],
      otherScores: [],
      colorQuery: ['rgb(17, 172, 223)', 'rgb(248, 149, 22)'],
    }
  },
  computed: {
    ...mapGetters([
      'currentProject',
      'currentAnalysis',
      'hasNPS',
      'currentModel',
      'featureFlags',
      'nps',
      'savedQueries',
    ]),
    overallNpsScores(): { [key: string]: string | number } {
      return calculateNPS(this.nps.detractors, this.nps.passives, this.nps.promoters, true)
    },
    loading(): boolean {
      return this.waiting > 0 || this.npsScores.length === 0 || this.npsScores.length !== this.queries.length
    },
  },
  watch: {
    queries() {
      this.$nextTick(() => {
        this.executeNpsQueries()
      })
    },
  },
  mounted() {
    this.executeNpsQueries()
  },
  methods: {
    formatNPS: FormatUtils.formatNPS,
    async getQueryNPS(query) {
      try {
        this.waiting += 1
        const result = await Query.getNpsData(
          this.currentProject.id,
          this.currentAnalysis.id,
          this.currentProject.chrysalis_ref,
          this.currentAnalysis.topic_framework_id,
          query,
          this.savedQueries,
          this.featureFlags,
        )
        const queryNpsFreq = {
          detractors: result.detractors,
          passives: result.passives,
          promoters: result.promoters,
        }
        return queryNpsFreq
      } catch (e) {
        return null
      } finally {
        this.waiting -= 1
      }
    },
    async executeNpsQueries(): Promise<void> {
      if (this.hasNPS === true) {
        this.queries.forEach(async (query, index) => {
          this.waiting += 1
          try {
            const freq = await this.getQueryNPS(query)
            const scores = calculateNPS(freq.detractors, freq.passives, freq.promoters, true)
            const impact = calculateNPSImpact(
              { ...this.nps, totalCount: this.currentModel.stats.n_documents },
              { ...freq, totalCount: this.queryResultCounts[index] },
              true,
            )
            const otherScores = calculateOtherNPS(
              { ...this.nps, totalCount: this.currentModel.stats.n_documents },
              { ...freq, totalCount: this.queryResultCounts[index] },
              true,
            )
            this.npsScores[index] = scores
            this.impactScores[index] = impact
            this.otherScores[index] = otherScores
          } finally {
            this.waiting -= 1
          }
        })
      }
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.ui.segment.header
  .right.floated.squares.title
    margin-right: 33px
    display: flex
    span.label
      color: rgba(56, 56, 56, 0.9)
      font-size: 11px
      font-weight: bold
    .square
      border-radius: 3px
      margin-left: 20px
      margin-right: 10px
      width: 20px
      height: 20px
      &.query_1
        background-color: rgb(17, 172, 223) !important
      &.query_2
        background-color: rgb(248, 149, 22) !important

div.segment
  padding: 0
  .query_0-colour
    color: rgb(17, 172, 223) !important
    font-weight: bold
  .query_1-colour
    color: rgb(248, 149, 22) !important
    font-weight: bold

  div.nps-statistics
    min-height: 225px
    width: 100%
    .row
      &.statline
        width: 100%
        margin-top: 30px
        .column
          display: flex
          height: 100px /* Height based on maximum bar height to allow divider line to read as a max*/
      &.labels
        padding-top: 0
        padding-bottom: 50px
  .stat-box
    display: flex
    padding-top: 0
    width: 50%
    height: 100%
    &.parent
      width: auto

    &.left
      padding-right: 2.5%
      flex-direction: column
      justify-content: flex-end
      align-items: flex-end
    &.right
      flex-direction: column
      padding-left: 2.5%
      justify-content: flex-end
      align-items: flex-start
    /* To get the truncated dividing line */
    &.nps-score
      padding-left: 0
      padding-right: 0
      &.left
        .value
          padding-right: 4px
      &.right
        .value
          padding-left: 4px
    .value
      font-weight: bold
      margin-top: 10px
      font-size: 24px
      align-items: center
    .label
      font-size: 16px
      color: rgb(56, 56, 56)
    .row.label
      height: 20px

  .bar-divider
    border-right: 1px solid rgb(229, 229, 229)

  div.impact-container
    font-size: rem(16px)
    padding: 0 rem(20px) rem(30px) rem(20px)
    .impact-score-text
      display: flex
      align-items: center
      margin-right: 10px
    .value
      margin-top: 0 !important
    .animated-background.subtext-loading
      height: 20px
      margin-left: 0
      width: 700px
    .animated-background.impact-loading
      display: inline-block
      height: 20px
      width: 80px
    .impact-score
      font-size: rem(26px)
      font-weight: bold
      padding-left: rem(10px)
      .negative
        color: $red
      .positive
        color: $green
    .other-nps
      font-weight: bold
    .subtext
      padding-top: rem(10px)

  .subtext
    color: $text-grey
  div.value
    font-weight: bold !important
  .impact-scores
    display: flex
  .animated-background
    height: 3rem
    margin-left: 1.5rem
    margin-right: 1.5rem
</style>
