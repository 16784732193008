<template>
  <modal :visible="isOpen" :click-to-close="closeOnBackdropClick" @close="$emit('close')">
    <template #header>
      <slot name="header" />
    </template>
    <template #content>
      <slot
        :will-close="willClose"
        :will-not-close="willNotClose"
        :validation-override="validationDisabled ? {} : undefined"
      />
    </template>
    <template #buttons>
      <slot name="buttons" />
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from 'components/Modal.vue'

/**
 * Renders a Modal context to interact with a vee-validate v4 Form
 *
 * Example usage from a parent component:
 * <template>
 *  <FormModal
 *    v-slot="{ willClose, willNotClose, validationOverride }"
 *    :is-open="isOpen"
 *    @close="close"
 *  >
 *    <Form
 *      ref="updateForm"
 *      v-slot="{ meta: { valid, pending }, isSubmitting }"
 *      :validation-schema="validationOverride"
 *      :initial-values="initialValues"
 *      :on-submit="updateFormHandler.then(close)"
 *    >
 *      <Field
 *        name="title"
 *        label="Title"
 *        rules="required|min:1|max:200"
 *        v-slot="{ field, errors }"
 *      >
 *        <label>
 *          <span>Title: </span>
 *          <bf-text-input v-bind="field" :errors="errors" placeholder="Enter title..." />
 *        <label>
 *      </Field>
 *      <bf-button
 *        type="button"
 *        @mousedown="willClose"
 *        @mouseleave="willNotClose"
 *        @click="close"
 *      >
 *        Cancel
 *      </bf-button>
 *      <bf-button
 *        type="submit"
 *        :disabled="
 *          !valid ||     // disable submit button if form validation is invalid (a form field does not pass validation)
 *          pending ||    // disable submit button if form validation is pending (asynchronous validation rule)
 *          isSubmitting  // disable submit button if form submit handler is running (asynchronous backend request)
 *        "
 *      >
 *        Update
 *      </bf-button>
 *    </Form>
 *  </FormModal>
 *  <button @click="open">
 *    Edit title
 *  </button>
 * </template>
 * <script setup>
 *  import { ref } from 'vue'
 *  const isOpen = ref(false)
 *  function open () { isOpen.value = true }
 *  function close () { isOpen.value = false }
 * < /script>
 */
const FormModal = defineComponent({
  name: 'FormModal',
  components: { Modal },
  inheritAttrs: false,
  props: {
    isOpen: { type: Boolean, required: false, default: false },
    closeOnBackdropClick: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      validationDisabled: false as boolean,
    }
  },
  watch: {
    // reset modal state when form opens
    isOpen(value) {
      if (value) {
        this.validationDisabled = false
      }
    },
  },
  methods: {
    willClose() {
      this.validationDisabled = true
    },
    willNotClose() {
      this.validationDisabled = false
    },
  },
})

export default FormModal
</script>
