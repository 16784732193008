<template>
  <modal :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Delete Dashboard</div>
    </template>
    <template #content>
      <div class="delete-dashboard">
        <div class="info-msg">
          <p>
            Delete "<strong>{{ currentDashboard.name }}</strong
            >"?
          </p>
          <p>{{ usersWithAccess }} people (not including you) will lose access.</p>
          <p>This action cannot be undone.</p>
        </div>
        <div class="buttons">
          <bf-button size="big" @click="$emit('close')"> Cancel </bf-button>
          <bf-button color="red" size="big" :disabled="isDeleteDashboardPending" @click="deleteDashboard">
            Delete
          </bf-button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { SET_DASHBOARD, SET_ERRORS } from 'src/store/types'
import { Analysis } from 'src/types/AnalysisTypes'
import { Dashboard } from 'src/types/DashboardTypes'
import ProjectAPI from 'src/api/project'
import Modal from 'components/Modal.vue'
import BfButton from 'src/components/Butterfly/BfButton/BfButton.vue'

export default defineComponent({
  name: 'DeleteModal',
  components: {
    Modal,
    BfButton,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    usersWithAccess: {
      type: Number,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const currentDashboard = computed(() => store.getters.currentDashboard as Dashboard)
    const currentAnalysis = computed(() => store.getters.currentAnalysis as Analysis)

    const isDeleteDashboardPending = ref(false)

    const deleteDashboard = async (): Promise<void> => {
      try {
        isDeleteDashboardPending.value = true
        const newId = currentAnalysis.value.dashboards?.find(({ id }) => id !== currentDashboard.value.id)?.id
        await ProjectAPI.deleteDashboard(currentDashboard.value.id)
        store.commit(SET_DASHBOARD, null)
        // close modal before changing Dashboards
        emit('close')
        // load first Dashboard in current analysis
        router.push({
          name: 'analysis-workbench',
          params: {
            ...route.params,
            dashboardId: newId,
          },
        })
      } catch (errors) {
        // close modal (handle error generally)
        emit('close')
        store.dispatch({ type: SET_ERRORS, errors })
      } finally {
        isDeleteDashboardPending.value = false
      }
    }

    return {
      isDeleteDashboardPending,
      currentDashboard,
      deleteDashboard,
    }
  },
})
</script>
<style lang="scss" scoped>
.buttons {
  text-align: center;
  margin-top: 20px;
}
</style>
