<template>
  <div id="network-wrapper" :style="{ height: `${height}px` }" class="ui segment">
    <interaction-menu
      v-for="idx in Object.keys(nodes)"
      :key="idx"
      :get-parent-element="nodeEls[idx]"
      :has-content="hasMenu"
    >
      <slot name="interaction-menu" :label="nodeNames[idx]"></slot>
    </interaction-menu>
    <simple-tooltip :x="hoverX" :y="hoverY" :visible="hovered">
      <template #content>
        <div v-if="hoverNode" style="width: 300px">
          <div v-if="clickableNodes" style="font-size: 13px; color: #95a6ac">
            {{ nodeTooltipClickText }}
          </div>
          <h4 style="margin-top: 0">
            {{ hoverNode.name || truncate }}
          </h4>
          <table style="border-top: 1px solid rgba(149, 166, 172, 0.5); margin-top: 10px; padding-top: 10px">
            <tbody>
              <tr>
                <td>{{ nodeTooltipVerbatimText }}</td>
                <td style="font-weight: bold">
                  {{ hoverNode.frequency }}/{{ model.n_frames }} ({{
                    decimalAsPercent(hoverNode.frequency / model.n_frames)
                  }})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </simple-tooltip>
    <div id="network-container"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as d3 from 'd3'
import { event as currentEvent } from 'd3'
import $ from 'jquery'
import { mapGetters } from 'vuex'

import SimpleTooltip from 'components/widgets/SimpleTooltip.vue'
import DrawUtils from 'src/utils/draw'
import FormatUtils from 'src/utils/formatters'
import InteractionMenu from 'components/widgets/InteractionMenu.vue'

// Visualization constants
const CIRCLE_DEFAULT_COLOUR = 'rgb(203, 203, 203)'
const CIRCLE_SIZE_RANGE = [10, 20] // node circle radius
const LINK_WIDTH_RANGE = [1, 6] // link stroke width
const MIN_LINK_STRENGTH_PERCENTILE = 0.8
const TEXT_SIZE_RANGE = [12, 24] // node font size
const ZOOM_DEFAULT = 1 // initial zoom scale

const TICKS_PER_DRAW = 3 // number of layout ticks for every draw cycle

// This network layout visualises the semantic structure for an arbitrary subset of data.
// It has two modes of operation, one that displays influence-based relationships and another
// that displays NPMI-based. The influence-based version is only used when the data subset
// has been selected due to specific search concepts
export default defineComponent({
  components: { SimpleTooltip, InteractionMenu },
  props: {
    clickableNodes: { type: Boolean, default: true },
    nodeTooltipClickText: { type: String as PropType<string | null>, default: 'Click to add concept to query:' },
    nodeTooltipVerbatimText: { type: String, default: 'Verbatims in this query:' },
    drawHulls: { type: Boolean, default: true },
    model: { type: Object, default: null },
    height: { type: Number, default: 600 },
  },
  data() {
    return {
      hoverNode: null,
      hoverX: 0,
      hoverY: 0,
      width: undefined,
      nodes: {} as Record<number, HTMLElement>,
      nodeNames: {} as Record<number, string>,
      mounted: true,
    }
  },
  computed: {
    ...mapGetters(['currentModel']),
    hasMenu() {
      return !!this.$slots?.['interaction-menu']
    },
    hovered() {
      return this.hoverNode !== null
    },
    // Are the links influence-based or alternatively, NPMI
    isInfluence() {
      return this.model.driving_concepts.length > 0
    },
    // Determine minimum strength for showing non-mst links.
    // Draw the strength from non-zero links at a partcular percentile.
    linkStrengthThreshold() {
      const links = this.model.links.flat().filter((l) => l > 0)
      links.sort()
      const index = Math.floor(links.length * MIN_LINK_STRENGTH_PERCENTILE)
      return links[index]
    },
    nodeEls(): Record<number, () => HTMLElement> {
      return Object.fromEntries(
        Object.entries(this.nodes as Record<number, HTMLElement>).map(([idx, node]) => [idx, () => node]),
      )
    },
  },
  watch: {
    model: {
      handler: function () {
        if (this.model.concepts.length === 0) {
          // Nothing to do
          this.$emit('loaded')
          return
        }
        this.marshallData()
        this.$nextTick(() => {
          this.mounted && this.draw()
        })
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    this.mounted = false
  },
  methods: {
    decimalAsPercent: FormatUtils.decimalAsPercent,
    marshallData() {
      // Create concepts index
      const conceptsIndex = {}
      this.model.concepts.forEach((t, i) => {
        conceptsIndex[t.name] = t
        t.neighbours = new Set()
        t.index = i
      })
      // Create dense, flat array of boolean values indicating if the link between two concepts is in the MST.
      // Index like: [i1j1, i2j1, i3j1, ..., iNj1, ..., i1jN, i2jN, i3jN, ..., iNjN]
      const isMstLinkMatrix = new Array(Math.pow(this.model.concepts.length, 2)).fill(false)
      this.model.mst.forEach((l) => {
        const index = this.model.concepts.length * l[0] + l[1]
        isMstLinkMatrix[index] = true
      })
      // Create flat array of links in the form [linkStrength, isMSTLink]
      const links = this.model.links.flat().map((v, i) => {
        return [v, isMstLinkMatrix[i]]
      })
      // Assemble all node links
      this.links = []
      this.mstLinks = [] // index-based MST links for layout
      links.forEach((item, i) => {
        const value = item[0]
        const isMstLink = item[1]
        const sourceIndex = Math.floor(i / this.model.concepts.length)
        const source = this.model.concepts[sourceIndex]
        const targetIndex = i % this.model.concepts.length
        const target = this.model.concepts[targetIndex]
        const link = {
          source: source,
          target: target,
          value: value,
        }
        if (isMstLink) {
          link.mst = true
          this.mstLinks.push({
            source: sourceIndex,
            target: targetIndex,
            value: value,
          })
        }
        if (value >= this.linkStrengthThreshold || isMstLink) {
          // Only draw high strength links to make interpretation easier
          // (too many links can be overwhelming)
          this.links.push(link)
          conceptsIndex[source.name].neighbours.add(target)
          conceptsIndex[target.name].neighbours.add(source)
        }
      })
    },
    draw() {
      const el = this.$el.querySelector('#network-container')

      // Setup
      this._setupDrawSpace(el)

      // Initialise scales & generators for drawing
      const freqDomain = d3.extent(this.model.concepts, (t) => t.frequency)
      const radiusScale = d3.scaleLinear().domain(freqDomain).range(CIRCLE_SIZE_RANGE)
      const textScale = d3.scaleSqrt().domain(freqDomain).range(TEXT_SIZE_RANGE)
      const xScale = d3
        .scaleLinear()
        .domain(d3.extent(this.model.concepts, (t) => t.position[0]))
        .range([0, this.width])
      const yScale = d3
        .scaleLinear()
        .domain(d3.extent(this.model.concepts, (t) => t.position[1]))
        .range([0, this.height])

      const maxLinkValue = this.isInfluence ? d3.max(this.links.map((l) => l.value)) : 1
      const linkWidthScale = d3.scaleLinear().domain([0, maxLinkValue]).range(LINK_WIDTH_RANGE)
      this.lineGenerator = d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y)

      // Initialise concept coordinates
      // (required before drawing node links)
      this.model.concepts.forEach((t) => {
        t.x = xScale(t.position[0])
        t.y = yScale(t.position[1])
      })

      // Draw node links (only MST links are shown by default)
      this.link = this.network.selectAll('.link').data(this.links).enter().append('path')
      this.link
        .attr('class', (d) => (!d.mst ? 'ray ' : '') + 'link')
        .attr('d', (d) => this.lineGenerator([d.source, d.target]))
        .style('stroke-width', (d) => linkWidthScale(d.value))

      // Draw concept nodes
      this.node = this.network
        .selectAll('.node')
        .data(this.model.concepts)
        .enter()
        .append('g')
        .attr('class', `node${this.clickableNodes ? ' clickable' : ''}`)
        // On mouseover we show rays to all neighbours of the hovered node
        // (including non-MST links) and fade all other links.
        .on('mouseover', (d, i, nodes) => {
          this.hoverNode = d
          this.hoverX = currentEvent.clientX
          this.hoverY = currentEvent.clientY
          this.node.classed('fade', (e, j) => i !== j && !d.neighbours.has(e))
          this.node.classed('highlight', (e, j) => i === j)
          this.link.classed('fade', (e) => e.source !== d && e.target !== d)
          this.link.classed('highlight', (e) => e.source === d || e.target === d)
        })
        .on('mouseout', () => {
          this.hoverNode = null
          this.hoverX = 0
          this.hoverY = 0
          this.node.classed('fade', false)
          this.node.classed('highlight', false)
          this.link.classed('fade', false)
          this.link.classed('highlight', false)
        })
        .on('click', (d) => {
          this.hoverNode = null
          if (this.clickableNodes) {
            this.$emit('concept-selected', d.name)
          }
        })
      // draw circles
      this.node
        .append('circle')
        .attr('r', (d, i) => {
          d.radius = radiusScale(d.frequency)
          return d.radius
        })
        .style('fill', (d) => this.currentModel.conceptColours[d.name] || CIRCLE_DEFAULT_COLOUR)
      // draw text
      this.node
        .append('text')
        .text((d) => d.name)
        .style('font-size', (d, i) => {
          d.fontSize = textScale(d.frequency)
          return `${d.fontSize}px`
        })
        .style('text-anchor', 'middle')
        .attr('y', (d) => d.fontSize / 4)
      // Setup index of nodes for calculating hulls
      const nodesIndex = {}
      const nodeNames = {}
      this.node.each(function (d, i) {
        nodesIndex[i] = this
        nodeNames[i] = d.name
      })
      this.nodesIndex = nodesIndex
      this.nodeNames = nodeNames

      // Draw hulls
      if (this.drawHulls) {
        this.hull = this.network.selectAll('.hull').data(this.model.clusters).enter().append('path')
        this.hull.attr('class', 'hull').attr('d', (d) => DrawUtils.generateHull(d.map((i) => this.nodesIndex[i])))
        this.hull.each(function () {
          // Hulls should always be drawn in the background
          DrawUtils.moveToBack(this)
        })
      }

      this.nodes = nodesIndex

      // Layout
      DrawUtils.deferredNetworkLayout(
        el,
        this.node,
        this.mstLinks,
        // On initial render
        () => {
          this._updatePositions()
          this.$emit('loaded')
        },
        // On draw tick
        (tickNum) => {
          if (tickNum % TICKS_PER_DRAW === 0) {
            // Only draw at certain tick intervals to reduce load
            this._updatePositions()
          }
        },
      )
    },
    getExportConfig() {
      // Determine bounds
      let minX = 0
      let minY = 0
      let maxX = 0
      let maxY = 0
      this.node.each(function () {
        let n = d3.select(this)
        let transform = n.attr('transform')
        let translate = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(',')
        let nodeDims = this.getBoundingClientRect()
        let x = parseInt(translate[0], 10)
        let y = parseInt(translate[1], 10)
        minX = Math.min(minX, x - nodeDims.width)
        minY = Math.min(minY, y - nodeDims.height)
        maxX = Math.max(maxX, x + nodeDims.width)
        maxY = Math.max(maxY, y + nodeDims.height)
      })

      // Determine required deltas & bounding box size
      let { width, height } = d3.select(this.$el).select('svg').node().getBoundingClientRect()
      let dims = { width, height }
      let dx = 0
      let dy = 0
      if (maxX > dims.width) {
        dims.width += maxX - dims.width
      }
      if (minX < 0) {
        dx = Math.abs(minX)
        dims.width += dx
      }
      if (maxY > dims.height) {
        dims.height += maxY - dims.height
      }
      if (minY < 0) {
        dy = Math.abs(minY)
        dims.height += dy
      }
      return {
        dims: dims,
        css: `
            svg > g {
              transform: translate(${dx}px, ${dy}px) !important;
            }
            .link {
              stroke: #ccc;
              stroke-opacity: 0.35;
            }
            .ray.link {
              stroke-opacity: 0
            }
          `,
      }
    },
    // Initialse SVG for drawing, including zoom & pan
    // Draw space cached in `this.network`.
    _setupDrawSpace(el) {
      if (this.svg) {
        // Clear old elements
        this.svg.selectAll('*').remove()
      } else {
        // Initialise svg for first time
        this.svg = d3.select(el).append('svg')
      }
      const width = (this.width = $(el).width())
      const height = $(el).height()
      // Setup zoom/pan
      this.zoom = d3
        .zoom()
        .scaleExtent([1 / 4, 4])
        .on('zoom', () => {
          this.currentZoom = currentEvent.transform.k
          this.currentPan = [currentEvent.transform.x, currentEvent.transform.y]
          this.network.attr('transform', currentEvent.transform)
        })
        .filter(() => {
          if (currentEvent.type === 'wheel') {
            return currentEvent.shiftKey
          }
          return true
        })
      // Attach the zoom behavior to your SVG element, and override the default filter
      this.svg.call(this.zoom).on(
        'wheel',
        function () {
          if (currentEvent.shiftKey) {
            // Call the default zoom handler
            this.zoom.on('zoom')()
          } else {
            // Prevent default behavior if shift key is not pressed
            currentEvent.stopPropagation()
          }
        },
        { passive: true },
      )
      // Draw space
      this.network = this.svg.append('g')
      // Init default zoom
      const zoomWidth = (width - ZOOM_DEFAULT * width) / 2
      const zoomHeight = (height - ZOOM_DEFAULT * height) / 2
      this.zoom.transform(this.svg, d3.zoomIdentity.translate(zoomWidth, zoomHeight).scale(ZOOM_DEFAULT))
      // Draw zoom controls
      const controls = d3.select(el).append('div').attr('class', 'controls')
      controls
        .append('i')
        .attr('class', 'large zoom icon')
        .on('click', () => {
          this.zoom.scaleBy(this.svg, 1.25)
        })
      controls
        .append('i')
        .attr('class', 'large zoom out icon')
        .on('click', () => {
          this.zoom.scaleBy(this.svg, 0.75)
        })
      controls.append('text').attr('class', 'caption').text('Shift + mousewheel to zoom')
    },
    // Update node, hull and link positions.
    // Called for layout ticks.
    _updatePositions() {
      this.node.attr('transform', (d) => `translate(${d.x},${d.y})`)
      if (this.hull) {
        this.hull.attr('d', (d) => DrawUtils.generateHull(d.map((i) => this.nodesIndex[i])))
      }
      this.link.attr('d', (d) => this.lineGenerator([d.source, d.target]))
    },
  },
})
</script>
<style lang="sass">
#network-wrapper
  background: white
  border: 0
  box-shadow: none
  padding: 0
  #network-container
    margin: 0 auto
    position: relative
    height: 100%
    width: 100%
    &.loading
      visibility: hidden
    svg
      background: white
      position: absolute
      height: 100%
      width: 100%
      .node
        cursor: default
        &.clickable
          cursor: pointer
      .ray.link
        stroke-opacity: 0
      .link
        stroke: #ccc
        stroke-opacity: 0.35
        transition: stroke-opacity 250ms
        &.fade
          stroke-opacity: 0.05
        &.highlight
         stroke-opacity: 0.9
      .hull
        fill: purple
        opacity: 0.03

    .controls
      position: absolute
      display: grid
      bottom: 10px
      right: 10px
      background: none
      color: #ccc
      opacity: 0.9
      i
        cursor: pointer
        margin: 5px
    .caption
      bottom: 8px
      color: #d2d2d2
      pointer-events: none
      position: absolute
      right: 20px
      width: 200px
</style>
