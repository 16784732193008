import { Project } from 'src/types/ProjectTypes'

// Types for date validation
type DateParts = {
  day: number
  month: number
  year: number
}

// Check if a date string is in a valid format
const isValidDateFormat = (date: string): boolean => {
  const separatorRegex = /^(\d{1,4})([-/.])(\d{1,2})\2(\d{1,4})$/
  return separatorRegex.test(date)
}

// Parse a date string into its component parts
const parseDateString = (date: string): DateParts | null => {
  const separatorRegex = /^(\d{1,4})([-/.])(\d{1,2})\2(\d{1,4})$/
  const match = date.match(separatorRegex)

  if (!match) return null

  const [_, first, separator, second, third] = match
  const parts = [first, second, third].map(Number)

  // Clearer YYYY-MM-DD detection
  if (parts[0] > 999) {
    return {
      year: parts[0],
      month: parts[1],
      day: parts[2],
    }
  }

  return {
    year: parts[2],
    month: parts[1],
    day: parts[0],
  }
}

// Check if a date is valid (handles leap years, month lengths, etc.)
const isValidDate = (parts: DateParts): boolean => {
  const { day, month, year } = parts

  // Basic range checks
  if (month < 1 || month > 12) return false
  if (day < 1 || day > 31) return false
  if (year < 1000 || year > 9999) return false

  // Days in each month (accounting for leap years)
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  const daysInMonth = [
    31, // January
    isLeapYear ? 29 : 28, // February
    31, // March
    30, // April
    31, // May
    30, // June
    31, // July
    31, // August
    30, // September
    31, // October
    30, // November
    31, // December
  ]

  return day <= daysInMonth[month - 1]
}

// Check if a date is ambiguous (could be either DD/MM or MM/DD)
export const isDateAmbiguous = (date: string): boolean => {
  if (!isValidDateFormat(date)) return false

  const parts = parseDateString(date)
  if (!parts) return false

  // If either number is greater than 12, it's not ambiguous
  if (parts.day > 12 || parts.month > 12) return false

  // If both numbers are the same, it's ambiguous (like 02/02/2020)
  if (parts.day === parts.month) return true

  // Check if both interpretations would be valid dates
  const asOriginal = isValidDate(parts)
  const swapped = isValidDate({
    day: parts.month,
    month: parts.day,
    year: parts.year,
  })

  // It's ambiguous if both interpretations are valid dates
  return asOriginal && swapped
}

// Returns true if all non-ambiguous dates are day-first
export const isDayFirstDates = (dates: string[]): boolean => {
  // Get only valid, non-ambiguous dates
  const nonAmbiguousMatches = dates.filter((date) => isValidDateFormat(date) && !isDateAmbiguous(date))

  if (nonAmbiguousMatches.length === 0) return false

  // For each non-ambiguous date, check if it's in month-first format
  return !nonAmbiguousMatches.some((date) => {
    const parts = parseDateString(date)
    if (!parts) return false

    // If the second number is > 12, it must be a day, meaning the first number
    // is a month, making this month-first format
    if (parts.month > 12) {
      return true
    }

    return false
  })
}

// Helper function to detect the most likely date format in a dataset
export const detectDateFormat = (dates: string[]): 'DMY' | 'MDY' | 'YMD' | 'UNKNOWN' => {
  if (dates.length === 0) return 'UNKNOWN'

  // Check if all dates are in YYYY-MM-DD format
  const ymdDates = dates.filter((date) => {
    const parts = parseDateString(date)
    return parts && parts.year > 999 && parts.year > 31
  })

  if (ymdDates.length === dates.length) return 'YMD'

  // If we have non-ambiguous dates, use them to determine format
  if (isDayFirstDates(dates)) return 'DMY'

  const nonAmbiguousMDY = dates.some((date) => {
    const parts = parseDateString(date)
    if (!parts || isDateAmbiguous(date)) return false
    return parts.month > 12 && isValidDate(parts)
  })

  if (nonAmbiguousMDY) return 'MDY'

  return 'UNKNOWN'
}

export const getProjectStatusText = (status: DemoProjectStatus): string | null => {
  if (status === 'READY') {
    return null
  }
  if (status === 'ERROR') {
    return 'Error processing data.'
  }
  if (status === 'INDEXING') {
    return 'Indexing data...'
  }
  if (status === 'ANALYZING') {
    return 'Analysing data and creating themes...'
  }

  return 'Initialising project...'
}

export type DemoProjectStatus = 'CREATED' | 'READY' | 'ERROR' | 'INDEXING' | 'ANALYZING'

export const getProjectStatus = (project: Project): DemoProjectStatus => {
  const analysis = project.analyses[0]
  if (!analysis) {
    return project.status === 'PROCESSING' ? 'INDEXING' : 'CREATED'
  }
  if (analysis.status === 'Error') {
    return 'ERROR'
  }
  if (analysis.status !== 'Finished') {
    return 'ANALYZING'
  }
  return 'READY'
}
