<template>
  <div class="schema-wrapper">
    <h2>Set up data type schema</h2>
    <p>
      Review & assign
      <a target="_blank" href="https://help.kapiche.com/en/articles/2546186-what-project-schema-should-i-use">
        data types
      </a>
      to your columns. Limited to {{ rowLimit }} rows, {{ columnLimit }} columns and 1 Text/Verbatim field.
    </p>
    <div class="button-group">
      <bf-button size="mini" color="blue" @click="ignoreAll">Ignore all</bf-button>
      <bf-button size="mini" color="blue" @click="resetToSuggested">Reset to suggested</bf-button>
    </div>
    <div v-if="filePreview" class="table-wrapper">
      <table class="schema-table">
        <thead>
          <tr>
            <th></th>
            <th v-for="(header, index) in filePreview.headers" :key="header">
              <div class="header-controls">
                <el-dropdown
                  trigger="click"
                  :model-value="schemaTypes[index]"
                  @command="handleSchemaTypeChange(index, $event)"
                >
                  <span class="schema-dropdown">
                    <img class="schema-icon" :src="schemaIcon[schemaTypes[index]]" :alt="schemaTypes[index]" />
                    {{ getSchemaLabel(schemaTypes[index]) }}
                    <i class="kapiche-icon-chevron-down"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="option in SCHEMA_OPTIONS"
                        :key="option.typename"
                        :command="option.typename"
                      >
                        <img class="schema-icon" :src="schemaIcon[option.typename]" :alt="option.typename" />
                        {{ option.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <button
                  v-if="schemaTypes[index] === 'SCORE'"
                  class="score-settings-button"
                  @click="openScoreSettings(index)"
                >
                  <img :src="cogIcon" alt="Score Settings" />
                </button>
              </div>
            </th>
          </tr>
          <tr>
            <th>1</th>
            <th v-for="header in filePreview.headers" :key="header">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sample, index) in filePreview.samples" :key="index">
            <td>{{ index + 2 }}</td>
            <td
              v-for="(value, colIndex) in sample"
              :key="colIndex"
              :class="{
                ignore: schemaTypes[colIndex] === 'IGNORE',
              }"
            >
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="filePreview" class="schema-info">
      Previewing
      <b>{{ filePreview.samples.length }}</b> of <b>{{ filePreview.num_responses }}</b> rows.
      <b :class="{ error: columnCount === 0 || columnCount > columnLimit }"> {{ columnCount }}/{{ columnLimit }} </b>
      columns.
      <b :class="{ error: textCount === 0 || textCount > textLimit }"> {{ textCount }}/{{ textLimit }} </b> text fields.
    </div>
    <score-settings-modal
      :visible="scoreModalIndex != null"
      :current-field-index="scoreModalIndex"
      :current-setting="scoreModalIndex != null ? scoreSettings[scoreModalIndex] : undefined"
      @close="scoreModalIndex = null"
      @values-changed="handleScoreSettingsChange"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue'
import ScoreSettingsModal from 'components/widgets/ScoreSettingsModal/ScoreSettingsModal.vue'
import { SchemaTypeNames } from 'src/types/SchemaTypes'
import { FilePreview } from '../api/project'
import { ScoreSetting } from './DataUpload.vue'
import { BfButton } from 'components/Butterfly'

interface SchemaOption {
  typename: SchemaTypeNames
  label: string
}

const SCHEMA_OPTIONS: SchemaOption[] = [
  {
    typename: 'IGNORE',
    label: 'Ignore',
  },
  {
    typename: 'TEXT',
    label: 'Text/Verbatim',
  },
  {
    typename: 'NUMBER',
    label: 'Numerical',
  },
  {
    typename: 'DATE',
    label: 'Date',
  },
  {
    typename: 'DATE_TIME',
    label: 'Date & Time',
  },
  {
    typename: 'LABEL',
    label: 'Category',
  },
  {
    typename: 'NPS',
    label: 'NPS (0-10)',
  },
  {
    typename: 'SCORE',
    label: 'Score',
  },
] as const

const schemaIcon: Record<SchemaTypeNames, string> = {
  /* eslint-disable @typescript-eslint/no-require-imports */
  IGNORE: require('../assets/schema/ignore.svg'),
  TEXT: require('../assets/schema/text.svg'),
  NUMBER: require('../assets/schema/numerical.svg'),
  DATE: require('../assets/schema/date.svg'),
  DATE_TIME: require('../assets/schema/date.svg'),
  LABEL: require('../assets/schema/category.svg'),
  NPS: require('../assets/schema/nps.svg'),
  SCORE: require('../assets/schema/score.svg'),
  BOOLEAN: '',
}

export default defineComponent({
  name: 'SchemaStep',
  components: {
    ScoreSettingsModal,
    BfButton,
  },
  props: {
    filePreview: {
      type: Object as PropType<FilePreview>,
      required: true,
    },
    schemaTypes: {
      type: Array as PropType<SchemaTypeNames[]>,
      required: true,
    },
    columnLimit: {
      type: Number,
      required: true,
    },
    rowLimit: {
      type: Number,
      required: true,
    },
    textLimit: {
      type: Number,
      required: true,
    },
    cogIcon: {
      type: String,
      required: true,
    },
    scoreSettings: {
      type: Array as PropType<ScoreSetting[]>,
      required: true,
    },
  },
  emits: ['update-schema-types', 'update-score-settings'],
  setup(props, { emit }) {
    const scoreModalIndex = ref<number | null>(null)
    const originalSchemaTypes = ref([...props.schemaTypes])

    const columnCount = computed(() => {
      return props.schemaTypes?.filter((type) => type !== 'IGNORE').length ?? 0
    })

    const textCount = computed(() => {
      return props.schemaTypes?.filter((type) => type === 'TEXT').length ?? 0
    })

    const getSchemaLabel = (name: string) => {
      return SCHEMA_OPTIONS.find((option) => option.typename === name)?.label ?? 'N/A'
    }

    const handleSchemaTypeChange = (index: number, type: SchemaTypeNames) => {
      const updatedSchemaTypes = [...props.schemaTypes]
      updatedSchemaTypes[index] = type
      emit('update-schema-types', updatedSchemaTypes)

      if (type === 'SCORE') {
        scoreModalIndex.value = index
      } else {
        // Remove score settings if the column is no longer a score
        const updatedScoreSettings = [...props.scoreSettings]
        delete updatedScoreSettings[index]
        emit('update-score-settings', updatedScoreSettings)
      }
    }

    const openScoreSettings = (index: number) => {
      scoreModalIndex.value = index
    }

    const handleScoreSettingsChange = (
      index: number,
      name: string,
      range: [number, number],
      aggMethod: string,
      excludeOutOfRange: boolean,
    ) => {
      const updatedScoreSettings = [...props.scoreSettings]
      updatedScoreSettings[index] = {
        score_name: (name || props.filePreview?.headers[index]) ?? '',
        score_range: range,
        score_aggregation: aggMethod,
        exclude_out_of_range: excludeOutOfRange,
      }
      emit('update-score-settings', updatedScoreSettings)
    }

    const ignoreAll = () => {
      const updatedSchemaTypes = props.schemaTypes.map(() => 'IGNORE')
      emit('update-schema-types', updatedSchemaTypes)
    }

    const resetToSuggested = () => {
      emit('update-schema-types', originalSchemaTypes.value)
    }

    return {
      columnCount,
      textCount,
      getSchemaLabel,
      handleSchemaTypeChange,
      openScoreSettings,
      handleScoreSettingsChange,
      scoreModalIndex,
      SCHEMA_OPTIONS,
      schemaIcon,
      ignoreAll,
      resetToSuggested,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.table-wrapper {
  overflow-x: auto;
  height: 400px;
  max-width: 1200px;
  border: 1px solid $grey-mid-light;
  margin-top: 20px;
  width: 100%;
}

.schema-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  .el-dropdown {
    width: 100%;
    cursor: pointer;

    > span {
      width: 100%;
    }
  }

  thead {
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  td,
  th {
    white-space: nowrap;
    max-width: 200px;
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    background: #fff;
    padding: 8px;
    &:not(:last-child) {
      border-right: 1px solid $grey-mid-light;
    }
    &:first-child {
      font-weight: normal;
      min-width: 0;
      text-align: center;
    }
  }

  td {
    &.ignore {
      color: $grey-light;
    }
  }

  th {
    background: $grey-blue-background;
  }

  tbody tr:not(:last-child) td,
  th {
    border-bottom: 1px solid $grey-mid-light;
  }
}

.schema-wrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schema-info {
  margin-top: 10px;

  .error {
    color: $red;
  }
}

.schema-icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.schema-dropdown {
  background: #fff;
  display: flex;
  padding: 8px 15px;
  align-items: center;
  border: 1px solid $grey;
  user-select: none;

  > i {
    margin-left: auto;
    padding-left: 8px;
    font-size: 8px;
  }
}

.header-controls {
  display: flex;
  align-items: center;
}

.score-settings-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 6px;
  padding: 0;
  margin-top: 3px;
  opacity: 0.5;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
</style>
