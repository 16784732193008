<template>
  <modal :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Select Themes to Display</div>
    </template>
    <template #content>
      <div class="query-modal">
        <p>Choose which Themes to use for this Dashboard.</p>
        <div v-if="availableQueries.length > 0" class="select-actions">
          <p class="select-action-item" @click="selectAllQueries">Select all</p>
          <p class="select-action-item" @click="deselectAllQueries">Deselect all</p>
        </div>
        <checkbox-tree
          v-if="themeGroupTree && availableQueries.length > 0"
          :tree-data="themeGroupTree"
          :checked="usedQueries"
          :truncate-labels="70"
          label="name"
          @checked-change="usedQueries = $event"
        />
        <p v-if="availableQueries.length === 0" class="muted">There are no Themes available.</p>
      </div>
    </template>
    <template #buttons>
      <bf-button color="white" size="large" @click="$emit('close')">Cancel</bf-button>
      <bf-button color="blue" size="large" @click="applyQueries">Apply</bf-button>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, watch, computed } from 'vue'
import { useStore } from 'vuex'

import Modal from 'components/Modal.vue'
import BfButton from 'src/components/Butterfly/BfButton/BfButton.vue'
import { SavedQuery } from 'src/types/Query.types'
import CheckboxTree from 'components/CheckboxTree.vue'
import { GroupOrTheme, processQueries } from 'src/pages/dashboard/Dashboard.utils'
import { SET_DASHBOARD_QUERIES } from 'src/store/types'
import { Dashboard } from 'src/types/DashboardTypes'

export default defineComponent({
  name: 'ShowThemesModal',
  components: {
    Modal,
    BfButton,
    CheckboxTree,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    availableQueries: {
      type: Array as PropType<SavedQuery[]>,
      required: true,
    },
    themeGroupTree: {
      type: Array as PropType<GroupOrTheme[]>,
      required: true,
    },
    queryId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()

    const dashboard = computed(() => store.getters.currentDashboard as Dashboard)

    const usedQueries = ref<number[]>([])

    const applyQueries = () => {
      const selected = props.availableQueries.filter((q) => usedQueries.value.includes(q.id))
      const selectedQueries = processQueries(selected)
      store.commit(SET_DASHBOARD_QUERIES, selectedQueries)
      emit('close')
    }

    const selectAllQueries = () => {
      usedQueries.value = props.availableQueries.map((q) => q.id)
    }

    const deselectAllQueries = () => {
      usedQueries.value = usedQueries.value.filter((q) => q === props.queryId)
    }

    watch(
      () => props.visible,
      (visible) => {
        if (visible) {
          usedQueries.value = dashboard.value.queries.map((q) => q.id)
        }
      },
    )

    return {
      applyQueries,
      usedQueries,
      selectAllQueries,
      deselectAllQueries,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.query-modal {
  .muted {
    color: rgba(56, 56, 56, 0.3);
  }
  > p {
    text-align: center;
    font-size: 1.15rem;
  }
  .segment {
    height: 25%;
    max-height: 50vh;
    overflow-y: auto;
    border-right: none;
    border-left: none;
    box-shadow: none;
    margin-top: 2rem;
    .ui.form {
      margin-top: 0;
      label {
        cursor: pointer;
        font-size: 1.3rem;
      }
    }
  }
  .select-actions {
    display: flex;
    color: $text-grey;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    .select-action-item {
      cursor: pointer;
      margin-right: 20px;
    }
  }
}
</style>
