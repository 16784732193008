<template>
  <div class="create-analysis-container wizard-layout">
    <progress-indicator :num-steps="3" :step="step"></progress-indicator>
    <h2>Run Analysis</h2>

    <div v-show="step === 1 || step === 2" class="ui segment">
      <!-- Step 1 -->
      <div v-show="step === 1">
        <!-- Text fields -->
        <div class="header">Select Text Fields to Analyze</div>
        <div v-for="field in textFields" :key="field.id" class="ui checkbox text-field" :class="'field-' + field.id">
          <input :id="field.id" v-model="selectedFields" type="checkbox" :name="field.name" :value="field.id" />
          <label :for="field.id" :title="field.name.length > 60 ? field.name : undefined">{{
            truncate(field.name, 60)
          }}</label>
        </div>
        <div v-if="noTextSelectedError" class="error">Choose at least one text field.</div>
        <div class="ui divider settings-divider" :class="{ errorAbove: noTextSelectedError }"></div>
        <div class="header">Add Segment Filters</div>
        <div class="segment-filters">
          <div class="description">Include/exclude specific segments from the analysis.</div>
          <radio-buttons
            :items="segmentFilterChoices"
            :selected="segmentFilterSelectedChoice"
            @change="(value) => (segmentFilterSelectedChoice = value)"
          />
          <transition :duration="250" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-show="showSegmentsList">
              <span v-show="selectedSegments.length > 0" class="item clear" @click.stop="clearSegmentFilters">
                Clear All
              </span>
              <div v-for="field in structuredFields" :key="field">
                <div class="item field link" @click="toggleSegments(field)">
                  {{ field }} <i class="dropdown icon"></i>
                </div>
                <multiselect
                  v-show="segmentOptions[field].visible"
                  v-model="segmentOptions[field].selected"
                  :multiple="true"
                  :options="segmentOptions[field].segments"
                  label="label"
                  :close-on-select="false"
                  :allow-empty="true"
                  :hide-selected="true"
                  :loading="segmentOptions[field].loading"
                  :placeholder="segmentMultiselectPlaceholder"
                  track-by="label"
                >
                  <template #clear>
                    <div>
                      <!-- Use mousedown instead of click so we can prevent the focus event which activates the multiselect -->
                      <div class="paste-button" @mousedown.stop.prevent="showPasteFiltersModal(field)">
                        <i class="icon paste"></i> Paste List
                      </div>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </transition>
        </div>
        <modal :visible="pasteFiltersModalVisible" class="paste-filters-modal" @close="closePasteFiltersModal">
          <template #header>
            <div>Add segments for "{{ pasteFiltersField }}"</div>
          </template>
          <template #content>
            <div class="content">
              <div class="subtext">Enter/paste a list of segments one per line or separated by commas.</div>
              <br />
              <textarea
                style="width: 100%"
                ref="filtersInput"
                rows="12"
                placeholder="Paris,London,New York,Moscow"
              ></textarea>
            </div>
          </template>
          <template #buttons>
            <div class="actions">
              <bf-button color="grey" size="large" @click="closePasteFiltersModal"> Cancel </bf-button>
              <bf-button color="blue" size="large" @click="loadInputFilters"> Next </bf-button>
            </div>
          </template>
        </modal>
      </div>

      <!-- Step 2 -->
      <div v-show="step === 2">
        <!-- Default date field -->
        <div v-if="featureFlags.automatic_framework_generation" class="framework-generation-wrapper">
          <div v-if="!currentProject.aitopic_classification_enabled">
            This project does not support AI Themes. Please reach out to us if you would like to enable them
          </div>
          <div v-else-if="selectedFields.length > 1">
            AI Themes not supported for multiple text fields in an analysis, Please select only 1 text field.
          </div>
          <div v-else>
            <el-switch
              :model-value="automaticFrameworkGeneration"
              active-color="#21ba45"
              active-text="Generate AI Themes"
              @update:model-value="automaticFrameworkGeneration = $event"
            />
            <div v-if="currentUser && currentUser.is_staff" class="clustering-options">
              <div class="scoring-method">
                <el-select
                  :model-value="clusteringScoringMethod"
                  @update:model-value="clusteringScoringMethod = $event"
                  style="width: 150px"
                >
                  <el-option :key="'elbow'" :value="'elbow'" :label="'Elbow Method'" />
                  <el-option :key="'silhouette'" :value="'silhouette'" :label="'Silhouette Score'" />
                </el-select>
              </div>
              <span class="staff-only" />
            </div>
            <div class="ai-themes-text">
              Themes will be automatically created from the text field selected.
              <a target="_blank" :href="CONST.intercom_links.AI_THEMES">Learn More</a>
            </div>
          </div>
        </div>
        <div class="ui divider settings-divider"></div>
        <div class="header">Choose Default Date Field</div>
        <div>This will be used as the default selected field for any widgets using dates.</div>
        <div v-if="dateFields.length === 0" class="subtext">(No date field present)</div>
        <div v-else>
          <simple-dropdown
            :value="defaultDateField"
            :options="dateFields.map((f) => [f.name, f.name])"
            @update:value="defaultDateField = $event"
          >
          </simple-dropdown>
        </div>
        <div class="ui divider settings-divider"></div>
        <!-- Date range -->
        <div class="header">Choose Date Range to Analyze</div>
        <div v-if="dateFields.length === 0" class="subtext">(No date field present)</div>
        <div v-else>
          <div class="item date-range">
            <span id="date-field" class="variable-select-datefields">
              <div class="dropdown-label">Date Field</div>
              <simple-dropdown
                :value="selectedDateField"
                :options="dateFields.map((d) => [d.name, d.name])"
                @update:value="selectedDateField = $event"
              ></simple-dropdown>
            </span>
          </div>

          <div class="ui horizontal big list date-picker-list" :class="{ 'mouse-over-clickable': allTime }">
            <div class="middle aligned item">
              <div id="fromContainer" class="ui input date">
                <vue-date-picker
                  v-model="dateFrom"
                  format="dd/MM/yyyy"
                  :placeholder="allTime ? 'All time' : 'Start date'"
                />
              </div>
            </div>
            <div class="item">to</div>
            <div class="middle aligned item">
              <div id="toContainer" class="ui input date">
                <vue-date-picker
                  v-model="dateTo"
                  format="dd/MM/yyyy"
                  :placeholder="allTime ? 'All time' : 'End date'"
                />
              </div>
            </div>
            <div class="ui middle aligned item toggle">
              <div class="ui toggle checkbox" :class="{ unclickable: !dateFrom && !dateTo }">
                <input id="alltime-checkbox" v-model="allTime" type="checkbox" />
                <label>All time</label>
              </div>
            </div>
          </div>
          <div v-show="!validDates" class="error">
            The <strong>Start Date</strong> must be before the <strong>End Date</strong>
          </div>
        </div>
        <div class="ui divider settings-divider" :class="{ errorAbove: !validDates }"></div>

        <!-- Settings / stopwords -->
        <div v-show="settingsVisible">
          <div class="header">Stopwords</div>
          <br />
          <stopwords ref="stopwords"></stopwords>
          <div class="ui divider stopwords-divider"></div>
          <!-- Remove duplicates -->
          <div class="setting ui grid">
            <div class="twelve wide column" :class="!removeDuplicates ? 'inactive' : ''">
              <el-popover effect="dark">
                <template #default>
                  <div>
                    <remove-duplicates-description></remove-duplicates-description>
                  </div>
                </template>
                <template #reference>
                  <div class="label">Remove duplicate verbatims</div>
                </template>
              </el-popover>
            </div>
            <div class="four wide column setting-toggle">
              <div class="ui toggle green checkbox">
                <input id="removeDuplicatesCbox" v-model="removeDuplicates" type="checkbox" />
                <label></label>
              </div>
            </div>
          </div>
          <div class="ui divider"></div>
        </div>
        <div class="settings-link">
          <div @click="settingsVisible = !settingsVisible">
            <template v-if="settingsVisible"> Hide advanced settings<i class="icon chevron up"></i> </template>
            <template v-else> Show stopwords &amp; advanced settings<i class="icon chevron down"></i> </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3 -->
    <div v-show="step === 3" class="basic segment">
      <div class="subtitle">Give your analysis a title:</div>
      <input
        v-model="name"
        type="text"
        class="streamline-input"
        :class="{ error: nameError || nameDuplicateError }"
        maxlength="60"
        placeholder="Enter a title..."
        @keydown.enter="proceed"
      />
      <div v-if="nameDuplicateError || (!loading && analysisWithThatNameAlreadyExists)" class="subtext error">
        An analysis with this title already exists
      </div>
      <div v-else-if="nameError" class="subtext error">The analysis needs a title</div>
      <div v-else-if="otherServerError" class="subtext error">
        {{ otherServerError }}
      </div>
      <div v-else class="subtext" :title="textFieldLabel.length > 50 ? textFieldLabel : undefined">
        Text Fields: {{ truncate(textFieldLabel, 50) }}
      </div>
      <!-- Separate analyses for each field
      <div class="name-segment" v-for="id, i in selectedFields">
        <input type="text" class="streamline-input" maxlength="60" :ref="`name-input-${id}`">
        <div class="subtext">Text Field: "{{ selectedNames[i] }}"</div>
      </div>
      -->
    </div>

    <!-- Buttons -->
    <div class="buttons">
      <router-link
        v-if="step === 1"
        :to="{ name: 'project-details', params: { projectId: currentProject.id } }"
        class="ui button cancel"
      >
        Cancel
      </router-link>
      <button v-else class="ui button back" :class="loading ? ['loading', 'events-disabled'] : ''" @click="back">
        Back
      </button>
      <button
        class="ui button proceed"
        :disabled="analysisWithThatNameAlreadyExists || !validDates || noTextSelectedError"
        :class="loading ? ['loading', 'events-disabled'] : ''"
        @click="proceed"
      >
        {{ step < 3 ? 'Proceed' : 'Run Analysis' }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex'
import md5 from 'crypto-js/md5'
import dayjs from 'dayjs'

import { BfButton } from 'components/Butterfly'
import SimpleDropdown from 'components/widgets/SimpleDropdown.vue'
import RemoveDuplicatesDescription from 'components/project/analysis/create/widgets/RemoveDuplicatesDescription.vue'
import ProgressIndicator from 'components/widgets/ProgressIndicator.vue'
import RadioButtons from 'components/widgets/RadioButtons.vue'
import Stopwords from 'components/project/analysis/results/widgets/Stopwords.vue'
import Project from 'src/api/project'
import { defineComponent } from 'vue'
import { CLEAR_ERRORS, CLEAR_REQUEST_ERRORS } from 'src/store/types'
import Multiselect from 'vue-multiselect'
import GeneralUtils from 'src/utils/general'
import FormatUtils from 'src/utils/formatters'
import { formatDrfErrors } from 'src/utils/display'
import VueDatePicker from '@vuepic/vue-datepicker'
import Modal from 'components/Modal.vue'

export default defineComponent({
  components: {
    Modal,
    BfButton,
    VueDatePicker,
    SimpleDropdown,
    ProgressIndicator,
    RadioButtons,
    Stopwords,
    RemoveDuplicatesDescription,
    Multiselect,
  },
  beforeRouteEnter(to, from, next) {
    Project.getStructuredDataFields(to.params.projectId)
      .then((response) => {
        const ops = {}
        for (const field of response) {
          ops[field] = {
            visible: false,
            loading: false,
            segments: [],
            selected: [],
            fetched: false,
          }
        }
        next((vm) => {
          vm.structuredFields = response
          vm.segmentOptions = ops
        })
      })
      .catch(() => {
        next(false)
      })
  },
  data() {
    return {
      loading: false,
      name: '',
      nameError: false,
      nameDuplicateError: false,
      otherServerError: undefined as undefined | string,
      other: false,
      pasteFiltersField: null,
      pasteFiltersModalVisible: false,
      phraseDetection: false,
      removeDuplicates: false,
      defaultDateField: undefined,
      automaticFrameworkGeneration: true,
      clusteringScoringMethod: 'elbow',
      selectedDateField: undefined,
      selectedFields: [],
      settingsVisible: false,
      stemming: true,
      step: 1,
      structuredDataIndex: {},
      // Actual date values
      dateTo: '',
      dateFrom: '',
      allTime: true,
      structuredFields: [],
      segmentOptions: [],
      segmentFilterChoices: [
        { label: 'Include all segments', value: 'all' },
        { label: 'Include only selected segments (choose)', value: 'include' },
        { label: 'Exclude the selected segments (choose)', value: 'exclude' },
      ],
      segmentFilterSelectedChoice: 'all',
    }
  },
  computed: {
    ...mapGetters(['currentProject', 'currentSite', 'featureFlags', 'currentUser']),
    validDates() {
      // if there is a from & a to date, from must be before the to
      let result = true
      if (this.dateFrom && this.dateTo) {
        result = dayjs(this.dateFrom).isSameOrBefore(dayjs(this.dateTo))
      }
      return result
    },
    noTextSelectedError() {
      return this.selectedFields.length === 0
    },
    metaInfo() {
      return {
        title: this.currentProject ? `${this.currentProject.name} Analysis - Kapiche` : null,
      }
    },
    dateFields() {
      return this.schemaFields.filter((field) => {
        return (
          field.type === Project.COLUMN_LABELED_TYPES.get('DATE') ||
          field.type === Project.COLUMN_LABELED_TYPES.get('DATE_TIME')
        )
      })
    },
    schemaFields() {
      return this.currentProject.schema.map((field) => {
        return {
          index_field: field.index_field,
          name: field.name,
          type: field.type,
          id: md5(field.name).toString(), // something safe to use as a class name
        }
      })
    },
    selectedNames() {
      return this.selectedFields.map((id) => this.textFields.find((f) => f.id === id).name)
    },
    showSegmentsList() {
      return ['include', 'exclude'].includes(this.segmentFilterSelectedChoice)
    },
    segmentMultiselectPlaceholder() {
      const include = this.segmentFilterSelectedChoice === 'include'
      return `Choose segments to ${include ? 'include in' : 'exclude from'} analysis...`
    },
    textFieldLabel() {
      return this.selectedNames.map((n) => `"${n}"`).join(', ')
    },
    textFields() {
      let isText = (field) => field.type === Project.COLUMN_LABELED_TYPES.get('TEXT')
      let isIndexed = (field) => !!field.index_field
      return this.schemaFields.filter((field) => isText(field) && isIndexed(field))
    },
    analysisWithThatNameAlreadyExists() {
      return this.currentProject.analyses.some((a) => a.name === this.name)
    },
    selectedSegments() {
      const result = []
      for (const [field, options] of Object.entries(this.segmentOptions)) {
        for (const option of options.selected) result.push([field, option.value])
      }
      return result
    },
  },
  watch: {
    dateTo() {
      this.allTime = !(this.dateTo || this.dateFrom)
    },
    dateFrom() {
      this.allTime = !(this.dateTo || this.dateFrom)
    },
    allTime() {
      if (this.allTime) {
        this.dateTo = ''
        this.dateFrom = ''
      }
    },
    featureFlags: {
      immediate: true,
      handler(newFlags) {
        if (newFlags?.automatic_framework_generation !== undefined) {
          this.automaticFrameworkGeneration = newFlags.automatic_framework_generation
        }
      },
    },
  },
  mounted() {
    // Initialise default selected text field
    if (this.textFields.length === 1) {
      this.selectedFields.push(this.textFields[0].id)
    }
    if (this.dateFields.length > 0) {
      // Set the default date field based on the first field
      this.defaultDateField = this.dateFields[0].name
      this.selectedDateField = this.dateFields[0].name
    }
  },
  methods: {
    truncate: FormatUtils.truncate,
    back() {
      // reset errors
      this.nameError = false
      this.nameDuplicateError = false
      this.otherServerError = undefined
      // if dates invalid, then clear them
      if (!this.validDates) {
        this.dateTo = ''
        this.dateFrom = ''
      }
      // go back
      this.step -= 1
    },
    clearSegmentFilters() {
      for (const option of Object.values(this.segmentOptions)) {
        option.selected = []
      }
    },
    createAnalysis() {
      this.$store.dispatch({ type: CLEAR_ERRORS })
      let postData = this._getPostData()
      this.loading = true
      Project.createAnalysis(this.currentProject.id, postData)
        .then((response) => {
          let eventData = {
            phraseDetection: postData.phrase_detection,
            removeDuplicates: postData.remove_duplicates,
            stemming: postData.stemming,
            stopwords: postData.stopwords,
            numTextFields: this.selectedFields.length,
            allTime: this.allTime,
            dateFrom: null,
            dateTo: null,
            dateField: null,
          }
          if (this.selectedDateField) {
            eventData.dateField = postData.date_field
          }
          if (!this.allTime) {
            eventData.dateFrom = postData.date_from
            eventData.dateTo = postData.date_to
          }
          this.$analytics.track.analysis.run(eventData)
          this.$router.push({ name: 'project-details', params: { id: this.currentProject.id } })
        })
        .catch((errors) => {
          let nonFieldErrors = errors.body['non_field_errors'] || ['']
          if (nonFieldErrors[0].toLowerCase().includes('name already exists')) {
            this.$store.dispatch(CLEAR_REQUEST_ERRORS) // use custom instead of global error handling
            this.nameDuplicateError = true
          } else {
            this.otherServerError = formatDrfErrors(errors?.body)
          }
          this.loading = false
        })
    },
    loadInputFilters() {
      const segOpts = this.segmentOptions[this.pasteFiltersField]
      const value = this.$refs.filtersInput.value
      let segments = GeneralUtils.parseSegmentsFromStr(value)
      segments.forEach((s) => {
        if (segOpts.selected.indexOf(s) < 0) {
          segOpts.selected.push({ label: s, value: s })
        }
      })
      this.pasteFiltersModalVisible = false
    },
    proceed() {
      if (this.step === 1) {
        // Check that text field is selected
        if (this.noTextSelectedError) return
        this.step += 1
      } else if (this.step === 2) {
        this.step += 1
      } else {
        this.nameDuplicateError = false
        this.nameError = false
        if (this.name.length === 0) {
          this.nameError = true
          return
        }
        this.createAnalysis()
      }
    },
    closePasteFiltersModal() {
      this.pasteFiltersModalVisible = false
    },
    showPasteFiltersModal(field) {
      this.pasteFiltersField = field
      this.pasteFiltersModalVisible = true
      return false
    },
    async toggleSegments(field) {
      let options = this.segmentOptions[field]
      if (options.fetched) {
        options.visible = !options.visible
        // Vue.set(this.segmentOptions, field, options)
        this.segmentOptions[field] = options
        return
      }
      try {
        options.loading = true
        options.visible = true
        // Vue.set(this.segmentOptions, field, options)
        this.segmentOptions[field] = options
        const response = await Project.getStructuredDataSegments(this.currentProject.id, field)
        options.segments = response.map((seg) =>
          seg === '' ? { value: seg, label: '(No Value)' } : { value: seg, label: seg },
        )
        options.fetched = true
        // Vue.set(this.segmentOptions, field, options)
        this.segmentOptions[field] = options
      } finally {
        options.loading = false
        // Vue.set(this.segmentOptions, field, options)
        this.segmentOptions[field] = options
      }
    },
    _getPostData() {
      let postData = {
        name: this.name,
        text_fields: this.selectedNames,
        phrase_detection: this.phraseDetection,
        stemming: this.stemming,
        automatic_framework_generation: this.automaticFrameworkGeneration,
        clustering_scoring_method: this.clusteringScoringMethod,
        remove_duplicates: this.removeDuplicates,
        stopwords: JSON.stringify(this.$refs.stopwords.stopwordList),
        excluded_segments: [],
        included_segments: [],
        automatic_theme_generation: this.automaticFrameworkGeneration,
      }
      // Currently, support for including or excluding
      // segments is mutually exclusive. This could be
      // changed by storing segments into separate exclude
      // & include lists that are always written to the
      // post data. This is supported by the backend.
      //
      // For now we selectively overwrite default empty
      // excluded or included segment lists on the post data
      // based on which radio button the user has selected.
      switch (this.segmentFilterSelectedChoice) {
        case 'include':
          postData.included_segments = this.selectedSegments
          break
        case 'exclude':
          postData.excluded_segments = this.selectedSegments
          break
        default:
          break
      }
      // Append dates only if the user has not selected "all time"
      // (dates restrict the analysis, alltime does not)
      //
      // TODO: it should not be required to also test the selected date
      //  field, but we've had a situation before where `allTime` was
      //  truthy and the selected date field was `null`. See ch10399.
      if (!this.allTime && this.selectedDateField) {
        // Parse dates from our known format before sending
        let dateToIso = this.dateTo !== '' ? dayjs.utc(this.dateTo).endOf('day').toISOString() : ''
        let dateFromIso = this.dateFrom !== '' ? dayjs.utc(this.dateFrom).startOf('day').toISOString() : ''
        // We are going to pass the relevant info to construct the date_query on the backend
        postData['date_to'] = dateToIso
        postData['date_from'] = dateFromIso
        postData['date_field'] = this.selectedDateField
      }
      if (this.defaultDateField) {
        postData['default_date_field'] = this.defaultDateField
      }
      return postData
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.create-analysis-container
  .ui.segment:not(.basic)
    border: 0
    box-shadow: 0 1px 5px 0 rgba(0, 1, 1, 0.1)
    margin-top: rem(40px)
    padding: rem(30px)
    text-align: left
    .header
      font-size: rem(14px)
      font-weight: bold
      letter-spacing: rem(0.7px)
      margin-bottom: rem(5px)
      text-transform: uppercase
    label
      cursor: pointer
    .error
      color: $red
      font-size: rem(16px)
      font-weight: bold
      margin: rem(10px) 0
    .subtext
      color: $text-grey
      font-size: rem(16px)
    .settings-divider
      margin-bottom: rem(30px)
      margin-top: rem(40px)
      &.errorAbove
        margin-top: 0

    /* Field selection */
    .checkbox.text-field
      display: block
      margin-bottom: rem(20px)
      margin-top: rem(20px)
      label
        font-size: rem(16px)
        padding-left: rem(40px)
        padding-top: rem(4px)
        &::before
          height: rem(25px)
          width: rem(25px)
        &::after
          font-size: rem(25px)
          padding-top: rem(4px)
    /* Segment filters */
    .segment-filters
      .link
        cursor: pointer
        color: $blue
        font-size: rem(16px)
      .item
        font-size: rem(16px)
        line-height: rem(40px)
        +truncate()
        &.clear
          color: $text-grey
          cursor: pointer
        &.field
          font-weight: bold
          margin-top: rem(10px)
        .checkbox, label
          cursor: pointer
      .popover
        background: white
        bottom: rem(70px)
        box-shadow: $box-shadow
        display: none
        left: rem(15px)
        max-height: rem(340px)
        overflow-y: auto
        padding-left: rem(30px)
        padding-right: rem(30px)
        position: absolute
        width: rem(270px)
        &.active
          display: block
      .description
        font-size: 16px
        margin-bottom: 15px
        margin-top: 10px

      ::v-deep .multiselect__tags
        padding-right: 120px
      ::v-deep .paste-button
        cursor: pointer
        color: $text-grey
        font-size: 12px
        font-weight: bold
        position: absolute
        right: 50px
        top: 10px
        text-transform: uppercase
        z-index: 2
        &:hover
          color: $blue
      /* Change the colour of vue-multiselect's highlights.
       * The default green looks like an inclusive filter. */
      ::v-deep .multiselect__tag
        background: darkorange
        padding-top: 6px
        padding-bottom: 6px
        font-weight: bold
      ::v-deep .multiselect__tag-icon:after
        color: white
      ::v-deep .multiselect__tag-icon:hover
        background: red
      ::v-deep .multiselect__option--highlight
        background: darkorange
        font-weight: bold
      ::v-deep .multiselect__option--highlight:after
        background: darkorange

    /* Date and settings */
    .date-range
      .dropdown-label
        color: $text-grey
        font-size: rem(12px)
        font-weight: bold
        letter-spacing: rem(0.6px)
        margin: rem(25px) 0 0
        text-transform: uppercase
      .dropdown .text
        color: $blue
        font-size: rem(16px)
        font-weight: bold
    .date.input input
      cursor: pointer
    .date-picker-list
      margin: 1em 0 0.3em
    .date-picker-list .toggle label
      font-size: rem(16px)
    .stopwords-divider
      margin-top: rem(40px)
    .settings-link
      text-align: right
      div
        color: $blue
        cursor: pointer
        display: inline-block
        font-size: rem(16px)
        &:hover
          color: $blue-light
        .icon.chevron
          font-size: rem(14px)
          margin-left: rem(5px)
    .setting
      font-size: rem(16px)
      .inactive
        color: $text-grey
      .setting-toggle
        text-align: right
    .tooltip
      width: rem(350px)

  .switch-container
    display: flex
    justify-content: center
    align-items: center

  /* Confirm and name */
  .segment.basic
    margin-top: rem(20px)
    .subtitle
      font-size: rem(16px)
      margin-bottom: rem(50px)
    .subtext
      color: $text-grey
      font-weight: bold
      margin-top: rem(10px)
      &.error
        color: $red

  div.buttons
    margin-top: rem(50px)
    .button
      border-radius: 3px
      font-size: rem(18px)
      margin-right: rem(20px)
      &.proceed
        background-color: $blue
        border-color: $blue
        color: white
        font-weight: bold
        &:hover
          background-color: $blue-light
          border-color: $blue-light

  .unclickable
    cursor: default !important
    pointer-events: none

.paste-filters-modal .dialog
  padding: 25px
  width: 800px
  text-align: center
  .h1
    font-size: 24px
  .subtext
    font-size: 14px
    /* Override leaky style from other parts of this component */
    color: $text-black !important
  textarea
    padding: 10px
  .actions
    margin: 25px
    .bf-button
      width: 120px
      &.grey
        color: $text-grey

.framework-generation-wrapper
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  margin: 1rem 0

  .clustering-options
    display: flex
    justify-content: center
    align-items: center
    margin: 0.5rem 0
    .scoring-method .staff-only
      width: auto
      margin: 0 auto

  .ai-themes-text
    color: $subdued

  ::v-deep .el-switch
    justify-content: center
    margin: 1rem 0

::v-deep
  .dp__input
    text-indent: 20px
  .el-switch__label *
    font-size: 16px
</style>
