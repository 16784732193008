<template>
  <div class="theme-tree">
    <div v-if="isLoading || updatingTree" class="loading">
      <bf-spinner v-if="isLoading || updatingTree" />
    </div>
    <template v-else-if="themes.length > 0">
      <div v-if="newThemes.length > 0" class="theme-section">
        <div class="theme-header">
          <span class="left-text">
            In Progress
            <icon color="#95a6ac" name="info" :size="14" hover-message="These themes are not yet saved." />
          </span>
          <span class="right-text"> % of all verbatims </span>
        </div>
        <div v-for="theme in newThemes" :key="theme.id" class="theme-item" @click="$emit('theme-clicked', theme.id)">
          <div :class="['theme-info', { selected: theme.id === selectedThemeID }]">
            <span class="left-text">
              <span class="theme-name">{{ theme.name }}</span>
            </span>
            <span class="right-text"> {{ getCoveragePercent({ id: theme.id, type: 'theme' }) }} % </span>
          </div>
          <div>
            <el-progress :show-text="false" :percentage="getCoveragePercent({ id: theme.id, type: 'theme' })" />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="theme-header">
          <span class="left-text">
            <span class="sort-wrapper" @click="sortClick('name')">
              Themes
              <up-down
                :up="sortMode === 'name' && sortOrder === 'asc'"
                :down="sortMode === 'name' && sortOrder === 'desc'"
              />
            </span>
            <bf-button color="transparent" @click="createGroupModalVisible = true">
              <icon color="#068ccc" name="plus" :size="11" />
              Create Group
            </bf-button>
          </span>
          <span class="right-text sort-wrapper" @click="sortClick('coverage')">
            % of all verbatims
            <up-down
              :up="sortMode === 'coverage' && sortOrder === 'asc'"
              :down="sortMode === 'coverage' && sortOrder === 'desc'"
            />
          </span>
        </div>
        <el-tree
          :allow-drop="() => true"
          :allow-drag="allowDrag"
          :data="convertedStructure"
          node-key="key"
          draggable
          :default-expanded-keys="Array.from(expandedNodeKeys)"
          @node-expand="nodeExpanded"
          @node-collapse="nodeCollapsed"
          @node-drop="handleDrop"
          @node-drag-enter="nodeDragEnter"
          @node-drag-start="draggingNode = $event"
          @node-drag-end="
            () => {
              draggingNode = null
              dropTarget = null
            }
          "
        >
          <template #default="{ node, data }">
            <div
              :class="[
                'node-content',
                {
                  'drop-target': data.type === 'group' && data.id === dropTarget,
                },
              ]"
              @click="() => nodeClick(data)"
            >
              <icon v-if="node.data.id !== -1" class="drag-dots" :size="14" color="#ccc" name="drag-dots" />
              <div
                :class="{
                  'theme-info': data.type === 'theme',
                  'group-info': data.type === 'group',
                  'ungrouped': data.id === -1,
                  'selected': data.type === 'theme' && data.id === selectedThemeID,
                }"
              >
                <span class="left-text">
                  <truncated-span class="theme-name">
                    {{ data.label }}
                  </truncated-span>
                  <span v-if="data.type === 'theme' && hasChanges[data.id]" class="unsaved"> unsaved </span>
                  <span v-if="data.type === 'group'" class="group-summary">
                    &#40;{{ countChildThemes(data) }}
                    {{ countChildThemes(data) === 1 ? 'theme' : 'themes' }}
                    <template v-if="groupUnsaved(data)">
                      , <span>{{ groupUnsaved(data) }} unsaved</span> </template
                    >&#41;
                  </span>
                  <icon
                    v-if="node.childNodes.length"
                    :size="11"
                    color="#888"
                    name="chevron-right"
                    :class="[
                      'expanded-icon',
                      {
                        expanded: node.expanded,
                      },
                    ]"
                  />
                </span>
                <span class="right-text" @click.stop>
                  <span v-if="data.id !== -1" class="dropdown">
                    <el-dropdown
                      trigger="click"
                      position="bottom-end"
                      @visible-change="(open) => groupMenuToggle(node, open)"
                      @command="dropdownClick"
                    >
                      <span class="theme-name">
                        <icon color="#aaa" name="horizontal-dots" :size="14" />
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :command="{
                              actions: 'rename',
                              type: data.type,
                              id: data.id,
                            }"
                          >
                            Rename
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="data.type === 'group' || !autoThemeIds.includes(data.id)"
                            :command="{
                              actions: 'delete',
                              type: data.type,
                              id: data.id,
                            }"
                            class="red"
                          >
                            Delete
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </span>
                  <span class="coverage"> {{ getCoveragePercent(data) }} % </span>
                </span>
              </div>
              <div>
                <el-progress :show-text="false" :percentage="getCoveragePercent(data)" />
              </div>
            </div>
          </template>
        </el-tree>
      </div>
    </template>
    <template v-else> No themes found. </template>
    <create-group-modal
      :group-structure="convertedStructure"
      :visible="createGroupModalVisible"
      :submit-errors="[]"
      :project-id="projectId"
      :analysis-id="analysisId"
      @close="createGroupModalVisible = false"
      @update-tree="$emit('update-tree')"
    />
    <group-name-modal
      :visible="renameGroupId !== null"
      :values="{ name: renameGroupName }"
      :submit-errors="renameErrors"
      @close="renameGroupId = null"
      @submit="renameGroupSubmit"
    />
    <group-delete-modal
      :visible="deleteGroupId !== null"
      :group-name="deleteGroupName"
      @close="deleteGroupId = null"
      @submit="deleteGroupSubmit"
    />
    <theme-name-modal
      :visible="renameThemeId !== null"
      :values="{
        name: renameTheme && renameTheme.name,
        description: renameTheme && renameTheme.description,
      }"
      :submit-errors="renameErrors"
      @close="renameThemeId = null"
      @update-theme="renameThemeSubmit(renameTheme, $event)"
    />
    <theme-delete-modal
      v-if="deletingTheme"
      :visible="deleteThemeId !== null"
      :selected-query="deletingTheme"
      :saved-queries="themes || []"
      :delete-theme="deleteThemeSubmit"
      @close="deleteThemeId = null"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, ref, watch, inject } from 'vue'
import { default as TreeNode } from 'element-plus/es/components/tree/src/model/node'
import { cloneDeep } from 'lodash'
import { SavedQuery } from 'src/types/Query.types'
import { BfSpinner, BfButton } from 'components/Butterfly'
import Icon from 'components/Icon.vue'
import CreateGroupModal from './CreateGroupModal.vue'
import GroupNameModal from './GroupNameModal.vue'
import ThemeNameModal from './ThemeNameModal.vue'
import GroupDeleteModal from './GroupDeleteModal.vue'
import ThemeDeleteModal from './ThemeDeleteModal.vue'
import { ThemeGroup } from 'src/api/query'
import { CoverageNode, SortMode, SortOrder, findNode, findParentNode, sortQueryStats } from './ThemeBuilder.utils'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import QueryUtils from 'src/utils/query'
import { Analytics } from 'src/analytics'
import TruncatedSpan from 'components/Butterfly/TruncatedSpan/TruncatedSpan.vue'

// Map CoverageNode to TreeNode
export const mapGroupTreeToElTree = <T extends GroupOrTheme>(coverageNodes: T[]): NodeData[] => {
  return coverageNodes.reduce((arr, node) => {
    if (node.is_new) return arr

    const treeNode: NodeData = {
      label: node.name,
      id: node.id,
      type: node.type,
      key: `${node.id}-${node.type}`,
    }
    if (node.type === 'group' && node.children) {
      treeNode.children = mapGroupTreeToElTree(node.children)
    }
    return arr.concat(treeNode)
  }, [] as NodeData[])
}

export interface NodeData {
  id: number
  type: 'group' | 'theme'
  label: string
  children?: NodeData[]
  key: string
}

type ElTreeNode = TreeNode

const ThemesTab = defineComponent({
  components: {
    TruncatedSpan,
    GroupDeleteModal,
    GroupNameModal,
    CreateGroupModal,
    BfSpinner,
    BfButton,
    Icon,
    ThemeNameModal,
    ThemeDeleteModal,
    UpDown,
  },
  props: {
    isLoading: { type: Boolean, required: false, default: true },
    themes: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    selectedThemeID: {
      type: [String, Number, null] as PropType<null | SavedQuery['id']>,
      default: null,
    },
    hasChanges: {
      type: Object as PropType<Record<SavedQuery['id'], boolean>>,
      required: false,
      default: () => ({}),
    },
    analysisId: { type: Number, required: true },
    projectId: { type: Number, required: true },
    groupStructure: { type: Array as PropType<CoverageNode[]>, required: true },
    saveTheme: {
      type: Function as PropType<(query: SavedQuery, partial?: Partial<SavedQuery> | undefined) => Promise<void>>,
      required: true,
    },
    deleteTheme: {
      type: Function as PropType<(theme: SavedQuery, conflicts: SavedQuery[]) => Promise<void>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const createGroupModalVisible = ref(false)
    const renameGroupId = ref<null | number>(null)
    const deleteGroupId = ref<null | number>(null)
    const renameThemeId = ref<null | number>(null)
    const deleteThemeId = ref<null | number>(null)
    const renameErrors = ref<string[]>([])
    const dropTarget = ref<null | number>(null)
    const updatingTree = ref(false)
    const draggingNode = ref<ElTreeNode | null>(null)
    const analytics = inject<Analytics>('analytics')

    watch(
      () => props.groupStructure,
      () => {
        updatingTree.value = false
      },
    )

    const autoThemeIds = computed(() => {
      return props.themes
        .filter((theme) => {
          const rows = QueryUtils.botanicToQueryRows(theme.query_value)
          return rows.some((row) => row.field === 'aitopic')
        })
        .map((q) => q.id)
    })

    const renameGroupName = computed(() => {
      if (renameGroupId.value !== null) {
        const node = findNode(props.groupStructure, { id: renameGroupId.value, type: 'group' })
        return node?.name
      }
      return ''
    })

    const deleteGroupName = computed(() => {
      if (deleteGroupId.value !== null) {
        const node = findNode(props.groupStructure, { id: deleteGroupId.value, type: 'group' })
        return node?.name
      }
      return ''
    })

    const renameTheme = computed<SavedQuery | null>(() => {
      if (renameThemeId.value !== null) {
        return props.themes.find((theme) => theme.id === renameThemeId.value) ?? null
      }
      return null
    })

    const deletingTheme = computed<SavedQuery | null>(() => {
      if (deleteThemeId.value !== null) {
        return props.themes.find((theme) => theme.id === deleteThemeId.value) ?? null
      }
      return null
    })

    const groupUnsaved = (node: CoverageNode) => {
      return node.children?.filter((child) => props.hasChanges[child.id])?.length ?? 0
    }

    const getCoverage = <T extends { id: number; type: 'theme' | 'group' }>(params: T): number => {
      const node = findNode(props.groupStructure, { id: params.id, type: params.type })
      return node?.coverage ?? 0
    }

    const getCoveragePercent = <T extends { id: number; type: 'theme' | 'group' }>(params: T): number => {
      return +(getCoverage(params) * 100).toFixed(2)
    }

    const countChildThemes = (node: CoverageNode): number => {
      if (!node.children) return 0
      return node.children.reduce((acc, child) => {
        if (child.type === 'theme') return acc + 1
        return acc + countChildThemes(child)
      }, 0)
    }

    const savedThemes = computed(() => {
      return props.themes.filter((theme) => !theme.is_new)
    })

    const newThemes = computed(() => {
      return props.themes.filter((theme) => theme.is_new)
    })

    const sortMode = ref<SortMode>(null)
    const sortOrder = ref<SortOrder>('desc')

    const sortClick = (mode: 'name' | 'coverage') => {
      if (sortMode.value === mode) {
        if (sortOrder.value === 'asc') {
          sortMode.value = null
        } else if (sortOrder.value === 'desc') {
          sortOrder.value = 'asc'
        } else {
          sortOrder.value = 'desc'
        }
      } else {
        sortMode.value = mode
        sortOrder.value = 'desc'
      }
    }

    const sortedCoverageTree = computed<CoverageNode[]>(() => {
      let data: CoverageNode[] = cloneDeep(props.groupStructure)
      const sort = sortMode.value
      if (sort) {
        data = sortQueryStats(data, sort, sortOrder.value)
      }
      return data
    })

    const convertedStructure = computed((): NodeData[] => {
      return mapGroupTreeToElTree(sortedCoverageTree.value as GroupOrTheme[])
    })

    const nodeClick = (data: NodeData) => {
      if (data.type === 'theme') {
        emit('theme-clicked', data.id)
      }
    }

    const handleDrop = async (child: ElTreeNode, other: ElTreeNode) => {
      updatingTree.value = true

      let parentId: number | undefined
      if (other.data.type === 'theme') {
        // Dropped as a sibling of "other"
        const searchParams = { id: other.data.id, type: other.data.type }
        const parent = findParentNode(props.groupStructure, searchParams)
        parentId = parent?.id
      } else {
        parentId = other.data.id
      }

      // If dropped in Ungrouped themes, parentId is -1
      if (parentId === -1) {
        parentId = undefined
      }
      if (child.data.type === 'theme') {
        analytics?.track.themeBuilder.moveTheme(
          { name: child.data.name, id: child.data.id },
          other.data.type === 'group' ? { name: other.data.name, id: other.data.id } : { id: other.data.id },
        )
      }

      const endpoint = child.data.type === 'theme' ? ThemeGroup.updateThemeParent : ThemeGroup.updateGroupParent

      await endpoint(props.projectId, props.analysisId, child.data.id, parentId ?? null)

      emit('update-tree')
    }

    const renameGroupSubmit = async ({ name }: { name: string }) => {
      renameErrors.value = []

      if (renameGroupId.value !== null) {
        try {
          await ThemeGroup.update(props.projectId, props.analysisId, renameGroupId.value, { group_name: name })
          analytics?.track.themeBuilder.renameThemeGroup(renameGroupName.value, name, renameGroupId.value)
          renameGroupId.value = null
          emit('update-tree')
        } catch (e) {
          console.error(e)
        }
      }
    }

    const deleteGroupSubmit = async () => {
      if (deleteGroupId.value !== null) {
        await ThemeGroup.delete(props.projectId, props.analysisId, deleteGroupId.value)
        analytics?.track.themeBuilder.deleteThemeGroup(deleteGroupName.value, deleteGroupId.value)
        deleteGroupId.value = null
        emit('update-tree')
      }
    }

    const nodeDragEnter = (dragNode: ElTreeNode, dropNode: ElTreeNode) => {
      const currentParent = findParentNode(props.groupStructure, {
        id: dragNode.data.id,
        type: dragNode.data.type,
      })

      const data = dropNode.data
      let dropTargetId = null

      if (data.type === 'group') {
        dropTargetId = data.id
      } else {
        const searchParams = { id: data.id, type: data.type }
        const parent = findParentNode(props.groupStructure, searchParams)
        if (parent) {
          dropTargetId = parent.id
        }
      }

      if (currentParent?.id !== dropTargetId) {
        dropTarget.value = dropTargetId
      } else {
        dropTarget.value = null
      }
    }

    const allowDrag = (node: ElTreeNode) => {
      return node.data.id !== -1
    }

    const groupMenuToggle = (node: ElTreeNode, open: boolean) => {
      if (open) node.expanded = true
    }

    const renameThemeSubmit = async (query: SavedQuery, data: Partial<SavedQuery>) => {
      renameErrors.value = []
      props
        .saveTheme(query, data)
        .then(() => {
          renameThemeId.value = null
        })
        .catch((e) => {
          renameErrors.value = e.body?.non_field_errors ?? ['An error occurred.']
        })
    }

    const deleteThemeSubmit = async (theme: SavedQuery, conflicts: SavedQuery[]) => {
      await props.deleteTheme(theme, conflicts)
      deleteThemeId.value = null
    }

    const dropdownClick = (command: { actions: 'rename' | 'delete'; type: 'group' | 'theme'; id: number }) => {
      if (command.actions === 'rename') {
        if (command.type === 'group') {
          renameGroupId.value = command.id
        } else {
          renameThemeId.value = command.id
        }
      } else if (command.actions === 'delete') {
        if (command.type === 'group') {
          deleteGroupId.value = command.id
        } else {
          deleteThemeId.value = command.id
        }
      }
    }

    // Ungrouped themes is expanded by default
    const expandedNodeKeys = ref<Set<string>>(new Set(['-1-group']))

    const nodeExpanded = (node: NodeData) => {
      expandedNodeKeys.value.add(node.key)
    }

    const nodeCollapsed = (node: NodeData) => {
      expandedNodeKeys.value.delete(node.key)
    }

    return {
      renameErrors,
      renameGroupId,
      renameGroupName,
      renameGroupSubmit,
      deleteGroupId,
      deleteGroupName,
      deleteGroupSubmit,
      getCoveragePercent,
      savedThemes,
      newThemes,
      convertedStructure,
      createGroupModalVisible,
      nodeClick,
      handleDrop,
      groupUnsaved,
      countChildThemes,
      nodeDragEnter,
      dropTarget,
      updatingTree,
      draggingNode,
      allowDrag,
      groupMenuToggle,
      renameThemeId,
      deleteThemeId,
      renameTheme,
      deletingTheme,
      renameThemeSubmit,
      deleteThemeSubmit,
      dropdownClick,
      nodeExpanded,
      nodeCollapsed,
      expandedNodeKeys,
      sortMode,
      sortOrder,
      sortClick,
      autoThemeIds,
    }
  },
})
export default ThemesTab
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.theme-section
  margin-bottom: 20px
  &:last-child
    margin-bottom: 0

.theme-item
  display: flex
  flex-direction: column
  color: $text-black
  cursor: pointer
  font-size: 14px
  margin-top: 16px

  &:hover
    color: $blue

.unsaved
  font-size: 14px
  color: $red
  margin-left: 6px
  font-style: italic

.group-info,
.theme-info
  display: flex
  justify-content: space-between
  width: 100%
  align-items: end
  margin-top: 2px
  margin-bottom: 2px
  height: 24px
  line-height: 24px
  min-width: 0
  &.selected
    color: $blue
    .theme-name
      font-weight: bold

.theme-info,
.group-info
  .dropdown
    display: none

.theme-header
  display: flex
  font-weight: bold
  text-transform: uppercase
  justify-content: space-between
  color: $text-black
  font-size: 12px
  margin-bottom: 10px

  .icon-wrapper
    margin-left: 3px
    position: relative
    top: 2px

  .bf-button
    padding: 0
    text-transform: uppercase
    font-size: 12px
    margin-left: 4px
    .icon-wrapper
      top: 0px
      margin-right: 4px

.left-text
  display: flex
  text-align: left
  flex: 1 1 0
  min-width: 0
.right-text
  flex-shrink: 0
  text-align: right
  min-width: 0

::v-deep
  .el-tree
    &.is-dragging
      .drag-dots
        visibility: hidden !important

  .el-tree-node__expand-icon
    display: none

  .el-progress-bar__outer
    margin-left: 1px

  .el-progress-bar__inner,
  .el-progress-bar__outer
    border-radius: 0

  .el-tree-node:focus > .el-tree-node__content
    background-color: unset

  .el-tree-node__content
    height: 38px
    &:hover
      background-color: unset
    .node-content
      width: 100%
      color: $text-black
      position: relative
      .drag-dots
        visibility: hidden
        position: absolute
        left: -19px
        top: 10px
      &.drop-target
        &::before
          content: ''
          position: absolute
          top: 0
          left: -12px
          width: 6px
          height: 100%
          background-color: $grey
      &:hover
        color: $blue
        .drag-dots:not(:active)
          visibility: visible
        .theme-info,
        .group-info:not(.ungrouped)
          .dropdown
            display: inline-block
          .coverage
            display: none

.theme-tree
  hr
    border: none
    border-bottom: 1px solid $grey
    margin: 16px 0

.group-summary
  font-style: italic
  margin-left: 2px
  > span
    color: $red

.red
  color: $red

.expanded-icon
  position: relative
  top: 1px
  margin-left: 2px
  transition: transform 0.3s ease
  &.expanded
    transform: rotate(90deg)

.loading
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.sort-wrapper
  cursor: pointer

.up-down
  margin-left: 2px
  position: relative
  top: 0.5px
</style>
