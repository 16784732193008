<template>
  <modal v-if="bodyText" :visible="visible">
    <template #header>Oops, something went wrong.</template>
    <template #content>
      {{ bodyText }}
    </template>
    <template #buttons>
      <a href="javascript:window.Intercom('show')" class="ui huge blue button"> Contact Us </a>
    </template>
  </modal>
  <modal v-else-if="exceededLimits" :visible="visible">
    <template #header>Your plan has exceeded limits</template>
    <template #content>
      <div class="exceeded-limits-content">
        Your Site is currently over the user limit.
        <template v-if="isAdmin">
          Please update your Site settings.
          <br /><br />
          <router-link
            v-if="isAdmin"
            :to="{ name: 'profile', params: { site: currentSite.domain } }"
            class="ui huge blue button"
          >
            Settings
          </router-link>
        </template>
        <template v-else> Please notify your administrator. </template>
        <br /><br />
        or <a href="javascript:window.Intercom('show')"> contact us for help </a>
      </div>
    </template>
  </modal>
  <modal v-else-if="trialing" :visible="visible">
    <template #header>Your trial has ended</template>
    <template #content>
      <div class="content description">
        Hey{{ space_first_name }}, your trial has ended. We'll be in touch soon to discuss your experience and steps
        moving forward, but feel free to
        <a href="javascript:window.Intercom('show')"> contact us </a>
        if you have any questions.
        <br />
      </div>
    </template>
    <template #buttons>
      <a href="javascript:window.Intercom('show')" class="ui huge blue button"> Contact Us </a>
    </template>
  </modal>
  <modal v-else-if="!trialing && !isAdmin" :visible="visible">
    <template #header>Your plan has expired</template>
    <template #content>
      <div class="content description">
        <b>This Site no longer has a valid subscription.</b>
        <br />
        Please contact your site administrator to renew your Kapiche plan and continue using Kapiche.
        <br />
        You still have access to your
        <router-link :to="{ name: 'profile', params: { site: currentSite.domain } }"> Settings </router-link>
      </div>
    </template>
    <template #buttons>
      <button class="ui huge blue button" @click="refresh">Refresh</button>
    </template>
  </modal>
  <modal v-else-if="!trialing && isAdmin" :visible="visible">
    <template #header>Your plan has expired</template>
    <template #content>
      <div class="content description">
        <b>This Site no longer has a valid subscription.</b>
        <br />
        Contact your account manager to renew your plan.
        <br />
        You still have access to your
        <router-link :to="{ name: 'profile', params: { site: currentSite.domain } }"> Settings </router-link>
      </div>
    </template>
    <template #buttons>
      <a href="javascript:window.Intercom('show')" class="ui huge blue button"> Contact Us </a>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from 'components/Modal.vue'

export default defineComponent({
  components: { Modal },
  props: {
    visible: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    trialing: { type: Boolean, default: false },
    exceededLimits: { type: Boolean, default: false },
    currentUser: { type: Object, required: false, default: null },
    currentSite: { type: Object, required: false, default: null },
    bodyText: { type: String, required: false, default: null },
  },
  computed: {
    space_first_name() {
      return this.currentUser && ` ${this.currentUser.first_name}`
    },
  },

  methods: {
    refresh() {
      window.location.reload()
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.header
  font-size: 30px
  margin-bottom: 25px
  color: $text-grey

.content
  line-height: 2
  margin-bottom: 25px
  text-align: center

.actions
  padding: 0 17.5px

.exceeded-limits-content
  color: #383838
  font-size: 18px
  line-height: 1.5
</style>
