<template>
  <div class="signup-page">
    <div class="signup-container">
      <div class="info-panel" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div style="width: 100%; max-width: 550px">
          <h2>
            Take a sneak peek.<br />
            <span class="subtitle">No cards necessary.</span>
          </h2>
          <p>With a Kapiche trial you can:</p>
          <ul>
            <li>Upload your own feedback data (up to 5k rows and 5 columns)</li>
            <li>See sentiment and themes in your feedback</li>
            <li>Measure impact of feedback themes on your metrics</li>
            <li>Access your results for 14 days before your data is wiped</li>
          </ul>
        </div>
      </div>

      <div class="signup-form">
        <div style="width: 100%; max-width: 500px">
          <h1>Create your account</h1>
          <p>Already have an account? <a href="#" @click="doLogin">Log in</a>.</p>
          <VeeForm @submit="(data) => doRegister(data, null)">
            <div class="field">
              <Field v-slot="{ field, errorMessage }" name="Name" rules="required">
                <div class="label-wrapper">
                  <label>Name</label>
                  <span class="error-msg">
                    {{ errorMessage }}
                  </span>
                </div>
                <input v-bind="field" type="text" :class="{ error: errorMessage }" placeholder="Name" />
              </Field>
            </div>

            <div class="field">
              <Field v-slot="{ field, errorMessage }" name="Email" rules="required|email">
                <div class="label-wrapper">
                  <label>Email</label>
                  <span class="error-msg">
                    {{ errorMessage }}
                  </span>
                </div>
                <input v-bind="field" type="text" :class="{ error: errorMessage }" placeholder="example@company.com" />
              </Field>
            </div>

            <div class="field">
              <Field v-slot="{ field, errorMessage }" name="Password" rules="required|validPassword">
                <div class="label-wrapper">
                  <label>Password</label>
                  <span class="error-msg">
                    {{ errorMessage }}
                  </span>
                </div>
                <div class="password-wrapper">
                  <input
                    v-bind="field"
                    :type="`${showPassword ? 'text' : 'password'}`"
                    :class="{ error: errorMessage }"
                    placeholder="Password"
                  />
                  <button class="button-like-link password-toggle" @click="showPassword = !showPassword">
                    {{ showPassword ? 'HIDE' : 'SHOW' }}
                  </button>
                </div>
                <password-checker :class="{ hidden: password.length > max_password_length }" :password="field.value" />
              </Field>
            </div>

            <button class="btn ld-over" type="submit" :class="{ running: doingRegister }" :disabled="doingRegister">
              Next
              <div class="ld ld-ring ld-cycle"></div>
            </button>
          </VeeForm>
          <div class="divider">
            <span class="line"></span>
            <span class="or-text">OR</span>
            <span class="line"></span>
          </div>
          <div class="social-buttons">
            <button
              class="social-signin google-signin ld-over"
              :class="{ running: doingRegister }"
              :disabled="doingRegister"
              @click="doSocialSignin('google-oauth2')"
            >
              <img :src="googleIcon" alt="Google SSO" />
              <span>Sign Up with Google</span>
              <div class="ld ld-ring ld-cycle"></div>
            </button>
            <!--             <button
              class="social-signin microsoft-signin ld-over"
              :class="{ running: doingRegister }"
              :disabled="doingRegister"
              @click="doSocialSignin('Kapiche-B2B')"
            >
              <img :src="microsoftIcon" alt="Microsoft Azure AD" />
              <span>Sign Up with Microsoft Azure AD</span>
              <div class="ld ld-ring ld-cycle"></div>
            </button> -->
          </div>

          <p class="terms">
            By registering an account, you are agreeing to be bound by our
            <a href="https://www.kapiche.com/terms-of-use" target="_blank">terms of use</a>.
          </p>

          <div v-if="registrationErrors.length > 0" class="errors">
            <h3>There was a error registering your account:</h3>
            <p v-if="registrationErrors.length === 1">{{ registrationErrors[0] }}</p>
            <ul v-else>
              <li v-for="error in registrationErrors" :key="error">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <footer class="security-info">
      <h2>Security Info</h2>
      <p>
        <span style="font-weight: bold">
          Your data is not sold to third parties, used for AI training, or used for anything unrelated to the product
          itself. It is deleted after the trial period.
        </span>
        Data privacy is a top concern of ours. We pride ourselves on security so you can relax and focus on finding
        insights.
        <a href="https://security.kapiche.com/" target="_blank">Read about our security and SOC2 standards here.</a>
      </p>
    </footer>
  </div>
</template>

<script lang="ts">
import { reactive, ref, inject } from 'vue'
import { Form as VeeForm, Field, defineRule } from 'vee-validate'

import backgroundImage from 'assets/img/signup-background.png'
import googleIcon from 'assets/icons/google-sso.svg'
import microsoftIcon from 'assets/icons/Azure-Active-Directory.svg'
import PasswordChecker from 'pages/PasswordChecker.vue'
import { isValidPassword, MAX_PASSWORD_LENGTH } from 'src/utils/validators'
import * as TrialSiteAPI from './api/site'
import { Auth0 } from 'src/plugins/auth0'
import { IAuthZeroUser } from 'types/UserTypes'
import { PopupCancelledError } from '@auth0/auth0-spa-js'
import { useRouter } from 'src/router'
import { setAuthHeader } from 'src/utils/auth'
import auth from 'src/api/auth'
import { useStore } from 'vuex'
import * as types from 'src/store/types'
import { Analytics } from 'src/analytics'

// ================================================================== VALIDATION RULES
// these can be declared in or outside the component
defineRule('validPassword', (value: string) => isValidPassword(value))

export default {
  components: {
    VeeForm,
    Field,
    // BfSpinner,
    PasswordChecker,
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const analytics = inject<Analytics>('analytics')

    // --------------- STATE DATA
    const formData = reactive({
      name: '',
      email: '',
      password: '',
    })
    const showPassword = ref(false)
    const max_password_length = MAX_PASSWORD_LENGTH
    const socialUser = ref()
    const doingRegister = ref(false)
    const registrationSuccessful = ref(false)
    const registrationErrors = ref<string[]>([])

    // --------------- METHODS
    // Build the user object we will send to the backend.
    const userForRegistering = (signUpMethod, data) => {
      switch (signUpMethod) {
        case 'social': {
          if (!socialUser.value) return null
          return {
            auth0User: socialUser.value,
          }
        }
        case 'password': {
          return {
            auth0User: {
              email: data.Email,
              name: data.Name,
              password1: data.Password,
              password2: data.Password,
            },
          }
        }
        case 'legacy': {
          return {
            email: data.Email,
            name: data.Name,
            password1: data.Password,
            password2: data.Password,
          }
        }
      }
      return null
    }

    // Do login via redirect then send to Overivew
    const doLogin = async () => {
      await Auth0.auth0.value.loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
        appState: {
          referrer: window.location.pathname,
          // Redirect to the overiew screen after login
          query: { next: router.resolve({ name: 'trial-overview' }).path },
        },
      })
    }

    // Register the user with botanic then send them to login
    const doRegister = async (data, connection = null) => {
      const social = connection != null
      doingRegister.value = true
      registrationSuccessful.value = false
      const user = userForRegistering(social === true ? 'social' : 'password', data)

      if (!user) return

      registrationErrors.value = []
      try {
        analytics?.track.trial.register(user.email, user.name)

        await TrialSiteAPI.registerAccount(user)
        registrationSuccessful.value = true

        if (social === true) {
          // Get the Auth0 token, set the user in our store, and go to overview
          const claims = await Auth0.getIdTokenClaims()
          const token = claims?.__raw
          setAuthHeader({
            auth0Token: claims?.__raw,
            claims: claims,
          })
          try {
            const user = await auth.getUser()
            store.commit(types.SET_USER, { token, user })
            router.push({ name: 'trial-overview' })
          } catch (e) {
            // This has been added to give us more instrumentation to diagnose the
            // occurrences of double log ins. See sc-44829
            console.warn(e)
            store.commit(types.CLEAR_ERRORS)

            // Something went wrong. Go back to regular login.
            await Auth0.auth0.value.loginWithRedirect({
              authorizationParams: {
                prompt: 'login',
                connection: connection,
              },
              appState: {
                referrer: window.location.pathname,
                // Redirect to the overiew screen after login
                query: { next: router.resolve({ name: 'trial-overview' }).path },
              },
            })
          }
        } else {
          await Auth0.auth0.value.loginWithRedirect({
            authorizationParams: {
              prompt: 'login',
              login_hint: data.Email,
            },
            appState: {
              referrer: window.location.pathname,
              // Redirect to the overview screen after login
              query: { next: router.resolve({ name: 'trial-overview' }).path },
            },
          })
        }
      } catch (error) {
        console.warn(error)
        const responseData = error.response.data
        if (error.status === 500) {
          registrationErrors.value = ['Something went wrong on our end. Please try again.']
        } else if (typeof responseData === 'string') {
          console.warn(responseData)
          registrationErrors.value = 'Something went wrong on our end. Please contact support.'
        }
        // error body is an array
        else if (Array.isArray(responseData)) {
          console.warn(responseData)
          registrationErrors.value = 'Something went wrong on our end. Please contact support.'
        }
        // error body is an object
        else if (typeof responseData === 'object') {
          const newErrors = []
          Object.keys(responseData).forEach((key) => {
            const theError = responseData[key]
            if (Array.isArray(theError)) {
              newErrors.push(theError.join())
            } else {
              newErrors.push(theError)
            }
          })
          registrationErrors.value = newErrors
        }
        // error body was something else? unlikely but just in case
        else {
          registrationErrors.value = ['Unexpected error:' + JSON.stringify(responseData, null, 2)]
        }
      } finally {
        doingRegister.value = false
      }
    }

    // Show the right popup for the social connection then register the user.
    const doSocialSignin = async (connection) => {
      doingRegister.value = true
      registrationErrors.value = []
      try {
        socialUser.value = null
        /*
         * 2 minute timeout on login.  After 2 minutes we start the popup
         * process again.  This resets the popup to the beginning.  If we don't
         * do this, we can get stuck with a blank unusable popup.
         */
        await Auth0.auth0.value.checkSession()
        await Auth0.auth0.value.loginWithPopup(
          {
            authorizationParams: {
              prompt: 'login',
              connection: connection,
            },
          },
          { timeoutInSeconds: 120 },
        )
        socialUser.value = (await Auth0.auth0.value.getUser()) as IAuthZeroUser | null
        await doRegister(socialUser.value, connection)
      } catch (e) {
        if (!(e instanceof PopupCancelledError)) {
          registrationErrors.value = [e.error_description]
          await Auth0.logout({ openUrl: false })
          socialUser.value = null
        } else {
          registrationErrors.value = [e]
        }
      } finally {
        doingRegister.value = false
      }
    }

    return {
      // STATE DATA
      ...formData,
      showPassword,
      max_password_length,
      doingRegister,
      registrationErrors,

      // IMAGES
      googleIcon,
      microsoftIcon,
      backgroundImage,

      // METHODS
      doLogin,
      doRegister,
      doSocialSignin,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.signup-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f6f6f6;
  margin: 0;
  overflow-y: auto;

  .signup-container {
    display: flex;
    height: 100%;
  }

  footer {
    padding: 50px;
    width: 100%;
    color: #383838;

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 44px;
    }

    p {
      max-width: 40%;
      font-size: 16px;

      a {
        color: $blue;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.signup-container {
  width: 100%;
  flex-grow: 1; // Ensure vertical space is occupied

  .info-panel,
  .signup-form {
    flex: 1; // Equal width for both containers
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .info-panel {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center; // Center content horizontally
    font-size: 18px;
    color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; // To position the overlay absolutely

    // Create the overlay
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(21, 43, 57, 0.9); // Light gray with 80% opacity
      z-index: 1; // Place it below the content
    }

    // Ensure content is above the overlay
    > * {
      position: relative;
      z-index: 2; // Place the content above the overlay
    }

    h2 {
      font-size: 36px;
      margin-bottom: 20px;
      font-weight: 900;
      line-height: 44px;
      .subtitle {
        color: #95a6ac;
        font-weight: 400;
      }
    }

    p {
      line-height: 28px;
      margin-bottom: 0;
    }

    ul {
      margin-top: 0;
      padding: 0 20px;
      line-height: 28px;
    }
  }

  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center; // Center content horizontally

    h1 {
      font-size: 36px;
      margin-bottom: 15px;
      font-weight: 900;
      line-height: 44px;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 18px;
      margin-bottom: 15px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;

      .field {
        margin-bottom: 20px;
        width: 100%;

        .label-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          width: 100%; // Ensure labels take full width of the form

          label {
            font-weight: bold;
          }

          .error-msg {
            color: $red;
            font-size: 14px;
            font-style: italic;
          }
        }

        input {
          padding: 10px;
          border: 1px solid #e5e5e5;
          width: 100%; // Ensure inputs take full width of the form
          height: 50px;
          border-radius: 0px;

          &.error {
            border-color: $red;
          }

          &:focus,
          &:active {
            outline: none;
            border-color: $blue;
            border-width: 2px;
          }
        }
      }

      .password-wrapper {
        position: relative;
        width: 100%; // Ensure inputs take full width of the form

        input {
          padding-right: 80px; // Space for the toggle button
          margin-bottom: 0;
        }

        button {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          background: none;
          border: none;
          color: #95a6ac;
          cursor: pointer;
          font-size: 14px;

          &:hover,
          &:active {
            text-decoration: underline;
            background: none;
          }
        }
      }

      > button {
        margin: 5px 0;
        padding: 10px 40px;
        font-size: 18px;
        line-height: 28px;
        background-color: $blue;
        color: white;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        width: 100%; // Make the div span full width of the form
        text-align: center; // Center the text inside the div

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background-color: $blue-light;
        }
        &:active {
          background-color: $blue;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .divider {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 500px;
      margin: 15px 0;

      .line {
        flex: 1;
        height: 1px;
        background-color: #d8d8d8;
      }

      .or-text {
        margin: 0 10px;
        color: #6c757d;
        font-weight: bold;
      }
    }

    .social-signin {
      background-color: white;
      color: #383838;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 15px;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      margin: 5px auto;
      cursor: pointer;
      display: flex; // Allow icon and text
      align-items: center;
      justify-content: center;
      gap: 15px;
      min-width: 220px;

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        border-color: $blue;
      }
      &:disabled {
        cursor: not-allowed;
      }

      > img {
        width: 20px;
        height: 20px;
        margin-right: auto; /* This will left align the image */
      }

      > span {
        flex-grow: 1; /* This will make the text span take the remaining space */
        text-align: center; /* This will center align the text */
      }
    }

    .terms {
      margin-top: 20px;
      font-size: 16px;
      text-align: center;
      font-style: italic;
      line-height: 24px;

      a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .errors {
      margin-top: 30px;
      color: $red;
      font-size: 16px;
      text-align: center;

      p {
        margin-bottom: 10px;
      }

      ul {
        padding-left: 20px;
        margin: 0;
        list-style-type: disc;
      }
    }
  }
}

.social-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
