<template>
  <modal :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Save new Dashboard as...</div>
    </template>
    <template #content>
      <VeeForm ref="saveAsObserver" v-slot="{ meta, handleSubmit }">
        <form class="save-as-dashboard" @submit.prevent="handleSubmit(saveNewDashboard)">
          <div class="form-body">
            <Field
              v-slot="{ field, errors }"
              mode="aggressive"
              rules="required|max:200"
              vid="name"
              name="Dashboard title"
              tag="div"
              class="input-section"
            >
              <label>
                <span>Dashboard title</span>
                <bf-text-input
                  v-bind="field"
                  :value="saveAsFormValues.name"
                  class="nameInput"
                  placeholder="Enter title"
                  :errors="errors"
                  focus
                  @input="saveAsFormValues.name = $event"
                />
              </label>
            </Field>
            <div class="buttons">
              <bf-button type="button" size="big" @click="$emit('close')"> Cancel </bf-button>
              <bf-button
                type="submit"
                color="blue"
                size="big"
                :disabled="!meta.valid || meta.pending || isSaveAsFormUpdating || !saveAsFormValues.name"
              >
                Save new Dashboard
              </bf-button>
            </div>
          </div>
        </form>
      </VeeForm>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, ref, computed, inject } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import ProjectAPI from 'src/api/project'
import { Analytics } from 'src/analytics'
import { Analysis } from 'src/types/AnalysisTypes'
import { Dashboard } from 'src/types/DashboardTypes'
import Modal from 'components/Modal.vue'
import BfButton from 'src/components/Butterfly/BfButton/BfButton.vue'
import BfTextInput from 'src/components/Butterfly/BfTextInput/BfTextInput.vue'
import { SavedQuery } from 'src/types/Query.types'

export default defineComponent({
  name: 'SaveAsModal',
  components: {
    Modal,
    VeeForm,
    Field,
    BfButton,
    BfTextInput,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const analytics = inject<Analytics>('analytics')

    const saveAsObserver = ref<InstanceType<typeof VeeForm>>()
    const isSaveAsFormUpdating = ref(false)
    const saveAsFormValues = ref({
      name: '',
    })

    const currentAnalysis = computed(() => store.getters.currentAnalysis as Analysis)
    const currentDashboard = computed(() => store.getters.currentDashboard as Dashboard)
    const dashboardQueries = computed(() => store.getters.dashboardQueries as SavedQuery[])
    const dashboardDateRange = computed(() => store.getters.dashboardDateRange)
    const dashboardWidgetConfig = computed(() => store.getters.dashboardWidgetConfig)

    const saveNewDashboard = async (): Promise<void> => {
      const { name } = saveAsFormValues.value
      try {
        isSaveAsFormUpdating.value = true

        const res = await ProjectAPI.createDashboard({
          name,
          analysis: currentAnalysis.value.id,
          queries: dashboardQueries.value.map(({ id }) => ({ id })),
          slice1_name: currentDashboard.value.slice1_name,
          slice2_name: currentDashboard.value.slice2_name,
          config: {
            dateRange: dashboardDateRange.value,
            widgets: dashboardWidgetConfig.value,
            queryRows: [], // TODO:
            compareQueryRows: [], // TODO:
            compareMode: false, // TODO:
          },
        })

        // update dashboards list
        // await this.loadAnalysis()

        emit('close')

        const oldDashboardId = currentDashboard.value.id
        const newDashboardId = res.body.id
        router.push({
          name: 'analysis-dashboard-overview',
          params: {
            site: route.params.site,
            projectId: route.params.projectId,
            analysisId: route.params.analysisId,
            dashboardId: newDashboardId,
          },
        })

        analytics?.track.dashboard.saveAs(oldDashboardId, newDashboardId)
      } catch (error: any) {
        if (error.status === 400 && error.body && error.body.name) {
          saveAsObserver.value?.setErrors({ 'Dashboard title': error.body.name[0] })
        } else if (error.bodyText) {
          const parsedBody = JSON.parse(error.bodyText)
          if (parsedBody.name && parsedBody.name.length > 0) {
            saveAsObserver.value?.setErrors({ 'Dashboard title': parsedBody.name[0] })
          }
        }
      } finally {
        isSaveAsFormUpdating.value = false
      }
    }

    return {
      saveNewDashboard,
      isSaveAsFormUpdating,
      saveAsFormValues,
      saveAsObserver,
    }
  },
})
</script>
<style lang="scss" scoped></style>
