<template>
  <div class="query-builder">
    <div v-if="$slots.title">
      <slot name="title" />
    </div>
    <div class="query-container">
      <div v-if="queryRows.length > 0 && !collapsed" class="query-rows">
        <div v-if="featureFlags.synonyms_calibrate_radius" class="eight wide column sliderbox">
          <label>Suggest synonyms within</label>
          <bf-slider v-model="synonymsMaxRadius" :min="40" :max="70" :step="1" />
          <label>{{ synonymsMaxRadius / 100 }}</label>
        </div>
        <query-row
          v-for="(row, i) in queryRows"
          :key="queryId + '-' + i"
          :text-variants="textVariants"
          :first-row="i === firstRow"
          :editable="true"
          :query="row"
          :location="location"
          :allowed-child-themes="allowedChildThemes"
          :filter-warnings="filterWarnings"
          :synonyms-max-radius="synonymsMaxRadius / 100"
          :query-name="queryName"
          @update-row="updateRow(i, $event)"
          @row-deleted="deleteRow(i)"
        />
      </div>
      <div class="query-bar-buttons">
        <dropdown no-padding>
          <template #trigger>
            <button class="add-filter">
              <icon name="plus" color="#21ba45" :size="14" />
              Add filter
            </button>
          </template>
          <template #default="{ isActive }">
            <segment-menu
              :query-rows="queryRows"
              :concept-names="conceptList"
              :themes="allowedChildThemes"
              :is-active="isActive"
              :allow-structured="allowStructured"
              :allow-text="allowText"
              :allow-operators="allowOperators"
              :allow-themes="allowThemes"
              :allow-concepts="allowConcepts"
              :allow-dates="allowDates"
              @add-filter="addRow"
            />
          </template>
        </dropdown>
        <button v-if="queryRows.length > 0 && !collapsed" class="clear-query" @click="clearRows()">
          <icon name="eraser" color="#068ccc" :size="14" />
          Clear all filters
        </button>
        <div v-if="collapsed" class="filter-summary" @click="collapsed = false">
          {{ queryRows.length }} filter{{ queryRows.length === 1 ? '' : 's' }} hidden (click to expand)
        </div>
        <button v-if="collapsable" class="collapse-button" @click="collapsed = !collapsed">
          <icon :name="collapsed ? 'double-chevron-down' : 'double-chevron-up'" color="#068ccc" :size="13" />
          {{ collapsed ? 'Expand' : 'Collapse' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import { difference, isEmpty, union } from 'lodash'
import { QueryElement, SavedQuery, QueryLocation } from 'src/types/Query.types'
import QueryRow from '../query/QueryRowV2.vue'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import SegmentMenu from 'src/pages/dashboard/SegmentMenu.vue'
import { getContainedQueries } from './ThemeBuilder.utils'
import QueryAPI from 'src/api/query'
import QueryUtils from 'src/utils/query'
import { Site } from 'src/store/modules/app'
import { Project } from 'src/types/ProjectTypes'
import { Analysis } from 'src/types/AnalysisTypes'
import Icon from 'components/Icon.vue'
import { BfSlider } from 'components/Butterfly'
import { useStore } from 'vuex'

export interface FilterWarning {
  field: string
  value: string | number
  text: string
}

const FilterBar = defineComponent({
  components: {
    QueryRow,
    Dropdown,
    SegmentMenu,
    Icon,
    BfSlider,
  },
  props: {
    queryId: { type: Number, required: true },
    queryName: { type: String, required: false, default: '' },
    queryScope: { type: String as PropType<'sentence' | 'frame'>, required: true },
    queryRows: { type: Array as PropType<QueryElement[]>, required: true },
    conceptList: { type: Array as PropType<string[]>, required: false, default: () => [] },
    savedQueries: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    currentSite: { type: Object as PropType<Site | null>, required: false, default: null },
    currentProject: { type: Object as PropType<Project | null>, required: false, default: null },
    currentAnalysis: { type: Object as PropType<Analysis | null>, required: false, default: null },
    // Allow structured fields in segment menu
    allowStructured: { type: Boolean, required: false, default: true },
    // Allow custom text addition in segment menu
    allowText: { type: Boolean, required: false, default: true },
    // Allow operators in segment menu
    allowOperators: { type: Boolean, required: false, default: true },
    // Allow themes in segment menu
    allowThemes: { type: Boolean, required: false, default: true },
    // Allow concepts in segment menu
    allowConcepts: { type: Boolean, required: false, default: true },
    // Allow date fields in segment menu
    allowDates: { type: Boolean, required: false, default: true },
    collapsable: { type: Boolean, required: false, default: true },
    location: { type: String as PropType<QueryLocation>, required: true },
    filterWarnings: { type: Array as PropType<FilterWarning[]>, required: false, default: () => [] },
  },
  setup(props, { emit }) {
    const store = useStore()
    const featureFlags = computed<Record<string, boolean>>(() => store.getters['featureFlags'])
    const textVariants = ref<Record<string, string[]>>({})
    const collapsed = ref(false)
    const synonymsMaxRadius = ref<number>(65)

    watch(collapsed, () => {
      emit('collapse-change', collapsed.value)
    })

    const firstRow = computed(() => {
      return props.queryRows.findIndex((q) => q['type'] !== 'all_data')
    })

    const isAutoTheme = computed(() => {
      return props.queryRows.some((q) => q.field === 'aitopic')
    })

    const setQueryRows = (rows: typeof props.queryRows) => {
      emit('set-query-rows', rows)
    }

    const deleteRow = (index: number) => {
      const rows = props.queryRows.filter((_, i) => i !== index)
      setQueryRows(rows)
    }

    const updateRow = (index: number, change: Partial<QueryElement>) => {
      const rows = props.queryRows.map((v, i) => {
        return i === index ? { ...v, ...change } : v
      })

      setQueryRows(rows)
    }

    const addRow = (row: QueryElement) => {
      const { queryRows } = props

      const existingRow = queryRows.find((r) => {
        return row.field === r.field && (r.operator === 'is' || r.operator === 'is not')
      })
      // NOTE: "is" and "is not" are only used for numeric and categorical fields,
      // text type uses the operator "includes" for the same purpose.
      // so this doesn't affect adding text to the filters.

      if (existingRow) {
        if (Array.isArray(existingRow.values) && Array.isArray(row.values)) {
          updateRow(queryRows.indexOf(existingRow), {
            values: [...existingRow.values, ...row.values],
          })
        }
      } else {
        setQueryRows([...queryRows, row])
      }
    }

    const clearRows = () => {
      setQueryRows([])
    }

    const allowedChildThemes = computed(() => {
      if (!props.allowThemes) return []
      return props.savedQueries
        .filter((q) => {
          const contained = getContainedQueries(q, props.savedQueries).map((q) => q.id)
          if (contained.includes(props.queryId)) {
            // The selected theme is contained within this theme
            // and would result in a circular reference
            return false
          }
          return true
        })
        .filter((q) => q.id !== props.queryId)
        .map((q) => ({ name: q.name, id: q.id }))
    })

    const fetchQueryTextVariants = () => {
      if (!props.currentProject || !props.currentSite || !props.currentAnalysis) return

      const botanicQuery = QueryUtils.queryRowsToBotanic(props.queryRows, props.queryScope)

      return QueryAPI.getQueryTextVariants(
        props.currentProject.id,
        props.currentProject.chrysalis_ref,
        props.currentAnalysis.topic_framework_id,
        botanicQuery,
        props.savedQueries,
      ).then((result) => {
        textVariants.value = result
      })
    }

    const queryTextValues = computed<string[]>(() => {
      let textArr: Array<string> = []
      props.queryRows.forEach((q) => {
        if (q.type === 'text') {
          textArr = union(textArr, q.values)
        }
      })
      return textArr
    })

    watch(
      queryTextValues,
      (newVal, oldVal) => {
        if (!oldVal) return
        const diff = difference(newVal, oldVal)
        if (isEmpty(diff)) return
        fetchQueryTextVariants()
      },
      {
        immediate: true,
      },
    )

    return {
      featureFlags,
      firstRow,
      deleteRow,
      updateRow,
      addRow,
      clearRows,
      allowedChildThemes,
      textVariants,
      collapsed,
      synonymsMaxRadius,
      isAutoTheme,
    }
  },
})

export default FilterBar
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.query-builder
  background: #fff
  padding: 30px
  border-radius: 4px
  padding: 25px 30px
  box-shadow: $box-shadow

.query-bar-buttons
  font-size: 15px
  display: flex
  align-items: center

  ::v-deep
    .dropdown-menu
      border: 1px solid #fafafa

  > *
    &:not(:last-child)
      margin-right: 50px

  .add-filter,
  .clear-query
    border: none
    font-weight: bold
    padding: 0

  .add-filter
    color: $green
  .clear-query
    color: $blue
  .icon-wrapper
    margin-right: 4px

  .collapse-button
    color: $blue
    border: 0
    font-weight: bold
    margin-left: auto
    font-size: 14px

    .icon-wrapper
      position: relative
      top: 1px

  button
    cursor: pointer
    white-space: nowrap

  .filter-summary
    color: $blue
    font-weight: bold
    cursor: pointer

.query-rows + .query-bar-buttons
  margin-top: 15px

::v-deep
  .query-row
    &:not(:first-of-type)
      margin-top: 15px

.sliderbox
  display: flex !important
  justify-content: flex-start
  align-items: center

  .bf-slider
    width: 100px
    margin: 0 15px
</style>
