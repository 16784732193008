<template>
  <div>
    <project-label name="All Projects" color="black" :invert="hasFilters()" @click="$emit('reset-label-filters')" />
    <project-label
      v-for="item in labels"
      :key="item.id"
      :name="item.name"
      :value="item.id"
      :color="item.color"
      :invert="!isActive(item.id)"
      @click="$emit('toggle-label-filter', item.id)"
    >
    </project-label>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import LabelAPI from 'src/api/labels'
import { Label } from 'src/pages/ProjectLabels/ProjectLabels.util'
import ProjectLabel from 'components/site/ProjectLabels/ProjectLabel.vue'

export default defineComponent({
  components: {
    ProjectLabel,
  },
  props: {
    filterLabels: { type: Array, default: () => [] },
  },
  setup() {
    const isFetching = ref(true)
    const labels = ref<Label[]>([])
    const newLabel = ref<undefined | Label>(undefined)
    const fetchLabels = async (q: string) => {
      isFetching.value = true
      const res = await LabelAPI.getLabels(undefined, q, q ? undefined : 50)
      labels.value = res?.results ?? []
      isFetching.value = false
      return res
    }

    onMounted(() => {
      fetchLabels('')
    })

    return {
      isFetching,
      newLabel,
      labels,
      fetchLabels,
    }
  },
  methods: {
    isActive(label: number) {
      return this.filterLabels.includes(label)
    },
    hasFilters() {
      return this.filterLabels.length > 0
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.label
  margin-bottom: 10px
</style>
