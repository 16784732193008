<template>
  <modal :visible="visible" @close="close">
    <template #header>
      <div>Set unique ID fields</div>
    </template>
    <template #content>
      <div class="content">
        <p class="help-text">
          Unique ID fields are used to identify individual records.<br />
          They should create a combination of values that are never duplicated.<br />
          They must be either a <strong>DATE</strong>, <strong>DATETIME</strong>, <strong>CATEGORY</strong>, or
          <strong>NUMERICAL</strong> data type.<br />
        </p>
        <div class="multiselect-container">
          <p class="multiselect-label">Choose one or more fields</p>
          <el-select
            v-if="options.length"
            :model-value="selected"
            class="options-list"
            multiple
            filterable
            placeholder='Choose fields e.g. "Survey_ID"'
            type="primary"
            no-match-text="No matching fields"
            @update:model-value="selected = $event"
          >
            <el-option
              v-for="o in options"
              :key="o.name"
              :label="o.name + ' ' + o.description"
              :value="o.name"
              :disabled="o.disabled"
              type="primary"
            />
          </el-select>
        </div>
      </div>
    </template>
    <template #buttons>
      <div class="footer">
        <bf-button color="grey" size="large" @click="close"> Cancel </bf-button>
        <bf-button color="blue" size="large" @click="done"> Done </bf-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { clone } from 'lodash'
import { BfButton } from 'components/Butterfly'
import Modal from 'components/Modal.vue'
import { RecordKeyDisplayType, SchemaColumn } from 'types/SchemaTypes'
import { ALLOWED_TYPES } from 'components/widgets/UniqueIdFieldsModal/UniqueIdUtils'

export default defineComponent({
  components: {
    Modal,
    BfButton,
  },
  props: {
    visible: { type: Boolean, default: false },
    schema: { type: Array as PropType<Array<SchemaColumn>>, required: true },
    currentFields: { type: Array as PropType<Array<string>>, required: true },
  },
  data() {
    return {
      selected: [] as Array<string>,
    }
  },
  computed: {
    options(): RecordKeyDisplayType {
      return this.schema.map((col) => {
        const isDisabled = !ALLOWED_TYPES.includes(col.type)
        const description = isDisabled ? '(' + col.typename + ' cannot be selected.)' : ''
        return {
          disabled: isDisabled,
          name: col.name,
          description: description,
        }
      })
    },
  },
  watch: {
    currentFields() {
      this.resetState()
    },
  },
  methods: {
    resetState() {
      this.selected = this.currentFields
    },
    close() {
      this.resetState()
      this.$emit('close')
    },
    done() {
      this.$emit('values-changed', clone(this.selected))
      this.close()
    },
  },
})
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
.title, .help-text
  width: 100%
  margin-bottom: 10px
  line-height: 1.5
.multiselect-container
  width: 100%
  max-width: 500px
  margin: 10px auto
  .options-list
    width: 100%
  .multiselect-label
    font-weight: bold
    text-align: left
    margin-bottom: 5px
</style>
