<template>
  <div class="widget-sort-controls">
    <div class="sort-inputs">
      <span>Show</span>
      <el-dropdown class="dropdown-input" trigger="click" @command="$emit('update-visible-count', $event)">
        <div>
          {{ visibleCount === 999 ? 'All' : `Top ${visibleCount}` }}
          <i class="icon dropdown"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="option in countOptions" :key="option[0]" :command="option[1]">
              {{ option[0] }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <span> by </span>
      <el-dropdown class="dropdown-input" trigger="click" @command="$emit('update-sort-method', $event)">
        <div>
          {{ formattedSortOptions.find((o) => o[1] === selectedSortOption)?.[0] }}
          <i class="icon dropdown"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="option in formattedSortOptions" :key="option[0]" :command="option[1]">
              {{ option[0] }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'SortControls',
  props: {
    // Sort options, either an array of strings or an array of [label, value]
    sortOptions: {
      type: Array as PropType<[string, string][] | string[]>,
      required: true,
    },
    // The currently selected sort option value
    selectedSortOption: {
      type: String,
      required: true,
    },
    // How many items are visible (e.g top 5, top 10)
    visibleCount: {
      type: Number,
      required: true,
    },
    // The maximum number of visible items
    maxVisibleCount: {
      type: Number,
      default: 999,
    },
    // Available options for the visible count dropdown
    visibleOptions: {
      type: Array as PropType<number[]>,
      default: () => [5, 10, 15, 20, 25, 30, 40],
    },
  },
  emits: ['update-visible-count', 'update-sort-method'],
  setup(props) {
    // We accept either an array of strings or an array of
    // [string, string] (label, value) tuples. This computed
    // property normalizes the prop to the latter format.
    const formattedSortOptions = computed(() => {
      if (props.sortOptions[0] instanceof Array) {
        return props.sortOptions as [string, string][]
      } else {
        return (props.sortOptions as string[]).map((option) => [option, option] as const)
      }
    })

    const countOptions = computed(() => {
      const options = props.visibleOptions.map((option) => [`Top ${option}`, option] as [string, number])
      const filtered = options.filter((option) => option[1] <= props.maxVisibleCount)
      return filtered.concat([['All', 999]])
    })

    return {
      formattedSortOptions,
      countOptions,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.widget-sort-controls {
  user-select: none;

  :deep(.el-dropdown) {
    font-size: 15px;
  }

  > div:nth-child(1) {
    font-size: 15px;
    font-weight: bold;
  }

  .dropdown-input {
    background: $blue;
    color: $white;
    border: 1px solid $blue;
    cursor: pointer;
    .icon {
      color: $white;
      margin-right: 0;
    }
  }
  > div > span {
    border: 1px solid $grey;
    color: $grey-mid;
    display: inline-block;
    background: $grey-light-background;
    font-weight: bold;
  }
  > div {
    > span,
    .dropdown-input {
      height: 30px;
      line-height: 28px;
      margin-right: 10px;
      padding: 0 10px;
      border-radius: 3px;
    }
  }
}

.widget.zoomed {
  .widget-sort-controls {
    padding-left: 0;
  }
}

.sort-inputs > * {
  margin-bottom: 5px;
}
</style>
