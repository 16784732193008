<template>
  <div class="workbench-header">
    <router-link :to="{ name: 'home' }" active-class="never-active">
      <img class="kapiche-logo" :src="kapiche_logo" />
    </router-link>

    <!-- Breadcrumb menu -->
    <div v-if="dashboard" class="breadcrumbs">
      <div class="level">
        <span>Project</span>
        <router-link v-if="dashboard.project" :to="{ name: 'project-details' }">
          {{ dashboard.project.name }}
        </router-link>
      </div>
      <icon :size="14" name="chevron-right" />
      <div class="level">
        <span>Analysis</span>
        <router-link v-if="dashboard.analysis" :to="{ name: 'view-analysis' }">
          {{ dashboard.analysis.name }}
        </router-link>
      </div>
      <icon :size="14" name="chevron-right" />
      <div class="level">
        <span>Dashboard</span>
        <router-link :to="{ name: 'analysis-dashboard-overview' }">
          {{ dashboard.name }}
        </router-link>
      </div>
      <template v-if="isDrilldown">
        <icon :size="14" name="chevron-right" />
        <div class="">
          <span>Theme</span>
          <router-link
            :to="{
              name: 'analysis-dashboard-query-view',
              params: {
                queryId: 123,
              },
            }"
          >
            {{ 'query name' }}
          </router-link>
        </div>
      </template>
    </div>
    <div
      v-if="currentUser"
      class="back-to-analysis"
      :style="{
        marginLeft: 'auto',
      }"
    >
      <router-link :to="{ name: 'storyboard' }">
        <icon :size="14" name="chevron-left" color="#fff" />
        Back to analysis
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

import Icon from 'components/Icon.vue'
import { Dashboard } from 'src/types/DashboardTypes'
import { UserProfile } from 'src/types/UserTypes'

export default defineComponent({
  name: 'WorkbenchHeader',
  components: {
    Icon,
  },
  props: {},
  setup() {
    const store = useStore()

    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
    const dashboard = computed(() => store.getters.currentDashboard as Dashboard | null)

    const isDrilldown = false

    return {
      isDrilldown,
      currentUser,
      dashboard,
      /* eslint-disable @typescript-eslint/no-require-imports */
      kapiche_logo: require('assets/img/dashboards/kapiche_mark_inverse.svg'),
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

$icon-color: rgba(255, 255, 255, 0.3);

.workbench-header {
  display: flex;
  align-items: center;
  background-color: $blue-dark;
  padding: 16px 30px;

  a {
    cursor: pointer;
  }
}

.kapiche-logo {
  height: 40px;
  margin-right: 16px;
}

.back-to-analysis {
  a {
    color: $grey-mid;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  :deep(.icon-wrapper > span) {
    margin-right: 4px;
    background-color: $grey-mid !important;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-left: 10px;

  :deep(.icon-wrapper > span) {
    background-color: $icon-color !important;
  }

  .level {
    display: flex;
    flex-direction: column;

    span {
      color: $icon-color;
      font-size: 12px;
      text-transform: uppercase;
    }

    a {
      color: $white;
      font-size: 16px;
    }
  }

  .icon-wrapper {
    margin: 0 12px;
  }
}
</style>
