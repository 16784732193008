<template>
  <div>
    <!-- This component automatically determines whether to show a data tutorial -->
    <data-tutorial ref="dataTutorial"></data-tutorial>

    <div class="create-project-container wizard-layout" :class="`step-${step}`">
      <el-radio-group v-if="isDev" :model-value="step" class="step-select" @update:model-value="step = $event">
        <el-radio :label="1" :value="1">Step 1: Upload</el-radio>
        <el-radio :label="2" :value="2">Step 2: Schema</el-radio>
        <el-radio :label="3" :value="3">Step 3: Add Context</el-radio>
        <el-radio :label="4" :value="4">Step 4: Project Details</el-radio>
      </el-radio-group>

      <progress-indicator :num-steps="4" :step="step"></progress-indicator>
      <h2 v-show="step === 1">Create New Project</h2>
      <div v-show="step === 2">
        <h2>Select Column Data Types</h2>
        <div class="subheader">
          Please review and correct the data types for each column. You must have <strong>at least one TEXT</strong>
          field.
        </div>
        <div class="schema-actions">
          <div>
            <bf-button
              size="tiny"
              color="white"
              :loading="schemaActionsLoading.ignoreAll"
              @click="setAllFieldsToIgnore"
            >
              <bf-spinner :class="{ hidden: !schemaActionsLoading.ignoreAll }" class="button-spinner" />
              <span :class="{ hidden: schemaActionsLoading.ignoreAll }" class="action-text"> Ignore all </span>
            </bf-button>
            <bf-button size="tiny" color="white" @click="setAllFieldsToInferredTypes">
              <bf-spinner :class="{ hidden: !schemaActionsLoading.reset }" class="button-spinner" />
              <span :class="{ hidden: schemaActionsLoading.reset }" class="action-text"> Reset to suggested </span>
            </bf-button>
            <bf-button
              size="tiny"
              color="white"
              :loading="schemaActionsLoading.collapseIgnored"
              @click="handleExpandClick"
            >
              <bf-spinner :class="{ hidden: !schemaActionsLoading.collapseIgnored }" class="button-spinner" />
              <span :class="{ hidden: schemaActionsLoading.collapseIgnored }" class="action-text">
                {{ collapseIgnored ? 'Expand ignored' : 'Collapse ignored' }}
              </span>
            </bf-button>
          </div>
          <div>
            <bf-button
              v-if="subscription.languageTranslation"
              size="tiny"
              color="white"
              class="action-text"
              @click="showTranslateModal = true"
            >
              Languages
            </bf-button>
            <bf-button size="tiny" color="white" class="action-text" @click="showAddField = true">
              Custom Columns
            </bf-button>
            <bf-button size="tiny" color="white" class="action-text" @click="showUniqueIdFieldsModal = true">
              Set unique id fields
            </bf-button>
          </div>
        </div>
      </div>
      <!-- Step 1: Upload -->
      <div v-show="step === 1" class="step-container">
        <data-upload
          v-show="!useIntegration"
          ref="dataUpload"
          :file-row-limit="fileRowLimit"
          :file-column-limit="fileColumnLimit"
          :project-row-limit="subscription.projectRowLimit"
          :project-column-limit="subscription.projectColumnLimit"
          @upload-failed="onUploadFailed"
          @file-selected="onUploadStarted"
          @file-ready="onFileReady"
          @use-integration="setUseIntegration"
          @show-tutorial="showDataTutorial"
        />
        <integration-import
          v-show="useIntegration"
          ref="integrationImport"
          :qualtrics-column-whitelist="featureFlags.qualtrics_column_whitelist"
          @provider-selected="setIntegrationProvider"
          @column-whitelist-changed="setIntegrationColumnWhitelist"
          @survey-selected="setIntegrationInfo"
          @folder-selected="setFolder"
        />
      </div>

      <!-- Step 2: Schema -->
      <div v-show="step === 2" class="step-container">
        <schema-table
          ref="schemaTable"
          class="schema-table"
          :schema="schema"
          :extra-type-options="extraTypeOptions"
          :samples="frozenSamples"
          :num-records="numRecords"
          :column-limit="fileColumnLimit"
          :row-limit="fileRowLimit"
          :collapse-ignored="collapseIgnored"
          :enforce-row-limit="subscription.enforceRowLimit"
          @type-changed="updateSchemaType"
        >
        </schema-table>
      </div>

      <div v-show="featureFlags.aitopic_classification && step === 3" class="step-container">
        <h2>Briefly describe the dataset in one sentence</h2>
        <p>Try to include the type of data and type of business</p>
        <el-input
          :model-value="datasetDescription"
          class="styled-input"
          :rows="2"
          type="textarea"
          placeholder='e.g. "An NPS survey of grocery store customers"'
          @update:model-value="datasetDescription = $event"
        />

        <h3>What type of data is this?</h3>
        <el-select
          :model-value="selectedDatasetType"
          class="dataset-questions"
          @update:model-value="selectedDatasetType = $event"
        >
          <el-option v-for="type in datasetTypes" :key="type.value" :label="type.label" :value="type.value">
            {{ type.label }}
          </el-option>
        </el-select>

        <template v-if="selectedDatasetType === 'survey'">
          <h3>Let's confirm some info about the dataset</h3>
          <div v-for="(field, index) in textFieldMetadata" :key="index">
            <p>For the text field</p>
            <p>
              <strong>"{{ field.text_field_name }}"</strong>
            </p>
            <p>Please review the intended question is being asked</p>
            <el-input
              :model-value="field.value"
              class="styled-input"
              type="textarea"
              :rows="2"
              placeholder='e.g. "Why did you give that score"'
              @update:model-value="field.value = $event"
            />
          </div>
        </template>
      </div>

      <!-- Step 3: Project Details -->
      <h2 v-show="step === 4">Project Details</h2>

      <div v-show="step === 4" class="step-container">
        <input
          v-model="name"
          type="text"
          class="streamline-input"
          :class="{ error: nameError || nameExistsError }"
          maxlength="60"
          placeholder="Project Name"
          @keydown.enter="goNext"
        />
        <div v-if="nameError" class="field-error">Your project needs a name</div>
        <div v-if="nameExistsError" class="field-error">A project with this name already exists</div>

        <div id="project-settings">
          <toggle-slider v-model="piiClean" class="setting" label="PII Redaction" data-cy="piiClean" />
          <toggle-slider
            v-if="featureFlags.aitopic_classification"
            v-model="aitopicClassificationEnabled"
            class="setting"
            label="Enable AI Themes"
            data-cy="aitopicClassificationEnabled"
          />
          <toggle-slider
            v-model="sentimentEnabled"
            class="setting"
            label="Enable Sentiment Identification"
            data-cy="sentimentEnabled"
          />
          <div v-show="sentimentEnabled && featureFlags.sentiment_choices">
            <h4>Sentiment Classifier</h4>
            <radio-buttons
              :selected="selectedSentimentValue"
              :items="sentimentOptions"
              @change="changeSentimentOption"
            />
          </div>
          <div class="week-settings">
            <div class="week-start-label">Start Day of Week On</div>
            <div class="week-start-select-container">
              <el-select
                placeholder="Select"
                :model-value="selectedWeekStart"
                @update:model-value="selectedWeekStart = $event"
              >
                <el-option
                  v-for="item in weekStartOptions"
                  :key="item"
                  :label="`${item}${item === weekStartDefault ? ' (default)' : ''}`"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <toggle-slider
            v-model="skipBadDates"
            class="setting"
            label="Skip Bad or Missing Dates"
            data-cy="skipBadDates"
          />
          <template v-if="useIntegration && integrationSettings.provider?.type !== 'advantage'">
            <toggle-slider
              class="setting"
              :label="autoAddSettingLabel"
              :value="autoAddFilesEnabled"
              @input="toggleAutoAdd"
            />
            <div>
              <toggle-slider
                v-if="integrationSettings.provider && integrationSettings.provider.type === 'survey_monkey'"
                v-model="autoAddPartials"
                class="setting"
                label="Import partially complete responses"
                :disabled="!autoAddPartialsEnabled.enabled"
              />
              <div class="note setting">
                {{ autoAddPartialsEnabled.helpMsg }}
              </div>
            </div>

            <div
              v-if="integrationSettings.provider && integrationSettings.provider.type === 's3' && autoAddFilesEnabled"
            >
              <div class="noteworthy setting">
                <strong>Note:</strong> all .csv and .xlsx files in the selected S3 folder (and subfolders) will attempt
                to be added to the Project. They <strong>must</strong> be in the same column structure as the existing
                files that have already been added to the project.
              </div>
              <p>
                Update Schedule:
                <el-select size="small" :model-value="autoAddInterval" @update:model-value="autoAddInterval = $event">
                  <el-option
                    v-for="item in Object.keys(autoAddIntervalOptions)"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </p>
              <p>
                Choose start date to import from:
                <vue-date-picker v-model="exportStartTime"> </vue-date-picker>
              </p>
              <p>
                <strong
                  >S3 folder to use:
                  <span>{{ selectedS3Folder ? selectedS3Folder : '(None)' }}</span>
                </strong>
              </p>
              <toggle-slider
                v-model="topLevelFolder"
                class="setting"
                label="Top Level Folder Only"
                data-cy="topLevelFolder"
              />
              <br />
              <a href="#" @click.prevent="showFileBrowser = !showFileBrowser">
                <span v-if="!showFileBrowser">Choose folder:</span>
                <span v-else>Hide folder view</span>
              </a>
              <object-browser
                v-if="showFileBrowser"
                :bucket="integrationSettings.provider.details.bucket"
                :allow-select-file="false"
                :allow-select-folder="true"
                :selected-items="[selectedS3Folder]"
                provider="s3"
                @object-selected="selectedS3Folder = $event"
              >
              </object-browser>
            </div>
            <div
              v-if="integrationSettings.provider && integrationSettings.provider.type === 'gcs' && autoAddFilesEnabled"
            >
              <div class="noteworthy setting">
                <strong>Note:</strong> all .csv and .xlsx files in the selected GCS folder (and subfolders) will attempt
                to be added to the Project. They <strong>must</strong> be in the same column structure as the existing
                files that have already been added to the project.
              </div>

              <p>
                <strong
                  >GCS folder to use:
                  <span>{{ selectedGCSFolder ? selectedGCSFolder : '(None)' }}</span>
                </strong>
              </p>
              <a href="#" @click.prevent="showFileBrowser = !showFileBrowser">
                <span v-if="!showFileBrowser">Choose folder:</span>
                <span v-else>Hide folder view</span>
              </a>

              <object-browser
                v-if="showFileBrowser"
                :bucket="integrationSettings.provider.details.bucket"
                :allow-select-file="false"
                :allow-select-folder="true"
                :selected-items="[selectedGCSFolder]"
                provider="gcs"
                @object-selected="selectedGCSFolder = $event"
              >
              </object-browser>
            </div>
          </template>
        </div>
        <div v-if="showDataUnits" class="data-units-text">
          <p>
            Creating this project will use <br />
            <span class="data-units">{{ number(numRecords) }} Data Units</span>
          </p>
        </div>
      </div>

      <!-- Actions -->
      <div class="actions">
        <button v-if="!canGoBack" class="ui button cancel" @click="goHome">Cancel</button>
        <button
          v-else-if="!isFileUploading"
          class="ui button cancel"
          :class="{ 'loading': isLoading, 'events-disabled': isLoading }"
          @click="goBack"
        >
          Back
        </button>
        <button
          v-if="canGoNext"
          class="ui button blue"
          :class="{ 'loading': isLoading, 'events-disabled': isLoading }"
          :disabled="nextDisabled"
          @click="goNext"
        >
          {{ step === 4 ? 'Finish & Create Project' : 'Proceed' }}
        </button>
      </div>

      <div v-if="step === 2" class="after-actions">
        <a :href="CONST.intercom_links.DATA_TYPES" target="_blank" class="help-link"
          >Learn more about data types.<img class="ui image logo" src="../../../assets/img/new-tab.png"
        /></a>
      </div>
    </div>
    <remap-column-modal
      :custom-columns="customColumns"
      :visible="showAddField"
      :fields="schema || []"
      @close="showAddField = false"
      @add-or-update="addOrUpdateCustomColumn"
      @remove="removeCustomColumn"
    />
    <unique-id-fields-modal
      :visible="showUniqueIdFieldsModal"
      :schema="schema || []"
      :current-fields="uniqueFields"
      @close="showUniqueIdFieldsModal = false"
      @values-changed="uniqueFields = $event"
    />
    <translate-modal
      :visible="showTranslateModal"
      :schema="schema || []"
      :language-options="languageOptions"
      :dev-mode="featureFlags.dev_mode"
      @close="showTranslateModal = false"
      @values-changed="languageOptions = $event"
    />
    <score-settings-modal
      :visible="showScoreSettingsModal"
      :current-field-index="currentScoreFieldIndex"
      @close="showScoreSettingsModal = false"
      @values-changed="updateToScoreField"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import VueDatePicker from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import DataUpload from './DataUpload.vue'
import DataTutorial from './DataTutorial.vue'
import IntegrationImport from './IntegrationImport.vue'
import ProgressIndicator from 'components/widgets/ProgressIndicator.vue'
import RemapColumnModal from 'components/widgets/RemapColumnModal/RemapColumnModal.vue'
import SchemaTable from './SchemaTable.vue'
import { FIELD_TYPES_INDEX } from 'components/project/create/SchemaUtils'
import UniqueIdFieldsModal from 'components/widgets/UniqueIdFieldsModal/UniqueIdFieldsModal.vue'
import ScoreSettingsModal from 'components/widgets/ScoreSettingsModal/ScoreSettingsModal.vue'
import { makeExtraTypeOptions as makeExtraTypeOptionsForUniqueId } from 'components/widgets/UniqueIdFieldsModal/UniqueIdUtils'
import { makeExtraTypeOptions as makeExtraTypeOptionsForRemapColumns } from 'components/widgets/RemapColumnModal/RemapColumnUtils'
import Project from 'src/api/project'
import store from 'src/store'
import DataUtils from 'src/utils/data'
import ToggleSlider from 'components/widgets/ToggleSlider.vue'
import ObjectBrowser from 'components/project/create/ObjectBrowser.vue'
import FormatUtils from 'src/utils/formatters'
import { CLEAR_REQUEST_ERRORS, FETCH_INTEGRATIONS } from 'src/store/types'
import RadioButtons from 'components/widgets/RadioButtons.vue'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { ExtraTypeOptionsMap, SchemaColumn, SchemaTypeIds, LanguageOptions } from 'types/SchemaTypes'
import TranslateModal from 'components/widgets/TranslateFieldModal/TranslateModal.vue'

export default defineComponent({
  components: {
    TranslateModal,
    RadioButtons,
    BfButton,
    BfSpinner,
    RemapColumnModal,
    DataUpload,
    DataTutorial,
    IntegrationImport,
    ProgressIndicator,
    SchemaTable,
    ToggleSlider,
    ObjectBrowser,
    UniqueIdFieldsModal,
    VueDatePicker,
    ScoreSettingsModal,
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch({ type: FETCH_INTEGRATIONS })
      .then(() => {
        next()
      })
      .catch(() => {
        next(false)
      })
  },
  data() {
    return {
      chrysalisRef: null,
      isLoading: false,
      isMounted: false,
      isFileUploading: false,
      name: '',
      nameError: false,
      nameExistsError: false,
      samples: null,
      frozenSamples: null,
      suggestedSchema: [] as Array<SchemaColumn>,
      collapseIgnored: false as boolean,
      schema: [] as Array<SchemaColumn>,
      uniqueFields: [] as Array<string>,
      step: 1,
      useIntegration: false,
      showAddField: false,
      showUniqueIdFieldsModal: false,
      showTranslateModal: false,
      showScoreSettingsModal: false,
      currentScoreFieldIndex: null,
      languageOptions: new Map() as LanguageOptions,
      customColumns: [],
      integrationSettings: {},
      autoAddFilesEnabled: false,
      selectedS3Folder: '',
      selectedGCSFolder: '',
      showFileBrowser: false,
      sentimentEnabled: true,
      skipBadDates: false,
      piiClean: false,
      topLevelFolder: false,
      autoAddPartials: false,
      autoAddInterval: 'every day',
      selectedWeekStart: 'Sunday',
      weekStartDefault: 'Sunday',
      weekStartOptions: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      exportStartTime: dayjs(new Date())
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate(),
      autoAddIntervalOptions: {
        'every hour': {
          every: 1,
          period: 'hours',
        },
        'every day': {
          every: 1,
          period: 'days',
        },
        'every 7 days': {
          every: 7,
          period: 'days',
        },
      },
      selectedSentimentValue: process.env.APP_ENV === 'production' ? 'plumeria_v3' : 'textblob',
      sentimentOptions: [
        { value: 'plumeria_v3', label: 'Kapiche Plumeria V3 - NextGen 🌺' },
        { value: 'plumeria_v2', label: 'Kapiche Plumeria V2 🌺' },
        { value: 'plumeria', label: 'Kapiche Plumeria 🌺' },
        { value: 'google', label: 'Google Cloud Natural Language (may be slow on larger datasets)' },
        { value: 'vader', label: 'VADER (best for short-form social media)' },
        { value: 'textblob', label: 'TextBlob (fast, poor accuracy)' },
      ],
      schemaActionsLoading: {
        ignoreAll: false,
        reset: false,
        collapseIgnored: false,
      },
      ignoreAllConfirmation: false,
      //
      // Project Metadata - used for aitopics
      //
      datasetDescription: '',
      textFieldMetadata: [],
      selectedDatasetType: 'other',
      datasetTypes: [
        { label: 'Feedback Survey', value: 'survey' },
        { label: 'Email', value: 'email' },
        { label: 'Chat', value: 'chat' },
        { label: 'Call Transcript', value: 'call_transcript' },
        { label: 'Online Reviews', value: 'online_reviews' },
        { label: 'Other', value: 'other' },
      ],
      aitopicClassificationEnabled: false,
    }
  },
  computed: {
    ...mapGetters(['dataUnits', 'domain', 'plan', 'featureFlags', 'currentSite', 'subscription', 'isDev']),
    nextDisabled() {
      if (this.step === 1) {
        return !this.$refs.integrationImport?.isValid()
      }
      if (this.step === 2) {
        return !this.allowedToProceed
      }
      return false
    },
    autoAddSettingLabel() {
      return `Automatically import new responses from ${this.integrationSettings.provider?.type.toUpperCase()}`
    },
    canGoBack() {
      return (
        this.step > 1 ||
        this.useIntegration ||
        (this.isMounted && this.$refs.dataUpload && this.$refs.dataUpload.fileSelected)
      )
    },
    canGoNext() {
      if (
        this.step === 1 &&
        this.useIntegration &&
        this.$refs.integrationImport &&
        !this.$refs.integrationImport.importError
      ) {
        // No next button when importing an integration, unless we are in the confirm step
        return this.$refs.integrationImport.readyToConfirm
      }
      return this.step > 1 || (this.isMounted && this.$refs.dataUpload && this.$refs.dataUpload.uploadFinished)
    },
    integrationNumResponses() {
      return this.$refs.integrationImport.numResponses
    },
    numRecords() {
      if (this.useIntegration) {
        return this.integrationNumResponses
      } else if (this.isMounted) {
        return this.$refs.dataUpload.numResponses
      }
      return null
    },
    showDataUnits() {
      return this.plan === 'data-unit-plan'
    },
    fileRowLimit() {
      return Math.min(500000, this.subscription.projectRowLimit ?? Infinity)
    },
    fileColumnLimit() {
      return Math.min(500, this.subscription.projectColumnLimit ?? Infinity)
    },
    countNonIgnoredColumns() {
      return this.schema.filter((col) => col.type !== -1).length
    },
    allowedToProceed() {
      const ignoredColumns = this.schema.filter((col) => col.type === -1)
      const hasUniqueFieldsInIgnoredColumns = this.uniqueFields.some((uniqueField) => {
        return ignoredColumns.some((ignoredCol) => ignoredCol.name === uniqueField)
      })
      return this.countNonIgnoredColumns <= this.fileColumnLimit && !hasUniqueFieldsInIgnoredColumns
    },

    remapSourceColumns(): Array[string] {
      return this.customColumns.map((t) => t.reference_field)
    },
    extraTypeOptions(): ExtraTypeOptionsMap {
      const uniqueIdTypeOptions = makeExtraTypeOptionsForUniqueId(this.uniqueFields)
      const remapColumnTypeOptions = makeExtraTypeOptionsForRemapColumns(this.remapSourceColumns)
      return new Map([...uniqueIdTypeOptions].concat([...remapColumnTypeOptions]))
    },
    autoAddPartialsEnabled(): { enabled: boolean; helpMsg: string } {
      if (!this.autoAddFilesEnabled) {
        return {
          enabled: false,
          helpMsg: 'Automatic imports must be enabled to turn this feature on.',
        }
      }
      if (this.uniqueFields.length === 0) {
        return {
          enabled: false,
          helpMsg:
            'This feature is only available for projects that are created with a unique row identifier. ' +
            'This is to prevent importing the same survey response multiple times.',
        }
      }
      return { enabled: true, helpMsg: '' }
    },
    integrationSettingsProviderType() {
      return this.integrationSettings?.provider?.type
    },
  },
  watch: {
    showAddField(v) {
      if (v) {
        this.$analytics.track.project.openCustomColumnsModal()
      }
    },
    integrationSettingsProviderType(newVal) {
      this.handleProviderTypeChange(newVal)
    },
  },
  mounted() {
    this.isMounted = true // refs available

    // Select default sentiment engine from the site profile
    const sentimentOptions = this.sentimentOptions.map(({ value }) => value)
    const defaultSentiment = this.currentSite?.default_sentiment_engine
    const pii_default = this.currentSite?.pii_clean
    this.weekStartDefault = this.currentSite?.week_start ?? 'Sunday'
    this.selectedWeekStart = this.weekStartDefault
    if (pii_default != null) {
      this.piiClean = pii_default
    }
    if (sentimentOptions.includes(defaultSentiment)) {
      this.selectedSentimentValue = defaultSentiment
    }
    if (this.featureFlags.aitopic_classification) {
      this.aitopicClassificationEnabled = true
    }
  },
  methods: {
    number: FormatUtils.number,
    setFolder(path: string, provider: 's3' | 'gcs') {
      if (provider === 's3') {
        this.selectedS3Folder = path
      } else if (provider === 'gcs') {
        this.selectedGCSFolder = path
      }
    },
    setIntegrationProvider(provider) {
      this.$set(this.integrationSettings, 'provider', provider)
    },
    setIntegrationColumnWhitelist(str: string) {
      this.integrationSettings.column_whitelist = str
    },
    handleProviderTypeChange(newVal) {
      if (newVal === 'qualtrics') {
        this.uniqueFields = ['Response ID']
      }
    },
    setIntegrationInfo(info) {
      this.integrationSettings.info = info
    },
    setUseIntegration() {
      this.useIntegration = true
      this.autoAddFilesEnabled = true
    },
    toggleAutoAdd(event) {
      this.autoAddFilesEnabled = event
      if (event === false && this.integrationSettings?.provider?.type === 'survey_monkey') {
        this.autoAddPartials = false
      }
    },
    convertLanguageOptionsToPayloadFields(languageOptions: LanguageOptions) {
      // Reformat the language options into separate objects for
      // langguage selection, and translation. Each object will have
      // a key for the field name, and a value for the setting of that field.
      // Here is an example of the local value of languageOptions:
      // {
      //    "tweet_text": {
      //      "enabledLanguages": [],
      //      "translationLanguage": "English"
      //    },
      //    "language": {
      //      "enabledLanguages": [ "German" ],
      //      "translationLanguage": "" },
      //    "Original sentiment": {
      //      "enabledLanguages": [ "Polish", "French" ],
      //      "translationLanguage": ""
      //    }
      // }
      //
      // We want to collect the fields that have a non-empty enabledLanguages
      // into the field_languages object, and the fields that have a non-empty
      // translationLanguage into the field_translations object.
      let field_languages = {}
      let field_translations = {}
      for (const [field, settings] of languageOptions) {
        if (settings.enabledLanguages.length > 0) {
          field_languages[field] = settings.enabledLanguages
        } else if (settings.translationLanguage) {
          field_translations[field] = settings.translationLanguage
        }
      }

      return { field_languages, field_translations }
    },
    async createProject() {
      this.isLoading = true
      let schema = [] // Schema defines fields for a project
      let mapping = {} // Mapping defines how data columns map to project fields
      let mappingColumns = []
      let mappingValues = []
      // Populate real schema and mappings from this component's editable schema
      let index = 0
      for (let item of Object.values(this.schema)) {
        mappingValues.push(item.type)
        mappingColumns.push(item.name)
        if (item.type > -1) {
          let newCol = {
            index: index,
            name: item.name,
            type: item.type,
          }
          if (item.num_type) {
            newCol.num_type = item.num_type
          }
          if (item.type == 8) {
            newCol = {
              ...newCol,
              score_range: item.score_range,
              score_aggregation: item.score_aggregation,
              score_name: item.score_name,
              exclude_out_of_range: item.exclude_out_of_range ?? false,
            }
          }
          schema.push(newCol)
          index += 1
        }
      }

      let languagePayloadFields = {}
      if (this.subscription.languageTranslation) {
        languagePayloadFields = this.convertLanguageOptionsToPayloadFields(this.languageOptions)
      }

      // Assemble payload
      let payload = {
        name: this.name,
        description: this.description,
        schema: schema,
        unique_fields: this.uniqueFields,
        sentiment_enabled: this.sentimentEnabled,
        sentiment_classifier: this.selectedSentimentValue,
        chrysalis_ref: this.chrysalisRef,
        numRecords: this.numRecords,
        skip_bad_dates: this.skipBadDates,
        pii_clean: this.piiClean,
        week_start: this.selectedWeekStart,
        column_whitelist: this.integrationSettings?.column_whitelist,
        ...languagePayloadFields,
      }
      // Project Metadata - used for aitopics
      if (this.featureFlags.aitopic_classification) {
        payload = Object.assign(
          {
            dataset_description: this.datasetDescription,
            dataset_type: this.selectedDatasetType,
            text_field_metadata: this.textFieldMetadata,
            aitopic_classification_enabled: this.aitopicClassificationEnabled,
          },
          payload,
        )
      }
      if (this.$refs.schemaTable.selectedDateType !== null) {
        payload.day_first_dates = this.$refs.schemaTable.selectedDateType === 'dayFirst'
      }
      if (this.useIntegration && this.integrationSettings.provider.type !== 'advantage') {
        payload = Object.assign(
          {
            integration: this.integrationSettings.provider.type,
            integration_info: this.integrationSettings.info,
            integration_status: 1,
          },
          payload,
        )
      }
      // Create the actual project
      try {
        const project = await Project.createProject(payload)
        this.projectId = project.id
        let eventData = {
          sentiment: this.sentimentEnabled,
          skipBadDates: this.skipBadDates,
          startWeekDay: this.startWeekDay,
          piiClean: this.piiClean,
          projectId: this.projectId,
          fileSize: null,
          integrationProvider: null,
        }
        if (!this.useIntegration) {
          eventData.fileSize = this.$refs.dataUpload.fileSize
          eventData.numResponses = this.$refs.dataUpload.numResponses
        } else {
          eventData.integrationProvider = this.integrationSettings.provider.type
          eventData.fileSize = this.$refs.integrationImport.objectSize
          eventData.numResponses = this.integrationNumResponses
        }
        this.$analytics.track.project.create(eventData)
        this.$analytics.track.project.addData(eventData.fileSize, eventData.numResponses, eventData.projectId)

        // Add custom columns
        if (this.customColumns.length > 0) {
          await Project.createTransformations(
            project.id,
            this.customColumns.map((c) => {
              return {
                reference_field: c.reference_field,
                name: c.name,
                schema_type: c.schema_type,
                transformations: c.transformations,
              }
            }),
          )
        }

        if (this.autoAddFilesEnabled && this.integrationSettings.provider.type !== 'advantage') {
          const data = {
            enabled: true,
            run_immediately: false,
            selected_folder: '',
            add_partials: this.autoAddPartials,
            auto_add_interval: this.autoAddIntervalOptions[this.autoAddInterval],
            export_start_time: this.exportStartTime,
          }
          if (this.integrationSettings?.provider?.type === 's3') {
            data.selected_folder = this.selectedS3Folder
            data.top_level_only = this.topLevelFolder
          } else if (this.integrationSettings?.provider?.type === 'gcs') {
            data.selected_folder = this.selectedGCSFolder
          }
          await Project.updateAutoAddFilesSetting(project.id, data)
        }

        // Map file to schema
        for (let [i, item] of mappingColumns.entries()) {
          if (mappingValues[i] > -1) {
            // Is this an ignored column?
            mapping[item] = project.schema.find((col) => col.name === item).index
          } else {
            mapping[item] = -1
          }
        }
        Project.indexDataFiles(this.projectId, [this.chrysalisRef], mapping)
        await this.$router.push({ name: 'project-details', params: { projectId: project.id } })
      } finally {
        this.isLoading = false
      }
    },
    // Go back to home route
    goHome() {
      this.$router.push({ name: 'home' })
    },
    // Go to previous wizard step
    goBack() {
      if (this.step === 1) {
        // Going back in step 1 means clearing state
        if (this.useIntegration) {
          if (!this.$refs.integrationImport.goBack()) {
            // IntegrationUpload has empty state, so switch back to upload type selection
            this.useIntegration = false
            this.autoAddFilesEnabled = false
          }
        } else {
          // Clear data file
          this.$refs.dataUpload.clearUpload()
        }
      } else {
        this.step -= 1
        if (!this.featureFlags.aitopic_classification && this.step === 3) {
          // We have to go back an additional step because when this fflag
          // is inactive, we skip the step where we collect survey question.
          this.step -= 1
        }
      }
    },
    // Go to next wizard step
    async goNext() {
      if (this.step === 1) {
        if (this.useIntegration) {
          this.isLoading = true
          // Initiate async import of survey responses
          this.$refs.integrationImport.runImport(
            (dataFile, metadata) => {
              // Load metadata and move to schema mappinges
              this.onFileReady(dataFile, metadata.headers, metadata.samples)
              this.isLoading = false
              this.step = 2
            },
            () => {
              // Error handler
              this.isLoading = false
            },
          )
        } else {
          this.step = 2
        }
      } else if (this.step === 2 && this.$refs.schemaTable.isValid()) {
        // Schema selection validation
        this.$refs.schemaTable.checkForAmbiguousDates(() => {
          this.step += 1

          if (!this.featureFlags.aitopic_classification) {
            // If aitopic_classification is not enabled just skip ahead to
            // the final screen.
            this.step += 1
          } else {
            // As we proceed to the next step, populate the text field metadata
            // structures in preparation for collecting survey question metadata.
            this.textFieldMetadata = this.schema
              .filter((col) => col.typename === 'TEXT')
              .map((col) => {
                return {
                  text_field_name: col.name,
                  key: 'survey_question',
                  value: '',
                }
              })
          }
        })
      } else if (this.step == 3) {
        this.step += 1
      } else if (this.step === 4) {
        this.nameError = false
        this.nameExistsError = false
        if (this.name.length === 0) {
          this.nameError = true
        } else {
          this.isLoading = true
          try {
            await Project.checkProjectName(this.name)
            await this.createProject()
          } catch (response) {
            // Check for duplicate name error
            let error = response.body['non_field_errors'] || ['']
            if (error[0].toLowerCase().indexOf('already exists') >= 0) {
              this.nameExistsError = true
              this.$store.dispatch(CLEAR_REQUEST_ERRORS) // custom error handling
            }
          } finally {
            this.isLoading = false
          }
        }
      }
    },
    onUploadStarted() {
      this.isFileUploading = true
    },
    onUploadFailed() {
      this.isFileUploading = false
    },
    // When DataUpload or IntegrationImport component is ready we have access to file metadata
    onFileReady(dataFile, headers, samples) {
      this.isFileUploading = false
      this.chrysalisRef = dataFile.id
      this.schema = Object.assign([], dataFile.schema)
      this.suggestedSchema = Object.assign([], _.cloneDeep(dataFile.schema))
      this.uniqueFields = this.uniqueFields || []
      this.samples = samples
      this.frozenSamples = DataUtils.deepFreeze(samples, true)
    },
    // Manually show the data tutorial
    showDataTutorial() {
      this.$refs.dataTutorial.show('organise')
    },
    addOrUpdateCustomColumn({ id, transformations, referenceField, newName, type }): void {
      const transformationMap = transformations.reduce((map, cur) => {
        map[cur.from.toLowerCase()] = cur.to
        return map
      }, {})
      const col = this.customColumns.find((t) => t.id === id)
      if (col) {
        // We are updating a custom column
        // The only thing we can change are the transformations
        col.transformations = transformations
        // This will require us to update the schema table & preview samples
        const targetIndex = this.schema.findIndex((f) => f.name === col.name)
        const sourceIndex = referenceField.index

        for (const sample of this.samples) {
          sample[targetIndex] = transformationMap[sample[sourceIndex].toLowerCase()] || sample[sourceIndex]
        }
        this.frozenSamples = DataUtils.deepFreeze(this.samples, true)
      } else {
        // We are adding a new column
        this.customColumns.push({
          id: id,
          reference_field: referenceField.name,
          name: newName,
          schema_type: type,
          transformations: transformations,
        })
        // The target field for the custom column needs to be added to the project schema definition
        this.schema.push({
          index: this.schema.length,
          name: newName,
          type: type,
        })
        // We now need to convert the samples we have so that the user can
        // preview what the results of their transformation is. This is
        // duplicated in the backend, but since this is just the preview,
        // we are going to just transform them here for speed and simplicity.
        const idx = referenceField.index
        for (const sample of this.samples) {
          const old_sample = sample[idx]
          const new_sample = transformationMap[old_sample.toLowerCase()] || old_sample
          sample.push(new_sample)
        }
        this.frozenSamples = DataUtils.deepFreeze(this.samples, true)
      }
      this.$analytics.track.project.addOrUpdateCustomColumn(
        col === undefined,
        col ? col.reference_field : referenceField.name,
        col ? col.name : newName,
        FIELD_TYPES_INDEX[col ? col.schema_type : type].label,
        transformations.length,
      )
    },
    removeCustomColumn(id): void {
      const col = this.customColumns.find((t) => t.id === id)
      if (!col) {
        return
      }
      this.customColumns = this.customColumns.filter((t) => t.id != id)
      // Update schema table and samples
      const fieldIndex = this.schema.findIndex((f) => f.name === col.name)
      this.schema.splice(fieldIndex, 1)
      for (const sample of this.samples) {
        sample.splice(fieldIndex, 1)
      }
      this.frozenSamples = DataUtils.deepFreeze(this.samples, true)
      this.$analytics.track.project.removeCustomColumn(col.name, FIELD_TYPES_INDEX[col.schema_type].label)
    },
    changeSentimentOption(value): void {
      this.selectedSentimentValue = value
    },
    updateSchemaType(index: number, newType: SchemaTypeIds) {
      if (newType == 8) {
        this.currentScoreFieldIndex = index
        this.showScoreSettingsModal = true
      } else {
        this.schema[index].type = newType
        this.schema[index].typename = Project.COLUMN_INDEXED_TYPES.get(newType)
      }
    },
    updateToScoreField(
      index: number,
      name: string,
      range: Array<number>,
      aggMethod: string,
      excludeOutOfRange: boolean,
    ) {
      this.schema[index].type = 8
      this.schema[index].typename = Project.COLUMN_INDEXED_TYPES.get(8)
      this.schema[index].score_range = range
      // TODO: Change to `name` when we start using score name in product.
      this.schema[index].score_name = this.schema[index].name
      this.schema[index].score_aggregation = aggMethod
      this.schema[index].exclude_out_of_range = excludeOutOfRange
    },
    setAllFieldsToIgnore() {
      this.schemaActionsLoading.ignoreAll = true
      setTimeout(() => {
        try {
          this.schema.map((col) => {
            if (!this.uniqueFields.includes(col.name)) {
              col.type = -1
              col.typename = 'IGNORE'
            }
          })
        } finally {
          this.schemaActionsLoading.ignoreAll = false
        }
      })
    },
    setAllFieldsToInferredTypes() {
      this.schemaActionsLoading.reset = true
      setTimeout(() => {
        try {
          const clonedSuggested = _.cloneDeep(this.suggestedSchema)
          this.schema = clonedSuggested
        } finally {
          this.schemaActionsLoading.reset = false
        }
      })
    },
    handleExpandClick() {
      this.schemaActionsLoading.collapseIgnored = true
      setTimeout(() => {
        try {
          this.collapseIgnored = !this.collapseIgnored
        } finally {
          this.schemaActionsLoading.collapseIgnored = false
        }
      })
    },
  },
})
</script>

<style lang="sass">
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

.dataset-questions > .el-select__wrapper
  text-align: center
  font-size: 18px
  background-color: #f5f5f5

.styled-input .el-textarea__inner
  border: 1px solid #d9d9d9 /* Light gray border */
  border-radius: 4px /* Rounded corners */
  padding: 20px /* Extra padding for a cleaner look */
  font-size: 18px /* Adjust font size for readability */
  color: #333 /* Darker text color for readability */
  background-color: #f5f5f5 /* Slightly lighter background */
  text-align: center

.styled-input .el-textarea__inner::placeholder
  color: #bfbfbf /* Light gray placeholder color */
  font-style: italic /* Italicize placeholder text */

.create-project-container
  font-size: rem(16px)
  .schema-actions
    display: flex
    justify-content: space-between
    .action-text
      text-transform: uppercase

    // Without this, then the buttons are 1px out of line when in loading
    // mode
    > div
      display: flex

    // These two styles means the buttons stay the same size when they are
    // in loading state
    button
      position: relative
    .button-spinner
      position: absolute

      // Make the spinner the same size as the button text so that the button
      // doesn't change size when loading. Taken from BfButton
      .bf-spinner
        margin: 0
        width: 0.85714286rem
        height: 0.85714286rem
        border-top-color: $blue
        border-width: 0.1rem


    .hidden
      visibility: hidden


  .schema-table
    .table-container
        min-height: 350px
  .subheader
    margin-bottom: rem(30px)
    margin-top: rem(20px)
  .button
    font-size: rem(18px) !important
  .step-container
    padding-top: rem(12px)
    .streamline-input
      margin-top: rem(30px)
    .field-error
      color: $red
      font-size: rem(14px)
      font-weight: bold
      margin-top: rem(10px)
    .ui.checkbox
      margin-top: rem(40px)
      label
        font-size: rem(16px)
  &.step-2
    margin-left: rem(30px)
    margin-right: rem(30px)
    max-width: 100%
    width: auto
  &.step-4, &.step-3
    .data-units-text
      font-size: rem(20px)
      padding-top: rem(50px)
      .data-units
        font-weight: bold
        margin-top: rem(5px)

    #project-settings
      text-align: left
      width: rem(500px)
      display: inline-block
      margin-top: rem(40px)
      .setting
        margin-bottom: rem(10px)
      .week-settings
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 10px
      .week-start-label
        flex: 1
        padding-right: 10px
        align-items: center
        justify-content: flex-end
      .week-start-select-container
        flex: 2
        text-align: right
      .noteworthy
        margin-top: 25px
        margin-bottom: 21px
        color: #f89516

      .note
        margin-top: 10px
        color: #95A6AC
        width: 80%

  /* Actions */
  .actions
    margin-top: rem(50px)
    .button:nth-child(1):not(:last-child)
      margin-right: rem(20px)
  .after-actions
    margin-top: rem(30px)

/* Modal */
div.ui.modal.data-units-modal
  border-radius: 3px
  .header
    color: $text-black !important
  .content
    font-size: rem(16px) !important
    margin-bottom: rem(10px)
</style>
