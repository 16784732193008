<template>
  <div class="cues-collapse">
    <el-collapse @change="cuesTrigger">
      <el-collapse-item>
        <template #title>
          <div class="cues-header">
            <span class="label"> Insight Cues <span class="ai-label beta">AI GENERATED</span> </span>
          </div>
        </template>
        <el-container
          v-loading="loading"
          element-loading-text="Generating Cues ..."
          :style="{ minHeight: '50px', margin: '5px' }"
        >
          <template v-if="!cuesStale">
            <div class="cues-content">
              <!-- Add comparison insights section -->
              <div v-if="comparisonInsights" class="comparison-insights">
                <div class="comparison-summary">{{ comparisonInsights.summary }}</div>
              </div>
              <div v-if="parsedCues.length > 0" class="events-section">
                <div v-for="(event, index) in parsedCues" :key="index" class="event">
                  <div class="event-header">
                    <div class="event-date">
                      <span
                        class="date-filter"
                        @click="() => dateFilter(selectedDateField, [event.date_range.from, event.date_range.to])"
                        @mouseenter="() => dateHover(event.date_range.from)"
                        @mouseleave="dateHoverEnd"
                      >
                        {{ formatDate(event.date_range.from) }}
                      </span>
                    </div>
                    <div class="event-details">
                      <div class="event-metric">
                        <template v-if="event.metric.name && event.metric.name.includes('[')">
                          <el-dropdown trigger="click" placement="bottom-start" popper-class="drilldown-menu">
                            <div class="tree-row-name">
                              {{ event.metric.name.split(' [')[0] }}
                              <span class="group-tag"> [{{ event.metric.name.split('[')[1].replace(']', '') }}] </span>
                            </div>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item @click="metricClick(event.metric.name, 'theme')">
                                  Drill into<b>{{ event.metric.name.split(' [')[0] }}</b>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </template>
                        <template v-else-if="isClickableMetric(event.metric.name, event.metric.metric_type)">
                          <span
                            class="metric-name clickable"
                            @click="metricClick(event.metric.name, event.metric.metric_type)"
                          >
                            {{ event.metric.name === 'Sentiment' ? event.metric.metric_type : event.metric.name }}
                          </span>
                        </template>
                        <template v-else-if="event.metric.name === 'NPS'">
                          <span class="metric-name">{{ event.metric.name }}</span>
                        </template>
                        <template v-else>
                          <span class="metric-name">{{ event.metric.name }}</span>
                          <div class="event-info">
                            <span class="metric-type">{{ event.metric.metric_type }}</span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="summary">{{ event.summary }}</div>
                </div>
              </div>
              <div v-else-if="!comparisonInsights" class="content" v-html="content" />
            </div>
          </template>
          <template v-else>
            <a class="refresh-link" @click="updateCue"> <i class="icon refresh" />Update cues </a>
          </template>
        </el-container>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import dayjs from 'dayjs'
import { SavedQuery } from 'types/Query.types'

const MetricMap = {
  Promoters: ['NPS Category', 'Promoter'],
  Detractors: ['NPS Category', 'Detractor'],
  Passives: ['NPS Category', 'Passive'],
  positive: ['sentiment', 'positive'],
  negative: ['sentiment', 'negative'],
  neutral: ['sentiment', 'neutral'],
  mixed: ['sentiment', 'mixed'],
}

const InsightCues = defineComponent({
  components: {},
  props: {
    cuesStale: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    content: { type: String, required: false, default: '' },
    selectedDateField: { type: String, required: true },
    themeNameMap: {
      type: Object,
      required: false,
      default: null,
    },
    themeToGroupMap: {
      type: Object,
      required: false,
      default: null,
    },
    queries: {
      type: Array as PropType<SavedQuery[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['set-date-filters', 'fetch', 'date-hover-start', 'date-hover-end', 'toggle-filter', 'go-to-theme'],
  setup(props, { emit }) {
    const parsedContent = computed(() => {
      try {
        return JSON.parse(props.content)
      } catch {
        return {}
      }
    })

    const comparisonInsights = computed(() => {
      return parsedContent.value?.comparison_insights || null
    })

    const parsedCues = computed(() => {
      return parsedContent.value?.significant_events || []
    })

    const isClickableMetric = (metricName: string, metricType?: string): boolean => {
      return (
        metricName.includes('Promoters') ||
        metricName.includes('Detractors') ||
        metricName.includes('Passives') ||
        (metricName === 'Sentiment' &&
          metricType &&
          ['positive', 'negative', 'neutral', 'mixed'].includes(metricType.toLowerCase()))
      )
    }

    const metricClick = (metricName: string | number, metricType: string) => {
      if (metricType === 'theme') {
        const cleanName = String(metricName).split(' [')[0]
        const query = props.queries.find((q) => q.name === cleanName)
        if (query) {
          emit('go-to-theme', query.id)
        }
        return
      }
      // For sentiment metrics
      if (metricName === 'Sentiment' && metricType) {
        const mapping = MetricMap[metricType.toLowerCase()]
        if (mapping) {
          const filter = {
            field: mapping[0],
            type: 'attribute',
            operator: 'is',
            values: [mapping[1]],
          }
          emit('set-date-filters', [filter])
        }
        return
      }

      // For NPS and other metrics
      const mapping = MetricMap[metricName]
      if (mapping) {
        emit('toggle-filter', [[mapping[0], mapping[1]]])
      }
    }

    const dateHover = (date: string) => {
      emit('date-hover-start', date)
    }

    const dateHoverEnd = () => {
      emit('date-hover-end')
    }

    const formatDate = (dateStr: string) => {
      return dayjs(dateStr).format('MMMM YYYY')
    }

    const dateFilter = (field: string, dateRange: [string, string]) => {
      if (!field) {
        console.error('Date field is undefined')
        return
      }
      const [from, to] = dateRange
      const filter = {
        field: field,
        type: 'segment',
        operator: 'is in the range',
        is_date: true,
        values: [dayjs(from).format('YYYY-MM-DDT00:00:00'), dayjs(to).format('YYYY-MM-DDT23:59:59')],
      }
      emit('set-date-filters', [filter])
    }

    const cuesTrigger = () => {
      if (props.cuesStale && !props.loading) {
        emit('fetch')
      }
    }

    const updateCue = () => {
      emit('fetch')
    }

    return {
      parsedCues,
      comparisonInsights,
      formatDate,
      cuesTrigger,
      updateCue,
      dateFilter,
      dateHover,
      dateHoverEnd,
      isClickableMetric,
      metricClick,
    }
  },
})
export default InsightCues
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.cues-collapse
  width: inherit

.cues-header
  display: flex
  justify-content: space-between
  width: inherit

::v-deep
  .content
    h1, h2, h3
      font-size: 15px
      font-weight: bold

.label
  font-size: 14px
  font-weight: bold
  letter-spacing: 0.6px
  color: $text-black
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.beta::after
  margin: 0rem 0.3rem !important
  content: 'BETA' !important
  background-color: $green-light !important
  padding: 0.2rem 0.4rem !important
  border-radius: 3px !important
  border: 1px solid lighten($green-light, 10%)
  color: white
  font-size: 0.7rem !important
  font-weight: bold
  letter-spacing: 2px
  height: 1rem !important
  position: relative !important
  top: -0.1rem !important

.ai-label
  font-size: 14px
  letter-spacing: 0.6px
  color: rgb(149, 166, 172)
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.event
  margin-bottom: 16px
  padding: 0 12px

.event-header
  display: flex
  align-items: center
  gap: 8px
  margin-bottom: 4px

.event-details
  display: flex
  flex-direction: column
  gap: 2px

.tree-row-name, .metric-name.clickable, .date-filter
  cursor: pointer
  padding-bottom: 2px
  border-bottom: 2px dotted transparent
  font-size: 14px
  &:hover
    color: $blue
    border-bottom: 2px dotted $blue

.event-metric
  display: flex
  gap: 6px
  align-items: center
  .metric-name
    font-size: 14px
    text-transform: capitalize
    color: $grey-dark

.separator
  color: #9ca3af

.event-info
  display: flex
  gap: 8px
  align-items: center
  .metric-type
    color: #666
    font-size: 0.9em

.summary
  color: #4b5563
  font-size: 13px
  line-height: 1.4

.group-tag
  color: #666
  font-size: 1.0em
  margin-left: 4px

:deep(.el-dropdown-menu__item)
  line-height: 1.5
  padding: 8px 12px
  font-size: 14px

  b
    margin-left: 4px

.comparison-insights
  padding: 12px
  margin-bottom: 16px
  background-color: #f8fafc
  border-radius: 6px

  .comparison-summary
    font-size: 14px
    color: $text-black
    line-height: 1.5
    margin-bottom: 12px
</style>
