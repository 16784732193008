<template>
  <modal :visible="visible" @close="visible = false">
    <template #header>
      <div>Export to PNG</div>
    </template>
    <template #content>
      <div>
        <p>
          To reduce the dimensions of the exported image,<br />please select the field segments you would like to
          include:
        </p>
        <table>
          <tbody>
            <tr v-for="field in fieldList" :key="field.label">
              <td>{{ field.label }}:&nbsp;</td>
              <td>
                <el-select
                  size="mini"
                  filterable
                  multiple
                  clearable
                  collapse-tags
                  :model-value="selectedValues[field.label]"
                  @update:model-value="updateSelection(field.label, $event)"
                >
                  <el-option v-for="value in field.values" :key="value" :label="value" :value="value" />
                </el-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template #buttons>
      <div>
        <bf-button size="large" color="grey" :disabled="loading" @click="visible = false"> Cancel </bf-button>
        <bf-button size="large" color="blue" :disabled="loading" @click="submit">
          {{ loading ? 'Exporting...' : 'Export' }}
        </bf-button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { BfButton } from 'components/Butterfly'
import Modal from 'components/Modal.vue'

const ExportModal = defineComponent({
  components: {
    BfButton,
    Modal,
  },
  setup() {
    const visible = ref(false)
    const loading = ref(false)
    const resolveFunc = ref<(value: unknown) => void>()
    const fieldList = ref<{ values: string[]; label: string }[]>([])
    const selectedValues = ref<Record<string, string[]>>({})

    const open = (fields: typeof fieldList.value) => {
      loading.value = false
      visible.value = true
      fieldList.value = fields
      selectedValues.value = fields.reduce(
        (acc, field) => {
          acc[field.label] = field.values
          return acc
        },
        {} as Record<string, string[]>,
      )

      return new Promise((resolve) => {
        resolveFunc.value = resolve
      })
    }

    const close = () => {
      visible.value = false
    }

    const submit = () => {
      loading.value = true
      resolveFunc.value?.(selectedValues.value)
    }

    const updateSelection = (label: string, value: any) => {
      selectedValues.value = {
        ...selectedValues.value,
        [label]: value,
      }
    }

    return {
      visible,
      open,
      close,
      submit,
      fieldList,
      updateSelection,
      selectedValues,
      loading,
    }
  },
})

export default ExportModal
</script>
<style lang="sass" scoped>
::v-deep .el-dialog__body
  text-align: center !important

.modal-actions
  display: flex
  justify-content: center
  margin-top: 30px
  > *:not(:last-child)
    margin-right: 20px

table
  width: 100%
  margin-bottom: 20px

  .el-select
    width: 100%

  td:first-child
    text-align: right
</style>
