import { clone } from 'lodash'
import Data from 'src/utils/data'
import Util from 'src/utils/general'
import tinycolor from 'tinycolor2'

export const menus = (
  orderBy: string,
  perPage: number,
  hasNPS: boolean,
  hasSentiment: boolean,
  hasDate: boolean,
  sentiment_classifier: string,
  aiTopics: string[],
  selectedTopic: string,
): Array<Record<string, unknown>> => {
  const orderByOptions = [
    { label: 'Most Relevant', value: 'most_relevant' },
    { label: 'Least Relevant', value: 'least_relevant' },
  ]
  if (hasDate) {
    orderByOptions.push({ label: 'Most Recent', value: 'most_recent' })
  }
  if (hasNPS) {
    orderByOptions.push({ label: 'Highest NPS', value: 'highest_nps' }, { label: 'Lowest NPS', value: 'lowest_nps' })
  }
  if (hasSentiment && sentiment_classifier === 'google') {
    orderByOptions.push(
      { label: 'Most Positive', value: 'most_positive' },
      { label: 'Most Negative', value: 'most_negative' },
      { label: 'Most Mixed', value: 'most_mixed' },
    )
  }
  const res = [
    {
      name: 'Order By',
      selection: orderBy,
      options: [
        [
          {
            type: 'menu',
            showSelected: true,
            selected: orderBy,
            options: orderByOptions,
          },
        ],
      ],
    },
    {
      name: 'Show Per Page',
      selection: perPage,
      options: [
        [
          {
            type: 'menu',
            showSelected: true,
            selected: perPage,
            options: ['5', '10', '20', '50', '100'],
          },
        ],
      ],
    },
  ]
  if (aiTopics) {
    const topicOptions: { label: string; value: string }[] = []
    topicOptions.push({ label: '(Show All)', value: '' })
    aiTopics.forEach((t) => {
      topicOptions.push({ label: t, value: t })
    })
    res.push({
      name: 'Filter Topic [staff only]',
      selection: selectedTopic,
      options: [
        [
          {
            type: 'menu',
            showSelected: true,
            selected: selectedTopic,
            options: topicOptions,
          },
        ],
      ],
    })
  }
  return res
}

export const extractFields = (
  value: Record<string, any>,
  hasSentiment = false,
  hasNps = false,
  hasFiles = false,
  featureFlags = {} as Record<string, boolean>,
): Array<Record<string, string>> => {
  const result = []
  let verbatim = clone(value)

  result.push({
    field: 'Text field',
    value: verbatim._field,
    tag: undefined,
  })

  /** Delete the unstructured text data so that we are only
   * left with the structured metadata. **/
  delete verbatim[verbatim._field]
  delete verbatim._field

  if (verbatim['ai topics'] && featureFlags.show_ai_topics) {
    verbatim['ai topics'] = verbatim['ai topics'].join(', ')
  } else {
    delete verbatim['ai topics']
  }

  if (hasFiles) {
    result.push({
      field: 'File',
      value: verbatim[`${Data.INVISIBLE_PREFIX}filename`],
      tag: undefined,
    })
  }

  if (hasSentiment) {
    const sentiment = {
      field: 'Sentiment',
      value: verbatim._attributes?.sentiment || '(No Value)',
      tag: verbatim._attributes?.sentiment,
    }
    result.push(sentiment)
  }

  if (hasNps) {
    const npscat = {
      field: 'NPS Category',
      value: verbatim['NPS Category'] || '(No Value)',
      tag: verbatim['NPS Category'],
    }
    delete verbatim['NPS Category']
    result.push(npscat)
  }

  let verbatim_new = Object.entries(verbatim)
    .filter(([k, _]) => !k.startsWith(Data.INVISIBLE_PREFIX) && !k.startsWith('_'))
    .map(([k, v]) => ({ field: k, value: v === '' ? '(No Value)' : v, tag: undefined }))

  return result.concat(verbatim_new)
}

const injectHighlight = (
  text: string,
  topics: Array<string>,
  model_topics: Record<string, Record<string, any>>,
  model_terms: Record<string, Record<string, any>>,
  model_colors: Record<string, any>,
  textValues: Array<string>,
): string => {
  // Check all text search values to see if they are concepts (topics)
  for (let v of textValues) {
    let variants: string[] = []
    if (topics.indexOf(v) !== -1) {
      variants = variants.concat(model_topics[v].concept_variants)
    } else if (model_terms[v]) {
      variants = variants.concat(model_terms[v].variants)
    } else {
      // Handle terms/phrases not in the language model
      const cleanedVariant = v.replace(/\"/g, '') // strip quotes for explicit searching
      variants.push(cleanedVariant)
    }
    for (let variant of variants) {
      let topicColour = model_colors[variant] ? tinycolor(model_colors[variant]).setAlpha(0.5) : tinycolor('#e5e5e5')
      text = Util.excerptTextTermReplace(
        text,
        variant,
        `<span style="background-color: ${topicColour}">${variant}</span>`,
      )
    }
  }
  return text
}

export const extractText = (
  value: string,
  topics: Array<string>,
  modelTopics: Record<string, Record<string, unknown>>,
  modelTerms: Record<string, Record<string, unknown>>,
  modelColors: Record<string, unknown>,
  textValues: Array<string>,
): string => {
  let text = injectHighlight(value, topics, modelTopics, modelTerms, modelColors, textValues)
  text = Util.excerptNewlineReplace(text)
  return text
}
