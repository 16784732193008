<template>
  <modal :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Rename Dashboard</div>
    </template>
    <template #content>
      <VeeForm ref="editDashboardObserver" v-slot="{ meta, handleSubmit }">
        <form class="edit-dashboard" @submit.prevent="handleSubmit(saveEditDashboard)">
          <div class="form-body">
            <Field
              v-slot="{ field, errors }"
              mode="aggressive"
              rules="required|max:200"
              vid="name"
              name="Name"
              tag="div"
              class="input-section"
            >
              <label>
                <span>Dashboard title</span>
                <bf-text-input
                  v-model="editDashboardFormValues.name"
                  class="nameInput"
                  placeholder="Dashboard title"
                  :errors="errors"
                  focus
                  @input="field.onInput"
                />
              </label>
            </Field>
            <div class="buttons">
              <bf-button type="button" size="big" @click="$emit('close')"> Cancel </bf-button>
              <bf-button
                type="submit"
                color="blue"
                size="big"
                :disabled="!meta.valid || meta.pending || isEditDashboardFormUpdating"
              >
                Save
              </bf-button>
            </div>
          </div>
        </form>
      </VeeForm>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { useStore } from 'vuex'

import Modal from 'components/Modal.vue'
import BfButton from 'src/components/Butterfly/BfButton/BfButton.vue'
import BfTextInput from 'src/components/Butterfly/BfTextInput/BfTextInput.vue'
import { Analysis } from 'src/types/AnalysisTypes'
import { Dashboard } from 'src/types/DashboardTypes'
import { UPDATE_DASHBOARD } from 'src/store/types'

export default defineComponent({
  name: 'RenameModal',
  components: {
    Modal,
    VeeForm,
    Field,
    BfButton,
    BfTextInput,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()

    const currentAnalysis = computed(() => store.getters.currentAnalysis as Analysis)
    const currentDashboard = computed(() => store.getters.currentDashboard as Dashboard)

    const editDashboardObserver = ref<InstanceType<typeof VeeForm>>()
    const isEditDashboardFormUpdating = ref(false)
    const editDashboardFormValues = ref({
      name: '',
    })

    const saveEditDashboard = async (): Promise<void> => {
      const { name } = editDashboardFormValues.value
      try {
        isEditDashboardFormUpdating.value = true
        await store.dispatch(UPDATE_DASHBOARD, {
          dashboard: {
            id: currentDashboard.value.id,
            analysis_id: currentAnalysis.value.id,
            name,
          },
          rethrowErrors: true,
        })
        // update dashboards list
        // await this.loadAnalysis()

        // close modal
        emit('close')
      } catch (resp: any) {
        if (resp.status === 400) {
          const errors = await resp.json()
          // pass errors to form context to highlight each input's errors
          editDashboardObserver.value?.setErrors(errors)
        }
      } finally {
        isEditDashboardFormUpdating.value = false
      }
    }

    return {
      editDashboardFormValues,
      isEditDashboardFormUpdating,
      saveEditDashboard,
      editDashboardObserver,
    }
  },
})
</script>
<style lang="scss" scoped></style>
