<script lang="ts">
import { ref, defineComponent } from 'vue'

/**
 * TruncatedSpan component.
 *
 * - it mimics a conventional span element, but truncates the text
 *   and shows a tooltip if the text overflows.
 * - The text is truncated with an ellipsis.
 *
 * Usage:
 *
 * ```vue
 * import { TruncatedSpan } from 'components/Butterfly/TruncatedSpan'
 *
 * <template>
 *   <div>
 *     <span>The column name is </span>>
 *     <truncated-span>
 *       A really long column name that will get truncated based
 *       on the available space.
 *     </truncated-span>
 *   </div>
 * </template>
 *
 * The text inside the `truncated-span` will be truncated and a tooltip
 * will be shown when the text overflows, and *only* if the text overflows.
 */
const TruncatedSpan = defineComponent({
  inheritAttrs: false,
  setup() {
    const textEl = ref<HTMLElement | null>(null)
    const showTooltip = ref(false)
    const slotText = ref('')

    const checkOverflow = () => {
      if (!textEl.value) return

      // Capture whatever text is inside the slot (ignores HTML tags)
      slotText.value = textEl.value.textContent || ''

      // Check if the element is actually overflowing
      showTooltip.value = textEl.value.scrollWidth > textEl.value.clientWidth
    }

    const handleMouseEnter = () => {
      checkOverflow()
    }

    return {
      showTooltip,
      textEl,
      slotText,
      handleMouseEnter,
    }
  },
})
export default TruncatedSpan
</script>

<template>
  <!--  Set :title to the slot text-->
  <span ref="textEl" :title="showTooltip ? slotText : ''" class="truncate" @mouseenter="handleMouseEnter">
    <slot />
  </span>
</template>

<style scoped lang="scss">
.truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  min-width: 0;
}
</style>
