<template>
  <div>
    <div class="directive">
      <template v-if="step === 'select-provider'"> Select an Integration </template>
      <template v-if="step === 'select-survey'"> Choose Survey to Import </template>
      <template v-if="step === 'select-s3-object'"> Choose File from S3 </template>
      <template v-if="step === 'select-gcs-object'"> Choose File from GCS </template>
      <template v-if="step === 'confirm'"> Import Data </template>
      <!-- Advantage -->
      <template v-if="step === 'advantage-import'"> Import Advantage Product Data </template>
    </div>

    <!-- List of providers -->
    <div v-show="step === 'select-provider'" class="providers" :class="{ loading: isLoading }">
      <div class="ui inverted dimmer" :class="{ active: isLoading }">
        <div class="ui large loader"></div>
      </div>

      <!-- Advantage -->
      <div v-if="featureFlags.advantage_integration" class="provider advantage" @click="selectProvider('advantage')">
        <img src="../../../assets/img/integrations/advantage.png" />
        <div class="text">
          <div class="name">Advantage Database</div>
          <p>Connect this project with a product's responses</p>
        </div>
      </div>

      <!-- Delighted -->
      <div
        v-if="'delighted' in integrationsDict"
        class="provider delighted"
        :class="{ disabled: !integrationsDict.delighted.enabled }"
        @click="selectProvider('delighted')"
      >
        <img src="../../../assets/img/integrations/delighted.png" />
        <div class="text">
          <div class="name">Delighted</div>
          <p>Connect this project with your Delighted responses</p>
        </div>
      </div>

      <!-- Qualtrics -->
      <div
        v-if="'qualtrics' in integrationsDict"
        class="provider qualtrics"
        :class="{ disabled: !integrationsDict.qualtrics.enabled }"
        @click="selectProvider('qualtrics')"
      >
        <img
          src="../../../assets/img/integrations/qualtrics.png"
          srcset="
            ../../../assets/img/integrations/qualtrics@2x.png 2x,
            ../../../assets/img/integrations/qualtrics@3x.png 3x
          "
        />
        <div class="text">
          <div class="name">Qualtrics</div>
          <p>Connect this project with one of your Qualtrics surveys</p>
        </div>
      </div>

      <!-- s3 -->
      <div
        v-if="'s3' in integrationsDict"
        class="provider s3"
        :class="{ disabled: !integrationsDict.s3.enabled }"
        @click="selectProvider('s3')"
      >
        <img
          src="../../../assets/img/integrations/s3.png"
          srcset="../../../assets/img/integrations/s3@2x.png 2x, ../../../assets/img/integrations/s3@3x.png 3x"
        />
        <div class="text">
          <div class="name">S3</div>
          <p>Import data from your Amazon S3 Bucket</p>
        </div>
      </div>

      <!-- gcs -->
      <div
        v-if="'gcs' in integrationsDict"
        class="provider gcs"
        :class="{ disabled: !integrationsDict.gcs.enabled }"
        @click="selectProvider('gcs')"
      >
        <img
          src="../../../assets/img/integrations/gcs-full.png"
          srcset="
            ../../../assets/img/integrations/gcs-full@2x.png 2x,
            ../../../assets/img/integrations/gcs-full@3x.png 3x
          "
        />
        <div class="text">
          <div class="name">GCS</div>
          <p>Import data from your Google Cloud Storage Bucket</p>
        </div>
      </div>

      <!-- Survey Monkey -->
      <div
        v-if="'survey_monkey' in integrationsDict"
        class="provider s3"
        :class="{ disabled: !integrationsDict.survey_monkey.enabled }"
        @click="selectProvider('survey_monkey')"
      >
        <img
          src="../../../assets/img/integrations/surveymonkey-square.png"
          srcset="
            ../../../assets/img/integrations/surveymonkey-square@2x.png 2x,
            ../../../assets/img/integrations/surveymonkey-full@3x.png   3x
          "
        />
        <div class="text">
          <div class="name">Survey Monkey</div>
          <p>Import data from Survey Monkey</p>
        </div>
      </div>
    </div>

    <!-- Survey list -->
    <survey-list
      v-if="step === 'select-survey'"
      :provider="provider"
      @survey-selected="selectIntegrationSurvey"
    ></survey-list>

    <!-- S3 object browser -->
    <div v-if="step === 'select-s3-object'">
      <div class="bucket-browser-info-text">
        Supported file formats: csv, xls, xlsx. Unsupported files are automatically hidden.
      </div>
      <object-browser
        :bucket="integrationsDict.s3.details.bucket"
        :allow-select-file="true"
        :allow-select-folder="false"
        :root-folder-open="true"
        provider="s3"
        @object-selected="selectFile"
      >
      </object-browser>
    </div>

    <!-- GCS object browser -->
    <div v-if="step === 'select-gcs-object'">
      <div class="bucket-browser-info-text">
        Supported file formats: csv, xls, xlsx. Unsupported files are automatically hidden.
      </div>
      <object-browser
        :bucket="integrationsDict.gcs.details.bucket"
        :allow-select-file="true"
        :allow-select-folder="false"
        :root-folder-open="true"
        provider="gcs"
        @object-selected="selectFile"
      >
      </object-browser>
    </div>

    <div v-if="step === 'advantage-import'">
      <advantage-integration-import ref="advantageImport"></advantage-integration-import>
    </div>

    <!-- Confirmation -->
    <div v-if="step === 'confirm'" class="confirm">
      <!-- Error -->
      <template v-if="importError">
        <div class="error-bar">Import failed</div>
        <div class="error-actions">Please <span class="clear-upload" @click="goBack">Go back</span> and try again</div>
      </template>
      <template v-else-if="!isImporting && provider === 's3'">
        <p>You are about to import data from Amazon S3 Bucket:</p>
        <p class="fileinfo">
          file: <strong>{{ objectPath }}</strong>
        </p>
        <p class="fileinfo">size: {{ objectSize }}</p>
      </template>
      <template v-else-if="!isImporting && provider === 'gcs'">
        <p>You are about to import data from Google Storage Bucket:</p>
        <p class="fileinfo">
          file: <strong>{{ objectPath }}</strong>
        </p>
        <p class="fileinfo">size: {{ objectSize }}</p>
      </template>
      <template v-else-if="!isImporting && provider === 'qualtrics'">
        <p>
          You are about to import <strong>{{ number(numResponses) }}</strong> responses from {{ provider }}.
        </p>
        <p></p>
        <div class="block">
          <span class="demonstration">Choose start date to import from:</span>
          &nbsp;
          <vue-date-picker
            v-model="exportStartTime"
            :style="{ width: '160px', display: 'inline-block' }"
            :enable-time-picker="false"
            :start-time="{ hours: 0, minutes: 0 }"
            format="yyyy-MM-dd"
            placeholder="All time"
          />
          <label v-if="qualtricsColumnWhitelist" class="columnWhitelist">
            Column name whitelist:
            <br />
            <textarea
              v-model="columnWhitelist"
              placeholder="Comma-separated list of column names (optional)"
              @input="$emit('column-whitelist-changed', columnWhitelist)"
            ></textarea>
          </label>
        </div>
      </template>
      <template v-else-if="!isImporting">
        You are about to import <strong>{{ number(numResponses) }}</strong> responses from {{ provider }}.
      </template>
      <!-- Progress -->
      <progressbar
        v-else
        :size="'xlarge'"
        :percent="100"
        :active="true"
        :stripe="true"
        :label="'Your data is being imported. Please bear with us!'"
      >
        <template #barLabel> Importing... </template>
      </progressbar>
      <!-- Error details -->
      <div v-if="importError && importErrorDetails" class="error-text">
        <strong>Error details:</strong> {{ importErrorDetails }}
      </div>
    </div>

    <!-- Insufficient data units modal -->
    <Modal :visible="dataUnitsModalVisible" @close="hideModal('dataUnitsModalVisible')">
      <template #header>
        <div class="header">Insufficient Data Units</div>
      </template>
      <template #content>
        <div class="content">
          This {{ capitalize(provider) }} survey has <strong>{{ number(numResponses) }}</strong> responses. <br />Your
          Site only has <strong>{{ number(dataUnits) }}</strong> Data Units. <br />Upgrade your plan or choose another
          survey to import.
        </div>
      </template>
      <template #buttons>
        <div class="actions">
          <button class="ui blue button" @click="hideModal('dataUnitsModalVisible')">Okay, got it</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import Tooltip from 'tooltip.js'
import dayjs from 'dayjs'
import { filesize } from 'src/utils/files'
import { mapGetters } from 'vuex'
import ObjectBrowser from './ObjectBrowser.vue'
import SurveyList from './SurveyList.vue'
import AdvantageIntegrationImport from './advantage/AdvantageIntegrationImport.vue'
import Modal from 'components/Modal.vue'
import Progressbar from 'components/widgets/Progressbar.vue'
import Project from 'src/api/project'
import FormatUtils from 'src/utils/formatters'
import VueDatePicker from '@vuepic/vue-datepicker'

const MULTI_SURVEY_INTEGRATIONS = ['qualtrics']

export default defineComponent({
  props: {
    qualtricsColumnWhitelist: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
    SurveyList,
    ObjectBrowser,
    AdvantageIntegrationImport,
    Progressbar,
    VueDatePicker,
  },
  setup(props, { emit }) {
    const dataFile = ref(null)
    const integrationsDict = ref<object>({})
    const isLoading = ref<boolean>(false)
    const isImporting = ref<boolean>(false)
    const numResponses = ref<number | null>(null)
    const provider = ref<string | null>(null)
    const step = ref<string>('select-provider')
    const surveyId = ref<string>('')
    const surveyName = ref<string | null>(null)
    const objectPath = ref<string>('')
    const objectSize = ref<number | null>(null)
    const importError = ref<boolean>(false)
    const importErrorDetails = ref<string | null>(null)
    const exportEndTime = ref<string | null>(null)
    const exportStartTime = ref<string | null>(null)
    const advantageImport = ref<typeof AdvantageIntegrationImport | null>(null)
    const readyToConfirm = computed(() => {
      return (
        step.value === 'confirm' ||
        (step.value === 'advantage-import' && advantageImport.value && advantageImport.value.readyToConfirm)
      )
    })
    const columnWhitelist = ref<string>('')

    const dataUnitsModalVisible = ref(false)

    return {
      dataFile,
      integrationsDict,
      isLoading,
      isImporting,
      numResponses,
      provider,
      step,
      surveyId,
      surveyName,
      objectPath,
      objectSize,
      importError,
      importErrorDetails,
      exportEndTime,
      exportStartTime,
      advantageImport,
      readyToConfirm,
      columnWhitelist,
      dataUnitsModalVisible,
    }
  },
  computed: {
    ...mapGetters(['dataUnits', 'featureFlags']),
  },
  mounted() {
    this.loadIntegrations()
  },
  methods: {
    number: FormatUtils.number,
    capitalize: FormatUtils.capitalize,
    startCase: FormatUtils.startCase,
    isValid() {
      // columnWhitelist must either be empty or a comma-separated list of column names
      const regex = /^([^,]+(,[^,]+)*)?$/
      return !this.columnWhitelist || regex.test(this.columnWhitelist)
    },
    // Go to previous step, returns true unless we are already at the first step.
    goBack() {
      switch (this.step) {
        case 'confirm':
          this.importError = false
          // Skip 2nd step for single survey providers
          if (MULTI_SURVEY_INTEGRATIONS.includes(this.provider as string)) {
            this.step = 'select-survey'
          } else {
            this.step = 'select-provider'
          }
          return true
        case 'select-survey':
          this.step = 'select-provider'
          return true
        case 'select-s3-object':
          this.step = 'select-provider'
          return true
        case 'select-gcs-object':
          this.step = 'select-provider'
          return true
        case 'select-provider':
          return false
        case 'advantage-import':
          this.importError = false
          if (!this.advantageImport.goBack()) {
            this.step = 'select-provider'
          }
          return true
      }
    },
    isProviderAvailable(name) {
      return name in this.integrationsDict && this.integrationsDict[name].enabled
    },
    loadIntegrations() {
      Project.getAllIntegrations().then((response) => {
        // pack the list into a dict indexed by type
        this.integrationsDict = {}
        for (let i of response.results) {
          this.integrationsDict[i.type] = i
          if (!i.enabled) {
            this.$nextTick(() => {
              new Tooltip(this.$el.querySelector(`.provider.${i.type}`), {
                title: '<p>Integration disabled by your admin.</p>',
                html: true,
              })
            })
          }
        }
      })
    },
    // Wait for a survey import to be fully loaded before execute the `callbackFn`
    pollImportStatus(callbackFn, errorFn) {
      // Check if we have a schema, if not, set a timeout to recall this function
      if (['PROCESSING', 'AWAITING', 'UPLOADED'].includes(this.dataFile.status)) {
        Project.fetchFileStatus(this.dataFile.id).then((response) => {
          this.dataFile = response
        })
        this.dataFileTimeout = setTimeout(this.pollImportStatus.bind(this, callbackFn, errorFn), 2000)
      } else if (this.dataFile.schema !== null || this.dataFile.status === 'INDEXING') {
        clearTimeout(this.dataFileTimeout)
        this.dataFileTimeout = null
        // Get processed file samples
        Project.getFileFromBlobStore(this.dataFile.id).then((data) => {
          let processedFile = data
          this.numResponses = processedFile.num_responses
          this.isImporting = false
          if (callbackFn) {
            callbackFn(this.dataFile, {
              headers: processedFile.headers,
              samples: processedFile.samples,
            })
          }
        })
        this.$analytics.track.integration.exportData(
          true,
          this.provider,
          this.numResponses || this.ObjectSize,
          this.dataFile.schema.length,
        )
      } else if (this.dataFile.status === 'ERROR') {
        this.importError = true
        this.importErrorDetails = this.dataFile.status_text
        this.isImporting = false
        if (errorFn) {
          errorFn()
        }
      }
    },
    // Initiate import of survey responses.
    // Runs `callbackFn` when complete with the `dataFile` a metadata dict containing:
    // samples -- subset of responses
    // headers -- survey column headers
    runImport(callbackFn, errorFn) {
      if (this.step === 'advantage-import') {
        this.isImporting = true
        return this.$refs.advantageImport
          .runImport()
          .then((data) => {
            this.dataFile = data
            // Initiate status check
            this.pollImportStatus(callbackFn, errorFn)
          })
          .catch(() => {
            this.importError = true
            this.isImporting = false
            errorFn()
          })
      }
      this.importError = false
      this.importErrorDetails = null
      let params = { prev_export_cutoff: this.exportEndTime }
      if (this.provider == 'survey_monkey') {
        Object.assign(params, { surveyId: this.surveyId })
      } else if (this.provider == 'qualtrics') {
        Object.assign(params, {
          surveyId: this.surveyId,
          export_start_time: this.exportStartTime ? dayjs(this.exportStartTime).toISOString() : null,
          column_whitelist: this.columnWhitelist,
        })
      } else if (this.provider === 's3') {
        Object.assign(params, { s3ObjectPath: this.objectPath })
      } else if (this.provider === 'gcs') {
        Object.assign(params, { objectPath: this.objectPath })
      }

      this.isImporting = true
      return Project.createIntegrationExport(this.provider, params)
        .then((response) => response.json())
        .then((data) => {
          this.dataFile = data
          // Initiate status check
          this.pollImportStatus(callbackFn, errorFn)
        })
    },
    // Select a specific survey to load for a provider
    selectIntegrationSurvey(surveyId, surveyName, numResponses) {
      this.numResponses = numResponses
      if (numResponses > this.dataUnits) {
        this.dataUnitsModalVisible = true
        return
      }
      this.surveyId = surveyId
      this.surveyName = surveyName
      this.exportEndTime = dayjs().utc().toISOString()
      this.$emit('survey-selected', {
        survey_id: this.surveyId,
        num_responses: this.numResponses,
        prev_export_cutoff: this.exportEndTime,
      })
      this.step = 'confirm'
    },
    selectFile(path, size) {
      this.objectSize = filesize(size)

      // Strip off the "bucket" prefix
      this.objectPath = path.split('/').slice(1).join('/')
      // Strip off the file name
      const folderPath = path.split('/').slice(0, -1).join('/')
      this.$emit('folder-selected', folderPath, this.provider)
      setTimeout(() => {
        this.step = 'confirm'
      }, 500)
    },
    selectProvider(name: string) {
      this.importError = false
      this.importErrorDetails = null
      if (name !== 'advantage' && this.isProviderAvailable(name) === false) {
        return
      }

      this.provider = name
      let payload
      if (name === 'advantage') {
        payload = {
          type: 'advantage',
        }
      } else {
        payload = {
          ...this.integrationsDict[this.provider],
        }
      }
      this.$emit('provider-selected', payload)
      if (name === 'qualtrics') {
        this.surveyId = ''
        this.surveyName = null
        this.step = 'select-survey'
      } else if (name === 'delighted') {
        this.surveyId = ''
        this.surveyName = null
        this.isLoading = true
        Project.getSurveyMetadata(name, '')
          .then((response) => {
            this.numResponses = response.result.response_count
            if (this.numResponses > this.dataUnits) {
              this.dataUnitsModalVisible = true
              return
            }
            this.step = 'confirm'
          })
          .finally(() => {
            this.isLoading = false
          })
      } else if (name === 's3') {
        // Fetch the list of objects from the bucket.
        this.objectPath = ''
        this.numResponses = null
        this.objectSize = null
        this.step = 'select-s3-object'
      } else if (name === 'gcs') {
        // Fetch the list of objects from the bucket.
        this.objectPath = ''
        this.numResponses = null
        this.objectSize = null
        this.step = 'select-gcs-object'
      } else if (name === 'survey_monkey') {
        this.surveyName = ''
        this.numResponses = null
        this.step = 'select-survey'
      } else if (name === 'advantage') {
        this.step = 'advantage-import'
      } else {
        throw new Error(`Unknown integration: ${name}`)
      }
    },
    hideModal(modal) {
      this[modal] = false
    },
  },
})
</script>
<style lang="sass" scoped>
@import '../../../assets/kapiche.sass'
@import '../../../assets/mixins.sass'

.bf-datepicker
  color: $text-black
  border: solid 1px $grey
  padding: 10px
  width: 140px
  border-radius: 3px
  cursor: pointer
  text-align: center

.fileinfo
  font-size: 16px

.directive
  font-size: rem(14px)
  font-weight: bold
  letter-spacing: rem(0.7px)
  margin-bottom: rem(10px)
  margin-top: rem(10px)
  text-transform: uppercase

.bucket-browser-info-text
  font-size: 18px
  margin-bottom: 30px

.providers
  position: relative
  .provider
    box-shadow: $box-shadow
    height: rem(102px)
    margin-left: auto
    margin-right: auto
    margin-top: rem(30px)
    width: rem(602px)
    border: rem(1px) solid transparent
    &:not(.disabled)
      cursor: pointer
      &:hover
        border-color: $blue
    &:first-child
      margin-top: rem(40px)
    &.disabled
      filter: grayscale(100%)
      opacity: 0.7
    &.advantage img
      padding: 10px
      background: white
    div.text, img
      float: left
      height: rem(100px)
    div.text
      background-color: white
      border-bottom-right-radius: 3px
      border-top-right-radius: 3px
      border: 1px solid transparent
      border-left: 0
      padding-left: rem(25px)
      padding-top: rem(25px)
      text-align: left
      width: rem(499px)
      .name
        font-size: rem(18px)
        font-weight: bold
        margin-bottom: rem(5px)
  .dimmer
    background: transparent
  &.loading .provider
    opacity: 0.3

.confirm
  font-size: rem(20px)
  padding-top: rem(20px)

.error-bar
  width: rem(500px)
  margin: rem(15px) auto !important
  background-color: $red
  border-radius: 3px
  color: white
  height: rem(40px)
  font-size: rem(16px)
  font-weight: bold
  padding-top: rem(10px)
  text-align: center
.error-actions
  font-size: 14px
.clear-upload
  color: $blue
  cursor: pointer
  font-weight: bold
.error-text
  font-size: 18px
  font-weight: normal
  margin-top: 40px

.columnWhitelist
  display: block
  margin-top: 20px
  textarea
    margin-top: 4px
    min-width: 462px
    padding: 10px
    resize: vertical
</style>
