<template>
  <modal :visible="visible" @close="close">
    <template #header>
      <div>Language Selection &amp; Translation</div>
    </template>
    <template #content>
      <div class="translate-modal content">
        <el-main>
          <div v-if="devMode" style="font-size: 12px; border: red solid 2px">
            <p>editedLanguageOptions: {{ Object.fromEntries(editedLanguageOptions) }}</p>
            <p>selectedField: {{ selectedField }}</p>
            <p>selectedSourceLanguage: {{ selectedSourceLanguage }}</p>
          </div>
          <el-form label-position="left" style="width: 500px; line-height: 50px">
            <p>
              For <strong>each field</strong> that should be modified for language settings, first choose the field in
              the list below, and then configure the desired option.
            </p>
            <el-form-item label="Field">
              <el-select
                :model-value="selectedField"
                placeholder="Select field"
                @update:model-value="selectedField = $event"
              >
                <el-option v-for="item in languageFields" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-main>
            <p>
              Choose which action should be taken with field <strong>{{ selectedField }}</strong
              >:
            </p>
          </el-main>
          <el-radio-group :model-value="radio" @update:model-value="radio = $event">
            <el-radio-button :label="1"> No change </el-radio-button>
            <el-radio-button v-if="featureFlags.multiple_languages" :label="2"> Set Languages </el-radio-button>
            <el-radio-button :label="3"> Translate </el-radio-button>
          </el-radio-group>
          <el-container>
            <el-main v-if="radio === 1"> This field will be indexed as normal. </el-main>
            <el-main v-if="radio === 2">
              <div class="multiselect-container">
                <p class="multiselect-label">Source languages</p>
                <p>
                  By specifying languages for this text field, language-appropriate stopword lists can be used when
                  analysing the text data.
                </p>
                <el-select
                  :model-value="selectedSourceLanguage"
                  class="options-list"
                  multiple
                  filterable
                  placeholder="Please select at least one language"
                  type="primary"
                  no-match-text="No matching fields"
                  @update:model-value="selectedSourceLanguage = $event"
                >
                  <el-option v-for="item in languages" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </div>
            </el-main>
            <el-main v-if="radio === 3">
              <div class="multiselect-container">
                <p class="multiselect-label">Translation</p>
                <p class="help-text">
                  The text in this field will be translated into a new language. After this conversion, all data
                  processing steps of the new language will be used, including sentiment analysis, phrase detection,
                  sentence embeddings, and word stemming.
                </p>
                <p>Target Language:</p>
                <el-select
                  :model-value="selectedTargetLanguage"
                  class="options-list"
                  disabled
                  filterable
                  placeholder="Selected Source Language"
                  @update:model-value="selectedTargetLanguage = $event"
                >
                  <el-option v-for="item in languages" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </div>
            </el-main>
          </el-container>
        </el-main>
      </div>
    </template>
    <template #buttons>
      <div class="footer">
        <bf-button color="grey" size="large" @click="close"> Cancel </bf-button>
        <bf-button color="blue" size="large" @click="done"> Done </bf-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { clone } from 'lodash'
import { BfButton } from 'components/Butterfly'
import { SchemaColumn, LanguageOptions } from 'types/SchemaTypes'
import Modal from 'components/Modal.vue'

export default defineComponent({
  components: {
    BfButton,
    Modal,
  },
  props: {
    devMode: { type: Boolean, required: false, default: false },
    visible: { type: Boolean, default: false },
    schema: { type: Array as Array<SchemaColumn>, required: true },
    languageOptions: { type: Map as LanguageOptions, required: true },
  },
  data() {
    return {
      editedLanguageOptions: new Map() as LanguageOptions,
      selected: [] as Array<string>,
      selectedSourceLanguage: [] as Array<string>,
      selectedTargetLanguage: 'English',
      translationEnabled: false,
      radio: 1,
      selectedField: '',
      /* See apps/projects/language/names.py in Botanic! */
      languages: [
        // 'Afrikaans',
        // 'Albanian',
        // 'Amharic',
        'Arabic',
        // 'Armenian',
        // 'Azerbaijani',
        // 'Basque',
        // 'Belarusian',
        // 'Bengali',
        // 'Bosnian',
        // 'Bulgarian',
        // 'Catalan',
        // 'Chichewa',
        'Chinese (Simplified)',
        // 'Chinese (Traditional)',
        // 'Corsican',
        // 'Croatian',
        // 'Czech',
        // 'Danish',
        'Dutch',
        'English',
        // 'Esperanto',
        // 'Estonian',
        // 'Filipino',
        // 'Finnish',
        'French',
        // 'Frisian',
        // 'Galician',
        // 'Georgian',
        'German',
        'Greek',
        // 'Gujarati',
        // 'Haitian Creole',
        // 'Hausa',
        // 'Hawaiian',
        // 'Hebrew',
        // 'Hindi',
        // 'Hmong',
        // 'Hungarian',
        // 'Icelandic',
        // 'Igbo',
        // 'Indonesian',
        // 'Irish',
        'Italian',
        'Japanese',
        // 'Javanese',
        // 'Kannada',
        // 'Kazakh',
        // 'Khmer',
        // 'Korean',
        // 'Kurdish (Kurmanji)',
        // 'Kyrgyz',
        // 'Lao',
        // 'Latin',
        // 'Latvian',
        // 'Lithuanian',
        // 'Luxembourgish',
        // 'Macedonian',
        // 'Malagasy',
        // 'Malay',
        // 'Malayalam',
        // 'Maltese',
        // 'Maori',
        // 'Marathi',
        // 'Mongolian',
        // 'Myanmar (Burmese)',
        // 'Nepali',
        // 'Norwegian',
        // 'Pashto',
        // 'Persian',
        // 'Polish',
        'Portuguese',
        // 'Punjabi',
        // 'Romanian',
        'Russian',
        // 'Samoan',
        // 'Scots Gaelic',
        // 'Serbian',
        // 'Sesotho',
        // 'Shona',
        // 'Sindhi',
        // 'Sinhala',
        // 'Slovak',
        // 'Slovenian',
        // 'Somali',
        'Spanish',
      ],
    }
  },
  computed: {
    ...mapGetters(['featureFlags']),
    languageFields(): Array<SchemaColumn> {
      return this.schema.filter((col) => ['TEXT', 'LABEL'].includes(col.typename))
    },
  },
  watch: {
    /**
     * Initialize state on visibility change.
     */
    visible(val: boolean): void {
      if (val) {
        // Initialize the first field in the dropdown, to avoid having
        // nothing selected.
        this.selectedField = this.languageFields[0].name
        // Initialize the local structure for holding the changed state. Just copy
        // the input prop value as given. The prop will never be updated in
        // this component - we will only ever emit the changed state when "DONE"
        // is clicked. The parent must receive the emitted event and update
        // their state, which is provided here as the `languageOptions` prop.
        this.editedLanguageOptions = clone(this.languageOptions)
      }
    },
    /**
     * Element-ui radio group works great with a simple integer value tracking
     * the selected option, but we need to map that into our mapping of
     * `editedLanguageOptions` when the radio value changes. This is done
     * in the `changeLanguageOption` method called below.
     */
    radio(): void {
      this.changeLanguageOption(this.radio)
    },
    /**
     * This fires when the field dropdown changes. We need to update the
     * radio group and its corresponding state when the selected field changes.
     */
    selectedField(newField): void {
      let languageOption = this.editedLanguageOptions.get(newField)
      if (!languageOption) {
        // If the field name doesn't appear in the mapping, that means the same
        // as "no change".
        this.radio = 1
        this.selectedSourceLanguage = []
        this.translationEnabled = false
      } else if (languageOption.enabledLanguages.length) {
        // If source languages are configured on the field, select that option.
        this.radio = 2
        // Also remember to update the local state for populating the combo
        // select with all the languages.
        this.selectedSourceLanguage = languageOption.enabledLanguages
        this.translationEnabled = false
      } else if (languageOption.translationLanguage) {
        // If we reach here, and a translation language has been set, then
        // we know that the field is set to be translated.
        this.radio = 3
        this.translationEnabled = true
      } else {
        // If nothing matched, then the field is set to "no change".
        // We'll also clear all the other state just in case.
        this.radio = 1
        this.selectedSourceLanguage = []
        this.translationEnabled = false
      }
    },
    selectedSourceLanguage(val): void {
      this.editedLanguageOptions.set(this.selectedField, {
        enabledLanguages: val || [],
        translationLanguage: '',
      })
    },
  },
  methods: {
    /**
     * This method is called when the radio group value changes. It maps the
     * radio value to the corresponding state in the `editedLanguageOptions`
     * mapping.
     */
    changeLanguageOption(newValue: number): void {
      if (newValue === 1) {
        // If the radio value is 1, then the field is set to "no change".
        // Also make sure to clear any other state that was set for this
        // schema field.
        this.editedLanguageOptions.set(this.selectedField, {
          enabledLanguages: [],
          translationLanguage: '',
        })
      } else if (newValue === 2) {
        // If the radio value is 2, then the field is set to have source
        // languages specified. Also make sure to clear any other state that
        // was set for this schema field.
        this.editedLanguageOptions.set(this.selectedField, {
          enabledLanguages: this.selectedSourceLanguage,
          translationLanguage: '',
        })
      } else {
        // If the radio value is 3, then the field is set to be translated.
        this.editedLanguageOptions.set(this.selectedField, {
          enabledLanguages: [],
          translationLanguage: this.selectedTargetLanguage,
        })
      }
    },
    close(): void {
      this.$emit('close')
    },
    done(): void {
      this.$emit('values-changed', clone(this.editedLanguageOptions))
      this.close()
    },
  },
})
</script>

<style lang="sass" scoped>
:deep(.translate-modal)
  label.el-form-item__label
    --el-font-size-base: 16px
    width: 50px
    line-height: 50px !important

.el-menu-item
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.el-menu-item:hover
  overflow: visible
  position: fixed
  z-index: 2

.content
  display: flex
  flex-direction: column
  align-items: center
  text-align: left

.title
  text-align: center

.title, .help-text
  width: 100%
  margin-bottom: 10px
  line-height: 1.5

.footer
  margin-top: 45px

.multiselect-container
  width: 100%
  max-width: 700px
  margin: 10px auto

  .options-list
    width: 100%

  .multiselect-label
    font-weight: bold
    text-align: left
    margin-bottom: 5px
</style>
