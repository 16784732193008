<template>
  <div>
    <bf-panel>
      <template #main>
        <div class="header">
          <span>
            <h2>Users</h2>
            <p v-loading="subscriptionLoading">
              {{ formattedUserLimit }}
              <br />
              {{ formattedViewerUserLimit }}
            </p>
          </span>
          <el-button class="invite-button" color="#068ccc" type="primary" @click="showInviteModal = true">
            <i class="kapiche-icon-people-plus"></i>
            <span>Invite user</span>
          </el-button>
        </div>

        <div>
          <div class="user-management-table">
            <user-list :current-user-id="currentUser.id" @user-changed="loadSubscription"> </user-list>
            <invitation-list :show-invite-modal="showInviteModal" @close="showInviteModal = false"></invitation-list>
          </div>
        </div>
      </template>
    </bf-panel>

    <br />
    <bf-panel>
      <template #header>
        <h2>Approved Domains</h2>
        <bf-button color="blue" size="large" @click="show_adding_new_domain"> Add domain </bf-button>
      </template>
      <template #main>
        <p class="subdued">
          Anyone with an email from approved domains can register as an Explorer from shared Dashboards. Explorers will
          automatically have access to the Dashboard you share from.
        </p>
        <p v-if="approved_domains.length === 0 && !approved_domains_loading">You do not have any approved domains.</p>
        <ul class="approved">
          <!-- the list of approved_domains OPR animated-fills if loading-->
          <li v-for="domain in approved_domains" :key="domain.id">
            <!-- if loading we show an animated-fill for each AD we currently have -->
            <bf-animated-fill v-if="approved_domains_loading" />
            <template v-else>
              <span @click.stop="show_del_ad_modal(domain.id)">Remove</span> <strong>{{ domain.domain }}</strong>
            </template>
          </li>
          <!-- if loading make sure we have at least one animated-fill -->
          <li v-if="approved_domains_loading && approved_domains.length === 0">
            <bf-animated-fill />
          </li>
        </ul>
      </template>
    </bf-panel>

    <FormModal
      v-slot="{ willClose, willNotClose, validationOverride }"
      :is-open="showing_add_ad_modal"
      @close="cancel_adding_new_domain"
    >
      <VeeForm
        v-slot="{ meta: { valid, pending }, isSubmitting }"
        :validation-schema="validationOverride"
        :initial-values="initialFormValues"
        :on-submit="add_new_approved_domain"
      >
        <div class="ad_dialog">
          <h2>Add approved domain</h2>
          <p>The domain is the part of email address after the @ symbol, e.g. <strong>company.com</strong></p>

          <Field
            v-slot="{ field, errors }"
            rules="required|validDomain"
            name="email_domain"
            label="Email domain"
            validate-on-input
          >
            <bf-text-input v-bind="field" :errors="errors" placeholder="emaildomain.com" width="100%" focus />
          </Field>

          <div class="buttons">
            <bf-button
              type="button"
              size="big"
              @mousedown="willClose"
              @mouseleave="willNotClose"
              @click="cancel_adding_new_domain"
            >
              Cancel
            </bf-button>
            <bf-button type="submit" color="blue" size="big" :disabled="!valid || pending || isSubmitting">
              Add domain
            </bf-button>
          </div>
        </div>
      </VeeForm>
    </FormModal>
    <modal :visible="showing_del_ad_modal" @close="cancel_del_new_domain">
      <template #header>
        <div>Remove approved domain</div>
      </template>
      <template #content>
        <div class="ad_dialog">
          <p>Confirm the domain to be removed (existing guests will retain their access):</p>
          <div>
            <strong>{{ approved_domain_to_be_deleted }}</strong>
          </div>
          <br />
        </div>
      </template>
      <template #buttons>
        <div class="buttons">
          <bf-button size="big" @click="cancel_del_new_domain"> Cancel </bf-button>
          <bf-button color="red" size="big" @click="del_approved_domain"> Remove </bf-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { Form as VeeForm, Field } from 'vee-validate'
import { BfPanel, BfButton, BfTextInput, BfAnimatedFill } from 'components/Butterfly'
import UserList from 'components/account/UserList/UserList.vue'
import InvitationList from 'components/account/UserList/InvitationList.vue'

import FormModal from 'components/forms/FormModal.vue'
import store from 'src/store'
import {
  CLEAR_ERRORS,
  FETCH_APPROVED_DOMAINS,
  ADD_APPROVED_DOMAIN,
  REMOVE_APPROVED_DOMAIN,
  LOAD_SUBSCRIPTION,
} from 'src/store/types'
import FormatUtils from 'src/utils/formatters'
import Modal from 'components/Modal.vue'

interface NewDomainFormValues {
  email_domain: string
}

export default defineComponent({
  components: {
    FormModal,
    VeeForm,
    Field,
    BfPanel,
    BfButton,
    BfTextInput,
    BfAnimatedFill,
    UserList,
    InvitationList,
    Modal,
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch({ type: CLEAR_ERRORS })
    try {
      next((vm) => {
        vm.FETCH_APPROVED_DOMAINS()
      })
    } catch {
      next(false)
    }
  },
  data() {
    return {
      showing_add_ad_modal: false,
      showing_del_ad_modal: false,
      initialFormValues: {
        email_domain: '',
      } as NewDomainFormValues,
      approved_domain_id_to_del: null,
      response: undefined,
      showInviteModal: false as boolean,
      subscriptionLoading: false as boolean,
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      'userLimit',
      'viewerUserLimit',
      'membershipCount',
      'viewerMembershipCount',
      'currentSite',
      'approved_domains',
      'approved_domains_loading',
      'currentUser',
    ]),
    approved_domain_to_be_deleted() {
      const domain = this.approved_domains.find((ad) => ad.id === this.approved_domain_id_to_del)
      return domain ? domain.domain : ''
    },
    formattedUserLimit() {
      if (this.userLimit === Infinity) {
        return `${this.membershipCount} creator/admin users`
      } else if (this.userLimit === this.membershipCount) {
        return `This site has no free seats for admin or creator users: (${this.membershipCount}/${this.userLimit} used).`
      } else if (this.userLimit < this.membershipCount) {
        return `This site has too many admin and creator users: (${this.membershipCount}/${this.userLimit} used)`
      }
      return `${this.userLimit - this.membershipCount} out of ${this.userLimit} creator or admin seats remaining.`
    },
    formattedViewerUserLimit() {
      if (this.viewerUserLimit === undefined) {
        return ''
      } else if (this.viewerUserLimit === Infinity) {
        return `${this.viewerMembershipCount} explorer users.`
      } else if (this.viewerUserLimit === this.viewerMembershipCount) {
        return `This site has no free seats for explorer users: (${this.viewerMembershipCount}/${this.viewerUserLimit} used).`
      } else if (this.viewerUserLimit < this.viewerMembershipCount) {
        return `This site has too many explorer users: (${this.membershipCount}/${this.userLimit} used)`
      }
      return `${this.viewerUserLimit - this.viewerMembershipCount} out of ${this.viewerUserLimit} explorer seats remaining.`
    },
  },
  metaInfo() {
    return {
      title: this.currentSite ? `${this.currentSite.site_name} Users - Kapiche` : null,
    }
  },
  methods: {
    ...mapActions({
      FETCH_APPROVED_DOMAINS,
      ADD_APPROVED_DOMAIN,
      REMOVE_APPROVED_DOMAIN,
      LOAD_SUBSCRIPTION,
    }),
    date: FormatUtils.date,
    async loadSubscription() {
      this.subscriptionLoading = true
      try {
        this.LOAD_SUBSCRIPTION()
      } finally {
        this.subscriptionLoading = false
      }
    },
    show_del_ad_modal(domainId) {
      this.approved_domain_id_to_del = domainId
      this.showing_del_ad_modal = true
    },
    cancel_del_new_domain() {
      this.showing_del_ad_modal = false
    },
    del_approved_domain() {
      this.REMOVE_APPROVED_DOMAIN(this.approved_domain_id_to_del)
      this.showing_del_ad_modal = false
      const ad = this.approved_domains.find((ad) => ad.id === this.approved_domain_id_to_del)
      this.$analytics.track.site.removeApprovedDomain(ad.domain)
    },
    show_adding_new_domain() {
      this.showing_add_ad_modal = true
    },
    cancel_adding_new_domain() {
      this.showing_add_ad_modal = false
    },
    add_new_approved_domain({ email_domain }: NewDomainFormValues) {
      const ad = email_domain.trim()
      this.ADD_APPROVED_DOMAIN(ad)
      this.showing_add_ad_modal = false
      this.$analytics.track.site.addApprovedDomain(ad)
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.header
  display: flex
  justify-content: space-between
  margin-bottom: 15px

  .invite-button
    align-self: center
    font-weight: bold
    font-size: 16px

    .kapiche-icon-people-plus
      margin-right: 5px

.user-management-table
  border: 1px solid #EBEEF5

/* APPROVED DOMAINS */
ul.approved
  list-style: none
  padding: 0
  li
    line-height: 20px
    height: 26px
    padding: 3px 10px
    margin-bottom: 2px
    span
      visibility: hidden
      cursor: pointer
      padding-right: 10px
      color: $red
    &:hover > span
      visibility: visible

.ad_dialog
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 25px
  h2
    padding-top: 20px
    font-size: 30px
  .buttons
    padding: 10px 0 10px
    button
      &:first-child
        margin-right: 20px
.subdued
  color: $subdued
</style>
