<template>
  <modal :visible="visible" @close="clearAndClose">
    <template #header> Invite user </template>
    <template #content>
      <div class="new-user">
        <el-form ref="form" :model="formData" :rules="rules" hide-required-asterisk class="new-user-form">
          <div class="row">
            <el-form-item label="Send invite via email" prop="email" class="label grow">
              <el-input
                :model-value="formData.email"
                placeholder="Enter email"
                @update:model-value="formData.email = $event"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="Role" class="label">
              <user-type-select :value="formData.userType" @change="handleUserTypeChange" />
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item v-if="formData.userType === 'ANALYST'" label="Add to projects (optional)" class="label grow">
              <el-dropdown
                trigger="click"
                :hide-on-click="false"
                style="width: 100%"
                @visible-change.capture="dropdownVisible = $event"
              >
                <el-input
                  :model-value="filterTextProjects"
                  placeholder="Search for a project"
                  @update:model-value="filterTextProjects = $event"
                  @click="preventCloseOnFocus($event)"
                >
                </el-input>
                <template #dropdown>
                  <el-dropdown-menu class="dropdown-menu" style="min-width: 40%; max-height: 400px; overflow-y: scroll">
                    <el-tree
                      v-show="!loading"
                      ref="projectTree"
                      :data="projectOptions"
                      show-checkbox
                      default-expand-all
                      check-on-click-node
                      :expand-on-click-node="false"
                      :props="props"
                      node-key="id"
                      :filter-node-method="filterNode"
                      @check="handleProjectCheck"
                    >
                    </el-tree>
                    <div v-show="loading">
                      <bf-spinner class="spinner" text-pos="top" size="small"></bf-spinner>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <div>
                <el-tag v-for="project in formData.projects" :key="project.id" class="tags">
                  {{ project.name }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item v-if="formData.userType === 'VIEWER'" label="Add to dashboards (optional)" class="label grow">
              <el-dropdown trigger="click" style="width: 100%" @visible-change.capture="dropdownVisible = $event">
                <el-input
                  :model-value="filterTextDashboards"
                  placeholder="Search for a dashboard"
                  @update:model-value="filterTextDashboards = $event"
                  @click="preventCloseOnFocus($event)"
                >
                </el-input>
                <template #dropdown>
                  <el-dropdown-menu class="dropdown-menu" style="min-width: 45%; max-height: 400px; overflow-y: scroll">
                    <el-tree
                      v-show="!loading"
                      ref="dashboardTree"
                      :data="dashboardOptions"
                      show-checkbox
                      check-on-click-node
                      default-expand-all
                      :props="props"
                      node-key="id"
                      :filter-node-method="filterNode"
                      @check="handleDashboardCheck"
                    >
                    </el-tree>
                    <div v-show="loading">
                      <bf-spinner class="spinner" text-pos="top" size="small"></bf-spinner>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <div>
                <el-tag v-for="dashboard in formData.dashboards" :key="dashboard.id" class="tags">
                  {{ dashboard.name }}
                </el-tag>
              </div>
            </el-form-item>
            <div v-if="formData.userType === 'ADMIN'">
              Admin users have access to all projects and dashboards on a site
            </div>
          </div>
        </el-form>
      </div>
    </template>
    <template #buttons>
      <div class="footer">
        <bf-button size="big" @click="clearAndClose"> Cancel </bf-button>
        <bf-button type="submit" color="blue" size="big" :disabled="waitingForResponse" @click="inviteUser">
          Send invite
        </bf-button>
        <div class="server-error">
          {{ error }}
        </div>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BfSpinner, BfButton } from 'components/Butterfly'
import Site from 'src/api/site'
import Project from 'src/api/project'
import UserTypeSelect from 'components/account/UserList/UserTypeSelect.vue'
import { HttpResponse } from 'vue-resource/types/vue_resource'
import { ElTree, ElForm } from 'element-plus'
import { arrayToTree, TreeNode } from 'src/utils/arrayToElTree'
import Modal from 'components/Modal.vue'

export default defineComponent({
  name: 'SiteUsersList',
  components: {
    BfSpinner,
    BfButton,
    UserTypeSelect,
    Modal,
  },
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true as boolean,
      waitingForResponse: false as boolean,
      error: undefined as undefined | string,
      dropdownVisible: false as boolean,
      formData: {
        email: '' as string,
        userType: 'ANALYST' as 'ADMIN' | 'ANALYST' | 'VIEWER',
        projects: [] as { name: string; id: number }[],
        dashboards: [] as { name: string; id: number }[],
      },
      filterTextProjects: '' as string,
      filterTextDashboards: '' as string,
      projectOptions: [] as { name: string; id: number }[],
      dashboardOptions: [] as { name: string; id: number }[],
      props: {
        label: 'name',
        children: 'children',
      },
      rules: {
        email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
      },
    }
  },
  watch: {
    filterTextProjects(val) {
      ;(this?.$refs?.projectTree as typeof ElTree)?.filter(val)
    },
    filterTextDashboards(val) {
      ;(this?.$refs?.dashboardTree as typeof ElTree)?.filter(val)
    },
  },
  mounted() {
    this.fetchProjectOptions()
    this.fetchDashboardOptions()
  },
  methods: {
    preventCloseOnFocus(event: Event) {
      if (this.dropdownVisible) {
        event.stopImmediatePropagation()
      }
    },
    clearAndClose() {
      ;(this.$refs.form as typeof ElForm).resetFields()
      this.formData = {
        email: '',
        userType: 'ANALYST',
        projects: [],
        dashboards: [],
      }
      this.error = undefined
      this.waitingForResponse = false
      const pt = this?.$refs?.projectTree as typeof ElTree | undefined
      if (pt) pt.setCheckedNodes([{}])
      const dt = this?.$refs?.dashboardTree as typeof ElTree | undefined
      if (dt) dt.setCheckedNodes([{}])
      this.$emit('close')
    },
    handleUserTypeChange(type: 'ADMIN' | 'ANALYST' | 'VIEWER') {
      this.formData.userType = type
      this.formData.projects = []
      this.formData.dashboards = []
    },
    async fetchProjectOptions() {
      this.loading = true
      const response = await Project.searchProjects('')
      if (!this) return
      this.projectOptions = response.map((project: { name: string; id: number }) => {
        return {
          name: project.name,
          id: project.id,
        }
      })
      this.loading = false
    },
    async fetchDashboardOptions() {
      this.loading = true

      interface Dashboard {
        project_id: number
        project_name: string
        analysis: number // This is the analysis id
        analysis_name: string
        id: number
        name: string
      }

      const response: Dashboard[] = await Project.getAllDashboards()
      if (!this) return

      this.dashboardOptions = arrayToTree(response, 'analysis', 'analysis_name', 'project_id', 'project_name')
      this.loading = false
    },
    filterNode(value: string | undefined, data: TreeNode) {
      if (!value) return true
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    },
    async inviteUser() {
      this.error = ''
      try {
        await (this.$refs.form as typeof ElForm).validate()
      } catch {
        return
      }

      this.waitingForResponse = true
      try {
        await Site.createMembershipInvitation(
          this.formData.email,
          this.formData.userType,
          this.formData.projects.map((p) => p.id),
          this.formData.dashboards.map((d) => d.id),
        )
        this.$emit('user-invited', this.formData.email)
        this.$analytics.track.site.inviteUser(this.formData.email, this.formData.userType)
        this.clearAndClose()
      } catch (e: any) {
        this.error = e?.body?.non_field_errors?.join(', ') ?? 'Failed to invite user'
      } finally {
        this.waitingForResponse = false
      }
    },
    handleProjectCheck() {
      this.formData.projects = (this.$refs.projectTree as typeof ElTree<unknown, TreeNode>).getCheckedNodes(true)
    },
    handleDashboardCheck() {
      this.formData.dashboards = (this.$refs.dashboardTree as typeof ElTree<unknown, TreeNode>).getCheckedNodes(true)
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.footer
  padding-top: 10px
  text-align: center
  button
    margin-right: 20px

.server-error
  color: $red
  display: flex
  align-items: flex-end
  justify-content: center
  padding-top: 10px

.el-form-item
  flex-direction: column
::v-deep .el-form-item__label
  justify-content: flex-start
  width: 100%
::v-deep .el-form-item__content
  padding-top: 5px

.el-select__wrapper
  height: 40px

.new-user-form
  .label
    font-weight: bold

  .row
    display: flex
    justify-content: center
    width: 100%
    .grow
      flex-grow: 1

  .el-form-item
    margin-right: 15px

.tags
  font-size: 14px
  margin-right: 5px

::v-deep div.el-dialog__body
  background-color: $grey-background
  padding-left: 80px
  padding-right: 80px
</style>
