<template>
  <div id="timeline-container">
    <!-- dimmer -->
    <div v-if="isLoading" id="timeline-dimmer" class="ui active inverted dimmer">
      <div class="ui loader"></div>
    </div>
    <div class="timeline-tooltip">
      <div></div>
      <span class="timeline-dot"></span>
    </div>
    <div id="timeline-graph-container">
      <div :id="timelineId" ref="timelineElement" class="timeline">
        <div class="timeline-crosshair">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-show="nVisible === 0" id="empty-state">
        <template v-if="allSeries.length > 0">
          <h2>No segments shown</h2>
          <p>Select segments below that you want to see</p>
        </template>
        <template v-else>
          <h2>No results</h2>
        </template>
      </div>
    </div>
    <slot name="legend" :hover-item="hoverSeries" :unhover-item="unhoverSeries" :toggle-item="toggleQueryVisible" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import * as d3 from 'd3'
import { mouse as currentMouse } from 'd3'
import dayjs from 'dayjs'
import _ from 'lodash'
import { mapGetters } from 'vuex'

import { number } from 'src/utils/formatters'
import Utils from 'src/utils/general'
import DataUtils from 'src/utils/data'
import MathUtil from 'src/utils/math'
import { formatTooltipDate, getTickInterval, xTickFormatter } from 'components/DataWidgets/Timeline/Timeline.utils'
import { TrendLine } from 'src/types/widgets.types'

const pointRadius = 3
const lineWidth = 1.5
const defaultColour = '#068ccc'
const defaultlineStyle = 'solid-line'

const Timeline = defineComponent({
  props: {
    timelineId: { type: String, required: false, default: 'nope' },
    // allSeries should be an array where each element is a series to be
    // plotted on the chart. Each series should have this structure:
    // {
    //   counts: Array[Numbers],
    //   datetimes: Array[Datetimes],
    //   color: Color,
    //   lineStyle: String,
    //   name: String,
    //   visible: boolean,
    // }
    allSeries: { type: Array as PropType<TrendLine[]>, required: true },
    // the yRange is the minimum and maximum values to show on the y-axis.
    // The format should look like: [minNumber, maxNumber]
    yRange: { type: Array, required: true },
    yRangeRight: { type: Array, default: undefined },
    // resolution changes the ticks on the x-axis
    resolution: {
      type: String,
      default: 'monthly',
      validator: (v) => ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'].includes(v),
    },
    yValueNumberFormat: {
      type: String,
      default: 'integer',
      validator: (v) =>
        ['integer', 'percentage', 'signAwareInteger', 'signAwarePercentage', 'signAwareRoundedFloat'].includes(v),
    },
    yAxisLeftColor: { type: String, default: '#000' },
    yAxisRightColor: { type: String, default: '#000' },
    yAxisRightNames: { type: Array as PropType<string[]>, default: () => [] },
    yLabel: { type: String, default: '' },
    yLabelRight: { type: String, default: '' },
    xLabel: { type: String, default: '' },
    records: { type: Object, required: false, default: () => ({}) },
    // seriesLabels is a map of series names to labels that will be
    // displayed on tooltips. Optional and defaults to yLabel.
    seriesLabels: { type: Object, required: false, default: () => ({}) },
    seriesTags: { type: Object, required: false, default: () => ({}) },
    chartHeight: { type: String, required: false, default: '320px' },
    yAxisLeftTicks: { type: Number, required: false, default: 6 },
    yAxisRightTicks: { type: Number, required: false, default: 6 },
    visibleYAxisLabels: { type: Array, required: false, default: null },
    visibleXAxisLabels: { type: Array, required: false, default: null },
    gutterLeft: { type: Number, required: false, default: null },
    gutterRight: { type: Number, required: false, default: null },
    gutterTop: { type: Number, required: false, default: null },
    gutterBottom: { type: Number, required: false, default: null },
    isExporting: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      defaultColour,
      allPoints: [],
      xPositions: [],
      selectedSeries: null,
      showNextSeries: null,
      filteredData: {},
      // resize has to be declared here to ensure that every instance of Timeline in
      // a page gets their own debounced version.  If declared in methods they will
      // all share the same one and it will only fire for one of them.
      resize: _.debounce(
        function () {
          this.$nextTick(() => this.draw())
        }.bind(this),
        250,
      ),
      xLabelPad: 15,
      isLoading: true,
      seriesHashMap: {},
      dateHoverLine: null,
      svg: null,
    }
  },
  computed: {
    ...mapGetters(['dashboardTimeseries', 'currentAnalysis']),
    gutters() {
      return {
        l: this.gutterLeft ?? 70,
        r: this.gutterRight ?? (this.yRangeRight ? 70 : 30),
        t: this.gutterTop ?? 15,
        b: this.gutterBottom ?? 50,
      }
    },
    timelineElement() {
      return this.$el.querySelector(`#${this.timelineId}`)
    },
    tooltipElement() {
      return this.$el.querySelector(`.timeline-tooltip`)
    },
    crosshairElement() {
      return this.$el.querySelector(`.timeline-crosshair`)
    },
    d3numberFormat() {
      const numberFormatMap = {
        integer: 'd',
        signAwareInteger: 'd',
        percentage: '.2%',
        signAwarePercentage: '.2%',
        signAwareRoundedFloat: '.2f',
      }
      return numberFormatMap[this.yValueNumberFormat]
    },
    d3numberAxisFormat() {
      const numberFormatMap = {
        integer: 'd',
        signAwareInteger: 'd',
        percentage: '.0%',
        signAwarePercentage: '.0%',
        signAwareRoundedFloat: '.0f',
      }
      return numberFormatMap[this.yValueNumberFormat]
    },
    nVisible() {
      return this.allSeries.filter((x) => x.visible).length
    },
  },
  watch: {
    selectedSeries() {
      if (this.selectedSeries) {
        this.svg.selectAll('path.line, circle.point').transition().style('opacity', 0.1)
        this.svg.selectAll(`path.line.${this.seriesHashMap[this.selectedSeries]}`).transition().style('opacity', 1)
        this.svg.selectAll(`circle.point.${this.seriesHashMap[this.selectedSeries]}`).transition().style('opacity', 1)
      } else {
        this.svg.selectAll('path.line, circle.point').transition().style('opacity', 1)
      }
    },
    allSeries() {
      this.draw()
    },
    resolution() {
      this.draw()
    },
    nVisible() {
      this.draw()
    },
    yRange() {
      this.draw()
    },
    yValueNumberFormat() {
      this.draw()
    },
    yLabel() {
      this.draw()
    },
    chartHeight() {
      this.draw()
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.draw()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    getSeriesName(name: string): string {
      return this.seriesLabels?.[name] ?? name
    },
    getSeriesTag(name: string | number): string {
      return this.seriesTags?.[name] ?? ''
    },
    formatTooltipDate: formatTooltipDate,
    uuid() {
      return Utils.uuid()
    },
    /**
     * @param {number} index Series index
     * @param {boolean} visible Desired state of the series
     */
    setSeriesVisibility(index, visible = true) {
      let series = this.allSeries[index]
      let name = series.name
      series.visible = visible
      let d3vis = visible ? 'visible' : 'hidden'
      d3.selectAll(`.${Utils.createClassHash(name)}`).style('visibility', d3vis)
      this.$emit('series-visibility-changed')
    },
    legendHideAll() {
      this.allSeries.forEach((s, i) => this.setSeriesVisibility(i, false))
    },
    legendShowAll() {
      this.allSeries.forEach((s, i) => this.setSeriesVisibility(i, true))
    },
    /**
     * @param {string} name Series name
     * @param {number} index Series index (in this.allSeries)
     * @param {Event} event Event instance generated by user click on
     *   a legend <li> entry.
     */
    toggleQueryVisible(index: number): void {
      const isVisible = this.allSeries[index].visible !== false
      this.setSeriesVisibility(index, !isVisible)
    },
    /**
     * @param {number} index Series index inside this.allSeries
     */
    hoverSeries(index: number): void {
      let s = this.allSeries[index]
      if (s.visible === false) {
        // Don't do anything if the hovered series is hidden
        return
      }
      const hash = this.seriesHashMap[s.name]
      //not hovered, so make line & point faint
      this.svg.selectAll(`.line:not(.${hash})`).transition().style('opacity', 0.1)
      this.svg.selectAll(`.point:not(.${hash})`).transition().style('opacity', 0.1)
      // hovered to make line thick and dark
      this.svg.selectAll(`.line.${hash}`).transition().style('opacity', 1).style('stroke-width', '3px')
    },
    unhoverSeries() {
      // make dark, normal width
      this.svg.selectAll(`.line`).transition().style('opacity', 1).style('stroke-width', `${lineWidth}px`)
      //make point dark
      this.svg.selectAll(`.point`).transition().style('opacity', 1)
    },
    styleOpacity(value: number) {
      if (window.ActiveXObject) {
        // For IE11
        return { filter: 'alpha(opacity=' + value * 100 + ')' }
      } else {
        // For everything else
        return { opacity: value }
      }
    },
    /**
     * Draw a line on our chart. The line will include relevant points,
     * tooltip on hover and clickthrough behaviours.
     *
     * @param {Object} series - The data to render. This is the data series
     *      that will be plotted on the graph.
     * @param {Object} xScale - The d3 xScale object to place points and
     *      lines accordingly
     * @param {Object} yScale - The d3 yScale object to place points and
     *      lines accordingly
     * @param {Boolean} renderZeroes - The chart will break if renderZeroes is
     *      false, else it will draw the zero points if renderZeroes is true.
     * @return {undefined} - This method draws lines to the svg element.
     */
    _drawLine(series, xScale, yScale, renderZeroes = false): void {
      const counts = series.counts
      const datetimes = series.datetimes
      // unique identifier for query
      const seriesHash = Utils.createSafeClassHash(series.name)
      this.seriesHashMap[series.name] = seriesHash
      // const el = this.timelineElement
      const color = series.color || defaultColour

      const line = d3
        .line()
        .defined((_, j) => {
          return (
            counts[j] !== null &&
            !isNaN(counts[j]) &&
            counts[j] !== Infinity &&
            counts[j] !== -Infinity &&
            (renderZeroes || counts[j] !== 0)
          )
        })
        .x((d) => xScale(d))
        .y((_, j) => yScale(counts[j]))

      this.svg
        .append('path')
        .data([series.datetimes])
        .attr('d', line)
        .style('stroke', color)
        .style('stroke-width', lineWidth)
        .attr('fill', 'none')
        .attr('class', `line ${seriesHash} ${series.lineStyle || defaultlineStyle}`)

      // This is our points variable. We need to filter out the 0 value data
      // for point render.
      let pointData = { counts: [], datetimes: [] }
      counts.forEach((val, idx) => {
        if (val !== null && !isNaN(val) && (renderZeroes || val !== 0)) {
          pointData['counts'].push(val)
          pointData['datetimes'].push(datetimes[idx])
        }
      })

      /**
       * A test to determine whether we need to draw a point on the graph.
       * Most points won't be drawn. Points that are not connected to a line
       * because their neighbouring values are missing, still need to be
       * visible, which is done with a point.
       */
      const _isConnected = function (val) {
        // We cannot rely on the index from the d3 selector because
        // pointData filters out nulls and zeros. What we actually want
        // is the index from within the counts array.
        const i = datetimes.indexOf(val)
        const left = counts[i - 1]
        const right = counts[i + 1]
        const gapValues = [undefined, null, -Infinity, Infinity]
        if (!renderZeroes) {
          gapValues.push(0)
        }
        const disconnected = (gapValues.includes(left) || isNaN(left)) && (gapValues.includes(right) || isNaN(right))
        return !disconnected
      }
      // Create the points that are actually visible during hover
      const points = this.svg.selectAll('points')
      points
        .data(pointData.datetimes)
        .enter()
        .append('circle')
        .attr('class', (val) => `visualPoint point ${seriesHash} ${_isConnected(val) ? 'connected' : 'disconnected'}`)
        .style('fill', color)
        .style('fill-opacity', 0)
        .style('stroke', color) // style as css
        .attr('stroke-width', '0')
        .attr('cx', (datum) => xScale(datum))
        .attr('cy', (_, j) => yScale(pointData.counts[j]))
        .attr('r', pointRadius)

      this.allPoints.push(
        ...pointData.datetimes.map((datetime, i) => {
          // Last point in line
          if (series.lastPointLabel && i === pointData.datetimes.length - 1) {
            const width = this.$el.querySelector(`#${this.timelineId} svg`).getBoundingClientRect().width
            const value = this.formatDecimalNumbers(pointData.counts[i], this.yValueNumberFormat)
            this.svg
              .append('text')
              .attr('y', yScale(pointData.counts[i]))
              .attr('x', width - this.gutters.r + 4)
              .style('text-anchor', 'right')
              .style('dominant-baseline', 'middle')
              .style('font-weight', 'bold')
              .style('fill', color)
              .text(value)
          }

          return {
            y: yScale(pointData.counts[i]),
            count: pointData.counts[i],
            date: dayjs(datetime),
            x: xScale(datetime),
            color: series.color,
            name: series.name,
            index: i,
          }
        }),
      )

      this.filteredData[series.name] = pointData

      this.svg.selectAll('circle.visualPoint.disconnected').style('fill-opacity', 1)

      d3.selectAll(`.${seriesHash}`).style('visibility', series.visible === false ? 'hidden' : 'visible')
    },
    draw(): void {
      this.isLoading = true
      let dataset = this.allSeries
      const el = this.timelineElement

      if (!el) {
        // When switching between queries quickly, it is possible for the
        // data fetch to lead here even after the element has been torn down.
        // If the element doesn't exist, it's time to bail.
        return
      }
      if (this.svg) {
        d3.select(el).selectAll('svg').remove()
      }
      if (this.nVisible === 0) {
        this.isLoading = false
        return
      }

      let lastPos = null

      // Initialise crosshair position
      const crosshairLeft = this.crosshairElement.querySelector('div:nth-child(1)')
      const crosshairTop = this.crosshairElement.querySelector('div:nth-child(2)')
      d3.select(crosshairLeft).style('margin-left', `${-this.gutters.l}px`)
      d3.select(crosshairTop).style('margin-top', `${-this.gutters.t}px`)

      d3.select(this.crosshairElement)
        .style('left', `${this.gutters.l}px`)
        .style('right', `${this.gutters.r}px`)
        .style('top', `${this.gutters.t}px`)
        .style('bottom', `${this.gutters.b}px`)

      this.svg = d3
        .select(el)
        .append('svg')
        .attr('width', '100%')
        .attr('height', this.chartHeight)
        .style('background-color', 'white')
        .on(
          'mousemove',
          _.throttle(
            (val, index, nodes) => {
              if (!this.xPositions.length) return

              const mouse = currentMouse(nodes[index])

              let closestDist = Infinity
              let closestPoint = null
              let closestX = null

              // Prioritise X proximity over Y
              for (const x of this.xPositions) {
                const dist = Math.abs(mouse[0] - x)

                if (dist < closestDist) {
                  closestDist = dist
                  closestX = x
                }

                if (dist > closestDist) break
              }

              const yPoints = this.allPoints.filter(({ x }) => x === closestX)

              closestDist = Infinity

              for (const point of yPoints) {
                const dist = Math.abs(mouse[1] - point.y)

                if (dist < closestDist) {
                  closestPoint = point
                  closestDist = dist
                }
              }

              // Overlapping points to cycle through
              const overlapping = this.allPoints.filter(({ x, y }) => x === closestPoint.x && y === closestPoint.y)

              // Only proceed if the tooltip position has changed
              if (lastPos === `${closestPoint.x}_${closestPoint.y}`) return

              lastPos = `${closestPoint.x}_${closestPoint.y}`

              let selectedIndex = -1

              this.showNextSeries = () => {
                // Cycle through overlapping points on each call
                selectedIndex = selectedIndex > overlapping.length - 2 ? 0 : selectedIndex + 1

                const point = overlapping[selectedIndex]
                this.selectedSeries = point.name

                const series = this.allSeries.find(({ name }) => name === point.name)
                const lastDate = this.filteredData[series.name].datetimes[point.index - 1]
                const lastValue = this.filteredData[series.name].counts[point.index - 1]
                const sinceLast = point.count - lastValue
                const sinceStart = point.count - series.counts[0]

                let records

                if (this.records) {
                  records = this.records[this.selectedSeries]
                } else {
                  const seriesKey = DataUtils.makeTimeSeriesKey(
                    this.resolution,
                    this.xLabel,
                    series.query_value || {},
                    Utils.getFilterKey([]),
                    this.currentAnalysis.id,
                  )

                  records = this.dashboardTimeseries[seriesKey]
                }

                const seriesLabel = this.getSeriesName(point.name) || this.yLabel
                const record = records && records[point.date]

                // Tooltip content
                const tooltip = d3.select(this.tooltipElement).select('div').html(`
                <div class="tooltip-content">
                  <div><span class="timeline-dot" style="margin-right: 8px;"></span>${seriesLabel}</div>
                  <div>${this.formatTooltipDate(point.date, this.resolution)}</div>
                  <div class="tooltip-row">
                    ${this.yLabel}:<span>${d3.format(this.d3numberFormat)(point.count)}</span>
                  </div>
                  ${
                    point.index > 0 ?
                      `
                    <div class="tooltip-row">
                      Change since last datapoint, ${this.formatTooltipDate(lastDate, this.resolution, true)}:
                      <span>
                        ${sinceLast > 0 ? '+' : ''}${d3.format(this.d3numberFormat)(sinceLast)}
                        ${
                          this.d3numberFormat !== '.2%' ?
                            `
                          (${MathUtil.getPercentDiff(lastValue, point.count)})
                        `
                          : ''
                        }
                      </span>
                    </div>
                    <div class="tooltip-row">
                      Change since beginning, ${this.formatTooltipDate(series.datetimes[0], this.resolution, true)}:
                      <span>
                        ${sinceStart > 0 ? '+' : ''}${d3.format(this.d3numberFormat)(sinceStart)}
                        ${
                          this.d3numberFormat !== '.2%' ?
                            `
                          (${MathUtil.getPercentDiff(series.counts[0], point.count)})
                        `
                          : ''
                        }
                      </span>
                    </div>
                  `
                    : ''
                  }
                  ${
                    record ?
                      `
                    <div class="tooltip-row">
                      # of records:<span>${number(record.countDocument)}</span>
                    </div>
                    ${
                      record.countDocumentFraction != null ?
                        `
                      <div class="tooltip-row">
                        % of all records (relative to the ${
                          {
                            daily: 'day',
                            weekly: 'week',
                            monthly: 'month',
                            quarterly: 'quarter',
                            yearly: 'year',
                          }[this.resolution]
                        }):
                        <span>
                          ${(record.countDocumentFraction * 100).toFixed(2)}%
                        </span>
                      </div>
                    `
                      : ''
                    }
                  `
                    : ''
                  }
                </div>
                ${
                  overlapping.length > 1 ?
                    `
                  <div class="tooltip-cycle">
                    ${overlapping.length} overlapping points - click to cycle tooltip.
                  </div>
                `
                  : ''
                }
              `)

                const tooltipWidth = tooltip.node().getBoundingClientRect().width
                const svgLeft = this.svg.node().getBoundingClientRect().left

                // Adjust tooltip so that it doesn't leave the screen
                const xPad = 20
                let overflow = 0
                overflow -= Math.max(svgLeft + point.x - (window.innerWidth - tooltipWidth / 2 - xPad), 0)
                overflow += Math.abs(Math.min(svgLeft + point.x - (tooltipWidth / 2 + xPad), 0))

                d3.select(this.tooltipElement).select('div').style('transform', `translate3d(${overflow}px, 0, 0)`)

                // Ensure valid colour from the assumed format
                const style = new Option().style
                style.color = `${series.color}22`
                style.color = style.color || '#0002'

                d3.select(this.tooltipElement)
                  .selectAll('.timeline-dot')
                  .style('background', series.color)
                  .style('color', style.color)

                d3.select(this.tooltipElement)
                  .style('transform', `translate3d(${point.x}px, ${point.y}px, 0)`)
                  .transition()
                  .duration(200)
                  .style('opacity', 1)

                // Move crosshair
                d3.select(this.crosshairElement).transition().duration(200).style('opacity', 1)
                d3.select(crosshairLeft).style('transform', `translateX(${point.x}px)`)
                d3.select(crosshairTop).style('transform', `translateY(${point.y}px)`)
              }

              this.showNextSeries()
            },
            100,
            { trailing: false },
          ),
        )
        .on('mouseleave', () => {
          d3.select(this.tooltipElement).transition().duration(200).style('opacity', 0)

          d3.select(this.crosshairElement).transition().duration(200).style('opacity', 0)

          this.selectedSeries = null
          lastPos = null
        })
        .on('mousedown', () => {
          this.showNextSeries && this.showNextSeries()
        })

      // Get our bounding width and height for calculations
      const width = this.$el.querySelector(`#${this.timelineId} svg`).getBoundingClientRect().width
      const height = this.$el.querySelector(`#${this.timelineId} svg`).getBoundingClientRect().height
      // TODO: Currently `flatMap` is not being polyfilled correctly by vue-cli,
      //  however, it may be fixed in the future. This should be revisited
      //  after future upgrades of vue, and tested on IE11 and Edge.
      //  See ENG-473.
      // const dateRange = d3.extent(this.allSeries.flatMap(x => x.datetimes))
      const dates = this.allSeries
        .map((x) => x.datetimes)
        .flat(1)
        .map((d) => {
          return dayjs(d)
        })
      if (dates.length === 0) return
      let dateRange = d3.extent(dates)
      let dateOp: 'week' | 'month' | 'quarter' | 'year' = 'week'
      if (this.resolution === 'monthly') {
        dateOp = 'month'
      } else if (this.resolution === 'quarterly') {
        dateOp = 'quarter'
      } else if (this.resolution === 'yearly') {
        dateOp = 'year'
      }
      // Set up the axes
      let xScale = d3
        .scaleTime()
        .range([this.gutters.l, width - this.gutters.r])
        .domain(dateRange)
        .nice(getTickInterval(this.resolution))

      const yScaleLeft = d3
        .scaleLinear()
        .range([this.gutters.t, height - this.gutters.b - this.xLabelPad])
        // We have to make a copy here or we will modify the yRange prop.
        // This will then trigger the watcher and start an infinite loop that
        // calls this.draw over and over.
        .domain(Array.from(this.yRange).sort((a, b) => b - a))

      const yScaleRight =
        this.yRangeRight &&
        d3
          .scaleLinear()
          .range([this.gutters.t, height - this.gutters.b - this.xLabelPad])
          // We have to make a copy here or we will modify the yRange prop.
          // This will then trigger the watcher and start an infinite loop that
          // calls this.draw over and over.
          .domain(Array.from(this.yRangeRight).sort((a, b) => b - a))

      let tickValues = xScale.ticks(getTickInterval(this.resolution))

      const allowedXIndices =
        this.visibleXAxisLabels &&
        this.visibleXAxisLabels.map((i: number) => {
          if (i < 0) return tickValues.length + i
          return i
        })

      const yTicks = this.yAxisLeftTicks || 6

      const allowedYIndices =
        this.visibleYAxisLabels &&
        this.visibleYAxisLabels.map((i: number) => {
          if (i < 0) return yTicks + i
          return i
        })

      const xAxis = d3
        .axisBottom(xScale) // Our x (time/date) axis
        .tickValues(tickValues)
        .tickFormat(xTickFormatter(this.resolution, tickValues.length, width, allowedXIndices))

      const yAxisLeft = d3
        .axisLeft(yScaleLeft)
        .tickSizeInner(-(width - (this.gutters.l + this.gutters.r)))
        .tickSizeOuter(0)
        .ticks(yTicks)
        .tickFormat((d, i) => {
          const yNumber = this.formatDecimalNumbers(d, this.yValueNumberFormat, MathUtil.getRange(this.yRange))
          return !allowedYIndices || allowedYIndices.includes(i) ? yNumber : ''
        })

      const yAxisRight =
        yScaleRight &&
        d3
          .axisRight(yScaleRight)
          .tickSizeOuter(0)
          .ticks(this.yAxisRightTicks || 6)
          .tickFormat((d, i) => {
            const yNumber = this.formatDecimalNumbers(d, this.yValueNumberFormat, MathUtil.getRange(this.yRangeRight))
            return !allowedYIndices || allowedYIndices.includes(i) ? yNumber : ''
          })

      // append our axes to the chart
      this.svg
        .append('g')
        .attr('transform', 'translate(0,' + (height - this.gutters.b - this.xLabelPad) + ')')
        .attr('class', 'x axis')
        .call(xAxis)
        .selectAll('text')
        .attr('y', '12px')
        .style('font-family', 'Lato')

      this.svg
        .append('g')
        .attr('transform', 'translate(' + this.gutters.l + ',0)')
        .attr('class', 'y axis')
        .call(yAxisLeft)
        .selectAll('text')
        .style('fill', this.yAxisLeftColor)
        .style('font-family', 'Lato')

      yAxisRight &&
        this.svg
          .append('g')
          .attr('transform', 'translate(' + (width - this.gutters.r) + ',0)')
          .attr('class', 'y axis')
          .call(yAxisRight)
          .selectAll('text')
          .style('fill', this.yAxisRightColor)
          .style('font-family', 'Lato')

      // Draw in the top horizontal margin
      this.svg
        .append('g')
        .attr('class', 'axis')
        .attr('transform', 'translate(0,' + this.gutters.t + ')')
        .call(
          d3
            .axisTop(xScale)
            // No ticks on this line
            .tickSizeInner(0)
            .tickSizeOuter(0)
            // No tick labels on this line
            .tickFormat(''),
        )

      // Append the x axis label
      this.svg
        .append('text')
        .attr('y', height - this.xLabelPad + 5)
        .attr('x', this.gutters.l - 15 + (width - this.gutters.l - this.gutters.r) / 2)
        .attr('dx', '1em')
        .style('text-anchor', 'middle')
        .style('font-weight', 'bold')
        .text(_.truncate(this.xLabel, { length: 60 }))

      // Append the y axis label
      this.svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0)
        .attr('x', 0 - (height - this.gutters.b + 10) / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .style('font-weight', 'bold')
        .text(_.truncate(this.yLabel))

      this.yLabelRight &&
        this.svg
          .append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', width - 18)
          .attr('x', 0 - (height - this.gutters.b + 10) / 2)
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .style('font-weight', 'bold')
          .text(_.truncate(this.yLabelRight))

      // Draw lines and points
      // If the values are sign aware, this means we want to treat 0 as a
      // valid value that should be plotted. Comparatively the other number
      // types (like plain integers), where we treat zeroes as a break in
      // the graph.
      const renderZeroes = this.yValueNumberFormat.startsWith('signAware')

      this.allPoints = []

      for (const query of dataset.filter((query) => query.visible)) {
        const yScale = this.yAxisRightNames.includes(query.name) ? yScaleRight : yScaleLeft
        this._drawLine(query, xScale, yScale, renderZeroes)
      }

      this.allPoints.sort((a, b) => {
        return a.x - b.x
      })

      this.xPositions = _.uniq(this.allPoints.map(({ x }) => x))

      this.isLoading = false
    },
    formatDecimalNumbers(counts: number, numberFormat: string, range = Infinity): string {
      let value
      if (numberFormat === 'percentage' && counts <= 0.0) {
        value = d3.format('.0%')(counts)
      } else if (numberFormat === 'percentage' && counts < 0.01) {
        value = d3.format('.2%')(counts)
      } else if (numberFormat === 'percentage' && counts < 0.05) {
        value = d3.format('.1%')(counts)
      } else if (numberFormat === 'percentage' && counts > 0.05) {
        value = d3.format('.0%')(counts)
      } else if (numberFormat === 'signAwareRoundedFloat' && Math.abs(counts) == 0) {
        value = d3.format('.0f')(counts)
      } else if (numberFormat === 'signAwareRoundedFloat' && Math.abs(counts) < 1) {
        value = d3.format('.2f')(counts)
      } else if (numberFormat === 'signAwareRoundedFloat' && Math.abs(counts) < 5) {
        value = d3.format('.1f')(counts)
      } else if (numberFormat === 'signAwareRoundedFloat' && Math.abs(counts) > 5) {
        // If the scale of the axis is small, show decimal places
        value = range > 4 ? d3.format('.0f')(counts) : d3.format('.1f')(counts)
      } else {
        value = d3.format(this.d3numberAxisFormat)(counts)
      }
      return value
    },
    showDateVerticalLine(dateStr: string) {
      // Safety checks
      if (!this.svg || !this.svg.node()) {
        console.warn('SVG not ready')
        return
      }

      // Remove existing hover line if it exists
      if (this.dateHoverLine) {
        this.dateHoverLine.remove()
      }

      try {
        // Convert the date string to a timestamp
        const date = dayjs(dateStr).valueOf()

        // Get dimensions
        const width = this.svg.node().getBoundingClientRect().width
        const height = this.svg.node().getBoundingClientRect().height

        // Create x scale
        const xScale = d3
          .scaleTime()
          .range([this.gutters.l, width - this.gutters.r])
          .domain(d3.extent(this.allPoints.map((p) => p.date)))

        const xPos = xScale(date)

        // Create the vertical line
        this.dateHoverLine = this.svg
          .append('line')
          .attr('class', 'date-hover-line')
          .attr('x1', xPos)
          .attr('x2', xPos)
          .attr('y1', this.gutters.t)
          .attr('y2', height - this.gutters.b - this.xLabelPad)

        // Set initial styles without transition
        this.dateHoverLine.style('stroke', '#21BA45').style('stroke-width', '3px').style('opacity', 0)

        // Create the transition separately
        this.dateHoverLine.transition().duration(200).style('opacity', 0.6)
      } catch (error) {
        console.error('Error showing date line:', error)
      }
    },
    hideDateVerticalLine() {
      if (this.dateHoverLine) {
        try {
          const line = this.dateHoverLine
          this.dateHoverLine = null

          d3.select(line.node())
            .transition()
            .duration(200)
            .style('opacity', 0)
            .end() // Returns a promise
            .then(() => {
              line.remove()
            })
            .catch((error) => {
              console.error('Error hiding date line:', error)
            })
        } catch (error) {
          console.error('Error hiding date line:', error)
          // Fallback cleanup
          if (this.dateHoverLine) {
            this.dateHoverLine.remove()
            this.dateHoverLine = null
          }
        }
      }
    },
  },
})

export default Timeline
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

#timeline-container
  flex-grow: 1
  min-width: 100%

.legend-nps-line
  width: 18px
  height: 14px
  margin-right: 5px
  margin-left: -3px
  align-self: center
  stroke: rgb(6, 140, 204)

// tooltips
.timeline-crosshair
  pointer-events: none
  position: absolute
  opacity: 0
  left: 0
  top: 0

  > div
    transition: transform 0.4s ease
    background: #068ccc
    position: absolute
    opacity: 0.3
    left: 0
    top: 0

    &:nth-child(1)
      height: 100%
      width: 1px

    &:nth-child(2)
      width: 100%
      height: 1px

::v-deep .timeline-dot
  border: 2px solid #fff
  box-shadow: 0 0 0 3px
  display: inline-block
  border-radius: 10px
  background: #000
  height: 10px
  width: 10px
  color: #000

::v-deep .timeline-tooltip
  transition: transform 0.4s ease
  justify-content: center
  pointer-events: none
  position: absolute
  color: #3b3b3b
  display: flex
  z-index: 999
  opacity: 0
  height: 1px
  width: 1px

  .tooltip-cycle
    text-transform: uppercase
    background: #95a6ac
    text-align: center
    color: #f5f5f5
    font-size: 11px
    font-weight: bold
    height: 30px
    line-height: 30px

  .tooltip-content
    padding: 14px
    > div:nth-child(1)
      font-size: 18px
      display: flex
      align-items: center
      font-weight: bold

    > div:nth-child(2)
      font-weight: bold
      margin: 6px 0 4px

  > .timeline-dot
    position: absolute
    bottom: -5px

  > div
    box-shadow: 0 0 4px 1px rgba(#000, 0.1)
    background: $grey-background
    position: absolute
    min-width: 340px
    color: #3f3f3f
    bottom: 15px

    .tooltip-row
      display: flex
      margin-bottom: 4px
      white-space: nowrap
      span
        margin-left: auto
        font-weight: bold
        padding-left: 8px

.ui.horizontal.list.dropdowns
  padding-left: 2rem
  .item
    padding-right: 2rem
.variable-select-timeline
  background: white
  .ui.dropdown
    font-weight: bold
    color: #068ccc
    .icon
      margin-left: 0.5em
/* TOOLTIP STYLE */
.tooltip.timeline
  background-color: $grey-background
  border-radius: 3px
  height: auto
  .tooltip-arrow
    visibility: hidden
  .tooltip-inner
    padding: 7px
    font-size: 16px
    color: black
#one-data-point
  padding: 50px
  color: #95a6ac
  text-align: center
#warning-text
  font-size: 20px
#timeline-dimmer
  z-index: 5
#empty-state
  background: white
  text-align: center
  color: $subdued
  display: flex
  flex-direction: column
  justify-content: center
  height: 100%
  margin: 30px 0
  p
    font-size: 1.28rem
  h2
    font-size: 2rem
.timeline
  position: relative
  user-select: none
  svg
    color: #383838
    .dashed-line
      stroke-dasharray: 3 3
    text
      font-size: 12px
      stroke: none
      color: #383838
      cursor: default
    .line, .point
      transition: opacity 0.4s ease
    .line
      stroke-width: 1.5px
      &.deselected
        visibility: hidden
      &.hover
        stroke-width: 3px
      &.preview
        visibility: visible !important
    .axis path, .axis line
      fill: none
      shape-rendering: crispEdges
      stroke: #e6e6e6
      opacity: 0.5
      stroke-width: 2
      &.hovered
        opacity: 1
    .legend
      text
        font-size: 1rem
      g.hover
        text
          font-weight: bold
      g text
        cursor: pointer
      g.deselected
        circle
          fill-opacity: 0.3
        text
          fill-opacity: 0.5

.show-more
  cursor: pointer
  color: $blue
  font-weight: bold
</style>
