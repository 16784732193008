import Overview from 'pages/trial/Overview.vue'
import Register from 'pages/trial/Register.vue'
import DataUpload from 'pages/trial/DataUpload/DataUpload.vue'
import TrialWrapper from 'pages/trial/TrialWrapper.vue'
import { RouteRecordRaw } from 'vue-router'
import Workbench from 'src/pages/trial/Workbench/Workbench.vue'

/**
 * All these routes are used for the free trial.
 *
 * We've seperated these out to make it easier to work on/understand the
 * trial flow.
 */
export const trialRoutes: RouteRecordRaw[] = [
  {
    path: 'trial',
    meta: { site: 'trial' },
    component: TrialWrapper,
    children: [
      {
        path: '',
        component: Overview,
        name: 'trial-overview',
        meta: {
          requiresAuth: false,
          title: 'Trial Home',
        },
        props: true,
      },
      {
        path: 'register',
        component: Register,
        name: 'trial-registration',
        meta: {
          requiresAuth: false,
          title: 'Trial Home',
        },
        props: true,
      },
      {
        path: 'upload',
        component: DataUpload,
        name: 'trial-data-upload',
        meta: {
          requiresAuth: false,
          title: 'Trial Data Upload',
        },
        props: true,
      },
      {
        path: 'results/:dashboardId',
        component: Workbench,
        name: 'trial-results',
        meta: {
          requiresAuth: false,
          title: 'Results',
        },
        props: (route) => ({
          trialMode: true,
          dashboardId: parseInt(route.params.dashboardId as string),
        }),
      },
      {
        path: 'results/:dashboardId/theme/:drilldownId',
        component: Workbench,
        name: 'trial-results-drilldown-theme',
        meta: {
          requiresAuth: false,
          title: 'Results - Theme Drilldown',
        },
        props: (route) => ({
          trialMode: true,
          dashboardId: parseInt(route.params.dashboardId as string),
          drilldownId: parseInt(route.params.drilldownId as string),
          drilldown: 'theme',
        }),
      },
      {
        path: 'results/:dashboardId/theme-group/:drilldownId',
        component: Workbench,
        name: 'trial-results-drilldown-theme-group',
        meta: {
          requiresAuth: false,
          title: 'Results - Theme Group Drilldown',
        },
        props: (route) => ({
          trialMode: true,
          dashboardId: parseInt(route.params.dashboardId as string),
          drilldownId: parseInt(route.params.drilldownId as string),
          drilldown: 'theme_group',
        }),
      },
    ],
  },
]
