<template>
  <div class="wrapper">
    <div v-if="isMobile && !mobileBannerDismissed" class="mobile-banner">
      <div class="mobile-banner-text">
        <div>MOBILE-FRIENDLY TRIAL IS COMING SOON.</div>
        <div>FOR THE BEST EXPERIENCE, USE DESKTOP</div>
      </div>
      <bf-button class="dismiss-button item" color="$blue" size="small" @click="hideMobileBanner"> DISMISS </bf-button>
    </div>
    <trial-header />
    <router-view />

    <!-- Mobile warning -->
    <Modal :visible="isMobile && mobileWarningVisible" width="90%">
      <template #header>
        <div class="header">The free trial is not optimised for mobile</div>
      </template>
      <template #content>
        <div class="content center">
          For the best experience out of your trial, we recommend you use the desktop version instead.
        </div>
      </template>
      <template #buttons>
        <div class="actions">
          <bf-button class="dismiss-button" color="blue" @click="mobileWarningVisible = false">Okay, got it</bf-button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide } from 'vue'
import { useStore } from 'vuex'
import TrialHeader from './Header.vue'
import { FETCH_USER, POLL_BACKEND } from 'src/store/types'
import { hasAuthHeader } from 'src/utils/auth'
import Modal from 'components/Modal.vue'
import { deviceSupport } from 'src/composables/deviceSupport'
import { BfButton } from 'components/Butterfly'

export default defineComponent({
  name: 'TrialWrapper',
  components: {
    BfButton,
    TrialHeader,
    Modal,
  },
  setup() {
    const store = useStore()

    const { isMobile } = deviceSupport()
    const isReady = ref(false)
    provide('isReady', isReady)

    const pageReady = ref<null | ((value: boolean) => void)>(null)
    provide('pageReady', readyPromise)

    const readyPromise = new Promise<boolean>((resolve) => {
      pageReady.value = resolve
    })

    const mobileWarningVisible = ref(true)

    // This setting must be saved in the user's local storage
    const mobileBannerDismissed = ref(false)
    const hideMobileBanner = () => {
      mobileBannerDismissed.value = true
      localStorage.setItem('mobileBannerDismissed', '1')
    }

    onMounted(async () => {
      if (localStorage.getItem('mobileBannerDismissed') == '1') {
        hideMobileBanner()
      }

      if (hasAuthHeader()) {
        await store.dispatch(FETCH_USER, { handleErrors: false })
      }
      await store.dispatch(POLL_BACKEND)
      pageReady.value?.(true)
      isReady.value = true
    })

    return {
      isMobile,
      mobileWarningVisible,
      mobileBannerDismissed,
      hideMobileBanner,
    }
  },
})
</script>

<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mobile-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-size: 15px;
  background-color: $blue;
  color: #fdfdfd;
  font-weight: bold;
  padding: rem(10px) rem(20px);
  text-align: center;
  width: 100%;
  height: rem(95px);
  z-index: 50;

  .mobile-banner-text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    white-space: nowrap;
    text-align: left;
    color: #fdfdfd;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 0;
  }

  .bf-button {
    max-width: 100px;
    background-color: #fdfdfd;
    color: $blue;
    font-size: 16px;
    font-weight: bold;
    padding: rem(7px) rem(12px);
    text-align: center;
    //width: 100%;
  }
}
</style>
