<template>
  <div ref="datePickerMenu" class="date-picker-menu">
    <div class="panel">
      <div class="sidebar">
        <radio-buttons :items="rangeTypes[0]" :selected="stagedType || type" @change="setRangeType" />
        <h1>Dynamic Ranges</h1>
        <radio-buttons :items="rangeTypes[1]" :selected="stagedType || type" @change="setRangeType" />
      </div>
      <div class="main">
        <div>
          <span class="label">Date range</span>
          <div ref="dateRanges" class="item date-ranges">
            <div ref="startDate" class="date-button date-from" @click="showCalendar('start')">
              <span class="range-date">{{ fromLabel[0] }}</span>
              <span class="range-hint">{{ fromLabel[1] }}</span>
            </div>
            <span> to </span>
            <div ref="endDate" class="date-button date-to" @click="showCalendar('end')">
              <span class="range-date">{{ toLabel[0] }}</span>
              <span class="range-hint">{{ toLabel[1] }}</span>
            </div>
          </div>
          <span class="label">Date field</span>
          <dropdown
            class="datefield-dropdown"
            :value="stagedDateField || dateField || defaultDateField"
            @input="(v) => (stagedDateField = v)"
          >
            <template #trigger>
              <div style="cursor: pointer" @click="closePika">
                {{ stagedDateField || dateField || defaultDateField }}
                <i class="icon dropdown"></i>
              </div>
            </template>
            <dropdown-item v-for="df in dateFields" :key="df.name" :value="df.name">
              {{ df.name }}
            </dropdown-item>
          </dropdown>
        </div>
      </div>
    </div>
    <div class="footer date-operations">
      <div class="left-align">
        <!--
              https://app.shortcut.com/kapiche/story/48745/remove-refiner-from-the-product
        <a class="item" href="#" onclick="_refiner('showForm', '45777e80-7b45-11ed-9087-c382eafb4365', true);">
          Need another date option?
        </a>
        -->
      </div>
      <div>
        <bf-button id="clear-button" color="transparent" @click.stop="clearDateFilters"> Clear changes </bf-button>
      </div>
      <div>
        <bf-button id="cancel-button" @click="cancel"> Cancel </bf-button>
        <bf-button id="apply-button" :disabled="!allowApply" color="blue" @click="apply"> Apply </bf-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { BfButton } from 'components/Butterfly'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import Pikaday from 'pikaday'
import RadioButtons from 'components/widgets/RadioButtons.vue'
import { DateRangeTypeEnum } from 'types/DashboardTypes'
import { calcRangeDates, dateRangeHints, formatDate, strToDate } from 'src/utils/dates'

const DateRangePicker = defineComponent({
  components: { Dropdown, DropdownItem, BfButton, RadioButtons },
  props: {
    type: { type: String as PropType<DateRangeTypeEnum>, required: false, default: null },
    dateFrom: { type: String, required: false, default: '' },
    dateTo: { type: String, required: false, default: '' },
    dateField: { type: String, required: false, default: '' },
    dateFields: { type: Array, required: true },
    defaultDateField: { type: String, required: false, default: '' },
  },
  data() {
    return {
      stagedType: null as null | DateRangeTypeEnum,
      stagedDateField: null as null | string,
      stagedDateEnd: null as null | string,
      stagedDateStart: null as null | string,
      pika: null as unknown,
    }
  },
  computed: {
    defaultDates(): [string, string] {
      const dates = calcRangeDates()
      switch (this.stagedType || this.type) {
        case null:
        case DateRangeTypeEnum.CUSTOM: {
          return [this.stagedDateStart || this.dateFrom, this.stagedDateEnd || this.dateTo]
        }
        default: {
          return [dates[this.stagedType || this.type]?.from, dates[this.stagedType || this.type]?.to]
        }
      }
    },
    allowApply(): boolean {
      const relevantFieldChange =
        ((this.stagedType !== DateRangeTypeEnum.ALL_TIME && this.stagedType !== null) ||
          this.type !== DateRangeTypeEnum.ALL_TIME) &&
        this.stagedDateField !== null &&
        this.dateField !== this.stagedDateField

      const toFromChange =
        (this.stagedDateStart !== null && this.dateFrom !== this.stagedDateStart) ||
        (this.stagedDateEnd !== null && this.dateTo !== this.stagedDateEnd)

      const typeChange = this.stagedType !== null && this.type !== this.stagedType

      return relevantFieldChange || toFromChange || typeChange
    },
    rangeTypes() {
      const types = Object.values(DateRangeTypeEnum).map((t) => ({ label: t, value: t }))
      return [types.slice(0, 2), types.slice(2)]
    },
    fromLabel(): [string, string] {
      // ------------------------------------------------------------------------------------------------
      if ((this.stagedType || this.type) === DateRangeTypeEnum.ALL_TIME) {
        return [DateRangeTypeEnum.ALL_TIME, '']
      }
      if ((this.stagedType || this.type) === DateRangeTypeEnum.CUSTOM) {
        return [this.stagedDateStart || this.dateFrom || 'All time', '']
      }

      return [
        calcRangeDates()[this.stagedType || this.type]?.from,
        `(${dateRangeHints[this.stagedType || this.type]?.from})`,
      ]
    },
    toLabel(): [string, string] {
      // ------------------------------------------------------------------------------------------------
      if ((this.stagedType || this.type) === DateRangeTypeEnum.ALL_TIME) {
        return [DateRangeTypeEnum.ALL_TIME, '']
      }
      if ((this.stagedType || this.type) === DateRangeTypeEnum.CUSTOM) {
        return [this.stagedDateEnd || this.dateTo || 'All time', '']
      }
      return [
        calcRangeDates()[this.stagedType || this.type]?.to,
        `(${dateRangeHints[this.stagedType || this.type]?.to})`,
      ]
    },
  },
  methods: {
    closePika() {
      if (this.pika) this.pika.hide()
    },
    showCalendar(calendar: 'start' | 'end') {
      let field = ''
      let defaultDate = new Date()
      let onSelect = (date: Date) => date

      if (calendar === 'start') {
        field = 'startDate'
        defaultDate = strToDate(this.defaultDates[0])
        onSelect = (date: Date) => this.setStagedStartDate(date)
      }
      if (calendar === 'end') {
        field = 'endDate'
        defaultDate = strToDate(this.defaultDates[1])
        onSelect = (date: Date) => this.setStagedEndDate(date)
      }

      if (this.pika) {
        this.pika.destroy()
      }
      this.pika = new Pikaday({
        field: this.$refs[field] as HTMLElement,
        container: this.$refs.datePickerMenu as HTMLElement,
        reposition: false,
        defaultDate,
        setDefaultDate: true,
        onSelect,
        onOpen: () => {
          this.pika.el.style.left = this.$refs[field].offsetLeft + 'px'
          this.pika.el.style.top = this.$refs[field].offsetTop + this.$refs[field].offsetHeight + 'px'
        },
        onClose: () => {
          this.pika.destroy()
        },
      })
      this.pika.show()
    },
    setStagedStartDate(date: Date) {
      /**
         if we are setting a date here then it's for CUSTOM range so
         set the range type to CUSTOM
        */
      const dateString = formatDate(date)
      this.setRangeType(DateRangeTypeEnum.CUSTOM)
      this.stagedDateStart = dateString
    },
    setStagedEndDate(date: Date) {
      /**
         if we are setting a date here then it's for CUSTOM range so
         set the range type to CUSTOM
        */
      const dateString = formatDate(date)
      this.setRangeType(DateRangeTypeEnum.CUSTOM)
      this.stagedDateEnd = dateString
    },
    setRangeType(type: DateRangeTypeEnum) {
      this.closePika()
      // ------------------------------------------------------------------------------------------------
      /**
         When setting the range type we may need to set or clear
         the to & from dates as well if the type is changing depending
         on what it was set to previously
         */
      const prevType = this.stagedType || this.type
      this.stagedType = type
      if (prevType === type) return

      if (type === DateRangeTypeEnum.CUSTOM) {
        if (prevType === DateRangeTypeEnum.ALL_TIME) {
          this.stagedDateEnd = ''
          this.stagedDateStart = ''
          return
        }
        this.stagedDateEnd = calcRangeDates()[prevType]?.to
        this.stagedDateStart = calcRangeDates()[prevType]?.from
        return
      }
      this.stagedDateEnd = ''
      this.stagedDateStart = ''
    },
    cancel() {
      this.$emit('cancel')
      this.stagedType = null
      this.stagedDateField = null
      this.stagedDateEnd = null
      this.stagedDateStart = null
    },
    apply() {
      this.$emit(
        'apply',
        this.stagedDateField || this.dateField || this.defaultDateField,
        this.stagedDateStart ?? this.dateFrom,
        this.stagedDateEnd ?? this.dateTo,
        this.stagedType || this.type,
      )
      this.stagedType = null
      this.stagedDateField = null
      this.stagedDateEnd = null
      this.stagedDateStart = null
    },
    clearDateFilters() {
      this.setRangeType(this.type ?? DateRangeTypeEnum.CUSTOM)
      this.stagedDateEnd = this.dateTo ?? null
      this.stagedDateStart = this.dateFrom ?? null
      this.stagedDateField = this.dateField ?? this.defaultDateField ?? null
    },
  },
})
export default DateRangePicker
</script>
<style lang="sass" scoped>
@import '~pikaday/scss/pikaday.scss'
@import 'assets/kapiche.sass'

.date-picker-menu
  padding: 0
  margin: 0
  width: max-content
  overflow: visible
  border-color: #e5e5e5
  background-color: white
  display: flex
  flex-direction: column
  font-size: 15px

.panel
  display: flex
  flex-direction: row

.range-hint
  color: $text-black

.range-date
  color: $text-grey

.main
  display: flex
  flex-direction: column
  padding: 12px

.sidebar
  background-color: #f7f7f7
  border-right: 1px solid #e5e5e5
  padding: 12px
  h1
    margin: 10px 10px 5px
    font-size: 14px
    font-weight: bold
    text-transform: uppercase
    color: $blue

.datefield-dropdown
  color: #383838
  border: solid 1px #e5e5e5
  padding: 10px
  border-radius: 3px
  .icon
    margin-left: 6px
    width: auto

.label
  cursor: default
  font-weight: bold
  color: #333
  display: flex
  align-items: center
  margin-bottom: 6px

  &:not(:first-child)
    margin-top: 20px

.date-ranges
  display: flex
  flex-direction: row
  justify-content: space-around
  align-items: center
  .date-button
    color: #383838
    border: solid 1px #e5e5e5
    padding: 10px
    min-width: 220px
    border-radius: 3px
    + span
      margin: 0 10px

.footer.date-operations
  padding: 10px
  border-top: 1px solid #e5e5e5
  margin: 0
  justify-content: end

  .left-align
    flex-grow: 1

.date-operations
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: baseline
  margin-top: 20px
  .button
    margin-left: 20px

  > .item
    border-top: none
    display: flex
    color: #383838
    font-size: 16px
    font-family: $standard-font
    font-weight: normal
    font-style: normal
    font-stretch: normal

  &:hover
    background-color: white
</style>
