<template>
  <div class="theme-tree">
    <div v-if="!isReady || updatingTree" class="loading">
      <bf-spinner v-if="!isReady || updatingTree" />
    </div>
    <template v-else-if="themes.length > 0">
      <div v-if="newThemes.length > 0" class="theme-section">
        <div class="theme-header">
          <span class="left-text">
            In Progress
            <icon color="#95a6ac" name="info" :size="14" hover-message="These themes are not yet saved." />
          </span>
          <span class="right-text"> % of all verbatims </span>
        </div>
        <div v-for="theme in newThemes" :key="theme.id" class="theme-item" @click="$emit('theme-clicked', theme.id)">
          <div :class="['theme-info', { selected: theme.id === selectedThemeID }]">
            <span class="left-text">
              <span class="theme-name">{{ theme.name }}</span>
            </span>
            <span class="right-text"> {{ getNewThemeCoverage(theme.id) }}% </span>
          </div>
          <div>
            <el-progress :show-text="false" :percentage="getNewThemeCoverage(theme.id)" />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="theme-header">
          <span class="left-text">
            <span class="sort-wrapper" @click="sortClick('name')">
              Themes
              <up-down
                :up="sortMode === 'name' && sortOrder === 'asc'"
                :down="sortMode === 'name' && sortOrder === 'desc'"
              />
            </span>
            <bf-button color="transparent" @click="createGroupModalVisible = true">
              <icon color="#068ccc" name="plus" :size="11" />
              Create Group
            </bf-button>
          </span>
          <span class="right-text sort-wrapper" @click="sortClick('coverage')">
            % of all verbatims
            <up-down
              :up="sortMode === 'coverage' && sortOrder === 'asc'"
              :down="sortMode === 'coverage' && sortOrder === 'desc'"
            />
          </span>
        </div>
        <el-tree
          ref="tree"
          :allow-drop="() => true"
          :allow-drag="allowDrag"
          :load="loadNode"
          :data="treeData"
          lazy
          node-key="id"
          :props="{
            isLeaf: 'leaf',
          }"
          draggable
          :auto-expand-parent="false"
          :default-expanded-keys="!initialLoad ? expandedNodeKeys : []"
          @node-expand="$emit('node-expand', $event)"
          @node-collapse="$emit('node-collapse', $event)"
          @node-drop="handleDrop"
          @node-drag-enter="nodeDragEnter"
          @node-drag-start="draggingNode = $event"
          @node-drag-end="
            () => {
              draggingNode = null
              dropTarget = null
            }
          "
        >
          <template #default="{ node, data }">
            <div
              :class="[
                'node-content',
                {
                  'drop-target': data.type === 'group' && data.id === dropTarget,
                },
              ]"
              @click="() => nodeClick(data)"
            >
              <icon v-if="node.data.id !== -1" class="drag-dots" :size="14" color="#ccc" name="drag-dots" />
              <div
                :class="{
                  'theme-info': data.type === 'theme',
                  'group-info': data.type === 'group',
                  'ungrouped': data.id === -1,
                  'selected': data.type === 'theme' && data.id === `query_${selectedThemeID}`,
                }"
              >
                <span class="left-text" :title="data.name">
                  <icon
                    v-if="data.type === 'group'"
                    :size="11"
                    color="#888"
                    name="chevron-right"
                    :class="[
                      'expanded-icon',
                      {
                        expanded: node.expanded,
                      },
                    ]"
                  />
                  <span class="theme-name">
                    {{ data.name }}
                  </span>
                  <span v-if="data.type === 'theme' && hasChanges[numericalId(data.id)]" class="unsaved">
                    unsaved
                  </span>
                </span>
                <span class="theme-count">
                  <span v-if="data.type === 'group'" class="group-summary">
                    &#40;{{ data.themeCount }} {{ data.themeCount === 1 ? 'theme' : 'themes'
                    }}<template v-if="groupUnsaved(node)">
                      , <span>{{ groupUnsaved(node) }} unsaved</span> </template
                    >&#41;
                  </span>
                </span>
                <span class="right-text" @click.stop>
                  <span v-if="data.id !== -1 && !data.updating" class="dropdown">
                    <el-dropdown trigger="click" position="bottom-end" @command="dropdownClick">
                      <span class="theme-name">
                        <icon color="#aaa" name="horizontal-dots" :size="14" />
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :command="{
                              actions: 'rename',
                              type: data.type,
                              id: data.id,
                            }"
                          >
                            Rename
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="data.type === 'group' || !autoThemeIds.includes(numericalId(data.id))"
                            :command="{
                              actions: 'delete',
                              type: data.type,
                              id: data.id,
                            }"
                            class="red"
                          >
                            Delete
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </span>
                  <span :class="['coverage', { updating: data.updating }]">
                    <template v-if="data.updating">
                      <loading-dots />
                    </template>
                    <template v-else> {{ getCoveragePercent(data.coverage) }} % </template>
                  </span>
                </span>
              </div>
              <div>
                <el-progress :show-text="false" :percentage="getCoveragePercent(data.coverage)" />
              </div>
            </div>
          </template>
        </el-tree>
      </div>
    </template>
    <template v-else> No themes found. </template>
    <create-group-modal
      :visible="createGroupModalVisible"
      :submit-errors="[]"
      :project-id="projectId"
      :analysis-id="analysisId"
      @close="createGroupModalVisible = false"
      @update-tree="$emit('update-tree')"
    />
    <group-name-modal
      :visible="renameGroupId !== null"
      :values="{ name: renameGroupName }"
      :submit-errors="renameErrors"
      @close="renameGroupId = null"
      @submit="renameGroupSubmit"
    />
    <group-delete-modal
      :visible="deleteGroupId !== null"
      :group-name="deleteGroupName"
      @close="deleteGroupId = null"
      @submit="deleteGroupSubmit"
    />
    <theme-name-modal
      v-if="renameTheme"
      :visible="renameThemeId !== null"
      :values="{
        name: renameTheme && renameTheme.name,
        description: renameTheme && renameTheme.description,
      }"
      :submit-errors="renameErrors"
      @close="renameThemeId = null"
      @update-theme="renameThemeSubmit(renameTheme, $event)"
    />
    <theme-delete-modal
      v-if="deletingTheme"
      :visible="deleteThemeId !== null"
      :selected-query="deletingTheme"
      :saved-queries="themes || []"
      :delete-theme="deleteThemeSubmit"
      @close="deleteThemeId = null"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, ref, inject, watch } from 'vue'
import { SavedQuery } from 'src/types/Query.types'
import { BfSpinner, BfButton } from 'components/Butterfly'
import Icon from 'components/Icon.vue'
import CreateGroupModal from './CreateGroupModal.vue'
import GroupNameModal from './GroupNameModal.vue'
import ThemeNameModal from './ThemeNameModal.vue'
import GroupDeleteModal from './GroupDeleteModal.vue'
import ThemeDeleteModal from './ThemeDeleteModal.vue'
import { ThemeGroup } from 'src/api/query'
import { CoverageNode, SortMode, SortOrder, TreeDataNode, findNode, findParentNode } from './ThemeBuilder.utils'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import { ElTree } from 'element-plus'
import LoadingDots from 'components/LoadingDots.vue'
import QueryUtils from 'src/utils/query'
import { Analytics } from 'src/analytics'

export interface NodeData {
  id: string
  type: 'group' | 'theme'
  label: string
  children?: NodeData[]
  key: string
}

type ElTreeNode = {
  childNodes: ElTreeNode[]
  data: TreeDataNode
}

export interface NodeStub {
  id: number
  type: 'group' | 'theme'
}

const ThemesTab = defineComponent({
  components: {
    GroupDeleteModal,
    GroupNameModal,
    CreateGroupModal,
    BfSpinner,
    BfButton,
    Icon,
    ThemeNameModal,
    ThemeDeleteModal,
    UpDown,
    LoadingDots,
  },
  props: {
    isReady: { type: Boolean, required: false, default: true },
    themes: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    selectedThemeID: {
      type: [String, Number, null] as PropType<null | SavedQuery['id']>,
      default: null,
    },
    hasChanges: {
      type: Object as PropType<Record<SavedQuery['id'], boolean>>,
      required: false,
      default: () => ({}),
    },
    analysisId: { type: Number, required: true },
    projectId: { type: Number, required: true },
    groupTree: { type: Array as PropType<GroupOrTheme[]>, required: true },
    saveTheme: {
      type: Function as PropType<(query: SavedQuery, partial?: Partial<SavedQuery> | undefined) => Promise<void>>,
      required: true,
    },
    deleteTheme: {
      type: Function as PropType<(theme: SavedQuery, conflicts: SavedQuery[]) => Promise<void>>,
      required: true,
    },
    topLevelData: { type: Array as PropType<TreeDataNode[]>, required: false, default: () => [] },
    expandedNodeKeys: { type: Array as PropType<string[]>, required: false, default: () => [] },
    loadLevel: { type: Function as PropType<(node: TreeDataNode) => Promise<CoverageNode[]>>, required: true },
  },
  emits: ['theme-clicked', 'update-tree', 'node-expand', 'node-collapse'],
  setup(props, { emit }) {
    const createGroupModalVisible = ref(false)
    const renameGroupId = ref<null | number>(null)
    const deleteGroupId = ref<null | number>(null)
    const renameThemeId = ref<null | number>(null)
    const deleteThemeId = ref<null | number>(null)
    const renameErrors = ref<string[]>([])
    const treeData = ref<CoverageNode[]>([])
    const dropTarget = ref<null | string>(null)
    const updatingTree = ref(false)
    const draggingNode = ref<ElTreeNode | null>(null)
    const tree = ref<typeof ElTree>()
    const analytics = inject<Analytics>('analytics')

    const initialLoad = ref<boolean>(true)

    const autoThemeIds = computed(() => {
      return props.themes
        .filter((theme) => {
          const rows = QueryUtils.botanicToQueryRows(theme.query_value)
          return rows.some((row) => row.field === 'aitopic')
        })
        .map((q) => q.id)
    })

    const renameGroupName = computed(() => {
      if (renameGroupId.value !== null) {
        const node = findNode(props.groupTree, { id: renameGroupId.value, type: 'group' })
        return node?.name
      }
      return ''
    })

    const deleteGroupName = computed(() => {
      if (deleteGroupId.value !== null) {
        const node = findNode(props.groupTree, { id: deleteGroupId.value, type: 'group' })
        return node?.name
      }
      return ''
    })

    const updateNodeData = (id: string, data: Partial<TreeDataNode>) => {
      const treeRef = tree.value
      if (!treeRef) return

      const node = treeRef.getNode(id)

      if (node) {
        node.data = {
          ...node.data,
          ...data,
        } as TreeDataNode
      }
    }

    const renameTheme = computed<SavedQuery | null>(() => {
      if (renameThemeId.value !== null) {
        return props.themes.find((theme) => theme.id === renameThemeId.value) ?? null
      }
      return null
    })

    const deletingTheme = computed<SavedQuery | null>(() => {
      if (deleteThemeId.value !== null) {
        return props.themes.find((theme) => theme.id === deleteThemeId.value) ?? null
      }
      return null
    })

    const groupUnsaved = (node: ElTreeNode) => {
      return (
        node.childNodes?.filter((child) => {
          const childId = numericalId(child.data.id.toString())
          return props.hasChanges[childId]
        })?.length ?? 0
      )
    }

    const getCoveragePercent = (decimal: number): number => {
      return +(decimal * 100).toFixed(2)
    }

    const countChildThemes = (node: CoverageNode): number => {
      if (!node.children) return 0
      return node.children.reduce((acc, child) => {
        if (child.type === 'theme') return acc + 1
        return acc + countChildThemes(child)
      }, 0)
    }

    const savedThemes = computed(() => {
      return props.themes.filter((theme) => !theme.is_new)
    })

    const newThemes = computed(() => {
      return props.themes.filter((theme) => theme.is_new)
    })

    const sortMode = ref<SortMode>('name')
    const sortOrder = ref<SortOrder>('asc')

    const sortClick = (mode: 'name' | 'coverage') => {
      if (sortMode.value === mode) {
        if (sortOrder.value === 'desc') {
          sortOrder.value = 'asc'
        } else {
          sortOrder.value = 'desc'
        }
      } else {
        sortMode.value = mode
        sortOrder.value = 'desc'
      }
    }

    const nodeClick = (data: NodeData) => {
      if (data.type === 'theme') {
        const id = numericalId(data.id)
        emit('theme-clicked', id)
      }
    }

    const handleDrop = async (child: ElTreeNode, other: ElTreeNode) => {
      updatingTree.value = true

      let parentId: number | undefined

      if (other.data.type === 'theme') {
        // Dropped as a sibling of "other"
        const searchParams = { id: other.data.id, type: other.data.type }
        const parent = findParentNode(props.groupTree, searchParams)
        if (!parent) return
        parentId = +parent.id
      } else {
        // Dropped as a child of "other"
        parentId = numericalId(other.data.id)
      }

      // If dropped in Ungrouped themes, parentId is -1
      if (parentId === -1) {
        parentId = undefined
      }
      if (child.data.type === 'theme') {
        analytics?.track.themeBuilder.moveTheme(
          { name: child.data.name, id: child.data.id },
          other.data.type === 'group' ? { name: other.data.name, id: other.data.id } : { id: other.data.id },
        )
      }

      const endpoint = child.data.type === 'theme' ? ThemeGroup.updateThemeParent : ThemeGroup.updateGroupParent

      await endpoint(props.projectId, props.analysisId, numericalId(child.data.id), parentId ?? null)

      emit('update-tree')
    }

    const renameGroupSubmit = async ({ name }: { name: string }) => {
      renameErrors.value = []

      if (renameGroupId.value !== null && renameGroupName.value) {
        try {
          await ThemeGroup.update(props.projectId, props.analysisId, renameGroupId.value, { group_name: name })
          analytics?.track.themeBuilder.renameThemeGroup(renameGroupName.value, name, renameGroupId.value)
          renameGroupId.value = null
          emit('update-tree')
        } catch (e) {
          console.error(e)
        }
      }
    }

    const deleteGroupSubmit = async () => {
      if (deleteGroupId.value !== null && deleteGroupName.value) {
        await ThemeGroup.delete(props.projectId, props.analysisId, deleteGroupId.value)
        analytics?.track.themeBuilder.deleteThemeGroup(deleteGroupName.value, deleteGroupId.value)
        deleteGroupId.value = null
        emit('update-tree')
      }
    }

    const nodeDragEnter = (dragNode: ElTreeNode, dropNode: ElTreeNode) => {
      const currentParent = findParentNode(props.groupTree, {
        id: dragNode.data.id,
        type: dragNode.data.type,
      })

      const data = dropNode.data
      let dropTargetId = null

      if (data.type === 'group') {
        dropTargetId = data.id
      } else {
        const searchParams = { id: data.id, type: data.type }
        const parent = findParentNode(props.groupTree, searchParams)
        if (parent) {
          dropTargetId = parent.id.toString()
        }
      }

      if (currentParent?.id !== dropTargetId) {
        dropTarget.value = dropTargetId
      } else {
        dropTarget.value = null
      }
    }

    const allowDrag = (node: ElTreeNode) => {
      return node.data.id !== 'group_-1'
    }

    const renameThemeSubmit = async (query: SavedQuery, data: Partial<SavedQuery>) => {
      renameErrors.value = []
      props
        .saveTheme(query, data)
        .then(() => {
          renameThemeId.value = null
          emit('update-tree')
        })
        .catch((e) => {
          renameErrors.value = e.body?.non_field_errors ?? ['An error occurred.']
        })
    }

    const numericalId = (id: string) => {
      return +id.replace(/^(query_|group_)/, '')
    }

    const deleteThemeSubmit = async (theme: SavedQuery, conflicts: SavedQuery[]) => {
      await props.deleteTheme(theme, conflicts)
      deleteThemeId.value = null
    }

    const dropdownClick = (command: { actions: 'rename' | 'delete'; type: 'group' | 'theme'; id: string }) => {
      const nId = numericalId(command.id)
      if (command.actions === 'rename') {
        if (command.type === 'group') {
          renameGroupId.value = nId
        } else {
          renameThemeId.value = nId
        }
      } else if (command.actions === 'delete') {
        if (command.type === 'group') {
          deleteGroupId.value = nId
        } else {
          deleteThemeId.value = nId
        }
      }
    }

    const getNewThemeCoverage = (id: number) => {
      const data = props.topLevelData.find((node) => node.id === `new_query_${id}`)
      if (!data) return 0
      return getCoveragePercent(data.coverage)
    }

    const sortData = (data: CoverageNode[]): CoverageNode[] => {
      return data.slice().sort((a, b) => {
        if (sortMode.value === 'name') {
          return sortOrder.value === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
        } else if (sortMode.value === 'coverage') {
          return sortOrder.value === 'asc' ? a.coverage - b.coverage : b.coverage - a.coverage
        }
        return 0
      })
    }

    const loadNode = async (node: ElTreeNode, resolve: (data: CoverageNode[]) => void) => {
      if (initialLoad.value) {
        const data = props.topLevelData.filter((d) => !d.id.startsWith('new_query_'))
        resolve(sortData(data))
        initialLoad.value = false
        return
      }
      const items = await props.loadLevel(node.data)
      resolve(sortData(items))
    }

    watch([sortMode, sortOrder], () => {
      treeData.value = sortData(props.topLevelData)
    })

    return {
      renameErrors,
      renameGroupId,
      renameGroupName,
      renameGroupSubmit,
      deleteGroupId,
      deleteGroupName,
      deleteGroupSubmit,
      getCoveragePercent,
      savedThemes,
      newThemes,
      createGroupModalVisible,
      nodeClick,
      handleDrop,
      groupUnsaved,
      countChildThemes,
      nodeDragEnter,
      dropTarget,
      updatingTree,
      draggingNode,
      allowDrag,
      renameThemeId,
      deleteThemeId,
      renameTheme,
      deletingTheme,
      renameThemeSubmit,
      deleteThemeSubmit,
      dropdownClick,
      sortMode,
      sortOrder,
      sortClick,
      loadNode,
      initialLoad,
      tree,
      updateNodeData,
      getNewThemeCoverage,
      numericalId,
      autoThemeIds,
      treeData,
    }
  },
})
export default ThemesTab
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.theme-section
  margin-bottom: 20px
  &:last-child
    margin-bottom: 0

.theme-item
  display: flex
  flex-direction: column
  color: $text-black
  cursor: pointer
  font-size: 14px
  margin-top: 16px

  &:hover
    color: $blue

.unsaved
  font-size: 14px
  color: $red
  margin-left: 6px
  font-style: italic

.group-info,
.theme-info
  display: flex
  justify-content: space-between
  align-items: end
  margin-top: 2px
  margin-bottom: 2px
  height: 24px
  line-height: 24px
  &.selected
    color: $blue
    .theme-name
      font-weight: bold

.theme-info,
.group-info
  .dropdown
    display: none

.theme-header
  display: flex
  font-weight: bold
  text-transform: uppercase
  justify-content: space-between
  color: $text-black
  font-size: 12px
  margin-bottom: 10px

  .icon-wrapper
    margin-left: 3px
    position: relative
    top: 2px

  .bf-button
    padding: 0
    text-transform: uppercase
    font-size: 12px
    margin-left: 4px
    .icon-wrapper
      top: 0px
      margin-right: 4px

.left-text
  text-align: left
  min-width: 0
  overflow: hidden
  text-overflow: ellipsis
.theme-count
  flex: 1
  margin: 0 5px
  color: $subdued
.right-text
  text-align: right
  flex-shrink: 0
  display: flex
  align-items: center
  justify-content: center
  height: 100%

::v-deep
  .el-icon.el-tree-node__loading-icon.is-loading
    display: none !important
  .el-tree
    &.is-dragging
      .drag-dots
        visibility: hidden !important

  .el-tree-node__expand-icon
    display: none

  .el-progress-bar__outer
    margin-left: 1px

  .el-progress-bar__inner,
  .el-progress-bar__outer
    border-radius: 0

  .el-tree-node:focus > .el-tree-node__content
    background-color: unset

  .el-tree-node__content
    height: 38px
    &:hover
      background-color: unset
    .node-content
      width: 100%
      color: $text-black
      position: relative
      .drag-dots
        visibility: hidden
        position: absolute
        width: 16px
        left: -16px
        top: 10px
        align-items: flex-start
        pointer-events: all
      &.drop-target
        &::before
          content: ''
          position: absolute
          top: 0
          left: -12px
          width: 6px
          height: 100%
          background-color: $grey
    &:hover
      color: $blue
      .drag-dots:not(:active)
        visibility: visible
      .theme-info,
      .group-info:not(.ungrouped)
        .dropdown
          display: flex
        .coverage:not(.updating)
          display: none

.theme-tree
  hr
    border: none
    border-bottom: 1px solid $grey
    margin: 16px 0

.group-summary
  font-style: italic
  margin-left: 2px
  > span
    color: $red

.red
  color: $red

.expanded-icon
  position: relative
  top: 1px
  margin-right: 2px
  transition: transform 0.3s ease
  &.expanded
    transform: rotate(90deg)

.loading
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.sort-wrapper
  cursor: pointer

.up-down
  margin-left: 2px
  position: relative
  top: 0.5px
</style>
