<template>
  <span>
    <project-label
      v-show="inputHidden && showIcon"
      class="add-label"
      name="Add label"
      color="#068ccc"
      :invert="true"
      @click.stop="handleAddClick"
    >
    </project-label>
    <el-select
      v-show="!inputHidden"
      ref="inputElm"
      :model-value="newLabel"
      remote
      filterable
      :remote-method="fetchLabels"
      :loading="isFetching"
      class="inline-input"
      size="small"
      @update:model-value="newLabel = $event"
      @visible-change="handleVisibleChange"
      @change="handleSelect"
    >
      <el-option
        v-for="item in labels.filter((label) => !currentLabelIds.includes(label.id))"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
        <template v-if="item.id === -1">
          <span class="more-text">
            {{ item.name }}
          </span>
        </template>
        <template v-else>
          <project-label :color="item.color" :name="item.name"> </project-label>
        </template>
      </el-option>
    </el-select>
  </span>
</template>
<script lang="ts">
import { defineComponent, ref, computed, PropType, onMounted } from 'vue'
import LabelAPI from 'src/api/labels'
import { Label } from 'src/pages/ProjectLabels/ProjectLabels.util'
import ProjectLabel from 'components/site/ProjectLabels/ProjectLabel.vue'
import { ElAutocomplete } from 'element-plus'

export default defineComponent({
  components: {
    ProjectLabel,
  },
  props: {
    showIcon: { type: Boolean, default: false },
    projectId: { type: Number, default: undefined },
    currentLabels: { type: Array as PropType<Label[]>, default: () => [] },
  },
  setup(props, { emit }) {
    const inputHidden = ref(true)
    const hidePlus = ref(false)
    const inputElm = ref<null | typeof ElAutocomplete>(null)

    const isFetching = ref(true)
    const labels = ref<Label[]>([])
    const nMore = ref(0)
    const newLabel = ref<undefined | Label>(undefined)

    const currentLabelIds = computed(() => props.currentLabels.map((l) => l.id))

    const fetchLabels = async (q: string) => {
      isFetching.value = true
      const res = await LabelAPI.getLabels(undefined, q, q ? undefined : 50)
      labels.value = res?.results ?? []
      nMore.value = res?.count - res?.results?.length ?? 0
      if (nMore.value > 0) {
        labels.value.push({
          id: -1,
          name: `and ${nMore.value} more...`,
          color: '',
          description: '',
        })
      }
      isFetching.value = false
      return res
    }

    onMounted(() => {
      fetchLabels('')
    })

    const handleSelect = async (id: number) => {
      emit(
        'label-selected',
        labels.value.find((label) => label.id === id),
      )
    }

    const handleAddClick = async () => {
      inputHidden.value = false
      setTimeout(() => {
        inputElm.value?.wrapperRef.click()
      }, 100)
    }

    const handleVisibleChange = (visible: boolean) => {
      newLabel.value = undefined
      if (!visible) inputHidden.value = true
    }

    return {
      isFetching,
      newLabel,
      labels,
      nMore,
      hidePlus,
      inputHidden,
      inputElm,
      currentLabelIds,
      handleSelect,
      fetchLabels,
      handleVisibleChange,
      handleAddClick,
    }
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.container
  margin-top: 40px

  h1
    margin-bottom: 40px

  .item
    margin-top: 15px

.more-text
  color: $text-grey
  font-style: italic

::v-deep #popper[data-popper-reference-hidden]
  visibility: hidden
  pointer-events: none
</style>
