<template>
  <widget-frame
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    class="emergent-concepts"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <download-export-button
        :name="`${exportName} - Emergent Concepts - ${displaySelection}`"
        short-name="Emergent Concepts"
        :is-loading="isLoading"
        :get-el="getChartEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getSvgExportConfig"
        :make-ppt-slide="makePptSlide"
      ></download-export-button>
      <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a :href="CONST.widget_help_links.emergent_concepts" class="widget-action help" target="_blank">
        <i class="kapiche-icon-info"></i>
      </a>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Emergent Concepts Icon" />
    </template>

    <!--======================== HEADING -->
    <template #header> Emergent Concepts </template>

    <!--======================== MENU -->
    <template #menu>
      <div class="menu-list">
        <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" @onSelect="setMenuSelection" />
      </div>
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <h2>this.props</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button> or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action"><button @click.stop="contact">Contact support</button> if the problem persists.</div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="groupbyNotSupported" #content>
      <div class="warning">Group by is not yet supported for this widget.</div>
    </template>
    <template v-else-if="hasValidData" #content>
      <segmentation-chart
        v-if="!isLoading"
        :rows="dataRows"
        :headings="headings"
        :legend="[]"
        :max="1"
        :min="minValue"
        :height="height"
        :width="width"
        :show-indicator-bar="false"
        empty-segment-text="(No Value)"
        :hover-row-pointer="showDrilldown"
        @clicked-heading="onHeadingClick"
        @row-clicked="rowClicked"
        @hover-row="setTooltipStats"
      >
        <template #row-tool-tip>
          <tooltip v-if="tooltipStats" v-bind="tooltipStats" :show-drilldown="showDrilldown" />
        </template>
      </segmentation-chart>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { PropType, defineComponent } from 'vue'
import { isEqual, debounce } from 'lodash'
import PptxGenJS from 'pptxgenjs'

import type { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import {
  fetchDataQueryListFilters,
  splitQuery,
  ConceptDiff,
  getDataRows,
  TooltipStats,
  ChartRow,
  getDateRange,
  generateCSV,
} from './EmergentConcepts.utils'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import SegmentationChart from 'components/charts/SegmentationChart/SegmentationChart.vue'
import Tooltip from 'components/DataWidgets/EmergentConcepts/Tooltip.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import { WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-emerging.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { QueryType } from 'src/types/Query.types'
import { KeysMatching } from 'src/types/utils'
import { expandQuery } from 'src/utils/query'
import ChartUtils from 'src/utils/chart'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { ChartHeading } from 'src/types/components/Charts.types'
import { makeBarChartSlide } from '../DataWidgetUtils'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { processFilters } from 'src/pages/dashboard/Dashboard.utils'

interface ECChartHeading extends ChartHeading {
  key?: KeysMatching<ConceptDiff, number>
}

interface DateField {
  name: string
}

export default defineComponent({
  components: {
    WidgetMessagePanel,
    DownloadExportButton,
    SegmentationChart,
    WidgetFrame,
    WidgetMenu,
    Tooltip,
  },
  props: {
    exportName: { type: String, required: false, default: '' },
    devMode: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    isZoomed: { type: Boolean, required: false, default: false },
    /** widget banner to display */
    banner: { type: Object, default: () => null, required: false },
    baseQuery: { type: Array as PropType<QueryType[]>, required: true },
    excludeQueries: { type: Array as PropType<QueryType[]>, required: false, default: () => [] },
    dateFields: { type: Array as PropType<DateField[]>, required: true },
    topicId: { type: Number, required: true },
    projectId: { type: Number, required: true },
    chrysalisRef: { type: String, required: true },
    savedQueries: { type: Array, required: true },
    defaultDateField: { type: String, required: true },
    showDrilldown: { type: Boolean, required: true },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'emergent-concepts'> | null>, required: false, default: null },
    groupbyNotSupported: { type: Boolean, default: false },
    queryListOperation: { type: String, required: false, default: 'intersection' },
    filters: { type: Array<ChrysalisFilter>, required: false, default: () => [] },
  },
  data() {
    return {
      icon,
      errorIcon,
      isLoading: false,
      hasErrored: false,
      width: 0,
      height: 0,
      conceptList: [] as ConceptDiff[],
      tooltipStats: null as TooltipStats | null,
      headings: [] as ECChartHeading[],
      sortSelection: 'freq_diff_fraction' as KeysMatching<ConceptDiff, number>,
      sortAsc: false,
      customDateRange: [undefined, undefined] as [string | undefined, string | undefined],
      dateField: this.defaultDateField || this.dateFields[0].name,
      displaySelection: 'Frequency change',
      fromSelection: 'Past 30 days',
      minConceptFreq: 0,
    }
  },
  computed: {
    hasValidData(): boolean {
      return this.dataRows.length > 0
    },
    minValue(): 0 | -1 {
      return this.sortSelection === 'freq_diff' ? 0 : -1
    },
    dataRows(): ChartRow[] {
      return getDataRows(
        this.conceptList,
        this.sortSelection,
        this.displaySelection === 'Frequency change' ? 'freq_diff_fraction' : 'reldiff',
      )
    },
    menus(): WidgetMenuOptions[] {
      return [
        {
          name: 'Display',
          selection: this.displaySelection,
          options: [
            [
              {
                type: 'menu',
                options: ['Frequency change', 'Relative difference'],
                showSelected: true,
                selected: this.displaySelection,
              },
            ],
          ],
        },
        {
          name: 'Min Concept Freq',
          selection: this.minConceptFreq,
          options: [
            [
              {
                type: 'inputNumber',
                // Without having something in the options list,
                // the dropdown shows "No Data"
                options: ['inputNumber'],
                settings: {
                  min: 0,
                  step: 10,
                  max: 100,
                },
                showSelected: true,
                selected: this.minConceptFreq,
              },
            ],
          ],
        },
        {
          name: 'From',
          selection: this.fromSelectionDisplayFormat(this.fromSelection),
          options: [
            [
              {
                title: 'From',
                type: 'radio',
                options: [
                  { label: 'Past 7 days', value: 'Past 7 days' },
                  { label: 'Past 30 days', value: 'Past 30 days' },
                  { label: 'Past 90 days', value: 'Past 90 days' },
                  { label: 'Past 365 days', value: 'Past 365 days' },
                  {
                    label: 'Custom range...',
                    value: 'Custom',
                    input: 'daterange',
                    inputValue: this.customDateRange,
                    onChange: this.setDateRange,
                  },
                ],
                showSelected: true,
                selected: [this.fromSelection],
              },
              {
                title: 'Date Field',
                type: 'radio',
                options: this.dateFields.map(({ name }) => name),
                showSelected: true,
                selected: [this.dateField],
              },
            ],
          ],
        },
      ]
    },
    dateRange(): [string, string] {
      return getDateRange(this.fromSelection, this.customDateRange)
    },
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.setOptionsFromConfig()
      },
    },
    baseQuery: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          void this.fetchData()
        }
      },
    },
    fromSelection(val: string) {
      if (val !== 'Custom' || this.customDateRange.every((d) => !!d)) {
        void this.fetchData()
      }
    },
    sortSelection() {
      void this.fetchData()
    },
    filters: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          void this.fetchData()
        }
      },
    },
    sortAsc() {
      void this.fetchData()
    },
    isZoomed() {
      void this.fetchData()
    },
    dateField() {
      void this.fetchData()
    },
    customDateRange(val) {
      if (val[0] && val[1]) {
        void this.fetchData()
      }
    },
    displaySelection(display: string) {
      this.setHeadings(display)
    },
    minConceptFreq: debounce(function () {
      this.fetchData()
    }, 500),
  },
  mounted() {
    this.setOptionsFromConfig()
    this.setHeadings(this.displaySelection)
    void this.fetchData()
  },
  methods: {
    updateConfig() {
      const updated: typeof this.config = Object.assign({}, this.config, {
        options: {
          customDateRange: this.customDateRange,
          display: this.displaySelection,
          dateField: this.dateField,
          from: this.fromSelection,
          minConceptFreq: this.minConceptFreq,
        },
      })
      this.$emit('config-changed', updated)
    },
    setOptionsFromConfig() {
      this.customDateRange = this.config?.options?.customDateRange ?? [undefined, undefined]
      this.displaySelection = this.config?.options?.display ?? 'Frequency change'
      this.fromSelection = this.config?.options?.from ?? 'Past 30 days'
      this.dateField = this.config?.options?.dateField ?? (this.defaultDateField || this.dateFields[0].name)
      this.minConceptFreq = this.config?.options?.minConceptFreq ?? 30
    },

    setDateRange(dates: [Date, Date]) {
      this.customDateRange = dates
      this.updateConfig()
    },
    setHeadings(display: string) {
      const headings: ECChartHeading[] = [
        { label: 'Concept', sortable: false },
        { label: 'Freq (#) change', sortable: true, sortAsc: null, key: 'freq_diff' },
      ]

      display === 'Frequency change' &&
        headings.push({ label: 'Freq (%) change', sortable: true, sortAsc: false, key: 'freq_diff_fraction' })

      display === 'Relative difference' &&
        headings.push({ label: 'Relative diff', sortable: true, sortAsc: false, key: 'reldiff' })

      this.headings = headings
      this.sortSelection = headings.slice(-1)[0].key || 'freq_diff'
      this.sortAsc = headings.slice(-1)[0].sortAsc || false
    },
    onHeadingClick(headingIndex: number) {
      const heading = this.headings[headingIndex]
      const { sortAsc } = heading

      // Reset and toggle sort mode
      this.headings.slice(1).forEach((h) => (h.sortAsc = null))
      this.sortAsc = heading.sortAsc = sortAsc == null ? false : !sortAsc

      if (heading.key) {
        this.sortSelection = heading.key
      }
      this.$analytics.track.emergent.changeSort(this.sortSelection, this.sortAsc ? 'asc' : 'desc')
    },
    setTooltipStats(index: number) {
      const concept = this.conceptList[index]
      this.tooltipStats = {
        freq_percent_1: ChartUtils.formatPercent(concept.freq_fraction_1 * 100),
        freq_percent_2: ChartUtils.formatPercent(concept.freq_fraction_2 * 100),
        freq_percent_total: ChartUtils.formatPercent(concept.freq_fraction_total * 100),
        freq_1: concept.freq_1,
        freq_2: concept.freq_2,
        freq_total: concept.freq_total,
        freq_diff_percent: ChartUtils.formatPercent(concept.freq_diff_fraction * 100),
        freq_diff: concept.freq_diff,
        daysAgo: this.fromSelection.match(/\d+/)?.[0] || '',
        label: concept.concept_name,
        reldiff: ChartUtils.formatPercent(concept.reldiff * 100),
        customDates: this.customDateRange,
      }
    },
    setChartDimensions(width: number, height: number): void {
      this.width = width
      this.height = height
    },
    async fetchData() {
      this.isLoading = true
      this.hasErrored = false

      try {
        if (!this.dateRange) {
          return
        }
        const [startDate, endDate] = this.dateRange
        // This is the baseline partition
        const filtersA = processFilters([{ field: this.dateField, op: '<', value: startDate }])
        // This is the partition being compared to the baseline
        const filtersB = processFilters([
          { field: this.dateField, op: '>=', value: startDate },
          { field: this.dateField, op: '<=', value: endDate },
        ])
        const { payload } = await fetchDataQueryListFilters({
          query_list_include: this.baseQuery,
          query_list_exclude: this.excludeQueries,
          // These would be dashboard queries
          filters: processFilters(this.filters),
          chrysalisRef: this.chrysalisRef,
          limit: this.isZoomed ? 30 : 10,
          queryListIncludeOperation: this.queryListOperation,
          queryListExcludeOperation: 'union',
          filtersA: filtersA,
          filtersB: filtersB,
          sortField: this.sortSelection,
          projectId: this.projectId,
          topicId: this.topicId,
          sortAsc: this.sortAsc,
          minConceptFreq: this.minConceptFreq,
        })

        this.conceptList = payload
        this.$analytics.track.emergent.load(this.conceptList.length)
      } catch (e) {
        this.hasErrored = true
      } finally {
        this.isLoading = false
      }
    },
    setMenuSelection(name: string, [title, val]: [string, string]) {
      if (name === 'Display') {
        this.displaySelection = val
        this.$analytics.track.emergent.changeDisplay(this.displaySelection)
      }
      if (name === 'From') {
        if (title === 'Date Field') {
          this.dateField = val
        }
        if (title === 'From') {
          this.fromSelection = val
        }
        this.$analytics.track.emergent.changeRange(this.dateField, this.fromSelection)
      }
      if (name === 'Min Concept Freq') {
        this.minConceptFreq = val
      }
      this.updateConfig()
    },
    fromSelectionDisplayFormat(fromSelection: string): string {
      function customDateFmt(dt: Date | undefined) {
        return dt == null ? 'No date set' : dayjs(dt).format('D MMM, YYYY')
      }
      if (fromSelection === 'Custom') {
        return `${customDateFmt(this.customDateRange[0])} \u2014 ${customDateFmt(this.customDateRange[1])}`
      }
      return fromSelection
    },
    getCsvData() {
      return generateCSV(this.conceptList, this.displaySelection)
    },
    getChartEl() {
      return this.$el.querySelector('div.content')
    },
    getSvgExportConfig() {
      const el = this.getChartEl()
      if (!el) return

      const bb = el.getBoundingClientRect()
      return {
        dims: { height: bb.height, width: bb.width },
        css: `
        svg {
          top: 0;
          left: 0;
        }
        .chart {
          background-color: #fff;
          cursor: default;
        }
        text.first {
          text-anchor: start;
        }
        text.end{
          text-anchor: end;
        }
        rect.bar{
            fill: #11ACDF;
        }
        rect.background {
          fill: #F1F1F1;
        }
        rect.indicator {
          fill: #8064AA;
        }
        text.sorted {
          fill: #068CCC;
          font-weight: bold;
        }
        rect.legend-icon-one {
          fill: #11ACDF;
        }
        rect.legend-icon-two {
          fill: #8064AA;
        }
        .legend {
          cursor: default;
        }`,
      }
    },
    rowClicked(rowIndex: number) {
      if (!this.showDrilldown) return
      const concept = this.dataRows[rowIndex]?.label
      this.$emit('concept-clicked', concept)
    },
    // error panel items
    refresh() {
      window.location.reload()
    },
    reload() {
      void this.fetchData()
    },
    contact() {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    },
    makePptSlide(pptx: PptxGenJS) {
      if (!this.dataRows.length) return
      const slide = pptx.addSlide()
      makeBarChartSlide(
        pptx,
        slide,
        [
          {
            name: 'Emergent Concepts',
            labels: this.dataRows.map((r) => r.label),
            values: this.dataRows.map((r) => r.columns[0].value),
          },
        ],
        `${this.exportName} - Emergent Concepts`,
        'Frequency Change',
        '',
        { showValue: true },
      )
    },
  },
})
</script>

<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255,0,0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0


.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px
  &:hover
    background-color: $grey-light
  &:focus
    border: 2px solid $blue-light
    outline: none

.concept-table
  th
    text-align: left

::v-deep footer
  display: none !important
</style>
