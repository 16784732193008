import { ref, onMounted, onUnmounted } from 'vue'

export function deviceSupport() {
  const isMobile = ref(false)
  const MOBILE_BREAKPOINT = 767

  const checkIsMobile = () => {
    isMobile.value = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`).matches
  }

  onMounted(() => {
    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', checkIsMobile)
  })

  return { isMobile }
}
