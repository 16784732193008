<template>
  <component :is="'script'" src="https://fast.wistia.com/embed/medias/t410bezl04.jsonp"></component>
  <component :is="'script'" src="https://fast.wistia.com/assets/external/E-v1.js"></component>
  <modal :visible="visible" @close="$emit('close')">
    <template #header> Welcome to Kapiche </template>
    <template #content>
      <p>
        Let us help you get acquainted with the outputs Kapiche has produced for the dataset and how to interpret them.
        The tour has {{ stepCount }} steps, takes approx. 2 minutes.
      </p>
    </template>
    <template #buttons>
      <div class="footer center">
        <span
          class="wistia_embed wistia_async_t410bezl04 popover=true popoverContent=link videoFoam=false"
          style="display: inline; position: relative"
        >
          <bf-button size="large" color="blue" :outlined="true"> Watch the video </bf-button>
        </span>
        <bf-button size="large" color="blue" @click="$emit('start-tour')">Take the tour</bf-button>
        <br />
        <br />
        <p><a href="#" @click="$emit('close')">Skip the tours for now</a></p>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import BfButton from 'components/Butterfly/BfButton/BfButton.vue'
import Modal from 'components/Modal.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TourModal',
  components: {
    Modal,
    BfButton,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    stepCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['close', 'start-tour'],
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';
</style>
