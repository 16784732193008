import { TypeOption } from 'types/SchemaTypes'

export const FIELD_TYPES: Array<TypeOption> = [
  {
    value: -1,
    label: 'Ignore',
    colour: 'red',
  },
  {
    value: 1,
    label: 'Text/Verbatim',
    colour: 'red',
  },
  {
    value: 2,
    label: 'Numerical',
    colour: 'yellow',
  },
  {
    value: 3,
    label: 'Date',
    colour: 'olive',
  },
  {
    value: 4,
    label: 'Date time',
    colour: 'green',
  },
  {
    value: 5,
    label: 'Category',
    colour: 'blue',
  },
  {
    value: 7,
    label: 'NPS (0-10)',
    colour: 'pink',
  },
  {
    value: 8,
    label: 'SCORE',
    colour: 'light green',
  },
]

export const FIELD_TYPES_INDEX = Object.fromEntries(FIELD_TYPES.map((t) => [t.value, t]))

/**
 * Checks if a date string is ambiguous (could be interpreted as either DD/MM/YYYY or MM/DD/YYYY)
 * @param dateStr - The date string to check, expected in format XX/XX/YYYY where / can also be . or -
 * @returns boolean - True if the date is ambiguous (both numbers could be valid month/day), false otherwise
 */
export function isAmbiguousDate(dateStr: string): boolean {
  const match = /^(\d{1,2})[\/.-](\d{1,2})[\/.-]\d{2,4}$/.exec(dateStr)
  if (!match) return false

  const first = parseInt(match[1], 10)
  const second = parseInt(match[2], 10)

  // Both numbers must be valid as either month (1-12) or day (1-12) to be ambiguous
  return first >= 1 && first <= 12 && second >= 1 && second <= 12
}
