<template>
  <div class="paginator">
    <i
      :class="{ inactive: startIndex === 1 }"
      class="icon chevron left"
      @click="$emit('page-changed', startIndex - perPage)"
    ></i>

    <div class="labels">
      <div class="paginator-label">{{ startIndex }}-{{ endIndex }} <span>of</span> {{ overallFinalIndex }}</div>
      <div class="jump-to" @click="showModal">Jump to ...</div>
    </div>

    <i
      class="icon chevron right"
      :class="{ inactive: endIndex >= overallFinalIndex }"
      @click="$emit('page-changed', startIndex + perPage)"
    ></i>
    <modal :visible="modalVisible" @close="cancelModal">
      <template #header> Jump to page </template>
      <template #content>
        <VeeForm
          ref="form"
          v-slot="{ meta: { valid, pending }, isSubmitting }"
          :initial-values="initialValues"
          :on-submit="submitModal"
        >
          <div class="page-values">
            <Field
              v-slot="{ field, errors }"
              name="page"
              label="Page Number"
              :rules="`required|between:1,${numberOfPages}`"
              validate-on-input
            >
              <bf-text-input v-bind="field" :focus="modalVisible" :errors="errors"></bf-text-input>
            </Field>
            <p>&nbsp;of {{ numberOfPages }}</p>
          </div>
          <div class="center buttons">
            <bf-button type="button" color="grey" size="big" @click="cancelModal"> Cancel </bf-button>
            <bf-button type="submit" :disabled="!valid || pending || isSubmitting" color="blue" size="big">
              Confirm
            </bf-button>
          </div>
        </VeeForm>
      </template>
    </modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { BfButton, BfTextInput } from 'components/Butterfly'
import Modal from 'components/Modal.vue'

interface FormValues {
  page: string
}

export default defineComponent({
  name: 'Paginator',
  components: { BfTextInput, BfButton, VeeForm, Field, Modal },
  props: {
    startIndex: { type: Number, required: true },
    overallFinalIndex: { type: Number, default: null },
    perPage: { type: Number, required: true },
  },
  data() {
    return {
      modalVisible: false,
      errors: [],
    }
  },
  computed: {
    initialValues(): FormValues {
      return {
        // convert startIndex to page number
        // protect against negative, undefined, and null numbers
        page: `${Math.floor(Math.max(this.startIndex / this.perPage, 0) + 1) || 1}`,
      }
    },
    endIndex() {
      return Math.min(this.startIndex + this.perPage - 1, this.overallFinalIndex)
    },
    numberOfPages() {
      return Math.ceil(this.overallFinalIndex / this.perPage)
    },
  },
  methods: {
    showModal() {
      this.modalVisible = true
      const formRef = this.$refs['form'] as InstanceType<typeof VeeForm>
      formRef?.resetForm()
    },
    cancelModal() {
      this.modalVisible = false
    },
    submitModal({ page }: FormValues) {
      this.modalVisible = false
      const goToPage = parseInt(page, 10) || 1
      const newStartIndex = Math.max((goToPage - 1) * this.perPage + 1, 1)
      this.$emit('page-changed', newStartIndex)
    },
  },
})
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.paginator
  display: flex
  flex-grow: 1
  justify-content: center
  align-items: center
  .labels
    display: flex
    flex-direction: column
    align-items: center
  .paginator-label
    font-weight: bold
    font-size: 16px
    color: rgb(56, 56, 56)
    margin: 10px
    span
      font-weight: normal
  .jump-to
    font-size: 14px
    color: rgb(6, 140, 204)
    cursor: pointer
    font-weight: bold
  .icon
    margin-left: 40px
    margin-right: 40px
    cursor: pointer
    color: $blue
    &.inactive
      color: rgb(229, 229, 229)
      pointer-events: none

.page-values
  display: flex
  justify-content: center
  align-items: center
  white-space: nowrap
</style>
