<template>
  <div class="wrapper">
    <workbench-header />
    <workbench :dashboard-id="dashboardId" :drilldown="drilldown" :drilldown-id="drilldownId" />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import WorkbenchHeader from './Header.vue'
import Workbench from 'src/pages/trial/Workbench/Workbench.vue'
import { Drilldown } from 'src/pages/trial/Workbench/Workbench.utils'

export default defineComponent({
  name: 'WorkbenchV2',
  components: {
    WorkbenchHeader,
    Workbench,
  },
  props: {
    dashboardId: {
      type: Number,
      required: true,
    },
    drilldown: {
      type: [String, Boolean] as PropType<Drilldown>,
      default: false,
    },
    drilldownId: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup() {
    return {}
  },
})
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
