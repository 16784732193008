<template>
  <modal :visible="visible" @close="visible = false">
    <template #header> Create email alert </template>
    <template #content>
      <VeeForm
        v-slot="{ meta: { valid, pending } }"
        class="form"
        :initial-values="formValues"
        :on-submit="submit"
        validate-on-mount
        @created="form = $event"
      >
        <Field
          v-if="!editMode && dashboardId == null"
          v-slot="{ field }"
          rules="required"
          name="dashboard"
          label="Dashboard"
          validate-on-input
        >
          <label>
            <span>Dashboard</span>
            <el-select
              :model-value="field.value"
              :multiple="false"
              placeholder="Select a dashboard..."
              :filterable="true"
              @update:model-value="field.value = $event"
            >
              <el-option
                v-for="dashboard in dashboards"
                :key="dashboard.id"
                :label="`${dashboard.project.name} > ${dashboard.analysis.name} > ${dashboard.name}`"
                :value="dashboard.id"
              />
            </el-select>
          </label>
          <br />
        </Field>
        <Field v-slot="{ field, errors }" rules="required|max:30" name="title" label="Alert title" validate-on-input>
          <label>
            <span>Title</span>
            <bf-text-input v-bind="field" :errors="errors" type="text" />
          </label>
        </Field>
        <Field v-slot="{ field, errors }" rules="max:200" name="description" label="Description" validate-on-input>
          <label>
            <span>Description</span>
            <bf-text-input v-bind="field" :errors="errors" type="text" />
          </label>
        </Field>
        <div class="buttons">
          <bf-button size="large" :disabled="false" @click="close"> Cancel </bf-button>
          <bf-button :disabled="!valid || pending" type="submit" color="blue" size="large">
            {{ editMode ? 'Update email alert' : 'Create email alert' }}
          </bf-button>
        </div>
      </VeeForm>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'

import Modal from 'components/Modal.vue'
import { BfButton, BfTextInput } from 'components/Butterfly'
import { Dashboard } from 'src/types/DashboardTypes'
import ProjectAPI from 'src/api/project'
import AlertsAPI from 'src/api/alerts'

export interface FormValues {
  dashboard?: number
  title: string
  description?: string
}

const initialFormValues: FormValues = {
  dashboard: undefined,
  title: '',
  description: '',
}

const AlertsModal = defineComponent({
  components: {
    BfTextInput,
    BfButton,
    Field,
    Modal,
    VeeForm,
  },
  props: {
    dashboardId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      formValues: { ...initialFormValues } as FormValues,
      form: null as InstanceType<typeof VeeForm> | null,
      currentID: null as number | null,
      dashboards: [] as Dashboard[],
      visible: false,
    }
  },
  computed: {
    editMode() {
      return this.dashboardId == null && this.currentID != null
    },
  },
  watch: {
    async visible(value) {
      if (!value) {
        // Delay until after closing animation
        setTimeout(() => {
          this.reset()
          this.currentID = null
          this.dashboards = []
        }, 250)
      } else {
        if (!this.editMode) {
          const dashboards = await ProjectAPI.getDashboards()
          this.dashboards = dashboards.results
        }
      }
    },
  },
  methods: {
    reset(values: FormValues = initialFormValues) {
      this.formValues = { ...values }
      this.form?.resetForm(this.formValues)
    },
    open(id?: number, data?: FormValues) {
      this.currentID = id
      this.visible = true
      this.$nextTick(() => {
        this.reset(data)
      })
    },
    close() {
      this.visible = false
    },
    async submit(values: FormValues) {
      const payload = {
        title: values.title,
        description: values.description ?? '',
        recipients: [],
      }

      if (this.editMode) {
        await AlertsAPI.updateAlert({
          ...payload,
          id: this.currentID,
        })
      } else {
        await AlertsAPI.createAlert({
          ...payload,
          dashboard: values.dashboard!,
        })
      }

      this.$emit('update')
    },
  },
})

export default AlertsModal
</script>
<style lang="sass" scoped>
.buttons
  padding-top: 25px
  text-align: center
  > button:not(:last-child)
    margin-right: 20px

.form
  label
    display: flex
    flex-direction: column
</style>
