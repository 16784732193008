<template>
  <div class="bf-text-input" :style="{ width }">
    <!-- TODO: should these events all just return $event ?-->
    <input
      v-bind="inputAttrs"
      ref="input"
      :class="{ error: hasErrors }"
      :placeholder="placeholder"
      :value="value"
      :type="type"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
      @keyup="$emit('keyup', $event)"
      @change="$emit('change', $event)"
      @update="$emit('update', $event)"
    />
    <div class="message" :class="{ error: hasErrors }">
      <span>{{ errors[0] }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  inheritAttrs: false,
  props: {
    /** text value for input.  Use `v-model` */
    value: { type: [String, Number], required: false, default: '' },
    /** vee-validate3 errors */
    errors: { type: Array, required: false, default: () => [] },
    /** place holder text to display */
    placeholder: { type: String, required: false, default: '' },
    /** physical width of box on screen. */
    width: { type: String, required: false, default: '100%' },
    focus: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'text' },
    /** if true, 1Password will ignore this input element */
    data1pIgnore: { type: Boolean, required: false, default: true },
  },
  computed: {
    hasErrors() {
      return this.errors ? this.errors.length > 0 : false
    },
    inputAttrs() {
      const attrs = { ...this.$attrs }
      if (this.data1pIgnore) {
        attrs['data-1p-ignore'] = true
      }
      return attrs
    },
  },
  watch: {
    focus(val) {
      if (val) {
        this.$refs.input.focus()
      }
    },
  },
  mounted() {
    if (this.focus) {
      setTimeout(() => {
        // It turned out to be insufficient to use $nextTick to focus the input
        // element, because if this input is inside an `el-dialog`, that will
        // steal the focus away. So we need to wait a bit longer.
        // More here: https://github.com/element-plus/element-plus/issues/3786
        if (this.$refs.input) {
          this.$refs.input.focus()
          this.$refs.input.select()
        }
      }, 5)
    }
  },
  methods: {
    focusInput() {
      this.$refs.input.focus()
    },
    selectText() {
      this.$refs.input.select()
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.bf-text-input
  display: flex
  flex-direction: column

input
  border: 1px solid #E5E5E5
  height: 50px
  font-size: 16px
  padding: 17px  15px 13px
  outline: none
  color: $text-black
  font-family: $standard-font
  &:hover
    border-color: #95A6AC
  &:focus
    border-color: $blue
    box-shadow: 0 1px 5px -2px $blue
    &::placeholder
      color: transparent
    &.error
      border-color: $red
      color: $red
      box-shadow: 0 1px 5px -2px $red !important
  .error
    border-color: $red
    color: $red

.message
  padding: 3px 5px 0 5px
  min-height: 23px
  position: relative
.error
  color: $red
</style>
