<template>
  <modal class="delete-theme-modal" :visible="visible" @close="$emit('close')">
    <template #header>
      <div>Delete Theme</div>
    </template>
    <template #content>
      <div>
        <p class="content">
          You are about to delete "{{ selectedQuery.name }}".
          <template v-if="!selectedQuery.is_new">
            <br /><strong>This Theme will no longer be usable on any Dashboards.</strong> <br /><strong
              >This Theme will be removed from any existing Theme it is a part of.</strong
            >
            <br />
            <br /><strong>Affected Themes ({{ deleteConflicts.length }})</strong>
            <div v-for="conflict in deleteConflicts" :key="conflict.id">
              {{ conflict.name }}
            </div>
          </template>
        </p>
      </div>
    </template>
    <template #buttons>
      <div class="modal-actions">
        <bf-button size="large" color="grey" :disabled="isLoading" @click="$emit('close')"> Cancel </bf-button>
        <bf-button size="large" color="red" :disabled="isLoading" @click="doDelete"> Delete </bf-button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { BfButton } from 'components/Butterfly'
import Modal from 'components/Modal.vue'
import { SavedQuery } from 'src/types/Query.types'
import QueryUtils from 'src/utils/query'

const ThemeDeleteModal = defineComponent({
  components: {
    BfButton,
    Modal,
  },
  props: {
    visible: { type: Boolean, required: true },
    selectedQuery: { type: Object as PropType<SavedQuery>, required: true },
    savedQueries: { type: Array as PropType<SavedQuery[]>, required: true },
    deleteTheme: {
      type: Function as PropType<(theme: SavedQuery, conflicts: SavedQuery[]) => Promise<void>>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false)

    const deleteConflicts = computed(() => {
      const conflicts: SavedQuery[] = []
      const id = props.selectedQuery.id.toString()

      props.savedQueries.forEach((query) => {
        const rows = QueryUtils.botanicToQueryRows(query.query_value)
        rows.forEach((row) => {
          if (row.type === 'query' && row.values?.includes(id)) {
            conflicts.push(query)
          }
        })
      })

      return conflicts
    })

    const doDelete = async () => {
      isLoading.value = true
      await props.deleteTheme(props.selectedQuery, deleteConflicts.value)
      isLoading.value = false
    }

    return {
      isLoading,
      deleteConflicts,
      doDelete,
    }
  },
})

export default ThemeDeleteModal
</script>
<style lang="sass" scoped>
::v-deep .el-dialog__body
  text-align: center !important

.content
  white-space: pre-wrap

.modal-actions
  display: flex
  justify-content: center
  margin-top: 30px
  > *:not(:last-child)
    margin-right: 20px
</style>
