<template>
  <div>
    <!-- File selection -->
    <div v-show="!fileSelected">
      <div v-if="!addData">
        <p>For best results, make sure your data follows the <a @click="showTutorial">best practice checklist</a>.</p>
        <p v-if="projectColumnLimit !== null || projectRowLimit !== null">
          <strong>
            Your project cannot have more than
            {{
              [
                // print both with an "or" joiner only if they are both not `null`
                projectColumnLimit !== null && `${formatNumber(projectColumnLimit)} columns`,
                projectRowLimit !== null && `${formatNumber(projectRowLimit)} rows`,
              ]
                .filter(Boolean)
                .join(' or ')
            }}.
          </strong>
        </p>
        <p>
          <strong
            >Your file cannot have more than {{ formatNumber(fileColumnLimit) }} columns or
            {{ formatNumber(fileRowLimit) }} rows.</strong
          >
        </p>
        <p><strong>Your file cannot be larger than 500MB (.csv) or 100MB (.xlsx).</strong></p>
        <p>
          <strong>The column name <em>NPS Category</em> is reserved and cannot be used.</strong>
        </p>
      </div>
      <div v-if="featureFlags && featureFlags.transcription">
        <input ref="fileInput" type="file" accept="allowedExtensions" @change="uploadFile" />
        <button class="ui blue button upload" @click="$refs.fileInput.click()">
          Upload data from .csv, .xls, .xlsx or audio/video file
        </button>
      </div>
      <div v-else>
        <input ref="fileInput" type="file" accept=".csv,.csv*,.xls,.xlsx" @change="uploadFile" />
        <button class="ui blue button upload" @click="$refs.fileInput.click()">
          Upload data from .csv, .xls or .xlsx
        </button>
      </div>
      <div v-if="!addData" class="subtext">Note: Kapiche will use the first sheet in your file.</div>
      <div v-if="invalidFileType" class="error-text">
        File type not supported. Please use a .csv, .xls, .xlsx, .mp3 or .wav file.
      </div>
    </div>

    <!-- Upload activity -->
    <transition enter-active-class="animated fadeIn">
      <div v-if="fileSelected === true">
        <div v-show="fileProgress !== undefined" class="ui basic segment">
          <div class="ui center aligned file-status">
            <div>
              <span class="file-name">{{ fileName }}</span>
              &nbsp;
              <span class="file-size">({{ fileSize }})</span>
            </div>
          </div>
          <!-- Error -->
          <template v-if="uploadError || fileTypeError">
            <div v-if="fileTypeError" class="error-bar">Upload failed - {{ fileTypeError }}</div>
            <div v-else class="error-bar">Upload failed</div>
            <div>
              <span class="clear-upload" @click="uploadFile">Retry upload</span>&nbsp;<strong>OR</strong>&nbsp;<span
                class="clear-upload"
                @click="clearUpload"
                >Choose a different file</span
              >
            </div>
          </template>
          <template v-else-if="fileSizeError">
            <div class="error-bar">Error - {{ fileSizeError }}</div>
            <div class="clear-upload">
              <span @click="clearUpload">Choose a different file</span>
            </div>
          </template>
          <!-- Progress -->
          <template v-else>
            <progressbar
              :percent="fileProgress"
              :size="'xlarge'"
              :active="!uploadFinished"
              :striped="!uploadFinished"
              :color="!uploadFinished ? 'blue' : 'green'"
            >
              <template v-if="uploadFinished" #barLabel> <i class="icon check"></i>Success </template>
              <template v-else-if="fileProgress === 100" #barLabel> Processing... </template>
            </progressbar>
            <div class="clear-upload">
              <span v-if="uploadFinished" @click="clearUpload">Remove and upload a different file</span>
              <span v-else @click="cancelUpload">Cancel upload</span>
            </div>
          </template>
        </div>
        <div v-if="uploadError && uploadErrorDetails" class="error-text data-units-error">
          <strong>Error details:</strong> {{ uploadErrorDetails }}
        </div>
        <!-- Best practice list -->
        <div v-if="!addData" class="best-practice-list">
          <div class="title">Best Practice Checklist</div>
          <div class="point">Field labels indicated by column headers</div>
          <div v-if="uploadFinished" class="status">
            <div v-if="hasMoreRowsThanColumns" class="ok">All good!</div>
            <div v-else class="warning">
              There are more columns than rows. Make sure your column headers indicate fields.
            </div>
          </div>
          <div class="point">Column headers not empty</div>
          <div v-if="uploadFinished" class="status">
            <div v-if="numEmptyHeaders === 0" class="ok">All good!</div>
            <div v-else class="warning">{{ numEmptyHeaders }} empty column headers detected.</div>
          </div>
          <div class="point">Sufficient text data (at least 250 rows)</div>
          <div v-if="uploadFinished" class="status">
            <div v-if="numResponses >= 250" class="ok">All good!</div>
            <div v-else class="warning">
              Less than 250 rows detected. There may not be enough data to provide optimal results.
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Integration -->
    <div v-if="!addData && !fileSelected">
      <div class="ui horizontal divider">OR</div>
      <el-popover :disabled="hasIntegrations" effect="dark">
        <template #default>
          <div>No integrations have been configured.</div>
        </template>
        <template #reference>
          <div class="label">
            <button class="ui button integration" :class="[!hasIntegrations ? 'disabled' : '']" @click="useIntegration">
              Import data from an integration
            </button>
          </div>
        </template>
      </el-popover>
    </div>

    <!-- Data units error -->
    <div v-if="dataUnitsError" class="error-text data-units-error">
      {{ dataUnitsError }}
    </div>
  </div>
</template>
<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { filesize } from 'src/utils/files'
import Progressbar from 'components/widgets/Progressbar.vue'
import Project from 'src/api/project'
import { CLEAR_REQUEST_ERRORS } from 'src/store/types'
import FormatUtils from 'src/utils/formatters'

const TIMER_DELAY = 2500
const INDEXING_STATUS = 'INDEXING'
const ERROR_STATUS = 'ERROR'
// See sc-26125 for why we allow csv\d+
const FILE_TYPE_RE = /(csv|xls|xlsx|mp3|wav|mp4|m4v|flac|m4a|csv\d+)$/i

const DataUpload = defineComponent({
  components: { Progressbar },
  props: {
    addData: { type: Boolean, default: false },
    fileRowLimit: { type: Number, required: true },
    fileColumnLimit: { type: Number, required: true },
    projectRowLimit: { type: Number, required: false, default: null },
    projectColumnLimit: { type: Number, required: false, default: null },
  },
  data() {
    return {
      dataFile: null, // data file placeholder returned by backend
      dataUnitsError: null,
      fileName: '',
      fileProgress: undefined,
      fileSelected: false,
      fileSize: 0,
      fileSizeError: null,
      fileTypeError: null,
      headers: null, // file headers
      invalidFileType: false,
      numResponses: 0,
      samples: null, // data samples
      uploadFinished: false,
      uploadError: false,
      uploadErrorDetails: null,
      uploadRequest: null,
      checkFileStatusRequest: null,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'integrations', 'featureFlags']),
    isAlive() {
      return !this._isDestroyed && !this._isBeingDestroyed
    },
    hasIntegrations() {
      return (this.integrations && Object.keys(this.integrations).length > 0) || this.featureFlags.advantage_integration
    },
    hasMoreRowsThanColumns() {
      return this.numResponses >= this.dataFile?.schema?.length ?? 0
    },
    numEmptyHeaders() {
      return this.headers?.filter((h) => h.length === 0).length
    },
    allowedExtensions() {
      return '.csv,.csv*,.xls,.xlsx,.mp3,.wav,.mp4,.m4v,.flac'
    },
  },
  beforeUnmount() {
    this.cancelUpload()
  },
  methods: {
    formatNumber: FormatUtils.number,
    clearUpload() {
      if (this.periodicTask) {
        clearTimeout(this.periodicTask)
      }
      this.resetFile()
    },
    // Check the status of the uploaded file.
    // This method continually polls the file status until it is ready for indexing.
    async checkFileStatus() {
      if (!this.dataFile) return
      try {
        const result = await Project.fetchFileStatus(this.dataFile.id, (request) => {
          this.checkFileStatusRequest = request
        })
        if (!this.isAlive) return
        if (this.dataFile.id !== result?.id) return
        this.dataFile = result
        if (this.dataFile.status === INDEXING_STATUS) {
          // File has finished processing and ready for indexing
          this.periodicTask = null
          this.getDataSample()
          this.$analytics.track.project.uploadFile(
            result.file_size,
            result.file_name,
            result.records,
            result.schema.length,
          )
        } else if (this.dataFile.status !== ERROR_STATUS) {
          this.periodicTask = setTimeout(this.checkFileStatus, TIMER_DELAY)
        } else if (this.dataFile.status === ERROR_STATUS) {
          if (this.dataFile.status_text.toLowerCase().indexOf('need more data units') >= 0) {
            this.dataUnitsError = this.dataFile.status_text
            this.resetFile()
          } else {
            this.uploadError = true
            this.uploadErrorDetails = this.dataFile.status_text
          }
        }
      } catch (errors) {
        if (!this.isAlive) return
        this.$store.dispatch(CLEAR_REQUEST_ERRORS) // use custom instead of global error handling
        this.uploadError = true
      } finally {
        this.checkFileStatusRequest = null
      }
    },
    // Get sample of the data
    async getDataSample() {
      if (!this.dataFile) return
      try {
        // uses httpretry so cannot be cancelled
        const file = await Project.getFileFromBlobStore(this.dataFile.id)
        if (!this.isAlive) return
        this.headers = file.headers
        this.samples = file.samples
        this.numResponses = file.num_responses
        this.uploadFinished = true
        this.$emit('file-ready', this.dataFile, this.headers, this.samples)
      } catch (errors) {
        if (!this.isAlive) return
        this.$store.dispatch(CLEAR_REQUEST_ERRORS) // use custom instead of global error handling
        this.uploadError = true
      }
    },
    // Clear file and cached attributes
    resetFile() {
      this.periodicTask = null
      this.dataFile = null
      this.fileSelected = false
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null
      }
      this.fileProgress = undefined
      this.uploadFinished = false
      this.numResponses = null
      this.$emit('file-cleared')
    },
    // Run the data tutorial
    showTutorial() {
      this.$emit('show-tutorial')
    },
    cancelUpload() {
      this.uploadRequest?.abort()
      this.checkFileStatusRequest?.abort()
      this.uploadRequest = null
      this.checkFileStatusRequest = null
      this.resetFile()
    },
    // Upload a file to the backend while updating progress indicator
    async uploadFile() {
      this.errorMessage = null
      this.dataUnitsError = null
      this.fileSizeError = null
      this.fileTypeError = null
      this.invalidFileType = false
      this.uploadError = false
      this.uploadErrorDetails = null
      this.fileSelected = true

      // bail if no selected file or no currentUser
      if (!this.$refs?.fileInput?.files?.length || !this.currentUser) {
        this.handleError('No file selected or user not authenticated')
        return
      }
      const file = this.$refs.fileInput.files[0]
      this.fileName = file.name

      if (!this.fileName.match(FILE_TYPE_RE)) {
        this.handleError('Invalid file type')
        return
      }

      this.$emit('file-selected')

      // Formatted file size
      this.fileSize = filesize(file.size)

      const formData = new FormData()
      formData.append('data', file)
      formData.append('user', this.currentUser.id)

      try {
        this.fileProgress = 0
        const response = await this.uploadFileWithRetry(formData)

        if (!this.isAlive) return

        this.dataFile = response
        this.startFileStatusCheck()
      } catch (error) {
        this.handleUploadError(error)
      }
    },
    async uploadFileWithRetry(formData, retries = 3) {
      for (let attempt = 0; attempt < retries; attempt++) {
        try {
          return await Project.uploadFile(
            formData,
            (progress) => {
              this.fileProgress = Math.round((progress.loaded / progress.total) * 100)
            },
            (request) => {
              this.uploadRequest = request
            },
          )
        } catch (error) {
          if (attempt === retries - 1) throw error
          await this.delay(1000 * Math.pow(2, attempt))
        }
      }
    },
    startFileStatusCheck() {
      this.periodicTask = setTimeout(this.checkFileStatus, TIMER_DELAY)
    },
    handleError(message) {
      this.resetFile()
      this.errorMessage = message
    },
    handleUploadError(error) {
      if (!this.isAlive || this.uploadRequest === null) return

      this.$emit('upload-failed')
      this.$store.dispatch(CLEAR_REQUEST_ERRORS)

      const errorMessage = error.body?.data?.[0] as string
      if (errorMessage?.toLowerCase().startsWith('file exceeds')) {
        this.fileSizeError = errorMessage
      } else if (errorMessage?.toLowerCase().startsWith('unsupported file type')) {
        this.fileTypeError = errorMessage
      } else {
        this.uploadError = true
        if (!errorMessage) {
          Sentry.setExtras({
            'file upload progress': this.fileProgress,
            'file name': this.fileName,
            'file size': this.fileSize,
          })
          Sentry.captureException(error)
        }
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    useIntegration() {
      this.$emit('use-integration')
    },
  },
})
export default DataUpload
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.subtext
  color: $text-grey
  margin-top: rem(20px)
.error-text
  margin-top: rem(10px)

a
  cursor: pointer


/* File upload */
input[type="file"]
  display: none
.button.upload
  margin-top: rem(40px)

/* Progress */
.file-status
  .file-name
    font-size: rem(20px)
    font-weight: bold
.file-size
  color: $text-grey
  font-size: rem(16px)
.large.progress, .error-bar, .success-bar
  width: rem(500px)
  margin: rem(15px) auto !important
  .progress
    color: white
.large.progress, .large.progress .bar, .error-bar, .success-bar
  border-radius: 3px
.error-bar, .success-bar
  color: white
  height: rem(40px)
  font-size: rem(16px)
  font-weight: bold
  padding-top: rem(10px)
  text-align: center
  .icon
    font-size: rem(18px)
.error-bar
  background-color: $red
.success-bar
  background-color: $green
.clear-upload
  color: $blue
  cursor: pointer
  font-weight: bold

/* Best practices */
.best-practice-list
  margin-top: rem(30px)
  .title
    font-size: rem(14px)
    font-weight: bold
    letter-spacing: rem(0.7px)
    text-transform: uppercase
  div.point
    color: $text-grey
    margin-top: rem(20px)
  .status
    font-weight: bold
    margin-top: rem(5px)
    .ok
      color: $green
    .warning
      color: $orange

.ui.divider
  color: $text-grey
  margin: rem(25px) auto
  width: rem(200px)
.button.integration
  background-color: transparent
  border: 1px solid $blue
  color: $blue
  &:hover:not(.disabled)
    background-color: $blue
    color: white
  &.disabled
    border-color: $grey
    color: $text-grey
    opacity: 0.5

.data-units-error
  font-size: rem(18px)
  font-weight: normal
  margin-top: rem(40px)
</style>
