<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :is-loading="false"
    :masked="masked"
    :dev-mode="devMode"
    :has-errored="!!false"
    :banner="banner"
    class="themes"
    @resize="width = $event"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <download-export-button
            :name="exportName + '-Themes'"
            :is-loading="isLoading"
            :get-el="getChartEl"
            short-name="Themes"
          ></download-export-button>
          <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
            <i class="kapiche-icon-fullscreen"></i>
          </router-link>
          <a :href="CONST.widget_help_links.themes" class="widget-action help" target="_blank">
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Themes Icon" />
    </template>

    <!--======================== HEADING -->
    <template #header>
      {{ isDrilldown ? 'Co-occurring Themes' : 'Themes' }}
    </template>

    <!--======================== MENU -->
    <template #menu>
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="$el" :max-label-length="35" @onSelect="setSelection" />
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <h2>this.props</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre"
          ><!--
          -->{{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="fetchData()">reloading this widget</button> or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="Intercom('show')">Contact support</button> if the problem persists.
        </div>
        <div v-if="userErrors" class="message">
          {{ userErrors }}
        </div>
      </div>
    </template>

    <template v-if="isLoading" #content>
      <div class="loading-wrapper">
        <bf-spinner />
      </div>
    </template>
    <template v-else-if="groupTree?.length > 0" #content>
      <div ref="wrapper" class="content" @mousemove="updateMouse">
        <div class="tree-row header">
          <div class="row-content">
            <div v-for="(header, i) in headers" :key="header">
              <span :class="['header-name', { sorted: i === sortAttribute }]" @click="toggleSort(i)">
                {{ header }}&nbsp;
                <up-down :up="i === sortAttribute && isAscending" :down="i === sortAttribute && !isAscending" />
              </span>
            </div>
          </div>
        </div>
        <el-tree
          class="theme-tree"
          :data="groupTree"
          :load="lazyLoadNodes"
          lazy
          node-key="key"
          :props="{ isLeaf }"
          :default-expanded-keys="expandedNodeKeys"
          @node-expand="nodeExpanded"
          @node-collapse="nodeCollapsed"
        >
          <template #default="{ node, data }">
            <div
              :class="[
                'tree-row',
                {
                  visible: node.canFocus,
                  group: data.type === 'group',
                  drilldown: isDrilldownNode(data),
                },
              ]"
              @mouseleave="tooltipNode = null"
              @mouseenter="
                (event) => {
                  if (!dropdownVisible && !nameHovered) {
                    tooltipNode = data
                  }
                  updateMouse(event)
                }
              "
            >
              <div v-if="node.expanded" class="side-line" />
              <div class="row-content">
                <div>
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    popper-class="drilldown-menu"
                    @visible-change="dropdownVisible = $event"
                  >
                    <div
                      class="tree-row-name"
                      @click.stop="tooltipNode = null"
                      @mouseenter="nameHovered = true"
                      @mouseleave="nameHovered = false"
                    >
                      {{ data.name }}
                    </div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          @click="
                            data.type === 'theme' ? $emit('go-to-theme', data.id) : $emit('go-to-theme-group', data.id)
                          "
                        >
                          Drill into&nbsp;<b>{{ data.name }}</b>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <div v-if="!columnValueMap[data.id] || node.loading" class="row-spinner">
                    <bf-spinner />
                  </div>
                  <div v-else-if="data.type === 'group'" class="expand-collapse">
                    <icon
                      :size="10"
                      color="#888"
                      name="chevron-right"
                      :class="[
                        'expanded-icon',
                        {
                          expanded: node.expanded,
                        },
                      ]"
                    />
                    <div
                      :class="[
                        'expanded-text',
                        {
                          hidden: nameHovered,
                        },
                      ]"
                    >
                      {{ node.expanded ? 'collapse' : 'expand' }}
                    </div>
                  </div>
                </div>
                <template v-if="columnValueMap[data.id]">
                  <div
                    v-for="(val, i) in columnValueMap[data.id]"
                    :key="`${data.name}_${val}`"
                    :class="{ sorted: i + 1 === sortAttribute }"
                  >
                    {{ val != null ? number(val) : 'N/A' }}{{ isPercent[i] ? '%' : '' }}
                  </div>
                </template>
              </div>
              <div class="progress-wrapper">
                <div class="tree-row-progress" :style="{ maxWidth: `${maxProgressWidth}px` }">
                  <div v-if="hasNegativeValue" class="negative">
                    <div v-if="getSortedVal(data.id) < 0" :style="{ width: `${getRelativePercent(data.id)}%` }" />
                  </div>
                  <div v-if="hasPositiveValue">
                    <div v-if="getSortedVal(data.id) >= 0" :style="{ width: `${getRelativePercent(data.id)}%` }" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-tree>
      </div>
      <floating-panel
        :visible="tooltipNode != null && tooltipData && !isLoadingNodes && !nameHovered"
        :y="mouseY"
        :x="mouseX"
      >
        <data-tool-tip :v-if="tooltipData" v-bind="tooltipData" />
      </floating-panel>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
    <template #footer>
      <div>
        <small>{{ footerText }} </small>
      </div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { cloneDeep, isEqual } from 'lodash'
import { useStore } from 'vuex'

import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import icon from 'assets/img/dashboards/dash-queries.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { Requirements } from 'types/PivotData.types'
import { WidgetMenuOptions } from 'types/components/WidgetMenu.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { SavedQuery } from 'src/types/Query.types'
import { ExpandedGroup, ExpandedQueryOrGroup, Group, GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { getScoreOptionsForRequirements } from './ScoreUtils'
import {
  getAllOptionKeys,
  getHeader,
  makeMenu,
  makeRequirements,
  payloadDataToRows,
  getTooltipData,
} from './ThemeTreeWidget.utils'
import { ThemeGroup } from 'src/api/query'
import { Analysis } from 'src/types/AnalysisTypes'
import { Project } from 'src/types/ProjectTypes'
import {
  findNode,
  findParentNode,
  isNodeContained,
  useFetchData,
} from 'components/project/analysis/results/ThemeBuilder/ThemeBuilder.utils'
import { fetch_pivot_data } from 'src/store/modules/data/api'
import { PivotData } from 'src/pages/trial/Workbench/Workbench.utils'
import BfSpinner from 'components/Butterfly/BfSpinner/BfSpinner.vue'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import Icon from 'components/Icon.vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import DataToolTip from '../DataToolTip/DataToolTip.vue'
import { number } from 'src/utils/formatters'
import { sleep } from 'src/utils/general'

// TODO: This should be moved to a shared location
// but is specific to this widget for now
const { fetch } = useFetchData()
const fetchWidgetData = (
  id: string,
  requirements: Requirements,
  projectId: number,
  chrysalisRef: string,
  topic_framework_id: number,
  filters: ChrysalisFilter[] = [],
  force = false,
) => {
  const fetchParams = [
    {
      id: id,
      projectId: projectId,
      chrysalisRef: chrysalisRef,
      topicId: topic_framework_id,
    },
    requirements,
    filters,
  ]

  const cacheKey = { fetchParams, name: id }
  return fetch<PivotData>(cacheKey, fetch_pivot_data, fetchParams, force)
}

type ElTreeNode = {
  childNodes: ElTreeNode[]
  data: GroupOrTheme
  level: number
}

const getNodeId = (node: GroupOrTheme) => {
  return node.type === 'group' ? `group_${node.id}` : `theme_${node.id}`
}

const ThemesWidget = defineComponent({
  name: 'ThemesWidget',
  components: {
    WidgetFrame,
    WidgetMenu,
    DownloadExportButton,
    BfSpinner,
    UpDown,
    Icon,
    FloatingPanel,
    DataToolTip,
  },
  props: {
    exportName: { type: String, required: false, default: '' },
    isZoomed: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    devMode: { type: Boolean, required: false, default: false },
    isFiltered: { type: Boolean, required: false, default: false },
    hasNps: { type: Boolean, required: false, default: false },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasNumericFields: { type: Boolean, default: false, required: false },
    banner: { type: Object, default: () => null, required: false },
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'themes-concepts'> | null>, required: false, default: null },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    dashboardFilters: { type: Array<ChrysalisFilter>, required: false, default: [] },
    analysis: { type: Object as PropType<Analysis>, required: true },
    project: { type: Object as PropType<Project>, required: true },
    baseQuery: { type: Object as PropType<ExpandedQueryOrGroup | null>, required: false, default: null },
  },
  setup(props) {
    const store = useStore()

    const expandedSavedQueries = computed(() => store.getters.expandedSavedQueries as SavedQuery[])
    const expandedThemeGroups = computed(() => store.getters.expandedThemeGroups as ExpandedGroup[])

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)
    const wrapper = ref<HTMLDivElement>()

    const isLoading = ref(false)
    const isLoadingNodes = ref(false)
    const selectedDisplay = ref('Frequency')
    const width = ref(300)
    const mouseX = ref(0)
    const mouseY = ref(0)
    const tooltipNode = ref<GroupOrTheme | null>(null)
    const groupTree = ref<GroupOrTheme[]>([])
    const columnValueMap = ref<Record<string, number[]>>({})
    const sortAttribute = ref(0)
    const isAscending = ref(true)
    const dropdownVisible = ref(false)
    const nameHovered = ref(false)

    // The data for each node, filtered
    const pivotData = ref<PivotData['payload']>([])
    // The data for each node, unfiltered
    const overallData = ref<PivotData['payload']>([])
    // The data for the drilldown node, filtered
    const drilldownData = ref<PivotData['payload']>([])
    // The data for each node AND drilldown query, filtered
    const drilldownOverlapData = ref<PivotData['payload']>([])

    // Table headers
    const headers = computed(() => {
      const headers = getHeader('THEME/THEME GROUP', selectedDisplay.value, props.isFiltered && !isDrilldown.value)

      return headers
    })

    const isDrilldown = computed(() => {
      return props.baseQuery != null
    })

    // The max value of the currently selected column
    // used to calculate the relative width of the progress bars
    const selectedMaxVal = computed(() => {
      const index = Math.max(0, sortAttribute.value - 1)
      const allColVals = Object.values(columnValueMap.value).map((vals) => Math.abs(vals[index]))
      return Math.max(...allColVals)
    })

    // These dictate how the progress bars are displayed
    const hasNegativeValue = computed(() => {
      const index = Math.max(0, sortAttribute.value - 1)
      const allColVals = Object.values(columnValueMap.value).map((vals) => vals[index])
      return allColVals.some((val) => val < 0)
    })
    const hasPositiveValue = computed(() => {
      const index = Math.max(0, sortAttribute.value - 1)
      const allColVals = Object.values(columnValueMap.value).map((vals) => vals[index])
      return allColVals.some((val) => val >= 0)
    })

    // Fetch coverage stats for a group of tree nodes
    const fetchColumnData = async (nodes: GroupOrTheme[]): Promise<number[][]> => {
      const queryForNode = (node: GroupOrTheme) => {
        return node.type === 'group' ?
            expandedThemeGroups.value.find((group) => group.id === node.id)
          : expandedSavedQueries.value.find((theme) => theme.id === node.id)
      }

      // Convert nodes to queries
      const themeQueries = (includeBase: boolean, matchAll: boolean) =>
        nodes
          .map((node) => {
            const query = queryForNode(node)
            if (!query) return null

            const query_value = {
              includes: [query.query_value],
              type: matchAll ? 'match_all' : 'match_any',
            }

            if (includeBase && props.baseQuery?.query_value) {
              query_value.includes.push(props.baseQuery.query_value)
            }

            return {
              ...query,
              name: getNodeId(node),
              query_value,
            } as SavedQuery
          })
          .filter((query) => query !== null)

      // Build pivtot requirements
      const scoreColumns = props.schema.filter((col) => col.type === 8)
      const scoreOptions = getScoreOptionsForRequirements(selectedDisplay.value, scoreColumns)
      let numericSelected = null
      if (selectedDisplay.value.startsWith('__avg__')) numericSelected = [selectedDisplay.value.slice(7)]
      if (selectedDisplay.value.startsWith('__impact_on_avg__')) numericSelected = [selectedDisplay.value.slice(17)]

      const fetchData = (id: string, queries: SavedQuery[], includeFilters = true) => {
        return fetchWidgetData(
          id,
          makeRequirements(props.hasNps, props.hasSentiment, numericSelected, scoreOptions, queries, false),
          props.project.id,
          props.project.chrysalis_ref,
          props.analysis.topic_framework_id,
          includeFilters ? props.dashboardFilters : [],
        )
      }

      // Filtered pivot data (if filters are applied)
      const filteredRows = await fetchData('themes', themeQueries(false, false))
      pivotData.value = [...pivotData.value, ...filteredRows.payload]

      // Overall pivot data (no filters)
      const overallRows = props.isFiltered ? await fetchData('themes-overall', themeQueries(false, false), false) : null
      if (overallRows) {
        overallData.value = [...overallData.value, ...overallRows.payload]
      }

      // If in drilldown mode, in order to calculate the relative freq %
      // we need to fetch the freq for the drilldown node along with the
      // freq for the drilldown node AND each tree node
      let drilldownRows = null
      let drilldownOverlapRows = null
      if (props.baseQuery && drilldownNode.value) {
        drilldownOverlapRows = await fetchData('themes-overlap-drilldown', themeQueries(true, true))
        if (drilldownOverlapRows) {
          drilldownOverlapData.value = [...drilldownOverlapData.value, ...drilldownOverlapRows.payload]
        }

        drilldownRows = await fetchData('themes-drilldown', [
          {
            ...props.baseQuery,
            name: 'drilldown',
          } as SavedQuery,
        ])
        if (drilldownRows) {
          drilldownData.value = [...drilldownData.value, ...drilldownRows.payload]
        }
      }

      const rows = payloadDataToRows(
        filteredRows.payload,
        overallRows ? overallRows.payload : null,
        drilldownRows ? drilldownRows.payload : null,
        drilldownOverlapRows ? drilldownOverlapRows.payload : null,
        nodes,
        selectedDisplay.value,
        props.isFiltered,
      )

      return rows.map((row) => {
        // Append diff if filtered
        if (props.isFiltered && !isDrilldown.value) {
          return row.concat(row[0] - row[1])
        }
        return row
      })
    }

    // Fetch and store coverage stats for a group of tree nodes
    const setColumnData = async (nodes: GroupOrTheme[]) => {
      const coverageStats = await fetchColumnData(nodes)
      for (const [i, node] of nodes.entries()) {
        columnValueMap.value[node.id] = coverageStats[i]
      }
    }

    // This is called by the tree component to load child nodes when a node is expanded
    const lazyLoadNodes = async (node: ElTreeNode, resolve: (n: GroupOrTheme[]) => void) => {
      isLoadingNodes.value = true

      const setNodeKeys = (nodes: GroupOrTheme[]) => {
        for (const n of nodes) {
          n.key = getNodeId(n)
        }
      }

      // This is the root node
      if (!node.level) {
        // This is assigned before and after the coverage stats are fetched
        // so we can display the nodes with the loading spinner
        setNodeKeys(groupTree.value)
        groupTree.value = sortTreeData(groupTree.value)
        await setColumnData(groupTree.value)
        const topLevel = sortTreeData(groupTree.value)
        groupTree.value = topLevel
        isLoadingNodes.value = false

        // Expand the drilldown group if it exists
        setTimeout(() => {
          if (drilldownNode.value) {
            setExpandedGroup(drilldownNode.value)
          }
        })

        return
      }

      // Find the node in the tree structure and fetch
      // coverage stats for its children
      const treeNode = findNode<Group>(groupTree.value, {
        id: node.data?.id,
        type: 'group',
      })
      const children = treeNode?.children || []
      setNodeKeys(children)
      resolve(sortTreeData(children))
      await setColumnData(children)
      isLoadingNodes.value = false

      // This has to be deferred to ensure the tree has expanded
      // and we can calculate the content height
      setTimeout(() => {
        updateIndentLines()
        maxProgressWidth.value = calcMaxProgressWidth()
      }, 400)
    }

    // Get the value of the sorted column for a node
    const getSortedVal = (id: number) => {
      const index = Math.max(0, sortAttribute.value - 1)
      if (!columnValueMap.value[id]) return 0
      return columnValueMap.value[id][index]
    }

    // Get the relative width of the progress bar for a node
    const getRelativePercent = (id: number) => {
      if (selectedMaxVal.value === 0) return 0
      const val = getSortedVal(id)
      return (Math.abs(val) / selectedMaxVal.value) * 100
    }

    // Determine which colums should be formatted as a percentage
    const isPercent = computed(() => {
      const result = [false, false, false]
      if (
        selectedDisplay.value.match(/^__score(__impact)?__(top|bot)_box/) ||
        selectedDisplay.value.endsWith(' Sentiment') ||
        selectedDisplay.value === 'Frequency (%)'
      ) {
        return [true, true, true]
      }
      if (selectedDisplay.value === 'Frequency') {
        return [false, true, false]
      }
      return result
    })

    const menus = computed((): WidgetMenuOptions[] => {
      if (isDrilldown.value) return []
      return makeMenu(
        props.hasNps,
        selectedDisplay.value,
        props.hasSentiment,
        props.hasNumericFields,
        props.isFiltered,
        props.schema,
      )
    })

    const tooltipData = computed(() => {
      if (tooltipNode.value == null) return null
      return getTooltipData(
        tooltipNode.value,
        pivotData.value,
        overallData.value,
        drilldownData.value,
        drilldownOverlapData.value,
        selectedDisplay.value,
        props.hasNps,
        props.hasSentiment,
        props.isFiltered,
      )
    })

    const footerText = computed(() => {
      const q = props.baseQuery
      return q ?
          `Calculated relative to Theme${q.group ? ' Group' : ''}: ${q.name} (including filters).`
        : 'Calculated relative to overall data (including filters).'
    })

    const toggleSort = (index: number) => {
      if (isLoadingNodes.value) return
      if (sortAttribute.value === index) {
        isAscending.value = !isAscending.value
      } else {
        sortAttribute.value = index
      }
    }

    // Computed property that returns a sorted version of the tree data
    const sortTreeData = (data: GroupOrTheme[]) => {
      return cloneDeep(data).sort((a, b) => {
        let compare = 0
        if (sortAttribute.value === 0) {
          compare = a.name.localeCompare(b.name)
        } else {
          const index = sortAttribute.value - 1
          if (columnValueMap.value[a.id] && columnValueMap.value[b.id]) {
            compare = columnValueMap.value[a.id][index] - columnValueMap.value[b.id][index]
          } else {
            compare = 0
          }
        }
        return isAscending.value ? compare : -compare
      })
    }

    // Update the selected display option if it is no longer valid
    const validateDisplayOption = () => {
      if (!menus?.value?.length) return
      const validatedOptions = getAllOptionKeys(menus.value[0])
      if (!validatedOptions.includes(selectedDisplay.value)) {
        selectedDisplay.value = validatedOptions[0] ?? 'Frequency'
      }
    }

    // Keep track of which nodes are expanded
    const expandedNodeKeys = ref<string[]>([])
    const nodeExpanded = (node: GroupOrTheme) => {
      tooltipNode.value = null
      expandedNodeKeys.value = [...expandedNodeKeys.value, getNodeId(node)]
    }
    const nodeCollapsed = (node: GroupOrTheme) => {
      tooltipNode.value = null
      expandedNodeKeys.value = expandedNodeKeys.value.filter((id) => id !== getNodeId(node))
    }

    // Calculate the maximum allowed width of the progress bar
    const maxProgressWidth = ref(Infinity)
    const calcMaxProgressWidth = () => {
      if (!wrapper.value) return Infinity

      const bars = Array.from(wrapper.value.querySelectorAll<HTMLDivElement>('.tree-row.visible'))
      if (!bars?.length) return Infinity

      return bars.reduce((acc, el) => {
        const width = el.offsetWidth
        return width < acc && width > 0 ? width : acc
      }, Infinity)
    }

    // Initial data fetch
    const fetchData = async () => {
      if (isLoading.value) return
      isLoading.value = true

      // This is awaited at the end to ensure the
      // loading spinner is displayed for at least 300ms
      const minTime = sleep(300)

      groupTree.value = []
      pivotData.value = []
      overallData.value = []
      drilldownData.value = []
      drilldownOverlapData.value = []
      columnValueMap.value = {}
      expandedNodeKeys.value = []
      const { group_tree } = await ThemeGroup.list(props.project.id, props.analysis.id)
      groupTree.value = sortTreeData(group_tree)
      await minTime
      isLoading.value = false
    }

    const setSelection = (menuName: 'Display', field: [string | null, string]) => {
      if (menuName === 'Display') {
        selectedDisplay.value = field[1]
      }
      fetchData()
    }

    const updateMouse = (e: MouseEvent) => {
      mouseX.value = e.clientX
      mouseY.value = e.clientY
    }

    // Update the indent lines when nodes are expanded.
    // This is a bit hacky but el-tree doesn't expose a way to do this.
    const updateIndentLines = () => {
      if (!wrapper.value) return Infinity

      const nodes = Array.from(wrapper.value.querySelectorAll<HTMLDivElement>('.el-tree-node.is-expanded'))
      if (!nodes?.length) return Infinity

      nodes.forEach((node) => {
        const height = node.querySelector<HTMLDivElement>('.el-tree-node__children')!.offsetHeight
        const line = node.querySelector<HTMLDivElement>('.side-line')
        line?.style.setProperty('height', `${height - 16}px`)
        line?.style.setProperty('opacity', '1')
      })
    }

    // The tree node that is currently being drilled into
    const drilldownNode = computed<GroupOrTheme | null>(() => {
      if (!isDrilldown.value) return null
      return findNode(groupTree.value, {
        id: props.baseQuery?.id,
        type: props.baseQuery?.group ? 'group' : 'theme',
      }) as GroupOrTheme
    })

    // Check if a node is, or is contained within, the drilldown node
    const isDrilldownNode = (query: GroupOrTheme) => {
      if (drilldownNode.value) {
        const typeMatch = query.type === 'group' ? props.baseQuery?.group : !props.baseQuery?.group

        return (
          (query.id === props.baseQuery?.id && typeMatch) ||
          isNodeContained<GroupOrTheme>(
            groupTree.value,
            { id: query.id, type: query.type },
            { id: drilldownNode.value.id, type: drilldownNode.value.type },
          )
        )
      }
      return false
    }

    const setExpandedGroup = (targetNode: GroupOrTheme) => {
      expandedNodeKeys.value = []

      // Target node
      let node = findNode<GroupOrTheme>(groupTree.value, { type: targetNode.type, id: targetNode.id })

      // If the target node is a theme, find the parent group
      if (node?.type === 'theme') {
        node = findParentNode(groupTree.value, { id: node.id, type: 'theme' })
      }
      if (!node) return

      // Expand all parent nodes
      do {
        expandedNodeKeys.value.push(getNodeId(node))
        node = findParentNode(groupTree.value, node)
      } while (node)
    }

    watch(
      [columnValueMap, expandedNodeKeys, width],
      () => {
        setTimeout(() => {
          maxProgressWidth.value = calcMaxProgressWidth()
        }, 400)
      },
      {
        deep: true,
      },
    )

    watch(
      expandedNodeKeys,
      () => {
        setTimeout(() => {
          updateIndentLines()
        }, 300)
      },
      {
        deep: true,
      },
    )

    watch(
      () => props.dashboardFilters,
      (newVal, oldVal) => {
        if (!isEqual(oldVal, newVal)) {
          fetchData()
        }
      },
      {
        deep: true,
      },
    )

    // Reset tree state when sorting changes
    watch([sortAttribute, isAscending], async () => {
      groupTree.value = sortTreeData(groupTree.value)
      await setColumnData(groupTree.value)
      const topLevel = sortTreeData(groupTree.value)
      groupTree.value = topLevel
    })

    watch(
      () => props.isFiltered,
      () => {
        validateDisplayOption()
      },
    )

    watch(selectedDisplay, () => {
      fetchData()
    })

    onMounted(() => {
      fetchData()
      validateDisplayOption()
    })

    return {
      getChartEl: () => root.value?.$el.querySelector('div.content'),
      root,
      icon,
      errorIcon,
      refresh: window.location.reload,
      wrapper,
      isLoading,
      menus,
      setSelection,
      width,
      userErrors: null,
      selectedDisplay,
      fetchData,
      Intercom: window.Intercom,
      sortAttribute,
      isAscending,
      lazyLoadNodes,
      groupTree,
      columnValueMap,
      headers,
      number,
      toggleSort,
      isPercent,
      getRelativePercent,
      hasNegativeValue,
      hasPositiveValue,
      getSortedVal,
      isLeaf: (n: GroupOrTheme) => n.type === 'theme',
      updateMouse,
      tooltipNode,
      mouseX,
      mouseY,
      tooltipData,
      maxProgressWidth,
      nodeExpanded,
      nodeCollapsed,
      isLoadingNodes,
      footerText,
      isDrilldown,
      isDrilldownNode,
      expandedNodeKeys,
      dropdownVisible,
      nameHovered,
    }
  },
})

export default ThemesWidget
</script>
<style lang="scss" scoped>
@import '../../../../semantic/dist/semantic.css';
@import '~assets/kapiche.sass';

.empty-message {
  text-align: center;
  color: $subdued;
  font-size: 2rem;
  margin: 40px 0;
}
.error-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding-bottom: 30px;

  button {
    background: none;
    border: none;
    border-bottom: 2px solid $blue;
    padding: 3px 4px;
    &:hover {
      background-color: $grey-light;
    }
    &:focus {
      border: 2px solid $blue-light;
      outline: none;
    }
  }
}
.message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 6px;
  color: $text-black;
  width: 100%;
  max-height: 30px;
  position: absolute;
  bottom: 0;
}

.errorIcon {
  position: relative;
  height: 32px;
  width: 32px;
  display: inline-block;
  top: 10px;
}
.action {
  padding-top: 20px;
}
.default-actions {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.ove-switch {
  margin-top: 10px;
}

.row-spinner {
  :deep(.bf-spinner-container) {
    height: 100%;
    .bf-spinner {
      width: 13px !important;
      height: 13px !important;
      border-width: 2px !important;
      margin-right: 0px;
    }
  }
}

.theme-tree {
  width: 100%;

  :deep {
    .el-icon.el-tree-node__loading-icon.is-loading {
      display: none !important;
    }
    .el-tree-node {
      overflow: hidden;
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: unset;
    }
    .el-tree-node__content {
      height: auto;
      margin-top: 10px;
      &:hover {
        background-color: unset;
      }
    }
    .el-tree-node__expand-icon {
      display: none;
    }
  }
}

.side-line {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 16px;
  width: 2px;
  background: $grey-light;
  opacity: 0;
  transition:
    height 0.3s ease,
    opacity 0.3s ease;
}

.tree-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  cursor: default;

  &:hover {
    color: $blue;
    .tree-row-name {
      color: $blue;
    }
    .expanded-text {
      opacity: 0.5;
    }
  }

  &.group {
    cursor: pointer;
  }

  .sorted {
    color: $blue;
  }

  .row-content {
    width: 100%;
    display: flex;
    height: 20px;

    > div {
      display: flex;
      align-items: center;
    }

    > div:nth-child(1) {
      user-select: none;
      flex: 1;
    }

    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
      min-width: 140px;
      justify-content: flex-end;
      margin-left: 10px;
    }
  }

  &.header {
    user-select: none;
    text-transform: uppercase;
  }
}

.progress-wrapper {
  display: flex;
  justify-content: flex-end;
  $color: #c8c8c8;
  background-image: linear-gradient(
    135deg,
    $grey-light 25%,
    $color 25%,
    $color 50%,
    $grey-light 50%,
    $grey-light 75%,
    $color 75%,
    $color 100%
  );
  background-size: 8.49px 8.49px;
  height: 8px;
  margin-top: 4px;
}

.tree-row-progress {
  width: 100%;
  height: 100%;
  background: $grey-light;
  display: flex;
  transition: max-width 0.3s ease;

  > div {
    height: 100%;
    flex: 1;
    display: flex;
    &.negative {
      justify-content: end;
    }
    > div {
      height: 100%;
      background: $blue-light;
      transition: width 0.3s ease;
    }
  }
}

.drilldown {
  .tree-row-progress {
    > div {
      > div {
        background: #8064aa;
      }
    }
  }
}

.header-name {
  color: $text-grey;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.expand-collapse {
  display: flex;
  align-items: center;
  margin-top: -6px;
  margin-left: 6px;
}

.expanded-icon {
  margin-right: 2px;
  margin-top: 3px;
  transition: transform 0.3s ease;
  transform-origin: 45% center;
  transform: rotate(90deg);
  &.expanded {
    transform: rotate(-90deg);
  }
}

.expanded-text {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-style: italic;
  color: #888;
  font-size: 13px;

  &.hidden {
    opacity: 0 !important;
  }
}

.content {
  width: 100%;
}

.tree-row-name {
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 2px dotted transparent;
  &:hover {
    border-bottom: 2px dotted $blue;
  }
}
</style>
