<template>
  <div v-if="!subscription.domain || !currentSite || !isReady" class="content-wrapper">
    <bf-spinner />
  </div>
  <div v-else class="content-wrapper">
    <component :is="'script'" src="https://fast.wistia.com/embed/medias/khmrdw7ltk.jsonp" async></component>
    <component :is="'script'" src="https://fast.wistia.com/assets/external/E-v1.js" async></component>
    <modal :visible="showBestPractices" @close="showBestPractices = false">
      <template #header>
        <div>Uploading Data</div>
      </template>
      <template #content>
        <div class="modal-body">
          <div class="wistia_responsive_padding" style="padding: 0; position: relative">
            <div
              class="wistia_responsive_wrapper"
              style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
            >
              <div
                class="wistia_embed wistia_async_khmrdw7ltk seo=true videoFoam=true"
                style="height: 100%; position: relative; width: 100%"
              ></div>
            </div>
          </div>
        </div>
      </template>
    </modal>
    <modal :visible="showDateTypeModal" @close="showDateTypeModal = false">
      <template #header>
        <div>Date Format</div>
      </template>
      <template #content>
        <div class="day-modal-content">
          <p>Your data has some dates that we aren't sure about.</p>
          <p>Please tell us whether the day or month comes first:</p>
        </div>
        <div class="day-modal-actions">
          <bf-button color="blue" size="small" style="margin-right: 10px" @click="dateTypeClick(true)">
            Day First
          </bf-button>
          <bf-button color="blue" size="small" @click="dateTypeClick(false)"> Month First </bf-button>
        </div>
      </template>
    </modal>
    <div v-if="formStep === 'schema'" class="schema-wrapper">
      <schema-step
        v-if="filePreview"
        :file-preview="filePreview"
        :schema-types="schemaTypes"
        :column-limit="columnLimit"
        :row-limit="rowLimit"
        :text-limit="textLimit"
        :score-settings="scoreSettings"
        :cog-icon="cogIcon"
        @update-schema-types="schemaTypes = $event"
        @update-score-settings="scoreSettings = $event"
      />
    </div>
    <info-step
      v-if="formStep === 'info'"
      :schema="fileData?.schema"
      :show-errors="showFormErrors"
      @value-change="infoFields = $event"
    />
    <div v-if="formStep === 'upload'" class="upload-wrapper">
      <h2>Create New Project</h2>
      <div class="info-rows">
        <p>
          For best results, make sure your data follows the
          <a @click.prevent="showBestPractices = true"> best practice checklist </a>.
        </p>
        <h4>
          Your file cannot have more than
          {{ subscription.projectColumnLimit }}
          columns and will be truncated to
          {{ subscription.projectRowLimit }}
          rows.
        </h4>
        <h4>The column name <em>NPS Category</em> is reserved and cannot be used.</h4>
      </div>
      <div v-if="uploadError" class="upload-error">
        {{ uploadError }}
      </div>
      <input ref="fileInput" type="file" accept=".csv,.csv*,.xls,.xlsx" @change="uploadFile" />
      <button :class="['upload-button', buttonState.class]" @click="!fileData && $refs.fileInput.click()">
        {{ buttonState.text }}
      </button>
    </div>
    <div v-if="formStep === 'create'" class="create-wrapper">
      <template v-if="createStatus === 'ERROR'">
        <h2>Project creation failed.</h2>
        <div>An error occurred</div>
        <div class="progress-wrapper">
          <progress-bar :progress="createProgress" status="error" />
        </div>
        <div class="step-buttons">
          <bf-button size="large" color="blue" @click="cancelUpload"> Go back </bf-button>
        </div>
      </template>
      <template v-else-if="createStatus === 'READY'">
        <h2>Success! Project created.</h2>
        <div class="progress-wrapper">
          <progress-bar :progress="100" status="success" />
        </div>
        <div class="step-buttons">
          <bf-button size="large" color="blue" @click="goToDashboard"> Finish & Go to Project </bf-button>
        </div>
      </template>
      <template v-else>
        <h2>
          <bf-spinner class="creating-spinner" />
          Creating your project
        </h2>
        <div>{{ createStatusText }}</div>
        <div class="progress-wrapper">
          <progress-bar :progress="createProgress" status="progress" />
        </div>
      </template>
    </div>
    <div v-if="formStep !== 'create'" class="step-buttons">
      <bf-button size="large" color="grey" @click="cancelUpload">
        {{ buttonState.class === 'processing' ? 'Cancel' : 'Back' }}
      </bf-button>
      <bf-button size="large" color="blue" :disabled="!canProceed" @click="nextStep"> Proceed </bf-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount, inject } from 'vue'
import { CancelTokenSource } from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { filesize } from 'src/utils/files'
import { UserProfile } from 'src/types/UserTypes'
import * as ProjectAPI from '../api/project'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { SchemaColumn, SchemaTypeNames } from 'src/types/SchemaTypes'
import { Site } from 'src/store/modules/app'
import { Subscription } from 'src/store/modules/subscription'
import { COLUMN_LABELED_TYPES } from 'src/api/project'
import InfoStep from './InfoStep.vue'
// import { fileDataP, filePreviewP } from './data'
import ProgressBar from './ProgressBar.vue'
import {
  DemoProjectStatus,
  getProjectStatus,
  getProjectStatusText,
  isDateAmbiguous,
  isDayFirstDates,
  detectDateFormat,
} from '../Workbench/trial.utils'
import SchemaStep from './SchemaStep.vue'
import { sleep } from 'src/utils/general'
import Modal from 'components/Modal.vue'
import { Analytics } from 'src/analytics'

interface SchemaOption {
  typename: SchemaTypeNames
  label: string
}

const SCHEMA_OPTIONS: SchemaOption[] = [
  {
    typename: 'IGNORE',
    label: 'Ignore',
  },
  {
    typename: 'TEXT',
    label: 'Text/Verbatim',
  },
  {
    typename: 'NUMBER',
    label: 'Numerical',
  },
  {
    typename: 'DATE',
    label: 'Date',
  },
  {
    typename: 'DATE_TIME',
    label: 'Date & Time',
  },
  {
    typename: 'LABEL',
    label: 'Category',
  },
  {
    typename: 'NPS',
    label: 'NPS (0-10)',
  },
  {
    typename: 'SCORE',
    label: 'Score',
  },
] as const

type FormStep = 'upload' | 'info' | 'schema' | 'create'

export interface ScoreSetting {
  score_range: [number, number]
  score_name: string
  score_aggregation: string
  exclude_out_of_range: boolean
}

interface InfoFields {
  projectDescription: string
  datasetDescription: string
  datasetType: 'survey' | 'other'
  textFieldMetadata: ProjectAPI.TextFieldMetadata[]
  piiRedaction: boolean
  allValid: boolean
}

export default defineComponent({
  name: 'TrialDataUpload',
  components: {
    BfButton,
    BfSpinner,
    InfoStep,
    Modal,
    ProgressBar,
    SchemaStep,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const pageReady = inject<Promise<boolean>>('pageReady')!
    const analytics = inject<Analytics>('analytics')

    const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
    const currentSite = computed(() => store.getters.currentSite as Site)
    const subscription = computed(() => store.getters.subscription as Subscription)
    const featureFlags = computed(() => store.getters.featureFlags as Record<string, boolean>)

    // Element refs
    const fileInput = ref<HTMLInputElement | null>(null)

    // Loading/status state
    const isLoading = ref(false)
    const showBestPractices = ref(false)
    const showDateTypeModal = ref(false)
    const fileSize = ref(0)
    const fileProgress = ref(0)
    const cancelToken = ref<CancelTokenSource | null>(null)
    const pollInterval = ref<ReturnType<typeof setTimeout> | null>(null)
    const createStatus = ref<DemoProjectStatus>('CREATED')
    const dashboardId = ref<number | null>(null)
    const textLimit = 1
    const uploadError = ref<string | null>(null)

    // Form data
    const formStep = ref<FormStep>('upload')
    const infoFields = ref<InfoFields>({
      projectDescription: '',
      datasetDescription: '',
      datasetType: 'survey',
      textFieldMetadata: [],
      piiRedaction: true,
      allValid: false,
    })
    const scoreSettings = ref<ScoreSetting[]>([])
    const dayFirst = ref(false)
    const dateSelectionCallback = ref<(() => void) | null>(null)
    const showFormErrors = ref(false)

    // For testing steps without having to process files
    // const fileData = ref<ProjectAPI.FileData | null>(fileDataP)
    // const filePreview = ref<ProjectAPI.FilePreview | null>(filePreviewP)
    // const schemaTypes = ref<SchemaTypeNames[]>(fileDataP.schema.map((col) => col.typename))
    const fileData = ref<ProjectAPI.FileData | null>(null)
    const filePreview = ref<ProjectAPI.FilePreview | null>(null)
    const schemaTypes = ref<SchemaTypeNames[]>([])

    const uploadFile = async () => {
      uploadError.value = null
      const file = fileInput.value?.files?.[0]

      if (!file || !currentUser.value) {
        console.log('No file selected')
        return
      }

      const validFileRegex = /(csv|xls|xlsx|mp3|wav|mp4|m4v|flac|m4a|csv\d+)$/i
      if (!validFileRegex.test(file.name)) {
        console.log('Invalid file type')
        return
      }

      isLoading.value = true
      fileSize.value = file.size

      const formData = new FormData()
      formData.append('data', file)
      formData.append('user', currentUser.value.id.toString())

      try {
        fileData.value =
          (await ProjectAPI.uploadFile(formData, {
            onBefore: (token) => (cancelToken.value = token),
            onProgress: ({ progress }) => (fileProgress.value = progress ?? 0),
          })) ?? null
        cancelToken.value = null
        if (fileData.value) {
          pollFileStatus()
        }
      } catch {
        isLoading.value = false
      }
    }

    const pollFileStatus = async () => {
      if (!fileData.value) {
        return
      }

      fileData.value = await ProjectAPI.fetchFileStatus(fileData.value.id)
      if (!fileData.value) return

      if (fileData.value.status === 'INDEXING') {
        pollInterval.value = null
        const records = fileData.value.records ?? 0
        if (records > rowLimit.value) {
          fileData.value = null
          uploadError.value = `Row limit exceeded. Please try a file that has ${rowLimit.value} or fewer rows.`
        } else {
          filePreview.value = await ProjectAPI.getFileFromBlobStore(fileData.value.id)
          const schema = fileData.value.schema
          schemaTypes.value = schema.map((col, i) => {
            if (i + 1 > columnLimit.value) {
              return 'IGNORE'
            }
            return col.typename
          })
          scoreSettings.value = []
          nextStep()
        }
        isLoading.value = false
      } else if (fileData.value?.status === 'ERROR') {
        pollInterval.value = null
        isLoading.value = false
      } else {
        pollInterval.value = setTimeout(pollFileStatus, 500)
      }
    }

    const buttonState = computed(() => {
      const status = fileData.value?.status
      if (cancelToken.value || ['UPLOADED', 'PROCESSING', 'INDEXING'].includes(status!)) {
        return {
          class: 'processing',
          text: 'Processing...',
        }
      } else if (status === 'ERROR') {
        return {
          class: 'error',
          text: 'Upload data from .csv, .xls or .xlsx',
        }
      } else {
        return {
          class: '',
          text: 'Upload data from .csv, .xls or .xlsx',
        }
      }
    })

    const cancelUpload = () => {
      if (cancelToken.value) {
        cancelToken.value.cancel('Upload cancelled')
      }
      if (pollInterval.value) {
        clearTimeout(pollInterval.value)
      }

      if (formStep.value === 'upload' && !fileData.value) {
        router.push({ name: 'trial-overview' })
        return
      } else {
        formStep.value = 'upload'
      }

      fileData.value = null
      filePreview.value = null
    }

    // Create dashboard (including project, indexing, analysis)
    const createDashboard = async () => {
      if (!fileData.value || !filePreview.value) {
        return
      }

      const schema = []

      let index = 0
      const schemaValues = Object.values(fileData.value.schema)
      for (let item of schemaValues) {
        const arrIndex = schemaValues.indexOf(item)
        const type = COLUMN_LABELED_TYPES.get(schemaTypes.value[arrIndex])

        // Skip ignored columns
        if (type === -1) {
          continue
        }

        const newCol: Partial<SchemaColumn> = {
          index: index,
          name: item.name,
          type: type,
          ...(scoreSettings.value[arrIndex] ?? {}),
        }

        schema.push(newCol)
        index += 1
      }

      const textFields = schema.filter((col) => COLUMN_LABELED_TYPES.get('TEXT') === col.type).map((col) => col.name)

      const validatedTextFieldMetadata = infoFields.value.textFieldMetadata.filter((meta) =>
        textFields.includes(meta.text_field_name),
      )

      try {
        const res = await ProjectAPI.createTrialProject({
          description: infoFields.value.projectDescription,
          schema: schema,
          chrysalis_ref: fileData.value.id,
          numRecords: filePreview.value.num_responses,
          day_first_dates: dayFirst.value,
          dataset_description: infoFields.value.datasetDescription,
          dataset_type: infoFields.value.datasetType,
          text_field_metadata: validatedTextFieldMetadata,
          pii_clean: infoFields.value.piiRedaction,
        })

        // Poll project status
        dashboardId.value = await checkProjectStatus(res.project_id, (status) => {
          createStatus.value = status
        })
      } catch {
        createStatus.value = 'ERROR'
      }
    }

    // Wait for project/analysis to be ready, return dashboard id
    const checkProjectStatus = async (id: number, onUpdate: (status: DemoProjectStatus) => void) => {
      return new Promise<number | null>((resolve) => {
        const checkStatus = async () => {
          let status: DemoProjectStatus = 'CREATED'
          let dashboard_id: number | null = null
          do {
            await sleep(5000)
            const project = await ProjectAPI.getProject(id)
            status = getProjectStatus(project)
            dashboard_id = project.analyses[0]?.dashboard_ids?.[0] ?? null
            onUpdate(status)
          } while (!['READY', 'ERROR'].includes(status))
          resolve(dashboard_id)
        }
        checkStatus()
      })
    }

    const nextStep = () => {
      if (formStep.value === 'upload') {
        formStep.value = 'info'
      } else if (formStep.value === 'info') {
        if (infoFields.value.allValid) {
          formStep.value = 'schema'
        } else {
          showFormErrors.value = true
        }
      } else if (formStep.value === 'schema') {
        checkForAmbiguousDates(() => {
          analytics?.track.trial.uploadedFile(
            fileData.value?.records ?? 0,
            fileData.value?.schema.length ?? 0,
            infoFields.value.datasetDescription,
            infoFields.value.datasetType,
          )
          formStep.value = 'create'
          createDashboard()
        })
      }
    }

    const checkForAmbiguousDates = (callbackFn: () => void) => {
      const schema = fileData.value?.schema
      if (!schema || !filePreview.value) return

      // Find all date fields in schema
      const dateFields = Object.values(schema).filter((entry) => entry.typename.startsWith('DATE'))

      if (dateFields.length > 0) {
        for (const field of dateFields) {
          // Get sample values for this date field
          const values = filePreview.value.samples.map((sample) => sample[field.index]).filter(Boolean) // Remove null/undefined values

          // First check if all dates in this field are ambiguous
          const allAmbiguous = values.every(isDateAmbiguous)

          if (allAmbiguous) {
            // Store callback and show modal for user to select format
            dateSelectionCallback.value = callbackFn
            showDateTypeModal.value = true
            return // Stop processing - wait for user input
          }

          // If not all ambiguous, try to auto-detect format
          const detectedFormat = detectDateFormat(values)

          if (detectedFormat === 'DMY') {
            dayFirst.value = true
          } else if (detectedFormat === 'MDY') {
            dayFirst.value = false
          } else if (detectedFormat === 'YMD') {
            // For YMD format, no ambiguity possible
            dayFirst.value = false
          } else {
            // If format is UNKNOWN and we have values, better ask user
            if (values.length > 0) {
              dateSelectionCallback.value = callbackFn
              showDateTypeModal.value = true
              return
            }
          }
        }
      }

      // If we got here, either no date fields or all formats were detectable
      callbackFn()
    }

    const columnLimit = computed(() => {
      return subscription.value?.projectColumnLimit ?? 5
    })

    const rowLimit = computed(() => {
      return subscription.value?.projectRowLimit ?? 5000
    })

    const columnCount = computed(() => {
      return schemaTypes.value.filter((type) => type !== 'IGNORE').length
    })

    const textCount = computed(() => {
      return schemaTypes.value.filter((type) => type === 'TEXT').length
    })

    const canProceed = computed(() => {
      if (formStep.value === 'info') {
        // We allow the button to be clicked but prevent
        // proceeding in nextStep if the form is invalid
        return true
      }
      if (formStep.value === 'schema') {
        const columnOkay = columnCount.value >= 1 && columnCount.value <= columnLimit.value
        const textOkay = textCount.value >= 1 && textCount.value <= textLimit
        return columnOkay && textOkay
      }
      return false
    })

    const goToDashboard = () => {
      if (dashboardId.value) {
        router.push({
          name: 'trial-results',
          params: {
            dashboardId: dashboardId.value,
          },
        })
      }
    }

    const getSchemaLabel = (name: string) => {
      return SCHEMA_OPTIONS.find((option) => option.typename === name)?.label ?? 'N/A'
    }

    const dateTypeClick = (isDayFirst: boolean) => {
      dayFirst.value = isDayFirst
      dateSelectionCallback.value?.()
      showDateTypeModal.value = false
    }

    const createStatusText = computed(() => {
      return getProjectStatusText(createStatus.value)
    })

    const createProgress = computed<number>(() => {
      switch (createStatus.value) {
        case 'CREATED':
          return 10
        case 'INDEXING':
          return 30
        case 'ANALYZING':
          return 70
        case 'READY':
          return 100
        default:
        case 'ERROR':
          return 0
      }
    })

    const isReady = ref(false)

    onBeforeMount(async () => {
      await pageReady
      if (!currentUser.value?.trialing || !featureFlags.value.allow_trial_create) {
        router.push({ name: 'trial-overview' })
      } else {
        isReady.value = true
      }
    })

    return {
      isReady,
      fileInput,
      uploadFile,
      fileSize: computed(() => filesize(fileSize.value)),
      buttonState,
      fileData,
      cancelUpload,
      filePreview,
      SCHEMA_OPTIONS,
      schemaTypes,
      formStep,
      isLoading,
      nextStep,
      canProceed,
      createProgress,
      goToDashboard,
      columnLimit,
      createStatus,
      subscription,
      showBestPractices,
      currentSite,
      getSchemaLabel,
      rowLimit,
      scoreSettings,
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      cogIcon: require('../assets/cog.svg'),
      infoFields,
      dayFirst,
      dateSelectionCallback,
      showDateTypeModal,
      dateTypeClick,
      columnCount,
      textCount,
      textLimit,
      createStatusText,
      uploadError,
      showFormErrors,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.progress-wrapper {
  width: 400px;
  text-align: center;
  margin-top: 20px;
  :deep {
    .el-progress-bar__outer {
      height: 40px !important;
      border-radius: 0;
    }
    .el-progress-bar__inner {
      border-radius: 0;
    }
  }
}

.upload-wrapper {
  max-width: 600px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  input[type='file'] {
    display: none;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }
}

.upload-button {
  background: $blue;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px;
  font-size: 17px;

  &.success {
    background: $green;
  }

  &.processing {
    $size: 8px;
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(#fff, 0.1),
      rgba(#fff, 0.1) $size,
      $blue $size,
      $blue calc(2 * $size)
    );
    background-size: 200% 200%;
    animation: barberpole 10s linear infinite;
  }
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}

.step-buttons {
  margin-top: 60px;

  > * {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.create-wrapper,
.schema-wrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-rows {
  h4 {
    font-weight: bold;
  }
  > * {
    margin: 0;
    font-size: 16px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

a {
  color: $blue;
  text-decoration: none;
  border-bottom: 2px dotted $blue;
  &:hover {
    text-decoration: none;
    color: $blue-light;
  }
}

.header-controls {
  display: flex;
  align-items: center;
}

.creating-spinner > :deep(.bf-spinner) {
  width: 20px;
  height: 20px;
}

.day-modal-content {
  text-align: center;
  color: black;
  font-size: 18px;
  line-height: 1.75;

  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.day-modal-actions {
  text-align: center;
  margin-top: 30px;

  .bf-button {
    font-size: 20px;
    font-weight: bold;

    &.bf-button--grey {
      color: #989898;
    }
  }
}

.upload-error {
  color: $red;
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
}
</style>
