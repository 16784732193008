<template>
  <modal :visible="visible" @close="close">
    <template #header>
      <div v-if="title">
        {{ title }}
      </div>
    </template>
    <template #content>
      <div class="center">
        <slot name="default" />
      </div>
    </template>
    <template #buttons>
      <bf-button size="huge" color="grey" @click="close">
        {{ cancelText }}
      </bf-button>
      <div class="spacer" />
      <bf-button size="huge" color="blue" @click="confirm">
        {{ confirmText }}
      </bf-button>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BfButton } from 'components/Butterfly'
import Modal from 'components/Modal.vue'

const ModalConfirmPrompt = defineComponent({
  components: { Modal, BfButton },
  props: {
    visible: { type: Boolean, required: true },
    confirmText: { type: String, required: false, default: 'Confirm' },
    cancelText: { type: String, required: false, default: 'Cancel' },
    title: { type: String, required: false, default: '' },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
})
export default ModalConfirmPrompt
</script>

<style lang="sass" scoped>
@import 'assets/kapiche'
</style>
