<template>
  <modal :visible="visible" @close="() => submit(false)">
    <template #header>
      <div>
        {{ title }}
      </div>
    </template>
    <template #content>
      <div>
        <slot v-if="!body" />
        <template v-else>
          {{ body }}
        </template>
      </div>
    </template>
    <template #buttons>
      <div class="modal-actions">
        <bf-button color="grey" size="large" @click="() => submit(false)"> Cancel </bf-button>
        <bf-button color="blue" size="large" @click="() => submit(true)"> Confirm </bf-button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import Modal from 'components/Modal.vue'
import { BfButton } from 'components/Butterfly'

const ThemeNameModal = defineComponent({
  components: {
    BfButton,
    Modal,
  },
  props: {
    title: { type: String, required: true },
    body: { type: String, required: false, default: '' },
  },
  setup() {
    const visible = ref(false)
    const resolve = ref<null | ((v: boolean) => void)>(null)

    // To be used via a ref in the parent component
    // e.g const res = await refs.confirmModal.value.confirm()
    const confirm = () => {
      return new Promise<boolean>((res) => {
        resolve.value = res
        visible.value = true
      })
    }

    const submit = (val: boolean) => {
      visible.value = false
      resolve.value?.(val)
    }

    return {
      visible,
      confirm,
      submit,
    }
  },
})

export default ThemeNameModal
</script>
<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.modal-actions
  display: flex
  justify-content: center
  margin-top: 30px
  > *:not(:last-child)
    margin-right: 20px

.content
  text-align: center
</style>
